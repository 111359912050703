import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";
import { useState, useEffect } from "react";
import { TabProvider } from "../hooks/TabContext";
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';

export const ProtectedLayout = () => {
  const { user, hasPermission} = useAuth();
  const outlet = useOutlet();
  const location = useLocation(); // get current route location
  const [currentPath, setCurrentPath] = useState(location.pathname); // initialize currentPath with current route location

  if (!user) {
    return <Navigate to="/" />;
  }
  

  return (
    <TabProvider>
      <div>
        <AppBar
          pages={[
            { label: "Start", path: "UserStart" },
            { label: "Suche", path: "KundenSuchePage" },
            { label: "Kunde", path: "UserKundenübersicht/:partnernummer" },
            ...(window.chrome && window.chrome.webview && isDesktop
              ? []
              : [{ label: "Schaden", path: "SchadenübersichtPage" }])
          ]}
          currentPath={currentPath} // pass current path to AppBar
          setCurrentPath={setCurrentPath} // pass setCurrentPath to AppBar
        />
        {outlet}
      </div>
    </TabProvider>
  );
};