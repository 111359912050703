import React, { useEffect, useState } from 'react';
import { IconButton, Popover, Grid, Avatar, Typography, Divider, Tooltip } from '@mui/material';
import { BlockSharp, CheckCircleOutlineSharp } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/de';
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from "../hooks/useAuth";
import { useDispatch, useSelector } from 'react-redux';
import { refreshuserstatus } from '../hooks/reduxactions';

moment.locale('de');

const UserActivityPopover = ({ open, anchorEl, onClose }) => {
    const useractivitydata = useSelector(state => state.currentuserstatus);
    const [isInConversation, setIsInConversation] = useState(false);
    const { user } = useAuth();
    const dispatch = useDispatch();

    const getStatusColor = (status) => {
        const lastActiveTime = moment(status.lastActive);
        const oneMinuteAgo = moment().subtract(60, 'seconds');

        if (!lastActiveTime || lastActiveTime.isBefore(oneMinuteAgo)) {
            return "#ff6d75"; // User is offline if last active time is more than 60 seconds ago
        } else {
            switch (status.onlinestatus) {
                case 'im Gespräch':
                    return "#c497ff";
                case 'verfügbar':
                    return "#14a37f"; // Green color for verfügbar
                case 'afk':
                    return "#3e91c1"; // Yellow color for AFK
                default:
                    return grey[500]; // Grey color if status is unknown
            }
        }
    };

    const handleToggle = () => {
        setIsInConversation(prevState => {
            let isinconversation;
            if (!prevState) {
                isinconversation = "im Gespräch";
            } else {
                isinconversation = "verfügbar";
            }

            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    updateStatusActivityInConversation: 1,
                    vollstaendigerName: user.vollstaendigerName,
                    inconversation: isinconversation,
                    lastActivebeforeAfk: moment().format('YYYY-MM-DD HH:mm:ss'),
                }),
            })
                .then(response => response.json())
                .then(data => {
                    dispatch(refreshuserstatus());
                })
                .catch(err => console.error("Error fetching user statuses", err));

            return !prevState;
        });
    };

    const setStatus = (status) => {
        const lastActiveTime = moment(status.lastActive);
        const oneMinuteAgo = moment().subtract(60, 'seconds');

        if (!lastActiveTime || lastActiveTime.isBefore(oneMinuteAgo)) {
            return "offline";
        } else {
            switch (status.onlinestatus) {
                case 'verfügbar':
                    return "verfügbar";
                case 'afk':
                    return "abwesend";
                case 'im Gespräch':
                    return "im Gespräch";
                default:
                    return "N/A";
            }
        }
    };

    // Sort user activity data
    const sortedUserActivityData = Array.isArray(useractivitydata)
    ? useractivitydata.filter(user => user.invisible !== 1).sort((a, b) => {
        const now = moment();
        const oneMinuteAgo = now.clone().subtract(1, 'minutes');

        const isOnlineA = moment(a.lastActive).isAfter(oneMinuteAgo) && a.lastActivebeforeAfk === null;
        const isOnlineB = moment(b.lastActive).isAfter(oneMinuteAgo) && b.lastActivebeforeAfk === null;

        const isInConversationA = a.onlinestatus === 'im Gespräch';
        const isInConversationB = b.onlinestatus === 'im Gespräch';

        const isAFKA = a.onlinestatus === 'afk';
        const isAFKB = b.onlinestatus === 'afk';

        const isOfflineA = moment(a.lastActive).isBefore(oneMinuteAgo);
        const isOfflineB = moment(b.lastActive).isBefore(oneMinuteAgo);

        // Prioritize online users
        if (isOnlineA && !isOnlineB) return -1;
        if (!isOnlineA && isOnlineB) return 1;

        // Prioritize "im Gespräch" users
        if (isInConversationA && !isInConversationB) return -1;
        if (!isInConversationA && isInConversationB) return 1;

        // Prioritize AFK users
        if (isAFKA && !isAFKB) return -1;
        if (!isAFKA && isAFKB) return 1;

        // Sort offline users by lastActive in descending order
        if (isOfflineA && isOfflineB) {
            return moment(b.lastActive).diff(moment(a.lastActive));
        } else if (isOfflineA) {
            return 1;
        } else if (isOfflineB) {
            return -1;
        }

        return 0;  // Fallback if none of the above conditions apply
    })
    : [];

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div style={{ width: "300px", maxHeight: "705px", overflowY: "auto", padding: "10px" }}>
                <span style={{ fontSize: "14px", marginLeft: "10px" }}><b>Benutzerübersicht</b></span>
                <Tooltip title={isInConversation ? "Nicht stören deaktivieren" : "Nicht stören aktivieren"}>
                    <IconButton sx={{ marginLeft: "5px" }} size='small' onClick={handleToggle}>
                        {isInConversation ? (
                            <BlockSharp sx={{ color: "#ff6d75" }} />
                        ) : (
                            <CheckCircleOutlineSharp sx={{ color: "#14a37f" }} />
                        )}
                    </IconButton>
                </Tooltip>
                <Divider sx={{ marginTop: "5px" }} />
                <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    {sortedUserActivityData.map(user => (
                        <Grid item key={user.kürzel} className='customactivityitem'>
                            <Tooltip title={
                                <div>
                                    <span>{user.vollstaendigerName}</span><br></br><br></br>
                                    <span>Aktueller Status:</span><span style={{
                                        color: setStatus(user) === "verfügbar" ? "#61caaf"
                                            : setStatus(user) === "abwesend" ? "#76adff"
                                                : setStatus(user) === "offline" ? "#ff6d75"
                                                    : setStatus(user) === "im Gespräch" ? "#c497ff"
                                                        : "#fff"
                                    }}><br></br>{setStatus(user)}</span><br></br><br></br>
                                    Zuletzt aktiv:<br />
                                    {user.lastActivebeforeAfk === null || setStatus(user) === "verfügbar" ?
                                        moment(user.lastActive).fromNow() :
                                        moment(user.lastActivebeforeAfk).fromNow()
                                    }
                                </div>
                            }>
                                <Avatar style={{ marginBottom: "5px", color: '#fff', backgroundColor: getStatusColor(user) }}>
                                    {user.kürzel}
                                </Avatar>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Popover>
    );
};

export default UserActivityPopover;
