import { PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Height } from '@mui/icons-material';
import {
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Divider,
    Modal,
    Typography,
    Select, 
    InputLabel,
    FormControl,
    Fade,
    Backdrop,
    FormHelperText,
    Grid,
    Popover,
    Switch,
    Toolbar
} from '@mui/material';
import moment from 'moment';
import { useState, useEffect, useRef } from "react";
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from "../hooks/useAuth";
import { globalAppBarMessage } from '../hooks/reduxactions';
import { useDispatch } from 'react-redux';

const style = {
    width: '500px',
    maxHeight: '500px',
    overflowY: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none !important',
    boxShadow: 24,
    p: 4,

};

const contentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    flexWrap: 'wrap',
    wordBreak: 'break-word'
};

const infosContainerStyle = {
    marginTop: '20px',
    maxHeight: '450px',
    paddingBottom: '15px',
    overflowY: 'auto',
};

export default function BenutzerInfoModal({ openModal, setopenModal, rowDATA, refreshdata }) {

    const handleClose = () => setopenModal(false);
    const [userGroup, setUserGroup] = useState([]);
    const { user, setUser } = useAuth();
    const dispatch = useDispatch();

    const handleGroupChange = (event, newGroups) => {

        if ((userGroup.includes("Serveradmin") && !newGroups.includes("Serveradmin")) || (!userGroup.includes("Serveradmin") && newGroups.includes("Serveradmin"))) {
            dispatch(globalAppBarMessage(`Dieser Status kann nur in der Datenbank geändert werden.`));
            return;
        } 

        if (user.berechtigungen.includes("Applikationsadmin") && !user.berechtigungen.includes("Serveradmin"))
        {
            if (userGroup.includes("Applikationsadmin") && !newGroups.includes("Applikationsadmin")) {
                dispatch(globalAppBarMessage(`Du bist bereits <b>Applikationsadmin</b>. Dieser Status kann dir nur von dem <b>Serveradmin</b> genommen werden.`));
                return;
            } 
        }

        if (user.berechtigungen.includes("Applikationsadmin") || user.berechtigungen.includes("Serveradmin"))
        {

            setUserGroup(newGroups);

            if (userGroup !== newGroups) {
                
                if (user.vollstaendigerName === rowDATA.vollstaendigerName) {
                  setUser({ ...user, berechtigungen: newGroups });
                }
    
                fetch(`${fetchdataUrl}fetchdata.php`, {
                  method: 'POST',
                  body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchSetUserBerechtigungen: 1,
                    vollstaendigerName: rowDATA.vollstaendigerName,
                    berechtigungen: newGroups
                  }),
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                  .then(response => response.json())
                  .then(data => {
                        // console.log(data)
                        // console.log(user.berechtigungen)
                })
                  .catch(error => {
                    console.log('Error: ' + error.message);
                  });
            }

            refreshdata();
        }
        else
        {
            dispatch(globalAppBarMessage(`Du hast hierzu keine Berechtigung. Nur <b>Serveradmins</b> oder <b>Applikationsadmins</b> können die Benutzerberechtigungen ändern.`));
        }
    };

    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAlleUser: 1,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            const filteredData = data.find(user => user.id === rowDATA.id);

            if (filteredData && filteredData.berechtigungen) {
                if (typeof filteredData.berechtigungen === 'string' && filteredData.berechtigungen.trim() !== '') {
                    try {
                        const groupsArray = JSON.parse(filteredData.berechtigungen);
                        if (Array.isArray(groupsArray)) {
                            setUserGroup(groupsArray);
                        } else {
                            setUserGroup([]); 
                        }
                    } catch (error) {
                        setUserGroup([]);
                    }
                } else {
                    setUserGroup([]);
                }
            } else {
                setUserGroup([]);
            }
        })
        .catch(error => {
            console.error('Error fetching user groups:', error);
        });    
    }, [rowDATA]); 

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={openModal}>
                    <Box sx={style}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                                <span><b>Übersicht:</b></span>
                            </div>
                        </div>
                        <Divider sx={{ marginTop: "5px" }}></Divider>
                        <div style={infosContainerStyle}>
                            <div style={contentStyle}>
                                <span><b>Benutzername:</b></span><span>{rowDATA.username}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Vollständiger Name:</b></span><span>{rowDATA.vollstaendigerName}</span>
                            </div>
                            <Divider sx={{ marginTop: "5px" }} />
                            <div style={{ marginTop: "10px" }}>
                                <span><b>Berechtigungen:</b></span>
                                <ToggleButtonGroup
                                sx={{marginTop: "10px"}}
                                value={userGroup}
                                color="primary"
                                onChange={handleGroupChange}
                                aria-label="user groups"
                                orientation="vertical"
                                fullWidth
                            >
                                <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ToggleButton value="Serveradmin" aria-label="serveradmin" fullWidth>
                                    Serveradmin
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Applikationsadmin" aria-label="Applikationsadmin" fullWidth>
                                    Applikationsadmin
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Admin" aria-label="admin" fullWidth>
                                    Admin
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Schadenbearbeiter" aria-label="schadenbearbeiter" fullWidth>
                                    Schadenbearbeiter
                                    </ToggleButton>
                                </Grid>  
                                <Grid item xs={6}>
                                    <ToggleButton value="Schadenbearbeitergruppe" aria-label="schadenbearbeitergruppe" fullWidth>
                                    Schadenbearbeitergruppe
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Zeiterfassungsadmin" aria-label="zeiterfassungsadmin" fullWidth>
                                    Zeiterfassungsadmin
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Zeiterfassungsbearbeiter" aria-label="zeiterfassungsbearbeiter" fullWidth>
                                    Zeiterfassungsbearbeiter
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Benutzeraktivitäten" aria-label="Benutzeraktivitäten" fullWidth>
                                    Benutzeraktivitäten
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ToggleButton value="Urlaubskoordinator" aria-label="Urlaubskoordinator" fullWidth>
                                    Urlaubskoordinator
                                    </ToggleButton>
                                </Grid>
                                </Grid>
                            </ToggleButtonGroup>
                            </div>
            
                            {/* <div style={contentStyle}>
                                <span><b>Uhrzeit:</b></span><span>{rowDATA.timestamp}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Aktionsbeschreibung:</b></span><span>{rowDATA.description}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Partnernummer:</b></span><span>{rowDATA.partnernummer}</span>
                            </div>
                            <Typography sx={{ overflowWrap: "anywhere", overflowX: "hidden" }} variant="body1" gutterBottom>
                                {rowDATA.infos}
                            </Typography> */}
                        </div>
                    </Box>
            </Fade>
        </Modal>
    );
}
