import React, { useEffect, useState, useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFullScreenButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
} from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { fetchdataUrl } from '../A-config/config';
import { Button, TextField, Popover, Stack, Box, Tooltip, IconButton, Checkbox } from '@mui/material';
import { PlaylistAddCheckSharp, CarCrashRounded, UnpublishedSharp, PrintSharp, EventAvailableRounded, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star, DeleteSharp} from '@mui/icons-material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { useNavigation } from '../hooks/useNavigateFunction';
import { debounce } from 'lodash';

const queryClient = new QueryClient();

const SchadenÄnderungTableServerside = () => {
    const { user, hasPermission } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { navigateToCustomer } = useNavigation();
    const newChangesCount = useSelector(state => state.änderungcount);
    const lastCheckTime = localStorage.getItem('lastCheckSchadenänderungen') || '1970-01-01 00:00:00';

    const [columnVisibility, setcolumnVisibility] = useState(() => {
        try {
            const visibilityString = localStorage.getItem('columnVisibilitySchadenÄnderungTable');
            return JSON.parse(visibilityString) || { ID: false };
        } catch (e) {
            console.error(e);
            return { ID: false };
        }
    });

    useEffect(() => {
        localStorage.setItem('columnVisibilitySchadenÄnderungTable', JSON.stringify(columnVisibility));
    }, [columnVisibility]);

    const handleColumnVisibilityChange = (updater) => {
        setcolumnVisibility(updater);
    };

    const defaultColumnWidthsTermine = 90;
    let storageItemTermine = localStorage.getItem('columnWidthsSchadenÄnderungTable');
    let columnWidthsFromLocalStorage;

    if (storageItemTermine === "undefined" || !storageItemTermine) {
        columnWidthsFromLocalStorage = defaultColumnWidthsTermine;
    } else {
        columnWidthsFromLocalStorage = JSON.parse(storageItemTermine);
    }

    const [columnSizingState, setcolumnSizingState] = useState(columnWidthsFromLocalStorage);

    const handleColumnSizingChange = (newColumnSizingState) => {
        const newState = newColumnSizingState(columnSizingState);
        localStorage.setItem('columnWidthsSchadenÄnderungTable', JSON.stringify(newState));
        setcolumnSizingState(newState);
    };

    const [contentHeight, setContentHeight] = useState(() => {
        const savedHeight = localStorage.getItem('contentHeightUserStart');
        return savedHeight ? parseInt(savedHeight) : 230;
    });

    const calculateContentHeightAndPageSize = () => {
        const windowHeight = window.innerHeight - 250;
        localStorage.setItem('contentHeightUserStart', windowHeight.toString());
        setContentHeight(windowHeight);
    };

    useEffect(() => {
        calculateContentHeightAndPageSize();
        window.addEventListener('resize', calculateContentHeightAndPageSize);
        return () => {
            window.removeEventListener('resize', calculateContentHeightAndPageSize);
        };
    }, []);   

    const [nureigeneanzeigen, setnureigeneanzeigen] = useState(localStorage.getItem('setnureigeneanzeigenSchadenÄnderung') === 'true');
    const handlenureigeneanzeigen = (event) => {
        const isChecked = event.target.checked;
        setnureigeneanzeigen(isChecked);
        localStorage.setItem('setnureigeneanzeigenSchadenÄnderung', isChecked);
    };

    const [showOnlyNewChanges, setShowOnlyNewChanges] = useState(localStorage.getItem('setnurneueChangesSchadenÄnderung') === 'true');
    const handleShowOnlyNewChanges = (event) => {
        const isChecked = event.target.checked;
        setShowOnlyNewChanges(isChecked);
        localStorage.setItem('setnurneueChangesSchadenÄnderung', isChecked);
    };

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });

    const {
        data: { data = [], meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            nureigeneanzeigen,
            showOnlyNewChanges,
        ],
        queryFn: async () => {

            const requestBody = {
                access_token: sessionStorage.getItem("access_token"),
                fetchRetrieveSchadenänderung: 1,
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters,
                globalFilter: globalFilter,
                sorting: sorting,
                nureigeneanzeigen: nureigeneanzeigen,
                username: user.vollstaendigerName,
                lastCheckDate: showOnlyNewChanges ? lastCheckTime : null,
            };

            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const json = await response.json();
            return json;
        },
        placeholderData: keepPreviousData,
    });

    const columns = useMemo(() => [       
        { accessorKey: 'ID', header: 'ID' },
        {
            header: 'Änderungsdatum',
            accessorKey: 'change_date',
            enableResizing: false,
            minSize: 80,
            size: 105,
            Cell: ({ cell }) => {
                const newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY - HH:mm:ss");
                return <span>{newdate}</span>;
            },
        },
        {
            header: 'Änderung von',
            accessorKey: 'bearbeiter_change',
            enableResizing: false,
            minSize: 60,
            size: 80,          
        },
        {
            header: 'Art der Änderung',
            accessorKey: 'aenderung',
            enableResizing: false,
            minSize: 60,
            size: 100,          
        },
        {
            header: 'Name',
            accessorFn: row => {
                const data = JSON.parse(row.data);
                return data.Name;
            },
            enableResizing: false,
            minSize: 80,
            size: 80,
        },
        {
            header: 'Vorname',
            accessorFn: row => {
                const data = JSON.parse(row.data);
                return data.Vorname;
            },
            enableResizing: false,
            minSize: 80,
            size: 80,
        },
        {
            header: 'Sparte',
            accessorFn: row => {
                const data = JSON.parse(row.data);
                return data.Sparte;
            },
            enableResizing: false,
            minSize: 80,
            size: 80,
        },
        {
            header: 'Schadennummer',
            accessorFn: row => {
                const data = JSON.parse(row.data);
                return data.Schadennummer;
            },
            enableResizing: false,
            minSize: 80,
            size: 80,
        },
        {
            header: 'Bearbeiter',
            accessorFn: row => {
                const data = JSON.parse(row.data);
                return data.Bearbeiter;
            },
            enableResizing: false,
            minSize: 80,
            size: 80,
        },
        
    ], []);

    const tableInstance = useMaterialReactTable({
        columns,
        data,
        state: {
            columnSizing: columnSizingState,
            columnVisibility: columnVisibility,
            isLoading,
            isError,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
            showProgressBars: isRefetching,
        },
        initialState: {
            showGlobalFilter: false,
            density: 'compact',
            columnSizing: columnSizingState,
            columnVisibility: columnVisibility,
        },
        onColumnVisibilityChange: handleColumnVisibilityChange,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableStickyHeader: true,
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        layoutMode: 'grid',
        onColumnSizingChange: handleColumnSizingChange,
        manualFilters: true,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        rowCount: meta?.totalRowCount ?? 0,
        localization: MRT_Localization_DE,
        muiTableContainerProps: ({ table }) => ({
            sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px'}
        }),        
        muiTableHeadRowProps: {
            sx: { justifyContent: "center" },
        },
        muiTableBodyCellProps: {
            style: { overflow: "hidden", fontSize: "13px" },
        },
        muiTableProps: {
            sx: { tableLayout: 'fixed' },
        },
        muiTablePaperProps: {
            elevation: 0,
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => {    
                let parseddata = JSON.parse(row.original.data);

                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchSucheSchadendatenbyID: 1,
                        ID: parseddata.ID
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (window.chrome && window.chrome.webview && isDesktop) {
                            const dataarray = ["OPENSchaden", data[0]];
                            window.chrome.webview.postMessage(dataarray);
                            dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
                            if (localStorage.getItem("setcheckboxEVTSchadenaufrufen") === "true") {
                                navigateToCustomer(data[0].PNr)
                            }
                        }
                        else {
                            dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
                            navigate('/dashboard/SchadenübersichtPage', { state: { data: data[0] } });
                        }
                    }).catch((error) => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            },
        }),
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
              <TextField
                size="small"
                onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
                spellCheck={false}
                placeholder="Suchen..."
                sx={{ minWidth: '300px' }}
                variant="outlined"
              />
            </Box>
          ),
        renderToolbarInternalActions: () => (
            <Box>   
                <Tooltip title="Nur eigene Schäden anzeigen">
                    <Checkbox onChange={handlenureigeneanzeigen} checked={nureigeneanzeigen} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
                </Tooltip>   
                <Tooltip title="Nur neue Änderungen anzeigen">
                    <Checkbox onChange={handleShowOnlyNewChanges} checked={showOnlyNewChanges} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
                </Tooltip>          
                <MRT_ShowHideColumnsButton table={tableInstance} />
                <MRT_ToggleFiltersButton table={tableInstance} />
                <MRT_ToggleFullScreenButton table={tableInstance} />                
            </Box>
        )
    });

    return (
        <div>
            <MaterialReactTable table={tableInstance} />       
        </div>
    );
};

const SchadenÄnderungTableServersideWithQueryProvider = () => (
    <QueryClientProvider client={queryClient}>
        <SchadenÄnderungTableServerside />
    </QueryClientProvider>
);

export default SchadenÄnderungTableServersideWithQueryProvider;
