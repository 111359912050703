import React from "react";
import { Box, Button, Typography } from '@mui/material';

const Krankenversicherung = ({ handleBack, handleNext }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Krankenversicherung
      </Typography>
      <Typography paragraph>
        Hier wird die Information zur Krankenversicherung angezeigt.
      </Typography>
      <Box display="flex" justifyContent="space-between" marginTop="20px">
        <Button variant="contained" onClick={handleBack}>
          Zurück
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Weiter
        </Button>
      </Box>
    </Box>
  );
};

export default Krankenversicherung;
