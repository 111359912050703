import { useLoaderData, useNavigate  } from "react-router-dom";
import { useAuth} from "../hooks/useAuth";
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import AufgabenModal from '../modals/AufgabenModalNeu';
import AufgabenModalUpdate from '../modals/AufgabenModalUpdate';
import moment from 'moment';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import {
  Avatar,
  CardHeader,
  Box,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Divider, 
  Stack,
  Checkbox,
  FormControlLabel, 
  Popover
} from '@mui/material';
import { PlaylistAddCheckSharp, CarCrashRounded, UnpublishedSharp, PrintSharp, EventAvailableRounded, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star, DeleteSharp} from '@mui/icons-material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '../hooks/useNavigateFunction';
import { fetchdataUrl } from '../A-config/config';


function isEmpty(value){
    return (value == null || value.length === 0);
}
//Tage zwischen 2 Daten - d1 und d2 müssen im Format new Date(Jahr, Monat, Tag) sein.
var numDaysBetween = function(d1, d2) {
    var diff = d1.getTime() - d2.getTime();
    return diff / (1000 * 60 * 60 * 24);
};

export const DashboardAufgabenTable = () => {  
  const dispatch = useDispatch();
  const refreshAufgaben = useSelector((state) => state.refreshDataAufgaben);
  const [data, setData] = useState([]);
  const { user, hasPermission} = useAuth();
  const [rowID, setRowID] = useState('');
  const [openModalAufgaben, setOpenModalAufgaben] = useState(false);
  const [openModalAufgabenUpdate, setOpenModalAufgabenUpdate] = useState(false);
  const [showonlyownAufgaben, setshowonlyownAufgaben] = useState(() => localStorage.getItem('setshowonlyownAufgaben') === 'true');
  const [showonlyDelegatedAufgaben, setshowonlyDelegatedAufgaben] = useState(() => localStorage.getItem('setshowonlyDelegatedAufgaben') === 'true');
  const [showonlyUnerledigte, setshowonlyUnerledigte] = useState(localStorage.getItem('setshowonlyUnerledigte') === 'true');

  const defaultColumnWidthsAufgaben = 90;
  let storageItemAufgaben = localStorage.getItem('columnWidthsAufgaben');
  let columnWidthsFromLocalStorageAufgaben;

  if (storageItemAufgaben === "undefined" || !storageItemAufgaben) {
        columnWidthsFromLocalStorageAufgaben = defaultColumnWidthsAufgaben;
  } else {
    columnWidthsFromLocalStorageAufgaben = JSON.parse(storageItemAufgaben);
  }

  const [columnSizingStateAufgaben, setColumnSizingStateAufgaben] = React.useState(columnWidthsFromLocalStorageAufgaben);

  const handleColumnSizingChangeAufgaben = (newColumnSizingState) => {
    const newState = newColumnSizingState(columnSizingStateAufgaben);
    localStorage.setItem('columnWidthsAufgaben', JSON.stringify(newState));
    setColumnSizingStateAufgaben(newState);
  };

  const [columnVisibilityAufgaben, setcolumnVisibilityAufgaben] = useState(() => {
      try {
        const visibilityString = localStorage.getItem('columnVisibilityAufgaben');
        return JSON.parse(visibilityString) || { ID: false, Datum: true, Thema: true, Art: true, Aufgabefür: true, Aufgabevon: true, Bemerkungen: false, Erledigtam: true };
      } catch (e) {
        console.error(e);
        return { ID: false, Datum: true, Thema: true, Art: true, Aufgabefür: true, Aufgabevon: true, Bemerkungen: false, Erledigtam: true };
      }
  });


  const handleOpenAufgaben = () => setOpenModalAufgaben(true);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [isTableAufgabenLoading, setisTableAufgabenLoading] = useState(true)

  const reloadData = () => {
    setRefresh(!refresh);
  };

  const handleShowOnlyMineCheckbox = (event) => {
      const isChecked = event.target.checked;
      setshowonlyownAufgaben(isChecked);
      if (isChecked) {
          setshowonlyDelegatedAufgaben(false);
          localStorage.setItem('setshowonlyDelegatedAufgaben', false);
      }
      localStorage.setItem('setshowonlyownAufgaben', isChecked);
  };

  const handleShowOnlyMyDelegatedAufgaben = (event) => {
      const isChecked = event.target.checked;
      setshowonlyDelegatedAufgaben(isChecked);
      if (isChecked) {
          setshowonlyownAufgaben(false);
          localStorage.setItem('setshowonlyownAufgaben', false);
      }
      localStorage.setItem('setshowonlyDelegatedAufgaben', isChecked);
  };  

  const handleShowOnlyUnerledigteCheckbox = (event) => {
      const isChecked = event.target.checked;
      setshowonlyUnerledigte(isChecked);
      localStorage.setItem('setshowonlyUnerledigte', isChecked);
  };
  
  useEffect(() => {
    localStorage.setItem('columnVisibilityAufgaben', JSON.stringify(columnVisibilityAufgaben));
    // Update the key to force re-mount and re-render of the table
  }, [columnVisibilityAufgaben]);

  const handleColumnVisibilityChange = (updater) => {
  // Update the visibility state of the columns
  setcolumnVisibilityAufgaben(updater);
  };

  useEffect(() => {  
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchAlleAufgaben: 1
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          setData(data);
          setisTableAufgabenLoading(false);
        }).catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        });

  }, [refresh, refreshAufgaben, dispatch]); 
 
  const filteredDataTableAufgaben = data && data.length > 0 ? data.filter(row => {
    // check if the showonlyownAufgaben checkbox is enabled
    if (showonlyownAufgaben && row.Aufgabefür !== user.vollstaendigerName) {
      return false;
    }

    // check if the showonlyownAufgaben checkbox is enabled
    if (showonlyDelegatedAufgaben && row.Aufgabevon !== user.vollstaendigerName) {
      return false;
    }

    // check if the showonlyUnerledigte checkbox is enabled
    if (showonlyUnerledigte && !isEmpty(row.Erledigtam)) {
      return false;
    }

    // if both checkboxes are disabled or the row matches the filters, return true
    return true;
  }) : [];

  const columnsTableAufgaben = React.useMemo(
        () => [
          {
            accessorKey: 'Überfälligkeit',
            header: '',
            Cell: ({ cell, dateTermin, DateNowArray, DateAk, DateNow, erledigtjanein }) => (   
              dateTermin  = cell.row.original.Datum.substring(0,10).split("-"),
              DateNowArray = moment().format('DD.MM.yyyy').split("."),
              DateAk = new Date(dateTermin[0],dateTermin[1],dateTermin[2]),
              DateNow = new Date(DateNowArray[2],DateNowArray[1],DateNowArray[0]),
              isEmpty(cell.row.original.Erledigtam) ? erledigtjanein = "#f14668" : erledigtjanein = "#2e3192",
              numDaysBetween(DateNow, DateAk) > 3 ? 
              <div style={{ textAlign: 'center' }}>  
                 <Tooltip arrow placement="left" title="Überfällige Aufgabe (älter als 3 Tage)">
                  <IconButton size='small' style={{color: '#f14668', padding: 0}}>
                    <WarningAmberOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              : numDaysBetween(DateNow, DateAk) == 0 ?
              <div style={{ textAlign: 'center' }}>  
                 <Tooltip arrow placement="left" title="Tagesaktuelle Aufgabe">
                  <IconButton size='small' style={{color: '#2e3192', padding: 0}}>
                    <EventAvailableRounded />
                  </IconButton>
                </Tooltip>
              </div> :
              <span></span>
            ),              
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: false,    
            enableColumnFilter: false, 
            enableResizing: false,       
            minSize: 20, 
            maxSize: 20,
            size: 20,
          },
          {
          header: 'ID',
          accessorKey: 'ID',
          enableResizing: false,      
          muiTableHeadCellProps: {
          },
          minSize: 60,
          maxSize: 60, 
          size: 60,
      },
      {
          header: 'Datum',
          accessorKey: 'Datum',
          minSize: 40,
          size: 90, 
          Cell: ({ cell }) => {
          const newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY - HH:mm:ss").split("-");
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{newdate[0]} <br></br>{newdate[1]}</span>;
        },
      },
      {
          header: 'Thema',
          accessorKey: 'Thema',
          muiTableHeadCellProps: {
          },
          minSize: 40,
          size: 90, 
         Cell: ({ cell }) => {
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{cell.getValue()}</span>;
        },
      },
      {
          header: 'Art',
          accessorKey: 'Art',
          muiTableHeadCellProps: {
          },
          minSize: 40,
          size: 90, 
          Cell: ({ cell }) => {
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{cell.getValue()}</span>;
        },

      },
          {
          header: 'Für',
          accessorKey: 'Aufgabefür',
          muiTableHeadCellProps: {
          },
          minSize: 40,
          size: 90, 
          Cell: ({ cell }) => {
          const newdate = cell.getValue().split(" ");
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{newdate[0]} <br></br>{newdate[1]}</span>;
        },
      },
          {
          header: 'Von',
          accessorKey: 'Aufgabevon',
          muiTableHeadCellProps: {
          },
          minSize: 40,
          size: 90, 
          Cell: ({ cell }) => {
          const newdate = cell.getValue().split(" ");
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{newdate[0]} <br></br>{newdate[1]}</span>;
        },
      },
            {
          header: 'Bemerkungen',
          accessorKey: 'Bemerkungen',
          muiTableHeadCellProps: {
          },
          minSize: 40,
          size: 90, 
          Cell: ({ cell }) => {
          return <span style={{fontSize: "13px"}} className={isEmpty(cell.row.original.Erledigtam) ? '' : 'strikethroughclass'}>{cell.getValue()}</span>;
        },
      },
       {
          header: 'Erledigt',
          accessorKey: 'Erledigtam',
          minSize: 40,
          size: 90, 
          Cell: ({ cell, newdate, erledigtjanein }) => 
          (   
            isEmpty(cell.getValue()) ? erledigtjanein = "#f14668" : erledigtjanein = "#2e3192",
            isEmpty(cell.getValue()) ?
            
               (newdate = "", 
                <Box>
                  <Tooltip arrow placement="right" title="Unerledigt">
                      <UnpublishedSharp style={{width: "20px", height: "20px", color: erledigtjanein}} />
                  </Tooltip>
                  <span>{newdate}</span>
                </Box>) :            
              (newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY - HH:mm:ss").split("-"),   
                  <div style={{display: "flex", alignItems:"center"}}>
                    <Tooltip arrow placement="right" title="Erledigt">
                        <CheckCircle style={{width: "20px", height: "20px", color: erledigtjanein}} />
                    </Tooltip>
                    <span style={{fontSize: "13px", marginLeft: 7}}>{newdate[0]} <br></br>{newdate[1]} </span>
                  </div>  
              )         
          ),
      },
        ],
        []
  )
  const customLocalization = {
    ...MRT_Localization_DE,
    noResultsFound: "Du hast aktuell keine unerledigten Aufgaben", 
    noRecordsToDisplay: "Du hast aktuell keine unerledigten Aufgaben"
  };

  const tableAufgaben = useMaterialReactTable({
    columns: columnsTableAufgaben,
    data: filteredDataTableAufgaben,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',
    onColumnSizingChange: handleColumnSizingChangeAufgaben,
    state: {
      isLoading: isTableAufgabenLoading,
      columnSizing: columnSizingStateAufgaben,
      columnVisibility: columnVisibilityAufgaben
    },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      density: 'compact',
      columnVisibility: columnVisibilityAufgaben,
      sorting: [{ id: "Datum", desc: true }]
    },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableBodyCellProps: {
      style: { overflow: "hidden" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0
    },
    enableMultiRowSelection: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setOpenModalAufgabenUpdate(true);
        setRowID(row.original.ID);
      }
    }),
    enablePagination: true,
    autoResetPageIndex: false,
    enableStickyHeader: true,
    muiTableContainerProps: ({ table }) => ({
      sx: { height: table.getState().isFullScreen ? '100%' : '230px', minHeight: '230px', maxHeight: '250px'}
  }),     
    localization: customLocalization,
    renderToolbarInternalActions: () => (
      <Box>                     
        <MRT_ToggleGlobalFilterButton table={tableAufgaben} />
        <Tooltip title="Neue Aufgabe hinzufügen">
        <IconButton
          onClick={() => {
            setOpenModalAufgaben(true);
          }}
        >
          <NoteAddSharp />
        </IconButton>
        </Tooltip>
        <Tooltip title="Nur Aufgaben an mich anzeigen">
        <Checkbox onChange={handleShowOnlyMineCheckbox} checked={showonlyownAufgaben} size="small" sx={{height: "35px", width: "35px"}} color="default"/>
        </Tooltip>
        <Tooltip title="Nur von mir erteilte Aufgaben anzeigen">
        <Checkbox onChange={handleShowOnlyMyDelegatedAufgaben} checked={showonlyDelegatedAufgaben} size="small" sx={{height: "35px", width: "35px"}} color="default"/>
        </Tooltip>
        <Tooltip title="Nur unerledigte Aufgaben anzeigen">
        <Checkbox onChange={handleShowOnlyUnerledigteCheckbox} checked={showonlyUnerledigte} size="small" sx={{height: "35px", width: "35px"}} color="default"/>
        </Tooltip>
        {/* along-side built-in buttons in whatever order you want them */}

        <MRT_ShowHideColumnsButton table={tableAufgaben} />
        <MRT_ToggleFiltersButton table={tableAufgaben} />
        <MRT_ToggleFullScreenButton table={tableAufgaben} />
      </Box>
    )
  });

  return (   
    <div>
        <MaterialReactTable table={tableAufgaben}></MaterialReactTable>
        <AufgabenModal openModalAufgaben={openModalAufgaben} setOpenModalAufgaben={setOpenModalAufgaben} reloadData={reloadData}></AufgabenModal> 
        <AufgabenModalUpdate openModalAufgaben={openModalAufgabenUpdate} setOpenModalAufgaben={setOpenModalAufgabenUpdate} reloadData={reloadData} rowID={rowID}></AufgabenModalUpdate>       
    </div>                  
    );
}
