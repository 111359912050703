import React, { useState, useEffect } from 'react';
import { Box, ListItemIcon, Button, Card, CardContent, List, ListItem, ListItemText, IconButton, Divider, LinearProgress } from '@mui/material';
import { Delete as DeleteIcon, Upload as UploadIcon } from '@mui/icons-material';
import { DescriptionSharp } from '@mui/icons-material';
import { fetchdataUrl } from '../A-config/config';

export const FlyerundDokumentePage = () => {
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Zustand für den Upload-Status

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightUserDokumente');
    return savedHeight ? parseInt(savedHeight) : 300; // Default to 300 if nothing is stored
  });


  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 500;

    localStorage.setItem('contentHeightUserDokumente', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  useEffect(() => {
    calculateContentHeightAndPageSize(); // Call on initial load

    // Set up event listener for resize
    window.addEventListener('resize', calculateContentHeightAndPageSize);

    // Clean-up function
    return () => {
      window.removeEventListener('resize', calculateContentHeightAndPageSize);
    };
  }, []);

  // Effekt, um die Liste der Dokumente beim Initialisieren zu laden
  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
        const formData = new FormData();
        formData.append('getFilesfromServer', true);
        formData.append('access_token', sessionStorage.getItem("access_token")); 

        const response = await fetch(`${fetchdataUrl}uploaddata.php`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Netzwerkantwort war nicht ok');
        }

        const documents = await response.json();
        setDocuments(documents);
    } catch (error) {
        console.error('Fehler beim Abrufen der Dokumente:', error);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) { 
        const file = files[0];
        handleUpload(file);
    } 
  };

  const handleUpload = async (file) => {
    if (!file) return;
    setIsUploading(true); 

    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadFlyerfile', true);
    formData.append('access_token', sessionStorage.getItem("access_token")); 

    try {
        const response = await fetch(`${fetchdataUrl}uploaddata.php`, {
            method: 'POST',
            body: formData,
        });
        if (response.ok) {
            console.log("Datei erfolgreich hochgeladen");
            await fetchDocuments(); 
        } else {
            throw new Error('Fehler beim Hochladen der Datei');
        }
    } catch (error) {
        console.error("Fehler:", error);
    } finally {
      setIsUploading(false); 
    }
};


const handleDelete = async (fileName) => {
    const formData = new FormData();
    formData.append('deleteFile', true);
    formData.append('fileName', fileName);
    formData.append('access_token', sessionStorage.getItem("access_token"));

    try {
      const response = await fetch(`${fetchdataUrl}uploaddata.php`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();

      if (response.ok && !result.error) {
        console.log(result.message); // Erfolgsmeldung anzeigen
        fetchDocuments(); // Dokumentenliste neu laden
      } else {
        throw new Error(result.error || 'Unbekannter Fehler beim Löschen der Datei');
      }
    } catch (error) {
      console.error("Fehler:", error);
    }
  };

  return (
    <Card sx={{minHeight: "412px"}}>
      <CardContent>
        <input
          accept="*/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="text" component="span" startIcon={<UploadIcon />}>
            Neue Datei hochladen
          </Button>
        </label>
        <Divider sx={{mt: 1}}></Divider>   
        {isUploading && <LinearProgress />}  
        <Box sx={{ width: '100%', height: contentHeight, overflowY: "auto", bgcolor: 'background.paper' }}>
          <List dense={true}>
          {documents
              .filter(doc => doc.name !== '.htaccess')
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((doc, index) => (
              <ListItem 
                  key={doc.name}
                  sx={{'&:hover': {bgcolor: 'grey.200'}}}
              > 
                  <ListItemIcon>
                  <DescriptionSharp />
                  </ListItemIcon>
                  <ListItemText
                  primary={
                      <a href={doc.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                      {doc.name}
                      </a>
                  }
                  />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(doc.name)}>
                  <DeleteIcon />
                  </IconButton>
              </ListItem>
          ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};
