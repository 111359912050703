import React, { useEffect, useState } from 'react';
import { Card, FormControlLabel, Checkbox, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import Berufsunfaehigkeit from "./VersorgungsrechnerPages/Berufsunfaehigkeit";
import Krankenversicherung from "./VersorgungsrechnerPages/Krankenversicherung";

const Steuerklassen = [
  { value: '1', label: 'Steuerklasse 1' },
  { value: '2', label: 'Steuerklasse 2' },
  { value: '3', label: 'Steuerklasse 3' },
  { value: '4', label: 'Steuerklasse 4' },
  { value: '5', label: 'Steuerklasse 5' },
];
const fetchGehalt = async (formData) => {
    const response = await fetch('https://www.brutto-netto-rechner.info/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        "f_bruttolohn": formData.einkommen,
        "f_abrechnungszeitraum": "monat",
        "f_geld_werter_vorteil": 0,
        "f_abrechnungsjahr": 2024,
        "f_steuerfreibetrag": "",
        "f_steuerklasse": formData.taxClass,
        "f_kirche": formData.kirchensteuer ? "ja" : "nein",
        "f_bundesland": "baden-wuerttemberg",
        "f_alter": 25,
        "f_kinder": "ja",
        "f_kinderfreibetrag": 1,
        "f_kinder_anz": 0,
        "f_krankenversicherung": "pflichtversichert",
        "f_private_kv": "",
        "f_arbeitgeberzuschuss_pkv": "ja",
        "f_KVZ": 1.7,
        "f_rentenversicherung": "pflichtversichert",
        "f_arbeitslosenversicherung": "pflichtversichert",
        "ok": 1
      })
    });
    const text = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const nettoWerte = doc.querySelectorAll('.right_column.orange.big b');
    const [nMonat, nJahr] = Array.from(nettoWerte).map(node => parseFloat(node.textContent.replace(/[. €]/g, '').replace(',', '.')));
    return { bMonat: formData.einkommen, bJahr: formData.einkommen * 12, nMonat, nJahr };
};

export const VersorgungsstreckePage = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    beruf: '',
    einkommen: '',
    taxClass: '',
    kirchensteuer: false,
  });

  const handleSubmit = async () => {
   
  };

  const handleNext = async () => {
    const gehalt = await fetchGehalt(formData);
    console.log(gehalt); // Do something with the result
    // setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKirchensteuerChecked = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      kirchensteuer: e.target.checked,
    }));
  };

  return (
    <div>
      <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, padding: '20px' }}>
        {step === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Persönliche Informationen
            </Typography>
            <TextField
              label="Beruf"
              name="beruf"
              value={formData.beruf}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Einkommen (Brutto)"
              name="einkommen"
              value={formData.einkommen}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Steuerklasse"
              name="taxClass"
              select
              value={formData.taxClass}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {Steuerklassen.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={<Checkbox checked={formData.kirchensteuer} onChange={handleKirchensteuerChecked} />}
              label="Kirchenzugehörigkeit?"
            />
            <Box display="flex" justifyContent="flex-end" marginTop="20px">
              <Button variant="contained" color="primary" onClick={handleNext}>
                Weiter
              </Button>
            </Box>
          </Box>
        )}

        {step === 1 && <Berufsunfaehigkeit formData={formData} handleBack={handleBack} handleNext={handleNext} />}
        {step === 2 && <Krankenversicherung formData={formData} handleBack={handleBack} handleNext={handleNext} />}
        {/* Weitere Seiten */}
      </Card>
    </div>
  );
};

export default VersorgungsstreckePage;
