import React, { useState, useEffect } from 'react';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useForm, Controller } from "react-hook-form";
import { Avatar, Button, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import { updateEventData, updatePnr } from '../hooks/reduxactions';
import { useDispatch } from 'react-redux';
import { fetchdataUrl } from '../A-config/config';

export const LoginPage = () => {
  const { login } = useAuth();
  const dispatch = useDispatch();


  const { control, handleSubmit, setError, clearErrors } = useForm({
        reValidateMode: "onBlur",
  });
  const [errorMessageLogin, seterrorMessageLogin] = useState('');


  const handleOnSubmit = async (evt) => {
    try {
      // Check Username and Passwort
      const loginResponse = await fetch(`${fetchdataUrl}loginandregister.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchCheckUsernameandPassword: 1,    
          username: evt.username,
          password: evt.password,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const loginData = await loginResponse.json();
  
      if (loginData["loginstatus"] === "ok") {
        // Store Access Token
        sessionStorage.setItem("access_token", loginData["access_token"]);
        localStorage.setItem("access_token", loginData["access_token"]);
        
        // Dispatch the user data to Redux
        login({
          ...loginData[0],  // Spread all properties from data[0]
          access_token: loginData["access_token"]  // Add access_token separately
        });
  
        // Fetch Firmeninfos
        const firmenInfoResponse = await fetch(`${fetchdataUrl}fetchdata.php`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchgetFirmeninfos: 1,
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        const firmenData = await firmenInfoResponse.json();
        localStorage.setItem('firmenInfos', JSON.stringify(firmenData));
  
        // Update htpasswd file
        await fetch(`${fetchdataUrl}addAuthUsers.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } else {
        seterrorMessageLogin(loginData["loginstatus"]);
      }
    } catch (error) {
      console.error('Error during login or fetching data:', error);
    }
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anmelden
        </Typography>
        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ mt: 1 }}>
                        <Controller                                                              
                                control={control}
                                name="username"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field} 
                                    margin="normal" 
                                    id="username"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Benutzername"
                                    name="username"
                                    required
                                    autoFocus
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('username', { type: 'required'});                                            
                                    } else {
                                        clearErrors("username");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />
                      
                         <Controller                                                              
                                control={control}
                                name="password"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}  
                                    margin="normal"
                                    id="password"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="password"                                    
                                    label="Passwort"
                                    name="password"
                                    required
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('password', { type: 'required'});                                            
                                    } else {
                                        clearErrors("password");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />
                        
                        <span style={{color: '#f14668', fontSize: '14px', fontWeight: 'bold'}}> {errorMessageLogin}</span>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item>
                <Link component={RouterLink} to="/register" variant="body2">
                    {"Du hast noch keinen Account? Hier registrieren."}
                </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};