import React, { useEffect, useRef } from 'react';
import { fetchdataUrl } from '../A-config/config';
import moment from 'moment'; 
import { useDispatch, useSelector } from 'react-redux';
import { refreshuserstatus, setuserstatus } from '../hooks/reduxactions';
import GlobalTaskManager from './GlobalTaskManager';

const ActivityUpdater = ({ user }) => {
    const lastActivityRef = useRef(new Date()); 
    const lastStatus = useRef('verfügbar');
    const refreshUserStatus = useSelector((state) => state.refreshuserstatus); 
    const { checkBeratungsterminIntervall, checkAufgaben, checkforservermessages, checkfornewSchadenÄnderungen } = GlobalTaskManager();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                getuserstatuses: 1,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                dispatch(setuserstatus(data))
            }
        })
        .catch(err => console.error("Error fetching user statuses", err));   

    }, [refreshUserStatus]); 

    // Function to set the status to verfügbar
    const setInitialStatusOnline = () => {
        if (!user || !user.vollstaendigerName) return;
        const bodyData = {
            access_token: sessionStorage.getItem("access_token"),
            updateactivityOnFirstMount: 1,
            vollstaendigerName: user.vollstaendigerName,
            status: 'verfügbar'
        };

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            // Update last status ref
            lastStatus.current = 'verfügbar';
            dispatch(refreshuserstatus());
        })
        .catch(err => console.error("Error setting initial status", err));
    };

    // Function to check user activity
    const checkUserActivity = async () => {
        // dispatch(refreshuserstatus());
        const now = new Date();
        const diff = now - lastActivityRef.current;

        await checkBeratungsterminIntervall();
        await checkAufgaben();
        await checkforservermessages();
        await checkfornewSchadenÄnderungen();

        if (diff > 60000 && lastStatus.current !== 'afk') { 
            await updateActivity('afk', moment().format('YYYY-MM-DD HH:mm:ss'));
        } else if (diff > 60000 && lastStatus.current === 'afk')
        {
            await updateActivity('afk', "nochange");
        } else if (lastStatus.current === 'verfügbar') {
            await updateActivity('verfügbar', null);
        }
    };

    // Handle user activity in the browser environment
    const handleUserActivity = () => {
        lastActivityRef.current = new Date(); // Reset the last active time
        if (lastStatus.current === 'afk') {
            updateActivity('verfügbar', null);
        }
    };

    // Initialize component state and listeners
    useEffect(() => {
        setInitialStatusOnline();

        // Setup listeners for user activity
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);

        // Setup interval to check activity every 30 seconds
        const activityCheckInterval = setInterval(checkUserActivity, 20000);
        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            clearInterval(activityCheckInterval);
        };      
    }, []); 

    const updateActivity = async (status, afksince) => {
        if (!user || !user.vollstaendigerName) return;                
        // Update last status ref
        lastStatus.current = status;

        const bodyData = {
            access_token: sessionStorage.getItem("access_token"),
            updateactivity: 1,            
            vollstaendigerName: user.vollstaendigerName,
            status: status,
            lastActivebeforeAfk: afksince
        };
        
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            //Update User Status
            dispatch(refreshuserstatus());
        })
        .catch(() => {
            console.log("Aktion fehlgeschlagen: Netzwerkfehler beim Abrufen der Aktivitätsdaten");
        });
    }; 
};

export default ActivityUpdater;