import { Chip, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, Tab, Tabs, Box, Tooltip, Card, CardHeader, Avatar, IconButton, CardMedia, Divider, CardContent, CardActions, Typography, Button, TextField, Container, Stack, Grid, Alert, Hidden, LinearProgress} from "@mui/material";
import { Upload, PasswordSharp, FavoriteSharp, EditRounded, HowToRegSharp, DeleteSharp, KeyboardDoubleArrowUpSharp, KeyboardDoubleArrowDownSharp, Favorite, Share, ExpandMore, ExpandMoreIcon, AutoAwesome, MilitaryTech, Verified, Grade, Flare, StarSharp} from "@mui/icons-material";
import { useAuth} from "../hooks/useAuth";
import moment from "moment";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop'
import { fetchdataUrl } from '../A-config/config';
import { globalAppBarMessage } from '../hooks/reduxactions'; // Adjust the path
import { useDispatch } from 'react-redux';
import { SettingsAdminMenü } from "./SettingsAdminMenü";

export const SettingsPage = () =>  {
  const dispatch = useDispatch();
  const [valueTab, setValueTab] = React.useState(0);
  const { hasPermission, user, updateProfilePicture, setUser, logout } = useAuth();
  const inputRef = useRef(null);
  const [refreshDataUser, setrefreshDataUser] = useState(false);
  const [newEmail, setNewEmail] = useState(user.email);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showCroppedDialog, setshowCroppedDialog] = useState(false);
  const [showErrorMessageEmail, setshowErrorMessageEmail] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [baseUploadImageUser, setbaseUploadImageUser] = useState(null)
  const [baseUploadImageUserFileName, setbaseUploadImageUserFileName] = useState(null)
  const [OpenPasswordChangeDialog, setOpenPasswordChangeDialog] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [bemerkungenlogdata, setbemerkungenlogdata] = useState([]); // Zustand für den Upload-Status

  const sortPermissions = (permissions) => {
    const priorityPermissions = ['Serveradmin', 'Applikationsadmin', 'Admin'];
    const sortedPermissions = permissions.sort((a, b) => {
      const indexA = priorityPermissions.indexOf(a);
      const indexB = priorityPermissions.indexOf(b);
      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    return sortedPermissions;
  };

  let permissions = [];
  if (Array.isArray(user.berechtigungen) && user.berechtigungen.length > 0) {
    permissions = user.berechtigungen;
  } else if (typeof user.berechtigungen === 'string' && user.berechtigungen.length > 0) {
    permissions = JSON.parse(user.berechtigungen);
  }

  const sortedPermissions = sortPermissions(permissions);

  const [snackbar, setSnackbar] = useState({
      open: false,
      message: "",
      severity: "error"
  });

  const handleSnackbarClose = () => {
      setSnackbar({
      open: false,
      message: "",
      severity: "error"
      });
  }

  const getBemerkungenlogdata = async () => {
    const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        selectBemerkungenlog: 1,
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();

    if (data && data.length > 0) {          
      setbemerkungenlogdata(data);
    }
  };

  useEffect(() => {
    // Function to start the timer
    const interval = setInterval(() => {
      getBemerkungenlogdata();
    }, 15000); // 15000 ms = 15 seconds

    // Cleanup function to clear the timer when the component unmounts or dependencies change
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


  const setEmergencystop = () => {
    
    try {     
      fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSetServerMessage: 1,
          message: "stoploop"
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data) => {
          alert('Nachricht an alle Nutzer gesendet!');
      });   

    } catch (error) {
      console.error('Error sending server message:', error);
      alert('Failed to send the message. Please try again.');
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // Convert the cropped image area to a Blob (similar to a File).
  const getCroppedImg = async (image, crop) => {
    const canvas = document.createElement('canvas'); // create a canvas
    const ctx = canvas.getContext('2d'); // get its context
    
    // dimensions of the canvas as the pixel dimensions of the crop
    canvas.width = crop.width;
    canvas.height = crop.height;
    
    // draw the cropped image on the canvas
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert the canvas to a Blob (so it's usable as a File)
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        // Convert this Blob to a File.
        const croppedImageFile = new File([blob], baseUploadImageUserFileName, { type: 'image/jpeg' });
        resolve(croppedImageFile);
      }, 'image/jpeg');
    });
  };

  const saveCroppedImage = async () => {
    // Create the cropped image file
    const image = new Image();
    image.src = baseUploadImageUser;
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);

    // Use the cropped image file instead of the original full-size file
    const formData = new FormData();
    formData.append('file', croppedImage);
    formData.append('updateProfilePicture', true);
    formData.append('username', user.username);
    formData.append('access_token', sessionStorage.getItem("access_token")); 
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: formData,
    }).then(() => {
        updateProfilePicture(user.username + baseUploadImageUserFileName);
    })
  }

  const handleFileInputOnChange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        setbaseUploadImageUserFileName(file.name)
        setbaseUploadImageUser(URL.createObjectURL(file));
        handleOpenCroppedDialog();
      }   
  };

  const handleOpenCroppedDialog = () => {
    setshowCroppedDialog(true);
  };

  const handleCloseCroppedDialog = () => {
    setshowCroppedDialog(false);
  };

  
  const [valueArbeitsstunden, setvalueArbeitsstunden] = useState(localStorage.getItem("valueArbeitsstunden") || '');

  const handlechangeArbeitsstunden = (e) => {
    setvalueArbeitsstunden(e.target.value);
    localStorage.setItem("valueArbeitsstunden", e.target.value);

    //update in database
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            updateUserArbeitsZeit: 1,
            vollstaendigerName: user.vollstaendigerName,
            arbeitsstunden: e.target.value
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
    });
  }

  const [checkboxEVTaufrufen, setcheckboxEVTaufrufen] = useState(
    localStorage.getItem("setcheckboxEVTaufrufen") === "true" ? true : false
  );

  const handlecheckboxEVTaufrufen = (e) => {
      const checked = e.target.checked;
      setcheckboxEVTaufrufen(checked);
      localStorage.setItem("setcheckboxEVTaufrufen", checked.toString());
  }

  const [checkboxEVTSchadenaufrufen, setcheckboxEVTSchadenaufrufen] = useState(
    localStorage.getItem("setcheckboxEVTSchadenaufrufen") === "true" ? true : false
  );

  const handlecheckboxEVTSchadenaufrufen = (e) => {
      const checked = e.target.checked;
      setcheckboxEVTSchadenaufrufen(checked);
      localStorage.setItem("setcheckboxEVTSchadenaufrufen", checked.toString());
  }

  const handleSaveEmail = () => {
    
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            checkEmailandChange: 1,
            email: newEmail,
            username: user.username
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
          if (data == "failed")
          {
              setshowErrorMessageEmail(true);
          }
          else
          { 
            setshowErrorMessageEmail(false);
            setUser({ ...user, email: newEmail });
            setShowEmailDialog(false);
          }
    });  
  };

  const handleEmailDialogOpen = () => {
    setShowEmailDialog(true);
  };

  const handleEmailDialogClose = () => {
    setShowEmailDialog(false);
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleChangePassword = async () => {

    if (newPassword !== newPasswordConfirm) {
      setSnackbar({
             open: true,
             message: "Die neuen Passwörter stimmen nicht überein.",
             severity: "error"
      });
      return;
    }

    fetch(`${fetchdataUrl}loginandregister.php`, {
      method: 'POST',
      body: JSON.stringify({
          changePassword: 1,
          username: user.username, 
          oldPassword: oldPassword,
          newPassword: newPassword,
          newPasswordConfirm: newPasswordConfirm
      }),
      headers: {
          'Content-Type': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if (data === 'Passwort geändert.') {
        setSnackbar({
              open: true,
              message: "Das Passwort wurde geändert.",
              severity: "success"
        });
      } else {
        setSnackbar({
             open: true,
             message: data,
             severity: "error"
        });
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    // setOpenPasswordChangeDialog(false);
  };

  const handleAvatarClick = () => {
  inputRef.current.click();
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

return (
  <div>
      <Snackbar   anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
            </Alert>
      </Snackbar>
      <Tabs sx={{ml: 4}} value={valueTab} onChange={handleChangeTabs}>
          <Tab label="Profil" />
          <Tab label="Einstellungen" />
          <Tab label="Adminmenü" />
          {/* {hasPermission(user, 'Serveradmin') ? <Tab label="Bemerkungenlog" /> : null } */}
      </Tabs>
      <div className={`tabContentSettings ${valueTab === 0 ? 'active' : ''}`}>                                     
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="top" sx={{width: "1500px"}}>
            <Grid item xs={0}>
              <Tooltip title="Neues Profilbild hochladen...">
                <Avatar
                  onClick={handleAvatarClick}
                  sx={{ cursor: 'pointer', width: 60, height: 60 }}
                  src={`${fetchdataUrl}profilepicture/${user.profilepicturepath}`}
                />
              </Tooltip>
              <input
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleFileInputOnChange}
              />
              <Dialog
                PaperProps={{ style: { width: "600px", height: "600px" } }}
                open={showCroppedDialog}
                onClose={handleCloseCroppedDialog}
              >
                <DialogContent>
                  <Cropper
                    image={baseUploadImageUser}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={saveCroppedImage}>Speichern</Button>
                  <Button variant="contained" color="error" onClick={handleCloseCroppedDialog}>Abbrechen</Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={6}>
            <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Benutzername</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1"><b>{user.username}</b></Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle1">Vollständiger Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1"><b>{user.vollstaendigerName}</b></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">E-Mail</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1"><b>{user.email ? user.email : "----"}</b></Typography>
                <IconButton onClick={handleEmailDialogOpen} size="small" sx={{ ml: 1 }}>
                  <EditRounded fontSize="small" />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle1">Kürzel</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1"><b>{user.kürzel}</b></Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle1">Organummer</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1"><b>{user.Organummer ? user.Organummer : "----"}</b></Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle1">Registriert seit</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1"><b>{moment(new Date(user.created_at)).format("DD.MM.YYYY - HH:mm:ss")}</b></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Berechtigungen</Typography>
            </Grid>
            <Grid item xs={8} sx={{ marginTop: "3px" }}>
              {sortedPermissions.length > 0 ? (
                sortedPermissions.map((berechtigung, index) => (
                  <Chip key={index} label={berechtigung} size="small" sx={{ marginRight: 1, marginBottom: 1 }} />
                ))
              ) : (
                <Typography variant="body1"><b>Keine zusätzlichen Berechtigungen</b></Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Button onClick={() => setOpenPasswordChangeDialog(true)}>Passwort ändern</Button>
                <Button onClick={logout} sx={{ ml: 2 }}>Ausloggen</Button>
              </Box>
            </Grid>

            <Dialog open={showEmailDialog} onClose={handleEmailDialogClose}>
              <DialogTitle>E-Mail bearbeiten</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  fullWidth
                  margin="dense"
                  label="E-Mail"
                  type="email"
                  value={newEmail}
                  onChange={handleEmailChange}
                  error={showErrorMessageEmail}
                  helperText={showErrorMessageEmail ? "Diese E-Mailadresse wird bereits verwendet." : ""}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSaveEmail}>Speichern</Button>
                <Button onClick={handleEmailDialogClose}>Abbrechen</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={OpenPasswordChangeDialog} onClose={() => setOpenPasswordChangeDialog(false)}>
              <DialogTitle>Passwort ändern</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="oldPassword"
                  label="Bisheriges Passwort"
                  type="password"
                  fullWidth
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="newPassword"
                  label="Neues Passwort"
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="newPasswordConfirm"
                  label="Neues Passwort bestätigen"
                  type="password"
                  fullWidth
                  value={newPasswordConfirm}
                  onChange={(e) => setNewPasswordConfirm(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleChangePassword}>Speichern</Button>
                <Button onClick={() => setOpenPasswordChangeDialog(false)}>Abbrechen</Button>
              </DialogActions>
            </Dialog> 
            </Grid>
          </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={OpenPasswordChangeDialog} onClose={() => setOpenPasswordChangeDialog(false)}>
        <DialogTitle>Passwort ändern</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="oldPassword"
            label="Bisheriges Passwort"
            type="password"
            fullWidth
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="newPassword"
            label="Neues Passwort"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="newPasswordConfirm"
            label="Neues Passwort bestätigen"
            type="password"
            fullWidth
            value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangePassword}>Speichern</Button>
          <Button onClick={() => setOpenPasswordChangeDialog(false)}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </div>
    <div className={`tabContentSettings ${valueTab === 1 ? 'active' : ''}`}> 
      <Card sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
        <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Allgemeine Einstellungen</h2> 
        <CardContent>
           <FormGroup>
              <FormControlLabel control={<Checkbox 
                checked={checkboxEVTaufrufen}
                onChange={handlecheckboxEVTaufrufen}
                />} label="Kunden im EVT-Portal automatisch aufrufen." />
              <FormControlLabel control={<Checkbox 
                checked={checkboxEVTSchadenaufrufen}
                onChange={handlecheckboxEVTSchadenaufrufen}
                />} label="Kunden im EVT-Portal automatisch aufrufen, wenn ein Schaden geöffnet wird." />
              <FormControlLabel control={<TextField type="number"
                label='Wöchentliche Arbeitsstunden' 
                variant="standard"
                size="small"
                sx={{margin: "10px"}}
                value={valueArbeitsstunden}
                onChange={handlechangeArbeitsstunden}
                />} />
          </FormGroup>
        </CardContent>
      </Card>
    </div>    
    <div className={`tabContentSettings ${valueTab === 2 ? 'active' : ''}`}>  
        <SettingsAdminMenü></SettingsAdminMenü>
    </div>  

    {/* <div className={`tabContentSettings ${valueTab === 3 ? 'active' : ''}`}>
       <Card variant="outlined">
        <Button variant="text" color="primary" onClick={getBemerkungenlogdata}>Refresh</Button>
        <Button variant="text" color="primary" onClick={setEmergencystop}>Emergency Stop</Button>
        <TableContainer component={Paper} style={{maxHeight: 1000, overflow: 'auto'}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>PNR</TableCell>
                <TableCell>Bemerkung</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bemerkungenlogdata.slice().sort((a, b) => new Date(b.Datum) - new Date(a.Datum)).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row">{row.ID}</TableCell>
                  <TableCell>{row.PNR}</TableCell>
                  <TableCell>{row.Bemerkung}</TableCell>
                  <TableCell style={{width: "200px"}}>{row.Datum}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>       */}
  </div>                             
)
};
