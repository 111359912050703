import { useAuth } from "../hooks/useAuth";
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconButton, Divider, LinearProgress, Card, Button, CardContent } from '@mui/material';
import { LocalConvenienceStoreOutlined, SaveSharp } from "@mui/icons-material";
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import Resizer from "react-image-file-resizer";
import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { fetchdataUrl } from '../A-config/config';
import { useDispatch, useSelector } from 'react-redux';

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

function stringToArrayBuffer(string) {
  const buffer = new ArrayBuffer(string.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < string.length; i++) {
      bufferView[i] = string.charCodeAt(i);
  }
  return buffer;
}

const CustomToolbar = ({ handleSaveButtonClick }) => {
  return (
    <div id="toolbar">  
    <span className="ql-formats">   
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <select className="ql-color" />
      </span>     
      <span className="ql-formats">
      <button  
          onClick={handleSaveButtonClick}>
          <SaveSharp></SaveSharp>
      </button>
      </span>   
    </div>
  );
};

async function rtftoHtml(rtf) {
  const doc = new RTFJS.Document(stringToArrayBuffer(rtf));
  const meta = doc.metadata();
  const htmlElements = await doc.render();
  const container = document.createElement('div');

  container.innerHTML = htmlElements.map((el) => el.outerHTML).join('');
  return container.innerHTML;
}

export const SchadenBemerkungen = ({data}) => {
  const dispatch = useDispatch();
  const { user, hasPermission} = useAuth();
  const quillRef = useRef(null);
	const editorRef = useRef(null);
  const quillDataRef = useRef();
  const [content, setContent] = useState('');
  const contentRef = useRef(content);
  const [isloading, setIsloading] = useState(true);
  const [showloading, setshowloading] = useState(false)

  const [editorHeight, setEditorHeight] = useState(() => {
    // Initial height calculation, similar to your content height logic
    const savedHeight = localStorage.getItem('contentHeightSchadenBemerkungen');
    return savedHeight ? `calc(${parseInt(savedHeight)}px - 20px)` : '440px';
  });

  useEffect(() => {
    const initializeQuill = async () => {
      try {
        if (editorRef.current) {
          quillRef.current = new Quill(editorRef.current, {
            theme: 'snow',
            modules: {
              toolbar: '#toolbar',
            },
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true
            },              
          });         

          const quill = quillRef.current;

          (async () => {
            if (data && data.Bemerkungen) {
              const plainText = await rtftoHtml(data.Bemerkungen);
              setContent(plainText);
         
            //Insert like this so that it is api driven. Quill weiss nun, dass eine history da ist und wir können sie danach clearen.
            //Wenn wir hier nicht clearen, entfernt das erste Strg+z den gesamten Inhalt.
            quill.clipboard.dangerouslyPasteHTML(plainText);
            quillDataRef.current = quill.root.innerHTML; 
            quill.history.clear();         
            }
          })();  

            //Kein Spellcheck
            quill.root.setAttribute("spellcheck", "false");

            quill.on('text-change', () => {
              quillDataRef.current = quill.root.innerHTML;
            });   
            
            const handleDoubleClick = () => {
              let dateTime = moment().format('DD.MM.yyyy - HH:mm:ss');
              let username = user.vollstaendigerName;
              let text = dateTime + " " + username + ':\n\n\n';
              quillRef.current.updateContents([
                { insert: text },
                { retain: quillRef.current.getLength() },
              ]);
              quillRef.current.setSelection(text.length - 2);
            };

            quill.root.addEventListener("dblclick", handleDoubleClick);

            //Fix, damit beim Click auf die Toolbar nicht automatisch alles deselected wird.
            const toolbar = document.querySelector(".ql-toolbar");
            if (toolbar) {
              toolbar.addEventListener("mousedown", (event) => {
                event.preventDefault();
              });
            }

            setIsloading(false);  
          return () => {
            quill.off('text-change');
            quill.root.removeEventListener('dblclick', handleDoubleClick);

          };
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setIsloading(false);
      }
    };

    initializeQuill();
  }, [data]);

  const calculateEditorHeight = () => {
    const windowHeight = window.innerHeight - 440;
    const newHeight = `calc(${windowHeight}px - 20px)`;
    setEditorHeight(newHeight);

    localStorage.setItem('contentHeightSchadenBemerkungen', windowHeight.toString());
  };

  useEffect(() => {
    calculateEditorHeight(); 

    window.addEventListener('resize', calculateEditorHeight); 

    return () => {
      window.removeEventListener('resize', calculateEditorHeight); 
    };
  }, []);
 
   const handleBemerkungenSpeichern = async () => {
    setshowloading(true);

    // Get the HTML content from contentRef.current
    const htmlContent = quillDataRef.current;
    // Modify the HTML content to include the desired font family and font size
    const modifiedHtmlContent = `
    <div style="font-family: 'Segoe UI'; font-size: 9pt;">
      ${htmlContent}
    </div>`;


    // // Convert the modified HTML content to RTF
    const htmlToRtf = new HtmlToRtfBrowser();
    const rtf = htmlToRtf.convertHtmlToRtf(modifiedHtmlContent);
    const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchUpdateBemerkungenSchaden: 1,    
          Bemerkungen: rtf,
          CBearbeiter: user.vollstaendigerName, 
          ID: data.ID
        }),
        headers: {
          'Content-Type': 'application/json'
        }
    });

    const updatedSchadenDaten = {
      ...data, 
      CBearbeiter: user.vollstaendigerName,
      Bearbeitungsdatum: moment().format("YYYY-MM-DD HH:mm:ss"),
      Bemerkungen: rtf
    };

    dispatch({ type: "SET_CURRENTSELECTEDSCHADEN", payload: updatedSchadenDaten });
    

    const storedData = localStorage.getItem('firmenInfos');
    const firmenInfos = JSON.parse(storedData);
    let date = new Date();
    let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    if (user.vollstaendigerName !== data.Bearbeiter)
    {
      if (firmenInfos.checkboxSchadenbearbeiterinformieren === true)
      {
        fetch(`${fetchdataUrl}fetchdata.php`, {
          method: 'POST',
          body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              fetchUpdateSchadenänderung: 1,
              data: data,
              changeDate: formattedDate,
              BearbeiterChange: user.vollstaendigerName,
              Aenderung: "Schadendaten geändert"
          }),
          headers: {
              'Content-Type': 'application/json'
          }
          })
          .then(response => response.json())
          .then(data => {
          })
        }
    }

    setshowloading(false);
  };
   
  

  return (
    <div>
            <LinearProgress sx={{marginTop: "5px", display: showloading ? "block" : "none"}}></LinearProgress>
            { hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Schadenbearbeiter') ? 
            <CustomToolbar handleSaveButtonClick={handleBemerkungenSpeichern}/> : null }
            <div ref={editorRef} style={{ display: isloading ? 'none' : 'block', height: editorHeight }} />           
    </div>

  );
};



