import React, { useState, useEffect, useRef, act } from 'react';
import {Paper, AlertTitle, Snackbar, Box, Icon, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, FormControl, Select, MenuItem, CircularProgress, Skeleton, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating, TextField, Alert,Popover, Card, Badge, CardMedia, Typography, CardContent, Button, CardActions, CardHeader, Avatar, Tabs, Tab, Grid, Divider, Stack, Link, Chip, Tooltip, IconButton} from "@mui/material";
import manimage from '../img/man.png';
import womanimage from '../img/woman.png';
import firmaimage from '../img/company.png';
import houseimage from '../img/house.png';
import coupleimage from '../img/couple.png';
import hulkimage from '../img/hulk.png';
import OkDialog from '../hooks/customOkDialog';
import { BemerkungenKunde } from "./BemerkungenKunde";
import { TermineKundePage} from "./KundeTermine";
import { DateiablageKundePage} from "./DateiablageKundePage";
import { KundeSchäden} from "./KundeSchäden";
import { VerifiedUserSharp, GppBadSharp, ThumbUpAlt, ThumbDownAlt, WarningAmber, ShareSharp, NotificationImportant, PeopleSharp, SettingsSharp, PersonRemoveSharp, JoinFullSharp, UnpublishedSharp, FavoriteSharp, FavoriteBorderSharp, UpdateRounded, HailRounded, BoyRounded, GirlRounded, MaleRounded, FemaleRounded, FamilyRestroomRounded, WorkHistoryRounded, ChildCareRounded, MailRounded, PhoneIphoneRounded, HeadphonesRounded, PhoneRounded, ContactEmergencyRounded, TopicRounded, ContactPageSharp, ContactPhoneSharp, CakeSharp, PersonSearchSharp, AssessmentRounded, RestartAltSharp, ElderlySharp, CloseSharp, ChangeCircle, BadgeSharp, Verified, Phone, Email, ContactMail, MobileFriendly, SupervisedUserCircle, History, ManageAccounts, Event, AccountCircle, Delete, DoneSharp, UpdateSharp, AssignmentRounded, PersonAddRounded, DeleteSharp, Co2Sharp, CheckCircleRounded, PersonSharp} from "@mui/icons-material";
import { useAuth} from "../hooks/useAuth";
import moment from "moment";
import AufgabenModal from '../modals/AufgabenModalNeu';
import BeratungsdokuModal from '../modals/BeratungsdokuModal';
import store from "../hooks/reduxstore";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import CustomAXAImage from '../img/axa-logo.svg';
import CustomDBVImage from '../img/DBVLogo.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { updateEventData, setloading, updatePnr, setAXADBVColor, toggleModalBeratungsdokumentation, updatejustoldPnr } from '../hooks/reduxactions';
import { useNavigation } from '../hooks/useNavigateFunction';
import ConfirmDialog from '../hooks/confirmDialog';
import { FlyerundDokumentePage } from './FlyerundDokumentePage';
import { fetchdataUrl } from '../A-config/config';
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: 'white', 
    fontWeight: 'bold',
   },
}));


const colorAXA = "#2e3192";
const colorDBV = "#e88401";

  export const UserKundenübersichtPage = () => {

  const { updateUserStats } = useUpdateUserStats();
  const { userStats, getUserStats } = useGetUserStats();
  const { navigateToCustomer } = useNavigation();
  const { user, hasPermission} = useAuth();
  const ref = useRef(null);
  const [showalertletzteAktualisierung, setShowalertletzteAktualisierung] = useState({ display: "none" });
  const [showalertAusbildung, setshowalertAusbildung] = useState({ display: "none" });
  const [showalertGeburtstag, setshowalertGeburtstag] = useState({ display: "none" });  
  const [Geburtstagstext, setGeburtstagstext] = useState("");  
  const [ColorLetzteAktualisierung, setColorLetzteAktualisierung] = useState("primary");
  const [anchorEl, setAnchorElHistorieLetzterKontakt] = useState(null);
  const [anchorElKontaktdatenPopover, setanchorElKontaktdatenPopover] = useState(null);
  const [anchorElKinderPopover, setanchorElKinderPopover] = useState(null);
  const [anchorElSettings, setanchorElSettings] = useState(null);
  const [anchorElBeziehungenPopover, setanchorElBeziehungenPopover] = useState(null);
  const [anchorElBeratungsintervallPopover, setanchorElBeratungsintervallPopover] = useState(null);
  const [anchorElAktualisierung, setAnchorElHistorieLetzteAktualisierung] = useState(null);
  const [checkIfAktualisierungDispatch, setcheckIfAktualisierungDispatch] = useState(false);
  const open = Boolean(anchorEl);
  const openAktualisierungPopover = Boolean(anchorElAktualisierung);
  const openKontaktdatenPopover = Boolean(anchorElKontaktdatenPopover);
  const openSettingsPopover = Boolean(anchorElSettings);  
  const openKinderPopover = Boolean(anchorElKinderPopover);
  const openBeziehungenPopover = Boolean(anchorElBeziehungenPopover);
  const openBeratungsintervallPopover = Boolean(anchorElBeratungsintervallPopover);
  const oldPNR = useSelector(state => state.updateoldpnr);
  const [openModalAufgaben, setOpenModalAufgaben] = useState(false);
  const handleOpenAufgaben = () => setOpenModalAufgaben(true);
  const [openDokuModal, setopenDokuModal] = useState(false);
  const isModalOpenFromDispatch = useSelector(state => state.isModalOpen);
  const handleOpenDoku = () => {
    setValue(0);
    if (window.chrome && window.chrome.webview && isDesktop) 
    { 
        window.chrome.webview.postMessage(['HideDateiablage', '']);
    }
    setopenDokuModal(true);  
    dispatch(toggleModalBeratungsdokumentation(true));   
  }

  const dispatch = useDispatch();
  const refreshSchäden = useSelector((state) => state.refreshDataKundenSchaeden);  
  const [value, setValue] = React.useState(0);
  const [valueRating, setValueRating] = useState(null);
  const [lastUpdatePA, setLastUpdatePA] = useState("Ablaufdatum: unbekannt");
  const [LastPASX, setLastPASX] = useState('primary');
  const [PAvorhanden, setPAvorhanden] = useState(true);
  const [handleDialogPAOpen, setDialogPAOpen] = useState(false);
  const data = useSelector(state => state.eventdata);
  const [refetchData, setRefetchData] = useState(false);
  const [dataAlleTerminePNR, setdataAlleTerminePNR] = useState({});
  const [dataAlleSchädenPNR, setdataAlleSchädenPNR] = useState({});
  const setloadingKunde = useSelector(state => state.loading);
  const navigate = useNavigate();
  const [KurzinfoTextFieldValue, setKurzinfoTextFieldValue] = useState();
  const [datePAValue, setDatePA] = React.useState('');
  const [showloadingPAUpload, setshowloadingPAUpload] = useState(false);
  const [age, setAge] = useState(null);
  const [openDialogKinder, setOpenDialogKinder] = useState(false);
  const [NameKind, setNameKind] = useState('');
  const [VornameKind, setVornameKind] = useState('');
  const [GebDatumKind, setGebDatumKind] = useState('');
  const [GeschlechtKind, setGeschlechtKind] = useState('');  
  const [loadingKundenbeziehungen, setloadingKundenbeziehungen] = useState(null);
  //Simple OK Dialog
  const [openOkDialog, setopenOkDialog] = useState(false);
  const [PartnerzusammenführenDialog, setPartnerzusammenführenDialog] = useState(false);
  const [NamenändernDialog, setNamenändernDialog] = useState(false);
  const [messageOkDialog, setmessageOkDialog] = useState('');
  const AXADBVColor = useSelector(state => state.axaDbvColor);
  const PreviousPNR = useSelector(state => state.pnrprocessed);
  const [newPartnernummerZusammenführung, setnewPartnernummerZusammenführung] = useState('');
  const [alterNachnameNamensänderung, setalterNachnameNamensänderung] = useState('');
  const [alterVornameNamensänderung, setalterVornameNamensänderung] = useState('');
  const [optionsBearbeiter, setoptionsBearbeiter] = useState([]);
  const Appbarheight = useSelector(state => state.Appbarheight);
  const resizeTimer = useRef(null); // Store the timer ID for throttling
  const [firmeninfosstate, setfirmeninfosstate] = useState(() => {
    const storedData = localStorage.getItem('firmenInfos');
    return storedData ? JSON.parse(storedData) : {};
  });


  const [showDialog, setShowDialog] = useState(false);
  const handleShowDialogDelete = () => setShowDialog(true);
  const handleHideDialogDelete = () => setShowDialog(false);
  const handleConfirmDialogDelete = () =>
  {
      fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchDeleteKunden: 1,
          ID: data.ID
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data) => {
        handleNavigation("/dashboard/KundenSuchePage")
      });
      setShowDialog(false);
  }

  useEffect(() => {
    const handleResize = () => {
      const appBarElement = document.querySelector('.app-bar-content');
      const userkundecard = document.querySelector('.userkunde-card-content');

      if (appBarElement && userkundecard) {
        const rect = appBarElement.getBoundingClientRect();
        const adjustedHeight = rect.height * window.devicePixelRatio;

        const rectcard = userkundecard.getBoundingClientRect();
        const cardHeightadjustedheight = rectcard.height * window.devicePixelRatio;

        if (window.chrome && window.chrome.webview && isDesktop) {
          const totalHeight = adjustedHeight + cardHeightadjustedheight;
          dispatch({ type: 'SET_CARD_HEIGHT', payload: totalHeight });
          window.chrome.webview.postMessage(['AppandCardHeight', totalHeight]);
        }
      }
    };

    const initialResize = () => {
      handleResize();
      const appBarElement = document.querySelector('.app-bar-content');
      const userkundecard = document.querySelector('.userkunde-card-content');
      if (!appBarElement || !userkundecard) {
        setTimeout(initialResize, 100); 
      }
    };

    initialResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, isDesktop]);

  const [AXALOGOOpacity, setAXALOGOOpacity] = useState(1);
  const [AXAimageSrc, setAXAimageSrc] = useState(CustomAXAImage);

  const componentTheme = createTheme({
    palette: {
      primary: { main: AXADBVColor },
      success: { main: "#14a37f" },
      info: { main: "#2c387e"},
      error: { main: "#ff6d75" }
    }
  });

  const [snackbar, setSnackbar] = useState({
      open: false,
      message: "",
      severity: "error"
  });

  const handleSnackbarClose = () => {
      setSnackbar({
      open: false,
      message: "",
      severity: "error"
      });
  }

  const handleStartPartnerzusammenführen = () => {
      if (!newPartnernummerZusammenführung.trim()) {
        setSnackbar({
              open: true,
              message: "Bitte trage die neue Partnernummer ein.",
              severity: "error"
        });
        return;
      }
 
    //als erstes versuchen wir die dateien zu verschieben
    if (window.chrome && window.chrome.webview && isDesktop) 
    {   
        window.chrome.webview.postMessage(['PartnerzusammenführungStarted',  data.PNr, newPartnernummerZusammenführung]);
    }
    // Die Antwort hier processen wir in AppBar.jsx!!!!!  
  }

  const handleStartNamensänderung = () => {
      if (!alterNachnameNamensänderung.trim() && !alterVornameNamensänderung.trim()) {
        setSnackbar({
              open: true,
              message: "Bitte trage zunächst den alten Vor- und Nachnamen ein.",
              severity: "error"
        });
        return;
      }
 
    //als erstes versuchen wir die dateien zu verschieben
    if (window.chrome && window.chrome.webview && isDesktop) 
    {   
        window.chrome.webview.postMessage(['NamensänderungDurchführen', alterNachnameNamensänderung, alterVornameNamensänderung, data.Nachname, data.Vorname]);
    }
    // Die Antwort hier processen wir in AppBar.jsx!!!!!  
  }

  const handleOpenDialog = (message) => {
    setmessageOkDialog(message);
    setopenOkDialog(true);
  };

  const closeOKDialog = () => {
    setopenOkDialog(false);
  };

  //Beratungs-Intervall 
  const [intervalType, setIntervalType] = useState("");
  const [intervalDays, setIntervalDays] = useState("");

  const handleRadioChangeIntervall = (event) => {
      setIntervalType(event.target.value);
      let intervaldaystochange  = 0;
      event.target.value == "1" ? intervaldaystochange = 365 : event.target.value == "2" ? intervaldaystochange = 730 : event.target.value == "3" ? intervaldaystochange = 1095 : intervaldaystochange = 0
      setIntervalDays(intervaldaystochange);
      updateKundenTerminIntervall(intervaldaystochange)
    };

  const handleTextChangeIntervall = (event) => {
      const intervaldaystochange = parseInt(event.target.value);
      setIntervalDays(intervaldaystochange);
      updateKundenTerminIntervall(intervaldaystochange);
      setIntervalType("4");
  };


  // useEffect(() => {
  //   if (userStats !== null) {

  //     console.log(userStats);
  //     // Perform any actions you need with the updated userStats
  //   }
  // }, [userStats]);

  const handleSwitchAXADBV = (event) => {

    updateUserStats(user.vollstaendigerName, "update", "kunden", "Kunde zwischen AXA und DBV gewechselt", data.PNr, "");

    (function() {
      let AXADBV;
      if (AXADBVColor === "#2e3192") {
        dispatch(setAXADBVColor(colorDBV));
        setAXAimageSrc(CustomDBVImage);
        AXADBV = "DBV";
      } else {
        dispatch(setAXADBVColor(colorAXA));
        setAXAimageSrc(CustomAXAImage);
        AXADBV = "AXA";
      }

      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: "POST",
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchUpdateAXADBV: 1,
          AXADBV: AXADBV,
          pnr: data.PNr,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data2) => {
            const updatedData = {
              ...data,
              Segment: AXADBV,
            };
            dispatch(updateEventData(updatedData));
        });
    })();
  };

  const updateKundenTerminIntervall = (event) => 
  {
    if (!data.LetzterKundentermin)
    { return; }
    const datum = data.LetzterKundentermin.datum;
    const kuerzel = user.kürzel;
    const intervall = event;
    const betreuer = user.vollstaendigerName;
    const nameuvorname = data.Nachname + ", " + data.Vorname;
    const pnr = data.PNr;

    const kundenterminArray = {
      datum,
      kuerzel,
      intervall,
      betreuer,
      nameuvorname,
      pnr
    };

    const ArrayToString1 = JSON.stringify(kundenterminArray, null, 2);
    updateUserStats(user.vollstaendigerName, "update", "kunden", "Intervall für nächsten Kundentermin geändert", data.PNr, ArrayToString1);

    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchAddorUpdateLetzterKundenTermin: 1,
            kundenterminArray: kundenterminArray,
            pnr:data.PNr,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data2) => {
          const updatedData = {
            ...data,
            LetzterKundentermin: kundenterminArray,
          };
          dispatch(updateEventData(updatedData));
      });     
  }

  const handleAddLetzterKundentermin = () =>
   {
    const datum = moment().format('DD.MM.YYYY');
    const kuerzel = user.kürzel;
    let intervall = 365;
    const betreuer = user.vollstaendigerName;
    intervalDays != "" ? intervall = intervalDays : intervall = 365;
    const nameuvorname = data.Nachname + ", " + data.Vorname;
    const pnr = data.PNr;

    const kundenterminArray = {
      datum,
      kuerzel,
      intervall,
      betreuer,
      nameuvorname,
      pnr
    };

    const ArrayToString1 = JSON.stringify(kundenterminArray, null, 2);
    updateUserStats(user.vollstaendigerName, "update", "kunden", "Letzter Kundentermin aktualisiert", data.PNr, ArrayToString1);

    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchAddorUpdateLetzterKundenTermin: 1,
            kundenterminArray: kundenterminArray,
            pnr:data.PNr,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data2) => {
          const updatedData = {
            ...data,
            LetzterKundentermin: kundenterminArray,
          };
          dispatch(updateEventData(updatedData));
      });

      //Delete Auto Generated Intervall Termin, falls dieser vorhanden ist.
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchDeleteIntervallAufgabe: 1,
            pnr:data.PNr,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data2) => {
      });
}

  useEffect(() => {
    if (!data)
    { return }

    if (data.PNr !== PreviousPNR)
    {
      setValue(0);
    }

    //Fetch Bearbeiter
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadBearbeiter: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json()).then((data) => {
        setoptionsBearbeiter(data);
    });

    dispatch({ type: "ADD_VISITED_CUSTOMER", payload: data });                               

    const currentDate = moment().format("YYYY-MM-DD");
    const lastVisitDate = localStorage.getItem(`lastVisitDate_${data.PNr}`);
    
    // Set Beratungsintervall:
    // Set the initial value of intervalType and intervalDays based on the intervall property
    if (data.LetzterKundentermin && data.LetzterKundentermin.intervall !== undefined) {
      if (data.LetzterKundentermin.intervall == "365") {
        setIntervalType("1");
        setIntervalDays("365");
      } else if (data.LetzterKundentermin.intervall == "730") {
        setIntervalType("2");
        setIntervalDays("730");
      } else if (data.LetzterKundentermin.intervall == "1095") {
        setIntervalType("3");
        setIntervalDays("1095");
      } else if (data.LetzterKundentermin.intervall == "0") {
        setIntervalType("0");
        setIntervalDays("0");
      } 
      else {
        setIntervalDays(data.LetzterKundentermin.intervall);
        setIntervalType("4");
      }
    }

    setshowalertAusbildung({ display: "none" });

    setKurzinfoTextFieldValue(data.kurzinfo || "");
    setValueRating(data.Bewertung || null);
    setPAvorhanden(data.PAAblauf || null);
    setDatePA(data.PAAblauf || "");
    data.PAAblauf
      ? setLastUpdatePA("Ablaufdatum: " + moment(data.PAAblauf, 'YYYY-MM-DD').format('DD.MM.YYYY'))
      : setLastUpdatePA("Kein Ablaufdatum gespeichert.");

    const today = moment();
    const birthdate = moment(data.gebdatum, 'DD.MM.YYYY'); 

    if (data.gebdatum) {  
        // Berechnen des Alters 
        setAge(today.diff(birthdate, 'years'));    
    }

    const difference = moment().diff(moment(data.LetzteAktualisierung, "DD.MM.YYYY - HH:mm:ss"), 'days');
    // Check if the current date is the same as the last visit date
    
    if (lastVisitDate !== currentDate) {
      // Execute this code only if it's the first visit of the day
      if (firmeninfosstate.checkboxkundenaktualisierunganzeigen === true && firmeninfosstate.dauerletzteaktualisierunganzeigen > 0)
      {
        setShowalertletzteAktualisierung({
          borderRadius: 0,
          display: (difference > firmeninfosstate.dauerletzteaktualisierunganzeigen || isNaN(difference)) ? "flex" : "none"
        });
      } 
      
      if (data.gebdatum) { 
        // Berechnen des Datumsbereichs für die Geburtstagsüberprüfung
        const threeDaysBeforeToday = moment().subtract(3, 'days');
        const threeDaysAfterToday = moment().add(1, 'days');    
        // Kopieren des Geburtsdatums in das aktuelle Jahr, um nur den Tag und Monat zu vergleichen
        const thisYearBirthday = birthdate.clone().year(today.year());    
        // Überprüfen, ob der Geburtstag innerhalb des berechneten Bereichs liegt
        if (thisYearBirthday.isBetween(threeDaysBeforeToday, threeDaysAfterToday, 'day', '[]')) {
          // Der Geburtstag liegt innerhalb von drei Tagen vor oder nach dem heutigen Tag
          setshowalertGeburtstag({display: "flex"});  
          const geburtstagWithoutYear = moment(data.gebdatum, 'DD.MM.YYYY').format('DD.MM.');

          if (thisYearBirthday.isBefore(today, 'day'))
          {
            setGeburtstagstext(`hatte am ${geburtstagWithoutYear} Geburtstag`);
          } else if ((thisYearBirthday.isSame(today, 'day')))
          {
            setGeburtstagstext("hat heute Geburtstag")
          } else
          {
            setGeburtstagstext(`hat am ${geburtstagWithoutYear} Geburtstag`);
          }
        } else {
          // Der Geburtstag liegt nicht in diesem Bereich
          setshowalertGeburtstag({display: "none"});  
        }
      }

    } else if (lastVisitDate === currentDate && moment().diff(moment(data.LetzteAktualisierung, "DD.MM.YYYY - HH:mm:ss"), 'days') < firmeninfosstate.dauerletzteaktualisierunganzeigen)
    {
        setShowalertletzteAktualisierung({
          borderRadius: 0,
          display: "none"
        });

        setshowalertGeburtstag({display: "none"});  
    }

    if (firmeninfosstate.dauerletzteaktualisierunganzeigen > 0)
    {
      setColorLetzteAktualisierung((difference > firmeninfosstate.dauerletzteaktualisierunganzeigen || isNaN(difference)) ? "error" : "primary");
    }


    // Set AXA DBV Color 
    data.Segment === "AXA"
      ? dispatch(setAXADBVColor(colorAXA)) && setAXAimageSrc(CustomAXAImage)
      : data.Segment === "DBV"
      ? dispatch(setAXADBVColor(colorDBV)) && setAXAimageSrc(CustomDBVImage)
      : dispatch(setAXADBVColor(colorAXA)) && setAXAimageSrc(CustomAXAImage);
    

    // Save the current date as the last visit date
    localStorage.setItem(`lastVisitDate_${data.PNr}`, currentDate);

    const fetchSchädenPartnernummer = fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSchädenPartnernummer: 1,
          pnr: data.PNr
      }),
      headers: {
          'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((datanew) => { 
      setdataAlleSchädenPNR(datanew);            
    });

    const fetchTerminePartnernummer = fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchTerminePartnernummer: 1,
          pnr: data.PNr
      }),
      headers: {
          'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((datanew) => { 
      setRefetchData(false);  
      setdataAlleTerminePNR(datanew);   
      if (lastVisitDate !== currentDate) {
        datanew.forEach((obj) => {
          const { TerminTitleText, TerminDateText, TerminArt } = obj;
          const isAusbildung = TerminTitleText.includes('Ausbildung');
          const isPHV = TerminTitleText.includes('PHV');
          const isAusbildung2 = TerminArt === 'Ausbildung' || TerminArt === 'PHV-Aktion';
          const date = moment(TerminDateText);
          const endOfYear = moment().endOf('year');
          const isBeforeEndOfYear = date.isBefore(endOfYear);
          if ((isAusbildung || isPHV || isAusbildung2) && isBeforeEndOfYear) {
            setshowalertAusbildung({display: "flex"});  
          }       
        }); 
      }              
    });

    Promise.all([fetchSchädenPartnernummer, fetchTerminePartnernummer]).then(() => {
      dispatch(setloading(false));
    });

    const handleMessage = (event) => {        
        if (event.data && Array.isArray(event.data) && event.data.length > 0 && event.data[0].PNBeziehungName) {

          let arrayBeziehungen;
          if (event.data[0].PNBeziehungName === "noentries") {
            arrayBeziehungen = [];
            const updatedData = {
            ...data,
              Beziehungen: []
            };
            dispatch(updateEventData(updatedData));
          } else {
            const updatedData = {
              ...data,
              Beziehungen: event.data.map((beziehung) => {
                return {
                  Anrede: beziehung.Anrede,
                  GebDatum: beziehung.GebDatum,
                  PNBeziehungName: beziehung.PNBeziehungName,
                  PNr: beziehung.PNr,
                  bezart: beziehung.bezart,
                };
              })
            };
            dispatch(updateEventData(updatedData));
            arrayBeziehungen = event.data;
          }

          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              fetchAddBeziehungen: 1,
              pnr: data.PNr,
              arrayBeziehungen: arrayBeziehungen,
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            return response.json();
          })
          .then((data2) => {   
             setloadingKundenbeziehungen(false);         
          })
          .catch((error) => {
          });  
        }      
      };
      window.chrome?.webview?.addEventListener("message", handleMessage);

      // Remove event listener on unmount
      return () => {
        dispatch(updatejustoldPnr(data.PNr));
        window.chrome?.webview?.removeEventListener("message", handleMessage);
      };    
  }, [data, refetchData, refreshSchäden]);

  //Wir setzen hier die Pnr, nachdem das loading beendet wurde. 
  //Diese benötigen wir bspw. für die Kontakthistorie.
  useEffect(() => {
      if (!setloadingKunde && data)
      {
          dispatch(updatePnr(data.PNr));   
      }

  }, [setloadingKunde]);

  const handleRefetchData = () => {
    setRefetchData(!refetchData);
  };

  const handleopenBeziehungen = () => {
    if (window.chrome && window.chrome.webview && isDesktop && localStorage.getItem("setcheckboxEVTaufrufen") === "true")
    {
      setloadingKundenbeziehungen(true);
      var dataarray = ['PNRBeziehungÜbertragen'];
      window.chrome.webview.postMessage(dataarray);    
    }     
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loadingKundenbeziehungen) {
        setloadingKundenbeziehungen(false);        
      }
    }, 3000);        
    // Clear the timeout when the component unmounts or when loadingKundenbeziehungen changes
    return () => clearTimeout(timeoutId);
  }, [loadingKundenbeziehungen]);

  const handleOpenPartnerbeziehnung = (PNR, myname1, mygebdatum) => {
        let fullName = myname1; 
        let myname;
        let myvorname;
        if (fullName) { // Check if fullName is not null, undefined, or empty
            let nameArray = fullName.split(',');

            myname = nameArray[0];
            myvorname = (nameArray.length > 1) ? nameArray[1].trim() : ""; // Check if there is a second part
        }        

        setanchorElBeziehungenPopover(null)
        dispatch(setloading(true));
        fetch(`${fetchdataUrl}fetchdata.php`, {
              method: 'POST',
              body: JSON.stringify({
                  access_token: sessionStorage.getItem("access_token"),
                  fetchUserDataBaseBeziehung: 1,
                  pnr: PNR,
              }),
              headers: {
                  'Content-Type': 'application/json'
              }
              }).then((response) => {
                  return response.json();
              }).then((data2) => {
                  if (data2 === null)
                  {
                      //Wir suchen dann mit Name, Vorname und Geburtsdatum nochmal !! KANN eigentlich nur bei Interessenten passieren, da hier die Partnernummer nicht stimmt.
                      fetch(`${fetchdataUrl}fetchdata.php`, {
                      method: 'POST',
                      body: JSON.stringify({
                          access_token: sessionStorage.getItem("access_token"),
                          fetchUserDataBaseBeziehungWithNameundGeb: 1,
                          name: myname,
                          vorname: myvorname,
                          gebdatum: mygebdatum
                      }),
                      headers: {
                          'Content-Type': 'application/json'
                      }
                      }).then((response) => {
                          return response.json();
                      }).then((data3) => {
                          if (data3 === null)
                          {                            
                            dispatch(setloading(false));
                            return;
                          }
                          navigateToCustomer(data3.PNr);                                                                                         
                    }); 
                    return;
                  }

                  navigateToCustomer(data2.PNr);                                                                               
        });   

        // localStorage.setItem("currentPNR", PNR) 
  }

 const handleDeleteChild = (index) => {
    // Create new array without selected child
    const updatedKinder = [...data.Kinder];
    updatedKinder.splice(index, 1);

    // Update data with new Kinder array
    const updatedData = {
      ...data,
      Kinder: updatedKinder
    };

    // Dispatch updated data to Redux store
    dispatch(updateEventData(updatedData));

    // Make fetch call to update database
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchUpdateKinder: 1,
        pnr: data.PNr,
        kinder: updatedKinder
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((data2) => {
      // Do something with response if needed
    })
    .catch((error) => {
      // Handle error
    });
};

  const handleAddChild = () => {

    if (!NameKind || !VornameKind || !GebDatumKind || !GeschlechtKind) {
      handleOpenDialog("Bitte fülle zunächst alle Felder aus.")
      // display error message or take any other appropriate action
      return;
    }

    const newChild = {
      Name: NameKind,
      Surname: VornameKind,
      Birthdate: GebDatumKind,
      Geschlecht: GeschlechtKind
    };

    // Create new array with new child, or add to existing array
    const updatedKinder = data.Kinder ? [...data.Kinder, newChild] : [newChild];

    // Update data with new kinder array
    const updatedData = {
      ...data,
      Kinder: updatedKinder
    };
    
    dispatch(updateEventData(updatedData));

    const ArrayToString1 = JSON.stringify(updatedKinder, null, 2);
    updateUserStats(user.vollstaendigerName, "update", "kunden", "Neues Kind hinzugefügt", data.PNr, ArrayToString1);

    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchAddChildren: 1,
        pnr: data.PNr,
        name: NameKind,
        surname: VornameKind,
        birthdate: GebDatumKind,
        Geschlecht: GeschlechtKind
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((data2) => {
    
    })
    .catch((error) => {
      // Handle error
    });

    const wordsToMatch = ['Ausbildung', NameKind, VornameKind];
    let matchFound = false;

    dataAlleTerminePNR.map(item => {
      if (item.TerminTitleText) {
        const words = item.TerminTitleText.split(' ');
        if (wordsToMatch.every(wordToMatch => words.includes(wordToMatch))) {
            matchFound = true;
        }
      } 
    });

    if (!matchFound) {
        let betreuerValue;
        if (data.Betreuer !== "")
        {
            const matchingBetreuer = optionsBearbeiter.find(betreuer => betreuer.Organummer === data.Betreuer); 
            betreuerValue = matchingBetreuer ? matchingBetreuer.vollstaendigerName : user.vollstaendigerName;
        }

        const gebDatumPlus23Years = moment(GebDatumKind).add(23, 'years');

        if (gebDatumPlus23Years.isBefore(moment())) {
            handleOpenDialog("Neues Kind angelegt. Es wurde kein Ausbildungstermin angelegt, da das Kind zu alt ist.");
        } else {
            // Berechne das Datum 15 Jahre nach dem Geburtsdatum
            const gebDatumPlus15Years = moment(GebDatumKind).add(15, 'years').startOf('year');
            
            // Überprüfe, ob das Kind jünger als 23 ist und der Termin vor dem letzten 01.01. liegt
            if (moment().isAfter(gebDatumPlus15Years) && moment().isBefore(moment().startOf('year').add(1, 'year'))) {
                // Automatische Anlage Ausbildungstermin zum nächsten 01.01.
                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchAddTermin: 1,
                        TerminNameVN: data.Nachname,
                        TerminVornameVN: data.Vorname,
                        TerminPartnernummer: data.PNr,
                        TerminDateText: moment().startOf('year').add(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
                        TerminBearbeiterText: betreuerValue,
                        TerminTitleText: "Ausbildung - " + VornameKind + " " + NameKind + " (" + GeschlechtKind + ")",
                        TerminArt: "Ausbildung",
                        BemerkungenTermin: null,
                        TerminFelder: null
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    handleRefetchData();
                    handleOpenDialog("Neues Kind angelegt. Zusätzlich wurde ein Ausbildungstermin zum 01.01. erstellt.");
                });
            } else {
                // Automatische Anlage Ausbildungstermin zum 15. Geburtstag
                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchAddTermin: 1,
                        TerminNameVN: data.Nachname,
                        TerminVornameVN: data.Vorname,
                        TerminPartnernummer: data.PNr,
                        TerminDateText: gebDatumPlus15Years.format('YYYY-MM-DD HH:mm:ss'),
                        TerminBearbeiterText: betreuerValue,
                        TerminTitleText: "Ausbildung - " + VornameKind + " " + NameKind + " (" + GeschlechtKind + ")",
                        TerminArt: "Ausbildung",
                        BemerkungenTermin: null,
                        TerminFelder: null
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    handleRefetchData();
                    handleOpenDialog("Neues Kind angelegt. Zusätzlich wurde ein Ausbildungstermin zum 15. Geburtstag erstellt.");
                });
            }
        }

        } else {
                  handleOpenDialog("Neues Kind angelegt. Es wurde kein Ausbildungstermin angelegt, da für dieses Kind bereits ein Termin besteht.")        

        }
  }

  const handleOpenAdressLink = () => {
    // Replace spaces in the address with plus signs
    const address = `${data.Straße}, ${data.PLZOrt}`.replace(/ /g, '+');

    // Open a new window with the Google Maps URL
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${address}`);
  };

  const handleChange = (event, newValue) => {
    if (newValue === 2)
    {
        setshowalertAusbildung({display: "none"});  
        setShowalertletzteAktualisierung({display: "none"});  
    }
    setValue(newValue);
  };

  const handleDatePAChange = (event) => {
    setDatePA(event.target.value);
  };

  function handleChangekurzinfo(event) {
    setKurzinfoTextFieldValue(event.target.value);
  }

  const handleNavigation = (path) => {
    if (path) {
      navigate(path);
    }
  };

  const handleClosePAAblauf = () => {
    setshowloadingPAUpload(false);
    setDialogPAOpen(false);
  };

  const handlePAAblaufspeichern = () => {
    var datetochange = null;
    datePAValue == "" ? datetochange = null: datetochange = datePAValue;

    updateUserStats(user.vollstaendigerName, "update", "kunden", "Personalausweisdaten hinterlegt oder abgeändert", data.PNr, "");

    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSetAblaufPA: 1,
          pnr: data.PNr,
          text: datetochange
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
          setDialogPAOpen(false);
          data2 ? setLastUpdatePA("Ablaufdatum: " + moment(data2, 'YYYY-MM-DD').format('DD.MM.YYYY')) : setLastUpdatePA("Kein Ablaufdatum gespeichert.")

          if (window.chrome && window.chrome.webview && isDesktop) 
          { 
              window.chrome.webview.postMessage(['PAUploaded', moment(data2, 'YYYY-MM-DD').format('DD.MM.YYYY'), data2.PNr ]);
          }

          const updatedData = {
            ...data,
            PAAblauf: datetochange,
          };
          dispatch(updateEventData(updatedData));

          setshowloadingPAUpload(false);     
          setPAvorhanden(true);               
    });
  };

  const handlesaveKurzInfos = () => {

    updateUserStats(user.vollstaendigerName, "update", "kunden", "Kurzinformationen geändert", data.PNr, KurzinfoTextFieldValue);

    //update kurzInfos
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSetKurzinfos: 1,
          pnr: data.PNr,
          text: KurzinfoTextFieldValue,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
        const updatedData = {
          ...data,
          kurzinfo: KurzinfoTextFieldValue,
        };
        dispatch(updateEventData(updatedData));
    });
  }

  const handleSetValueRating = (value) => {

    updateUserStats(user.vollstaendigerName, "update", "kunden", "Kundenrating geändert", data.PNr, "Rating: " + value);

    setValueRating(value)
    //update valuerataing
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchsetvaluerating: 1,
          pnr: data.PNr,
          valuerating: value,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
          const updatedData = {
          ...data,
          Bewertung: value,
        };
        dispatch(updateEventData(updatedData));
    });
  }

  useEffect(() => {
    const difference = moment().diff(moment(datePAValue, 'YYYY-MM-DD'), 'days');
    if (PAvorhanden && difference < 0 && !isNaN(difference)) {
      setLastPASX('primary');
    } else if (PAvorhanden && difference > 0) {
      setLastPASX('error');
    } else {
      setLastPASX('error');
    }
  }, [PAvorhanden, datePAValue]);

  const Handlesetlablepa = () => {
    if (PAvorhanden) {
      const difference = moment().diff(moment(datePAValue, 'YYYY-MM-DD'), 'days');
      if (difference < 0 && !isNaN(difference)) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: "4px" }}>
            <span><b>PA</b></span>
            <DoneSharp sx={{ width: '15px', height: '15px', ml: 0.5 }} color="primary" />
          </div>
        );
      } else {
        return (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: "4px" }}>
            <span><b>PA</b></span>
            <ElderlySharp sx={{ width: '15px', height: '15px', ml: 0.5 }} color="error" />
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span><b>PA</b></span>
          <CloseSharp sx={{ width: '15px', height: '15px', ml: 0.5 }} color="error" />
        </div>
      );
    }
  };

  const handlePAUploadClick = () => {
    setValue(0);
    if (window.chrome && window.chrome.webview && isDesktop) 
    { 
        window.chrome.webview.postMessage(['HideDateiablage', '']);
    }
    setshowloadingPAUpload(true)
    setDialogPAOpen(true);  
  };

  const handleLoadPAClick = () => {
    if (window.chrome && window.chrome.webview && isDesktop) 
    { 
        window.chrome.webview.postMessage(['PAOpen']);
    }
  }
  
  const [showDialogDatenteilung, setshowDialogDatenteilung] = useState(false);
  const handleShowDialogDatenteilung = () => setshowDialogDatenteilung(true);
  const handleHideDialogDatenteilung = () => setshowDialogDatenteilung(false);

  const handleConfirmDialogDatenteilung = () => {
    const updatedData = { ...data, Datenteilung: 1 };
  
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchUpdateDatenteilung: 1,
        pnr: data.PNr,
        datenteilung: 1,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(() => dispatch(updateEventData(updatedData)));
  
    if (window.chrome?.webview && isDesktop) {
      window.chrome.webview.postMessage(["ChangeDatenteilung"]);
    }
  
    setshowDialogDatenteilung(false);
  };
  
  const handleChangeDatenteilung = () => {
    updateUserStats(user.vollstaendigerName, "update", "kunden", "Datenteilung des Kunden aktualisiert", data.PNr, "");
  
    const setDatenteilung = data?.Datenteilung === 0 ? null : 0;
  
    if (data?.Datenteilung === 0) {
      setshowDialogDatenteilung(true);
      return;
    }
  
    const updatedData = { ...data, Datenteilung: setDatenteilung };
  
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchUpdateDatenteilung: 1,
        pnr: data.PNr,
        datenteilung: setDatenteilung,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(() => dispatch(updateEventData(updatedData)));
  };

  useEffect(() => {

    if (!data || !data.PNr || user && user.invisible) {
      return;
    }

    if (data.PNr === PreviousPNR)
    {
      return;
    }  
    
    //update LetzterKundenkontakt - nicht im useeffect sondern hier, damit es nicht jedes mal geupdated wird.
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSetletzterKundenkontakt: 1,
          pnr:data.PNr,
          kürzelbearbeiter: user.kürzel,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
    });
  }, [data?.PNr]);


  const handleLetzteAktualisierung = () => {

    updateUserStats(user.vollstaendigerName, "update", "kunden", "Kontaktdaten des Kunden aktualisiert", data.PNr, "");

    setcheckIfAktualisierungDispatch(true); 

    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchSetletzteAktualisierung: 1,
          pnr:data.PNr,
          kürzelbearbeiter: user.kürzel,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
        const updatedData = {
          ...data,
          LetzteAktualisierungName: user.kürzel,
          LetzteAktualisierung: data2[data2.length - 1][0],
          LetzteAktualisierungAlle: data2
        };
        dispatch(updateEventData(updatedData));
        setcheckIfAktualisierungDispatch(false); // set the flag to false after the dispatch is executed
    });
  };

    const handleResetLetzteAktualisierungen = () => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchResetLetzteAktualisierungen: 1,
          pnr:data.PNr,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
        const updatedData = {
          ...data,
          LetzteAktualisierung: null,
          LetzteAktualisierungAlle: null,
          LetzteAktualisierungName: null
        };
        dispatch(updateEventData(updatedData));
    });
  };

   const handleResetLetzteKundenkontakte = () => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchResetLetzteKundenkontakte: 1,
          pnr:data.PNr,
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data2) => {
        const updatedData = {
          ...data,
          LetzterKontakt: null,
          LetzterKontaktAlle: null,
          LetzterKontaktName: null
        };
        dispatch(updateEventData(updatedData));
    });
  };

  const handleOpenHistorieLetzteAktualisierung = (event) => {
      event.preventDefault();
      setAnchorElHistorieLetzteAktualisierung(event.currentTarget);
  };

  const handleCloseHistorieLetzteAktualisierung = (event) => {
      setAnchorElHistorieLetzteAktualisierung(null);
  };

  const handleOpenHistorieLetzterKontakt = (event) => {
      event.preventDefault();
      setAnchorElHistorieLetzterKontakt(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorElHistorieLetzterKontakt(null);
  };

  const closeAlertDaten = () => {
    setShowalertletzteAktualisierung({ display: "none" });
  };

  const closeAlertAusbildung = () => {
    setshowalertAusbildung({ display: "none" });
  };

  const closeAlertGeburtstag = () => {
    setshowalertGeburtstag({ display: "none" });
  };

return (
  <div>
    {data ? (
      !setloadingKunde ?
      <ThemeProvider theme={componentTheme}>
      <div style={{position: "relative"}}>        
        <div style={{position: "absolute", top: 0, left: 0, width: '100%', zIndex: 9999}}>
        <Snackbar   anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
            </Alert>
        </Snackbar>
        <Alert  onClose={closeAlertGeburtstag} sx={{ ...showalertGeburtstag }} icon={<CakeSharp></CakeSharp>} severity="info">
          <AlertTitle>Geburtstag 🥳</AlertTitle>
          {data.Vorname} {Geburtstagstext} - <strong>Happy Birthday!</strong>
        </Alert>
        {/* <Alert onClose={closeAlertGeburtstag} severity="info" sx={{ ...showalertGeburtstag }}><CakeSharp sx={{color: "#ff6d75"}}></CakeSharp>.</Alert> */}
        {firmeninfosstate && firmeninfosstate.dauerletzteaktualisierunganzeigen !== undefined && (
          <Alert
            onClose={closeAlertDaten}
            severity="error"
            sx={showalertletzteAktualisierung}
          >
            Die Kundendaten dieses VN wurden in den letzten <b>{firmeninfosstate.dauerletzteaktualisierunganzeigen} Tagen</b> nicht aktualisiert. Bitte gleiche die Kundendaten ab und bestätige dies mit dem Button zur letzten Aktualisierung.
          </Alert>
        )}
        <Alert onClose={closeAlertAusbildung} severity="info" sx={{ ...showalertAusbildung }}>Für diesen VN besteht ein Ausbildungs- oder PHV-Termin, welcher auf dieses oder ein vorheriges Jahr datiert ist. Bitte erfrage dringend den aktuellen Stand.
          <span> <Link onClick={handleOpenAufgaben} sx={{ color: "#014361", cursor: "pointer" }}><b>Aktuellen Stand direkt als Aufgabe an den zuständigen Betreuer weiterleiten.</b></Link></span>
        </Alert>
        </div>
        <Card className='userkunde-card-content' style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, filter: showalertletzteAktualisierung.display === "none" && showalertAusbildung.display === "none" && isModalOpenFromDispatch === false ? "none" : "blur(10px)", pointerEvents: showalertletzteAktualisierung.display === "none" && showalertAusbildung.display === "none" ? "auto" : "none" }}>  
          <AufgabenModal openModalAufgaben={openModalAufgaben} setOpenModalAufgaben={setOpenModalAufgaben} reloadData={null}></AufgabenModal>
          <BeratungsdokuModal openDokuModal={openDokuModal} setopenDokuModal={setopenDokuModal} vndata={data} alletermine={dataAlleTerminePNR}></BeratungsdokuModal>
          <ConfirmDialog
              open={showDialogDatenteilung}
              onClose={() => setshowDialogDatenteilung(false)}
              message={<div>Soll die Datenteilung <b>automatisch</b> erfasst werden? Bitte beachte, dass der Kunde hierüber <u>schriftlich</u> informiert wird.</div>}
              onConfirm={handleConfirmDialogDatenteilung}
              onCancel={handleHideDialogDatenteilung}
          />       
          <Tabs value={value} onChange={handleChange}>
            <Tab label={`Partnerdaten [${data.PNr ? data.PNr.length > 6 ? data.PNr.substring(0, 6) + '...' : data.PNr : ''}]`} />    
            <Tab label={`Termine [${dataAlleTerminePNR.length}]`} />
            <Tab label="Dateiablage" />
            <Tab label={`Schäden [${dataAlleSchädenPNR.length}]`} />
            <Tab label={`Beratungshilfen`} />
          </Tabs>     
          <OkDialog open={openOkDialog} message={messageOkDialog} onClose={closeOKDialog} />
          <CardContent>  
          <Grid container spacing={0}>
            {/* Avatar und Personalausweis */}
            <Grid item style={{width: "100px", marginLeft: "10px"}}>
              <Stack direction="column">
            <Icon
                onClick={handleSwitchAXADBV}
                onMouseEnter={() => {
                  setAXALOGOOpacity(0.5);
                }}
                onMouseLeave={() => {
                  setAXALOGOOpacity(1);
                }}
                style={{paddingLeft: "20px", height: '40px', width: '40px', opacity: AXALOGOOpacity }}
              >
                <img
                  style={{ height: '40px', width: '40px' }}
                  src={AXAimageSrc}
            />
            </Icon>
              <Avatar sx={{width: "60px", height: "60px", ml: 1}}
              src={
                data && data.PNr === "LLZSOK"
                    ? hulkimage
                    : data && data.Titel === "Herr"
                    ? manimage
                    : data && data.Titel === "Frau"
                    ? womanimage
                    : data && data.Titel && data.Titel.includes("Firm") && data.Nachname && !data.Nachname.startsWith("ETG")
                    ? firmaimage
                    : data && data.Titel && data.Titel.includes("Firm") && data.Nachname && data.Nachname.startsWith("ETG")
                    ? houseimage
                    : data && data.Titel === "Herr und Frau"
                    ? coupleimage
                    : null
            }
              
               />
              <Stack sx={{mt: 1}}>
                  {!showloadingPAUpload ? (
                  <div>
                  <Tooltip title={lastUpdatePA}>
                     <Chip onClick={handleLoadPAClick} variant="outlined" label={<Handlesetlablepa/>} color={LastPASX} size="small" icon={<BadgeSharp sx={{pl:0.7}} />} />
                  </Tooltip>
                  <Tooltip title="Neuen Personalausweis speichern">
                    <IconButton onClick={handlePAUploadClick} color={LastPASX} sx={{width: "15px", height: "15px", top: -10, left: -85}} size="small"><ChangeCircle sx={{width: "18px", height: "18px"}}></ChangeCircle></IconButton>
                  </Tooltip>
                  </div>

                 ) : ( <Stack direction="column">
                        <span style={{fontSize: "12px"}}>In Bearbeitung...</span>
                        <LinearProgress></LinearProgress>
                        </Stack>)}
              </Stack>
              {/* <Stack sx={{mt: 0.5}}>
              <div>
              <Tooltip title={(data && data.Datenteilung == 1 ? "Datenteilung erfasst - Klicken zum aktualisieren." : "Datenteilung nicht erfasst - Klicken zum aktualisieren.")}>
                     <Chip onClick={handleChangeDatenteilung} variant="outlined" label={<Handlesetlabledatenteilung/>} color={(data && data.Datenteilung ? "primary" : "error")} size="small" icon={<ShareSharp sx={{pl:0.7}} />} />
              </Tooltip>  
              </div>
              </Stack> */}
                        
              <Dialog open={handleDialogPAOpen} onClose={handleClosePAAblauf}>
                <DialogTitle>Personalausweisdaten</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Bitte trage das Ablaufdatum des Personalausweises ein.
                  </DialogContentText>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    autoFocus
                    margin="dense"
                    label="Ablaufdatum"
                    type="date"
                    fullWidth
                    variant="standard"
                    value={datePAValue}
                    onChange={handleDatePAChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlePAAblaufspeichern}>Speichern</Button>
                  <Button color="error" onClick={handleClosePAAblauf}>Abbrechen</Button>
                </DialogActions>
              </Dialog> 
              </Stack>
            </Grid>
            <Grid item sx={{mr: 3}}>
                <Divider orientation="vertical"/>
            </Grid>
            {/* Name und Kontaktdaten */}
            <Grid style={{maxWidth: "500px", overflow: "hidden"}} item xs> 
            <Stack direction="row" sx={{alignItems: "center", mb: 0.5}}>
              <Rating
                      value={valueRating}
                      sx={{color: AXADBVColor}}
                      // icon={<FavoriteSharp fontSize="inherit" />}
                      // emptyIcon={<FavoriteBorderSharp fontSize="inherit" />}
                      onChange={(event, newValue) => {
                        handleSetValueRating(newValue)
                      }}
                />
              <div style={{marginLeft: "2px"}}>
              {data && (data.Titel === "Herr" || data.Titel === "Frau") ?
               data.Datenteilung === 1 ? 
               <Tooltip title="Datenteilung erfasst - Klick zum aktualisieren.">
                <IconButton color='success' onClick={handleChangeDatenteilung} size="small" >
                  <VerifiedUserSharp />
                </IconButton>
               </Tooltip>
                :
                <Tooltip title="Datenteilung nicht erfasst - Klick zum aktualisieren.">
                <IconButton color='error' onClick={handleChangeDatenteilung} size="small" >
                  <GppBadSharp />
                </IconButton>
               </Tooltip> : null                                
                }
              </div>
              </Stack> 
              <Stack direction="column" sx={{ml: 0.5}}>             
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.Titel}
                </span>
                 <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <b>{data.Vorname} {data.Nachname}</b>                  
                </span>
                <Tooltip title={<span>{data.Straße}<br/>{data.PLZOrt}</span>}>     
                 <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link style={{ cursor: "pointer", fontSize: "14px" }} onClick={handleOpenAdressLink}>
                    {data.Straße}
                    <br />
                    {data.PLZOrt}
                  </Link>                  
                </div>
                </Tooltip>
                {data.gebdatum ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CakeSharp
                      color="inherit"
                      sx={{ width: "15px", height: "15px", mr: 0.5 }}
                    ></CakeSharp>
                    <span>
                      <small>
                        {" "}
                        {data.gebdatum} - Alter: <b>{age}</b>
                      </small>
                    </span>
                  </div>
                ) : null}
              </Stack>

               <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Tooltip title="Letzter Kundenkontakt - Rechtsklick für Historie.">
                    <Badge 
                      sx={{
                        mt: 2,
                        cursor: "context-menu",
                        color: "white",
                        '& .MuiBadge-badge': {
                          fontSize: "12px",
                          color: 'white',
                        },
                      }}                      
                      badgeContent={data.LetzterKontaktAlle && data.LetzterKontaktAlle.length > 1 ? data.LetzterKontaktAlle[data.LetzterKontaktAlle.length - 2][1] : null} color="primary">                        
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onContextMenu={handleOpenHistorieLetzterKontakt}
                        sx={{ mr: 0.9, mt: -1, fontSize: "12px", justifyContent: "left", fontWeight: "bold" }}
                        icon={<SupervisedUserCircle />}
                        label={
                          (() => {
                            if (data.LetzterKontaktAlle && data.LetzterKontaktAlle.length > 1) {
                              const lastContactDate = moment(data.LetzterKontaktAlle[data.LetzterKontaktAlle.length - 2][0], 'DD.MM.YYYY - HH:mm:ss');
                              const daysDifference = moment().diff(lastContactDate, 'days');                    
                             
                              const dayLabel = daysDifference === 1 ? 'Tag' : 'Tagen';
                              return `${lastContactDate.format('DD.MM.YYYY')} vor ${daysDifference} ${dayLabel}`;
                              
                            } else {
                              return "Keine Informationen";
                            }
                          })()
                        }
                      />
                    </Badge>
                  </Tooltip>                
                    <Popover
                        sx={{height: "200px"}}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                          <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Letzte Kundenkontakte:</b></span>
                          {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ? 
                          <IconButton onClick={handleResetLetzteKundenkontakte} sx={{width: "30px", height: "30px"}} title="Zurücksetzen" color="primary"><RestartAltSharp></RestartAltSharp></IconButton> : <div> </div>}
                        </div>
                        <Divider></Divider>
                        {data.LetzterKontaktAlle && data.LetzterKontaktAlle.length > 0 ?
                        [...data.LetzterKontaktAlle].reverse().map((entry, index) => (
                            <Stack direction="row" key={index} spacing={1} sx={{display:"flex", alignItems:"center", margin: 1, maxHeight: "150px", fontSize: "12px"}}>
                                <Event></Event>
                                <span>{entry[0]}</span>
                                <span sx={{color: "gray"}}>|</span>
                                <AccountCircle></AccountCircle>
                                <span>{entry[1]}</span>
                            </Stack>
                        )) :
                        <Stack direction="row" spacing={1} sx={{display:"flex", alignItems:"center", margin: 1, maxHeight: "150px", fontSize: "12px"}}>
                          <span >Keine Einträge gefunden.</span>
                        </Stack>
                        }
                    </Popover>
              </div>
              <div style={{ marginTop: "5px", display: "flex", alignItems: "center", position: "relative" }}>
              <Tooltip title={<div>Letzte Datenaktualisierung<br></br>Linksklick zum aktualisieren.<br></br>Rechtsklick für Historie.</div>}>                      
                    <Badge 
                        color={ColorLetzteAktualisierung}
                        sx={{
                          
                          mt: 1,
                          color: "white",
                          '& .MuiBadge-badge': {
                            fontSize: "12px",
                            color: 'white',
                          },
                        }}  
                       badgeContent={data.LetzteAktualisierungName}>
                     <Chip
                        variant='outlined'
                        size="small"
                        sx={{ mr: 0.9, mt: -1, fontSize: "12px", justifyContent: "left", fontWeight: "bold" }}
                        color={ColorLetzteAktualisierung}
                        onContextMenu={handleOpenHistorieLetzteAktualisierung}
                        onClick={handleLetzteAktualisierung}
                        icon={<ManageAccounts />}
                        label={
                          (() => {
                            if (data.LetzteAktualisierung) {
                              const lastUpdateDate = moment(data.LetzteAktualisierung, 'DD.MM.YYYY - HH:mm:ss');
                              const daysDifference = moment().diff(lastUpdateDate, 'days');

                              const dayLabel = daysDifference === 1 ? 'Tag' : 'Tagen';
                              return `${lastUpdateDate.format('DD.MM.YYYY')} vor ${daysDifference} ${dayLabel}`;
                            } else {
                              return "Keine Informationen";
                            }
                          })()
                        }
                      />
                    </Badge>
              </Tooltip>             
              <Popover
                  sx={{height: "200px"}}
                  open={openAktualisierungPopover}
                  anchorEl={anchorElAktualisierung}
                  onClose={handleCloseHistorieLetzteAktualisierung}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}>
                  <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                    <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Letzte Aktualisierungen:</b></span>
                    {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ? 
                    <IconButton onClick={handleResetLetzteAktualisierungen} sx={{width: "30px", height: "30px"}} title="Zurücksetzen" color="primary"><RestartAltSharp></RestartAltSharp></IconButton> : <div> </div>}
                  </div>
                  <Divider></Divider>
                  {data.LetzteAktualisierungAlle && data.LetzteAktualisierungAlle.length > 0 ?
                  [...data.LetzteAktualisierungAlle].reverse().map((entry, index) => (
                      <Stack direction="row" key={index} spacing={1} sx={{display:"flex", alignItems:"center", margin: 1, maxHeight: "150px", fontSize: "12px"}}>
                          <Event></Event>
                          <span>{entry[0]}</span>
                          <span sx={{color: "gray"}}>|</span>
                          <AccountCircle></AccountCircle>
                          <span>{entry[1]}</span>
                      </Stack>
                  )) :
                  <Stack direction="row" spacing={1} sx={{display:"flex", alignItems:"center", margin: 1, maxHeight: "150px", fontSize: "12px"}}>
                    <span >Keine Einträge gefunden.</span>
                  </Stack>
                  }                          
              </Popover>
              </div>            
            </Grid>
            {/* Button Row */}
            <Grid item>
              <Stack direction="column">
              <Tooltip title="Beratungsdokumentation öffnen">
                  <IconButton color='primary' onClick={handleOpenDoku} size="small" >
                    <AssignmentRounded />
                  </IconButton>
              </Tooltip>
              <Tooltip title="Kontaktinformationen öffnen">
                  <IconButton color='primary' 
                    onClick={(event) => {
                      setanchorElKontaktdatenPopover(event.currentTarget);
                    }}
                    size="small" >
                    <ContactEmergencyRounded />
                  </IconButton>
              </Tooltip>
                  <Popover
                    sx={{height: "200px"}}
                    open={openKontaktdatenPopover}
                    anchorEl={anchorElKontaktdatenPopover}
                    onClose={() => {
                      setanchorElKontaktdatenPopover(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                      <span style={{fontSize: "14px", marginLeft: "10px", marginRight: "100px"}}><b>Kontaktinformationen</b></span>
                    </div>
                    <Divider></Divider>
                    <Stack direction="column" style={{margin: "20px"}}>                                
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center", fontSize: "14px"}}>
                              <Tooltip title="Telefon Festnetz privat">
                              <PhoneRounded sx={{width: "20px", height: "20px"}}></PhoneRounded>
                              </Tooltip>
                                {data.Festnetz !== null && data.Festnetz !== "" && data.Festnetz !== " " && (
                                  <Link sx={{cursor: "pointer"}} href={`tel:${data.Festnetz}`} style={{marginLeft: "15px"}}>
                                    {data.Festnetz}
                                  </Link>
                                )}
                                {(data.Festnetz === null || data.Festnetz === "" || data.Festnetz === " ") && (
                                  <Link sx={{cursor: "pointer"}} underline="none" style={{marginLeft: "15px"}}>
                                    -
                                  </Link>
                                )}   
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center", fontSize: "14px", marginTop: "5px"}}>
                              <Tooltip title="Telefon Festnetz geschäftl.">
                              <HeadphonesRounded sx={{width: "20px", height: "20px"}}></HeadphonesRounded>
                              </Tooltip>
                                {data.telgesch !== null && data.telgesch !== "" && data.telgesch !== " " && (
                                <Link sx={{cursor: "pointer"}} href={`tel:${data.telgesch}`} style={{marginLeft: "15px"}}>
                                  {data.telgesch}
                                </Link>
                              )}
                                {(data.telgesch === null || data.telgesch === "" || data.telgesch === " ") && (
                                <Link sx={{cursor: "pointer"}} underline="none" style={{marginLeft: "15px"}}>
                                  -
                                </Link>
                              )}    
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center", fontSize: "14px", marginTop: "5px" }}>
                              <Tooltip title="Telefon Mobil">
                              <PhoneIphoneRounded sx={{width: "20px", height: "20px"}}></PhoneIphoneRounded>
                              </Tooltip>
                                {data.Handy !== null && data.Handy !== "" && data.Handy !== " " && (
                                <Link sx={{cursor: "pointer"}} href={`tel:${data.Handy}`} style={{marginLeft: "15px"}}>
                                  {data.Handy}
                                </Link>
                              )}
                                {(data.Handy === null || data.Handy === "" || data.Handy === " ") && (
                                <Link sx={{cursor: "pointer"}} underline="none" style={{marginLeft: "15px"}}>
                                  -
                                </Link>
                              )}    
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center", fontSize: "14px", marginTop: "5px"}}>
                              <Tooltip title="E-Mail Adresse">
                              <MailRounded sx={{width: "20px", height: "20px"}}></MailRounded>
                              </Tooltip>
                                {data.Email !== null && data.Email !== "" && data.Email !== " " && (
                                <Link sx={{cursor: "pointer"}} href={`mailto:${data.Email}`} style={{marginLeft: "15px"}}>
                                  {data.Email}
                                </Link>
                              )}
                                {(data.Email === null || data.Email === "" || data.Email === " ") && (
                                <Link sx={{cursor: "pointer"}} underline="none" style={{marginLeft: "15px"}}>
                                  -
                                </Link>
                              )}                   
                              </div>
                    </Stack> 
                </Popover>
              <Tooltip 
                title={   <span>
                            Beratungsintervall<br/><br/>
                            Letzter Termin:<br/>
                            {data.LetzterKundentermin && data.LetzterKundentermin.datum
                              ? moment().diff(moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY'), 'days') === 0
                                ? "heute"
                                : `vor ${moment().diff(moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY'), 'days')} Tag(en)`
                              : "Keine Informationen"
                            }
                            <br/><br/>
                            Nächster planmäßiger Termin:<br/>                                
                            {data.LetzterKundentermin && intervalDays !== "0"
                              ? `${moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY')
                                    .add(Number(intervalDays), 'days')
                                    .format('DD.MM.YYYY')} ${
                                        moment().diff(moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY').add(Number(intervalDays), 'days'), 'days') < 0 
                                        ? `(in ${Math.abs(moment().diff(moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY').add(Number(intervalDays), 'days'), 'days'))} Tag(en))`
                                        : `(vor ${Math.abs(moment().diff(moment(data.LetzterKundentermin.datum, 'DD.MM.YYYY').add(Number(intervalDays), 'days'), 'days'))} Tag(en))`
                                    }`
                              : intervalDays === "0"
                                ? "Nicht gewünscht"
                                : "Keine Informationen"
                            }
                          </span>                                
                }>
                <IconButton color='primary'
                    onClick={(event) => {
                      setanchorElBeratungsintervallPopover(event.currentTarget);         
                    }}>   
                  <Box sx={{height: "20px"}} position="relative" display="inline-block">
                    <WorkHistoryRounded />
                    {data.LetzterKundentermin && data.LetzterKundentermin.datum ? (
                      <CheckCircleRounded
                        size="small"
                        sx={{ fontSize: "15px", position: 'absolute', top: 0, right: -15 }}
                      />
                    ) : (
                        <UnpublishedSharp color='error'
                        size="small"
                        sx={{ fontSize: "15px", position: 'absolute', top: 0, right: -15 }}
                      />
                    )}
                  </Box> 
                  </IconButton>
              </Tooltip>                   
                  <Popover
                    sx={{maxHeight: "410px"}}
                    open={openBeratungsintervallPopover}
                    anchorEl={anchorElBeratungsintervallPopover}
                    onClose={() => {
                      setanchorElBeratungsintervallPopover(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                      <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Beratungen</b></span>
                    </div>
                    <Divider></Divider>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px", marginLeft: "5px", marginBottom: "15px"}}>
                    <HailRounded color="primary" style={{height: "40px", width: "40px"}}></HailRounded>
                    <div style={{display: "flex", flexDirection: "column", marginRight: "15px"}}>
                      <span style={{fontSize: "14px"}}>Letztes Kundengespräch:</span>
                      <span style={{ fontSize: "14px", color: "gray" }}>
                        {data.LetzterKundentermin && data.LetzterKundentermin.datum ?
                          <b>{data.LetzterKundentermin.datum}</b> :
                          "Keine Informationen"
                        }
                        {data.LetzterKundentermin && data.LetzterKundentermin.kuerzel ?
                          <React.Fragment>&nbsp;({data.LetzterKundentermin.kuerzel})</React.Fragment> :
                          <React.Fragment>&nbsp;(--)</React.Fragment>
                        }
                      </span>
                    </div>
                    <IconButton onClick={handleAddLetzterKundentermin} sx={{mr: 2}}>
                      <UpdateRounded></UpdateRounded>
                    </IconButton>       
                    </div>
                    <Divider></Divider>
                    <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                      <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Beratungsintervall</b></span>
                    </div>
                    <Divider></Divider>
                    <FormControl style={{ marginLeft: "15px", marginBottom: "20px"}}>
                      <RadioGroup value={intervalType} onChange={handleRadioChangeIntervall}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="1 Jahr"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="2 Jahre"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="3 Jahre"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Nicht gewünscht"
                        />
                      </RadioGroup>
                      <TextField
                        sx={{mt: 1}}
                        size='small'
                        value={intervalDays}
                        onChange={handleTextChangeIntervall}
                        label="Individuell in Tagen"
                      />
                    </FormControl>
                </Popover>
              <Tooltip title="Kinder">
                  <IconButton color='primary'
                  onClick={(event) => {
                      setanchorElKinderPopover(event.currentTarget);
                    }}>
                  <StyledBadge badgeContent={data.Kinder ? data.Kinder.length : 0}
                  color="error"
                  sx={{
                    '& .MuiBadge-badge': {
                      color: 'white', 
                      fontWeight: 'bold',
                    },
                  }}
                  >
                    <ChildCareRounded />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
                <Popover
                    sx={{maxHeight: "400px"}}
                    open={openKinderPopover}
                    anchorEl={anchorElKinderPopover}
                    onClose={() => {
                      setanchorElKinderPopover(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div style={{display: "flex", alignItems: "center", height: "30px"}}>
                      <span style={{fontSize: "14px", marginLeft: "10px", marginRight: "200px"}}><b>Kinder</b></span>
                      <IconButton  onClick={() => {
                        setOpenDialogKinder(true);
                        }}>
                        <PersonAddRounded></PersonAddRounded>
                      </IconButton>
                      <Dialog open={openDialogKinder} onClose={() => setOpenDialogKinder(false)}>
                      <DialogTitle>Kind hinzufügen</DialogTitle>
                      <DialogContent>
                        <TextField sx={{mt: 1, mb: 1.5}} InputLabelProps={{ shrink: true }} label="Vorname" onChange={(event) => setVornameKind(event.target.value)} value={VornameKind} fullWidth />
                        <TextField sx={{mb: 1.5}} InputLabelProps={{ shrink: true }} label="Name" onChange={(event) => setNameKind(event.target.value)} value={NameKind} fullWidth />
                        <FormControl sx={{ mb: 1.5 }} fullWidth>
                          <InputLabel>Geschlecht</InputLabel>
                          <Select
                            value={GeschlechtKind}
                            label="Geschlecht"
                            onChange={(event) => setGeschlechtKind(event.target.value)}
                          >
                            <MenuItem value="männlich">männlich</MenuItem>
                            <MenuItem value="weiblich">weiblich</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField sx={{mb: 1.5}} InputLabelProps={{ shrink: true }} label="Geburtsdatum" type="date" onChange={(event) => setGebDatumKind(event.target.value)} value={GebDatumKind} fullWidth />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleAddChild}>Hinzufügen</Button>
                        <Button onClick={() => setOpenDialogKinder(false)}>Abbrechen</Button>
                      </DialogActions>
                    </Dialog>
                    </div>
                    <Divider></Divider>
                  {data.Kinder && data.Kinder.length > 0 ? (
                    data.Kinder.map((child, index) => (
                      <div key={index}>
                        <Divider />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px"}}>
                          <div>
                            <div style={{display: "flex", alignItems: "center"}}>
                              <Link sx={{cursor: "pointer"}} style={{fontWeight: "bold"}}>{child.Surname} {child.Name}</Link>
                              {child.Geschlecht === "männlich" ? <MaleRounded color='primary' style={{marginLeft: "5px"}}/> : <FemaleRounded color='primary' style={{marginLeft: "5px"}}/>}
                            </div>
                            <div>Geburtsdatum: {moment(child.Birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')}</div>
                          </div>
                          <IconButton onClick={() => handleDeleteChild(index)}>
                            <DeleteSharp />
                          </IconButton>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{padding: "10px"}}>Keine Kinder</div>
                  )}
                </Popover>
              <Tooltip title="Partnerbeziehungen">
                  <IconButton color='primary'
                  onClick={(event) => {
                      setanchorElBeziehungenPopover(event.currentTarget);
                      handleopenBeziehungen()                          
                    }}>
                  <StyledBadge badgeContent={data.Beziehungen ? data.Beziehungen.length : 0}
                  color="error">
                    <FamilyRestroomRounded />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Popover
                    sx={{maxHeight: "400px"}}
                    open={openBeziehungenPopover}
                    anchorEl={anchorElBeziehungenPopover}
                    onClose={() => {
                      setanchorElBeziehungenPopover(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div style={{display: "flex", alignItems: "center", height: "30px", justifyContent: "space-between"}}>
                      <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Partnerbeziehungen</b></span>     
                      {loadingKundenbeziehungen ? <span><CircularProgress size="1em" sx={{mr: 1}}/></span> : null }                              
                    </div>
                    <Divider></Divider>
                  {data.Beziehungen && data.Beziehungen.length > 0 ? (
                      data.Beziehungen.map((Beziehungen, index) => (
                        Beziehungen.PNBeziehungName !== "BESTANDSFREMDER PARTNER" &&
                        <div key={index}>
                          <Divider />
                          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px"}}>
                            <div>
                              <div>{Beziehungen.Anrede}</div>
                              <Link sx={{cursor: "pointer"}} onClick={() => handleOpenPartnerbeziehnung(Beziehungen.PNr, Beziehungen.PNBeziehungName, Beziehungen.GebDatum)}  style={{fontWeight: "bold"}}>{Beziehungen.PNBeziehungName} ({Beziehungen.PNr})</Link>
                              <div style={{fontSize: "14px"}}>{Beziehungen.bezart}</div>
                              <div><b>{Beziehungen.GebDatum}</b></div>
                            </div>                             
                          </div>
                        </div>           
                      ))
                    ) : (
                      <div style={{padding: "10px"}}>Keine Partnerbeziehungen hinterlegt</div>
                    )
                  }
              </Popover>              
              </Stack>


            </Grid>
            <Grid item sx={{ml: 3, mr: 3}}>
                <Divider orientation="vertical"/>
            </Grid>
            {/* Kurzinformationen und Frühwarnliste */}
            <Grid item style={{width: "290px"}}>
              <Stack direction="row">
              <Stack direction="column">
                <TextField
                    label="Kurzinformationen"
                    spellCheck="false"
                    multiline
                    variant="outlined"
                    size="small"
                    rows={6}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 12 } }}
                    sx={{ mt: 1, width: "250px", '& .MuiOutlinedInput-root': { fontWeight: firmeninfosstate && firmeninfosstate.checkboxkurzinfohervorheben === true ? "bold" : "normal", color: firmeninfosstate && firmeninfosstate.checkboxkurzinfohervorheben === true ? "#ef2f39" : "black" } }}
                    onBlur={handlesaveKurzInfos}
                    onChange={handleChangekurzinfo}
                    value={KurzinfoTextFieldValue}
                  />
                {data.ZusatzinfoWichtig ? (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "250px", // Set your desired max-width here
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <NotificationImportant
                      color="inherit"
                      sx={{ color: "#ff6d75", width: "20px", height: "20px", mr: 1.5 }}
                    ></NotificationImportant>
                    <span
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      <small>
                        <b>{data.ZusatzinfoWichtig}</b>
                      </small>
                    </span>
                  </div>
                ) : null}
              </Stack>                   
              <Tooltip title="Aktionen zum Kunden">
                            <IconButton color='primary' sx={{width: 35, height: 35, ml: 2}}
                            onClick={(event) => {
                                setanchorElSettings(event.currentTarget);                   
                              }}>
                              <SettingsSharp />
                          </IconButton>
              </Tooltip> 
              <Popover
                        sx={{height: "200px"}}
                        open={openSettingsPopover}
                        anchorEl={anchorElSettings}
                        onClose={(e) => {setanchorElSettings(null)}}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                          <Stack direction="column" sx={{ alignItems: 'flex-start' }}>
                                <Button startIcon={<JoinFullSharp></JoinFullSharp>} 
                                onClick={(event) => {
                                          setPartnerzusammenführenDialog(true)                       
                                        }}>
                                Partnerzusammenführung</Button>
                              <Dialog open={PartnerzusammenführenDialog} onClose={() => {setPartnerzusammenführenDialog(false)}}>
                                <DialogTitle>Partnerzusammenführung</DialogTitle>
                                <DialogContent>
                                  <div style={{marginBottom: "20px"}}><u>Bitte beachten:</u><br></br>Trage hier die <b>neue</b> Partnernummer des Kunden gemäß EVT-Portal ein.<br></br>Die Partnernummer des Interessenten (<b>{data.PNr}</b>) wird dann in diese umgewandelt.<br></br><br></br><b>Alle</b> Daten die in PHX unter der neuen Partnernummer gespeichert wurden, werden durch diesen Vorgang gelöscht!<br></br>Sowohl der Interessent, als auch der Partner sind weiterhin im EVT-Portal ersichtlich. Durch die Zusammenführung werden dann alle Informationen bei dem Kunden angezeigt. Der Interessent ist leer.
                                  <br></br><br></br>
                                  <b>Bitte aktualisiere danach die Suche und rufe den Kunden erneut auf, damit die neue Partnernummer hinterlegt ist.</b>
                                  </div>
                                  <TextField
                                    sx={{width: "300px"}}
                                    autoFocus
                                    margin="dense"
                                    label="Neue Partnernummer"
                                    type="email"
                                    fullWidth
                                    value={newPartnernummerZusammenführung}
                                    onChange={e => setnewPartnernummerZusammenführung(e.target.value)}  
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleStartPartnerzusammenführen}>Zusammenführen</Button>
                                  <Button onClick={() => {setPartnerzusammenführenDialog(false)}}>Abbrechen</Button>
                                </DialogActions>
                              </Dialog> 
                               <Button startIcon={<PersonSharp></PersonSharp>} 
                                onClick={(event) => {
                                          setNamenändernDialog(true)                       
                                        }}>
                                Namensänderung</Button>
                                <Dialog open={NamenändernDialog} onClose={() => {setNamenändernDialog(false)}}>
                                <DialogTitle>Namensänderung Dateiablage</DialogTitle>
                                <DialogContent>
                                  <div style={{marginBottom: "20px"}}><u>Bitte beachten:</u><br></br>Trage hier den <b>alten</b> Vor- und Nachname des Kunden ein. <br></br>Im Vorfeld sollte die Namensänderung bereits im EVT-Portal durchgeführt worden sein, sodass hier im Partnerbild bereits der neue Namen angezeigt wird. Falls du die Änderung gerade erst durchgeführt hast und hier noch der alte Namen angezeigt wird, einfach einen anderen Kunden öffnen und danach wieder zurück auf diesen Kunden switchen. 
                                  </div>
                                  <TextField
                                    sx={{width: "300px"}}
                                    autoFocus
                                    margin="dense"
                                    label="Alter Nachname"
                                    fullWidth
                                    value={alterNachnameNamensänderung}
                                    onChange={e => setalterNachnameNamensänderung(e.target.value)}  
                                  />
                                  <TextField
                                    sx={{width: "300px"}}
                                    margin="dense"
                                    label="Alter Vorname"
                                    fullWidth
                                    value={alterVornameNamensänderung}
                                    onChange={e => setalterVornameNamensänderung(e.target.value)}  
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleStartNamensänderung}>Ändern</Button>
                                  <Button onClick={() => {setNamenändernDialog(false)}}>Abbrechen</Button>
                                </DialogActions>
                              </Dialog> 
                              {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ? 
                              <div style={{width: "100%"}}>
                              <Button sx={{width: "100%", justifyContent: "start"}} startIcon={<PersonRemoveSharp></PersonRemoveSharp>} onClick={(event) => {
                                          handleShowDialogDelete(true)                       
                                        }}>
                            Kunden löschen</Button>                                             
                            <ConfirmDialog
                                  open={showDialog}
                                  onClose={() => setShowDialog(false)}
                                  message="Möchtest du den Kunden wirklich vollständig löschen?"
                                  onConfirm={handleConfirmDialogDelete}
                                  onCancel={handleHideDialogDelete}
                              /> 
                              </div>
                              : null }
                          </Stack>           
              </Popover>  
              </Stack>              
            </Grid>
          </Grid>  
          </CardContent>
        </Card>  
          <Typography component="div" sx={{mt: 1.2, filter: showalertletzteAktualisierung.display === "none" && showalertAusbildung.display === "none" && isModalOpenFromDispatch === false ? "none" : "blur(10px)", pointerEvents: showalertletzteAktualisierung.display === "none" && showalertAusbildung.display === "none" ? "auto" : "none"}} variant="body1">
            {value === 0 && <BemerkungenKunde data={data}/>}
            {/* {value === 0 && <UserBemerkungenSlate data={data}/>} */}

            {value === 1 && <TermineKundePage TermineData={dataAlleTerminePNR} myData={data} handleRefetchData={handleRefetchData}/>}
            {value === 2 && <DateiablageKundePage />}
            {value === 3 && <KundeSchäden data={data} schadenData={dataAlleSchädenPNR} refetchdata={handleRefetchData} />}
            {value === 4 && <FlyerundDokumentePage/>}

          </Typography>
      </div>
      </ThemeProvider>
      :
      <Card style={{borderTopRightRadius: "0px", borderTopLeftRadius: 0}}>
        <Stack direction="column">
         <Alert icon={false} color="info">Die Kundendaten werden geladen...<br></br>
         <b>Sollte diese Nachricht mehrere Sekunden angezeigt werden, lade den Kunden
         bitte {<Link onClick={() => dispatch(setloading(false))}>hierüber</Link>} erneut.</b><br>
         </br><br></br>
         Sollte keine Verbindung zum EVT-Portal bestehen, empfiehlt es sich den Kundenaufruf über das EVT-Portal über die Einstellungen zu deaktiveren. Drücke 
        {
          <Link onClick={() => { 
              localStorage.setItem("setcheckboxEVTaufrufen", "false"); 
              dispatch(setloading(false)); 
          }}> hier</Link>} um die Einstellung dauerhaft zu ändern.
         </Alert>
         
        <LinearProgress></LinearProgress>
         </Stack>
            <div style={{display: "flex", marginTop: "30px", marginLeft: "10px"}}>
              <Skeleton variant="circular" sx={{mt: 2}} width={65} height={65} />
              <Stack direction="column" sx={{ml: 2}}>
                <Skeleton variant="text" width={105} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton variant="text" width={217} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton sx={{mt: 0.5}} variant="rounded" width={217} height={60} />
              </Stack>
           </div>
              <Divider sx={{mt: 3}}></Divider>
              <Skeleton sx={{mt: 0.5, ml: 2, mt: 3, mb: 3}} variant="rounded" width={650} height={300} />
        </Card>

    ) : (
       <Card style={{borderTopRightRadius: "0px", borderTopLeftRadius: 0}}>
        <Stack direction="column">
        <Alert variant='standard' icon={<PersonSearchSharp></PersonSearchSharp>} severity="info">Es wurden noch keine Kundendaten ausgewählt. Bitte rufen Sie entweder über die interne Suche einen Kunden auf
        oder wählen Sie einen Kunden im EVT-Portal aus.
        <span> <Link onClick={() => handleNavigation("/dashboard/KundenSuchePage")} sx={{color: "#014361", cursor: "pointer"}}><b>Hier geht es zur Suche.</b></Link></span>
        </Alert>
         </Stack>
            <div style={{display: "flex", marginTop: "30px", marginLeft: "10px"}}>
              <Skeleton variant="circular" sx={{mt: 2}} width={65} height={65} />
              <Stack direction="column" sx={{ml: 2}}>
                <Skeleton variant="text" width={105} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton variant="text" width={217} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton sx={{mt: 0.5}} variant="rounded" width={217} height={60} />
              </Stack>
           </div>
              <Divider sx={{mt: 3}}></Divider>
              <Skeleton sx={{mt: 0.5, ml: 2, mt: 3, mb: 3}} variant="rounded" width={650} height={300} />
        </Card>
    )}
  </div>
);
};

