import React, { useRef, useEffect, useState, useMemo } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { Chip, TextField, Box, IconButton, Tooltip, Checkbox } from '@mui/material';
import { ReceiptSharp, RefreshSharp, DoNotDisturbAltSharp, CheckCircleSharp, UnpublishedSharp } from "@mui/icons-material";            
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { fetchdataUrl } from '../A-config/config';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import moment from 'moment';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { debounce } from 'lodash';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';

const SchadenSuchenPageServerSide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navigateToCustomer } = useNavigation();
  const textFieldRef = useRef(null);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(() => {
    const savedGlobalFilter = localStorage.getItem('globalFilterSchadenSucheSave');
    return savedGlobalFilter || '';
  });
  
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    const savedColumnFilters = localStorage.getItem('columnFiltersSchadenSucheSave');
    const savedGlobalFilter = localStorage.getItem('globalFilterSchadenSucheSave');
    const savedSorting = localStorage.getItem('sortingSchadenSucheSave');
    const savedPagination = localStorage.getItem('paginationSchadenSucheSave');

    if (savedColumnFilters) {
      setColumnFilters(JSON.parse(savedColumnFilters));
    }
    if (savedGlobalFilter) {
      setGlobalFilter(savedGlobalFilter);
    }
    if (savedSorting) {
      setSorting(JSON.parse(savedSorting));
    }
    if (savedPagination) {
      setPagination(JSON.parse(savedPagination));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('columnFiltersSchadenSucheSave', JSON.stringify(columnFilters));
  }, [columnFilters]);

  useEffect(() => {
    localStorage.setItem('globalFilterSchadenSucheSave', globalFilter);    
  }, [globalFilter]);

  useEffect(() => {
    localStorage.setItem('sortingSchadenSucheSave', JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    localStorage.setItem('paginationSchadenSucheSave', JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, []);

  const [showonlyOffeneSchäden, setshowonlyOffeneSchäden] = useState(localStorage.getItem('setshowonlyOffeneSchäden') === 'true');
  const handleshowonlyOffeneSchäden = (event) => {
    const isChecked = event.target.checked;
    setshowonlyOffeneSchäden(isChecked);
    localStorage.setItem('setshowonlyOffeneSchäden', isChecked);
    setshowonlyKVBelege(false);
    localStorage.setItem('setshowonlyKVBelege', false);
  };

  const [showonlyKVBelege, setshowonlyKVBelege] = useState(localStorage.getItem('setshowonlyKVBelege') === 'true');
  const handleshowonlyKVBelege = (event) => {
    const isChecked = event.target.checked;
    setshowonlyKVBelege(isChecked);
    localStorage.setItem('setshowonlyKVBelege', isChecked);
    sethideKVBelege(false);
    localStorage.setItem('sethideKVBelege', "false");
    setshowonlyOffeneSchäden(false);
    localStorage.setItem('setshowonlyOffeneSchäden', false);
  };

  const [hideKVBelege, sethideKVBelege] = useState(localStorage.getItem('sethideKVBelege') === 'true');
  const handlehideKVBelege = (event) => {
    const isChecked = event.target.checked;
    sethideKVBelege(isChecked);
    localStorage.setItem('sethideKVBelege', isChecked);
    setshowonlyKVBelege(false);
    localStorage.setItem('setshowonlyKVBelege', "false");
  };

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightUserSchadensuche');
    return savedHeight ? parseInt(savedHeight) : 300;
  });

  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 300;
    localStorage.setItem('contentHeightUserSchadensuche', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  useEffect(() => {
    calculateContentHeightAndPageSize();
    window.addEventListener('resize', calculateContentHeightAndPageSize);
    return () => {
      window.removeEventListener('resize', calculateContentHeightAndPageSize);
    };
  }, []);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    try {
      const visibilityString = localStorage.getItem('columnVisibilitySchadenSuche');
      return JSON.parse(visibilityString) || { ID: false };
    } catch (e) {
      console.error(e);
      return { ID: false };
    }
  });

  useEffect(() => {
    localStorage.setItem('columnVisibilitySchadenSuche', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleColumnVisibilityChange = (updater) => {
    setColumnVisibility(updater);
  };

  const defaultColumnWidthsSchadensuche = 90;
  let storageItemSchadensuche = localStorage.getItem('columnWidthsSchadensuche');
  let columnWidthsFromLocalStorageSchadensuche;

  if (storageItemSchadensuche === "undefined" || !storageItemSchadensuche) {
    columnWidthsFromLocalStorageSchadensuche = defaultColumnWidthsSchadensuche;
  } else {
    columnWidthsFromLocalStorageSchadensuche = JSON.parse(storageItemSchadensuche);
  }

  const [columnSizingStateSchadensuche, setcolumnSizingStateSchadensuche] = useState(columnWidthsFromLocalStorageSchadensuche);

  const handleColumnSizingChange = (newColumnSizingState) => {
    const newState = newColumnSizingState(columnSizingStateSchadensuche);
    localStorage.setItem('columnWidthsSchadensuche', JSON.stringify(newState));
    setcolumnSizingStateSchadensuche(newState);
  };

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      showonlyKVBelege,
      showonlyOffeneSchäden,
      hideKVBelege
    ],
    queryFn: async () => {
      const requestBody = {
        access_token: sessionStorage.getItem("access_token"),
        fetchAlleSchädenServerSide: 1,
        start: pagination.pageIndex * pagination.pageSize,
        size: pagination.pageSize,
        filters: columnFilters,
        globalFilter: globalFilter,
        sorting: sorting,
        showonlyKVBelege: showonlyKVBelege,
        showonlyOffeneSchäden: showonlyOffeneSchäden,
        hideKVBelege: hideKVBelege
      };

      const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(() => [
    { accessorKey: 'ID', header: 'ID', size: 40, minSize: 40, enableHiding: false, enableSorting: false, enableGlobalFilter: false },
    {
      accessorKey: 'Status', header: 'Status', size: 80, minSize: 40, maxSize: 80,
      Cell: ({ cell }) => cell.row.original.Status === "ERLEDIGT" ?
        cell.row.original.Sparte === "KV Belege" ?
        <Chip sx={{ bgcolor: '#6579d7', color: "white" }} size='small' icon={<ReceiptSharp color="white" />} label="Belege" /> : 
        <Chip color='success' sx={{color: "white"}} size='small' icon={<CheckCircleSharp></CheckCircleSharp>} label="Erledigt"></Chip> : 
        <Chip color='error' sx={{color: "white"}} size='small' icon={<UnpublishedSharp></UnpublishedSharp>} label="Offen"></Chip>,
        muiTableBodyCellProps: { align: 'left' }
    },
    { accessorKey: 'Titel', header: 'Anrede', size: 60, minSize: 60, maxSize: 120 },
    { accessorKey: 'PNr', header: 'PNr', size: 60, minSize: 60, maxSize: 120 },
    { accessorKey: 'Name', header: 'Name', size: 80, minSize: 80, maxSize: 200 },
    { accessorKey: 'Vorname', header: 'Vorname', size: 80, minSize: 80, maxSize: 200 },
    { accessorKey: 'Schadenart', header: 'Schadenart', size: 80, minSize: 80, maxSize: 200 },
    {
      accessorKey: 'Schadendatum', header: 'Schadendatum', size: 80, minSize: 80, maxSize: 200, enableSorting: true,
      Cell: ({ cell }) => {
        const date = cell.row.original.Schadendatum;
        if (date === '0000-00-00' || !date) {
          return null;
        }
        const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        return <span>{formattedDate}</span>;
      },
      filterFn: (row, id, filterValue) => {
        const date = row.getValue(id);
        if (date === '0000-00-00' || !date) {
          return false;
        }
        const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        return formattedDate.includes(filterValue);
      }
    },
    { accessorKey: 'Schadennummer', header: 'Schadennummer', size: 80, minSize: 80, maxSize: 200 },
    {
      accessorKey: 'Sparte', header: 'Sparte', size: 80, minSize: 80, maxSize: 200,    
    },
    { accessorKey: 'Regulierungsart', header: 'Regulierungsart', size: 80, minSize: 80, maxSize: 200 },
    { accessorKey: 'Straße', header: 'Straße', size: 80, minSize: 80, maxSize: 200 },
    {
      accessorKey: 'PLZ', header: 'PLZ / Wohnort', size: 140, minSize: 140, maxSize: 200,
      Cell: ({ cell }) => <span>{cell.row.original.PLZ} {cell.row.original.Wohnort}</span>
    },
    { accessorKey: 'Bearbeiter', header: 'Bearbeiter', size: 80, minSize: 80, maxSize: 200, enableGlobalFilter: false },
  ], []);

  const tableInstance = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row.id,
    state: {
      columnSizing: columnSizingStateSchadensuche,
      columnVisibility: columnVisibility,
      isLoading,
      isError,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      showProgressBars: isRefetching,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
      columnVisibility: columnVisibility,
    },
    enableFilterMatchHighlighting: false,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableStickyHeader: true,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',
    onColumnSizingChange: handleColumnSizingChange,
    manualFilters: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: meta?.totalRowCount ?? 0,
    localization: MRT_Localization_DE,
    muiTableContainerProps: ({ table }) => ({
      sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px'}
    }),     
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchSucheSchadendatenbyID: 1,
            ID: row.original.ID
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            if (window.chrome && window.chrome.webview && isDesktop) {
              const dataarray = ["OPENSchaden", data[0]];
              window.chrome.webview.postMessage(dataarray);
              dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
              if (localStorage.getItem("setcheckboxEVTSchadenaufrufen") === "true") {
                navigateToCustomer(data[0].PNr)
              }
            } else {
              dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
              navigate('/dashboard/SchadenübersichtPage', { state: { data: data[0] } });
            }
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      }
    }),
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: { margin: 1 }
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <TextField
          inputRef={textFieldRef}
          autoFocus
          size="small"
          defaultValue={globalFilter}
          onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
          spellCheck={false}
          placeholder="Suchen..."
          sx={{ minWidth: '300px' }}
          variant="outlined"
        />
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box>
        <Tooltip title="Nur offene Schäden anzeigen">
          <Checkbox onChange={handleshowonlyOffeneSchäden} checked={showonlyOffeneSchäden} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
        </Tooltip>
        <Tooltip title="Nur KV-Belege anzeigen">
          <Checkbox onChange={handleshowonlyKVBelege} checked={showonlyKVBelege} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
        </Tooltip>
        <Tooltip title="KV-Belege ausblenden">
          <Checkbox onChange={handlehideKVBelege} checked={hideKVBelege} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
        </Tooltip>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });

  return (
    <div>
      <MaterialReactTable table={tableInstance} />
    </div>
  );
};

const queryClient = new QueryClient();

const SchadenSuchenPageServerSideWithQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <SchadenSuchenPageServerSide />
  </QueryClientProvider>
);

export default SchadenSuchenPageServerSideWithQueryProvider;