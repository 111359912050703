import { Chip, List, ListSubheader, ListItem, ListItemIcon, ListItemText, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, Tab, Tabs, Box, Tooltip, Card, CardHeader, Avatar, IconButton, CardMedia, Divider, CardContent, CardActions, Typography, Button, TextField, Container, Stack, Grid, Alert, Hidden, LinearProgress} from "@mui/material";
import { LocalPoliceSharp, AdminPanelSettings, SupervisorAccount, Build, AccessTime, Timer, BugReport, Assignment, AddTaskSharp, VerifiedSharp, Upload, PasswordSharp, FavoriteSharp, EditRounded, HowToRegSharp, DeleteSharp, KeyboardDoubleArrowUpSharp, KeyboardDoubleArrowDownSharp, Favorite, Share, ExpandMore, ExpandMoreIcon, AutoAwesome, MilitaryTech, Verified, Grade, Flare, StarSharp, Star, Padding, LocalPolice, MarkEmailRead, FlightSharp} from "@mui/icons-material";
import { useAuth} from "../hooks/useAuth";
import moment from "moment";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import OkDialog from '../hooks/customOkDialog';
import ConfirmDialog from "../hooks/confirmDialog";
import * as XLSX from 'xlsx';
import DragDropFruehwarn from '../hooks/dragoDropFruehwarnliste'
import DragoDropExcel from '../hooks/dragDropTermineExcel'
import DragoDropExcelKunden from '../hooks/dragDropKundenExcel'
import DragoDropDatenteilung     from '../hooks/dragoDropDatenteilung'
import Cropper from 'react-easy-crop'
import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import { fetchdataUrl } from '../A-config/config';
import { globalAppBarMessage } from '../hooks/reduxactions'; // Adjust the path
import { useDispatch } from 'react-redux';
import BenutzerlogsServerside from './BenutzerlogsServerside';
import BenutzerInfoModal from "../modals/BenutzerInfoModal";
import { SettingsAdminMenüServerNachricht } from "./SettingsAdminMenüServerNachricht";

function stringToArrayBuffer(string) {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
}

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

export const SettingsAdminMenü = () =>  {
    const dispatch = useDispatch();
    const [valueTab, setValueTab] = React.useState(0);
    const { user, hasPermission, updateProfilePicture, setUser, logout } = useAuth();
    const inputRef = useRef(null);
    const [isTableUserLoading, setisTableUserLoading] = useState(true)
    const [dataUser, setdataUser] = useState([]);
    const [refreshDataUser, setrefreshDataUser] = useState(false);
    const [processingROW, setprocessingROW] = useState({});
    const [fileTermine, setFileTermine] = useState(null);
    const [fileDataTermine, setDataTermine] = useState([]);
    const [excelColumns, setExcelColumns] = useState([]);
    const [sortedColumns, setSortedColumns] = useState([]);
    const [loadingExcelTermine, setloadingExcelTermine] = useState(null);
    const [loadingExcelKunden, setloadingExcelKunden] = useState(null);
    const [loadingCombineBemerkungen, setloadingCombineBemerkungen] = useState(null);
    const [dataBemerkungenExcel, setdataBemerkungenExcel] = useState(null);
    const [ServerMessage, setServerMessage] = useState('');
    const [firmenName, setFirmenName] = useState('');
    const [strasse, setStrasse] = useState('');
    const [plzStadt, setPlzStadt] = useState('');
    const [telefonnummer, setTelefonnummer] = useState('');
    const [webauftritt, setWebauftritt] = useState('');
    const [email, setEmail] = useState('');
    const [emailausweich, setEmailausweich] = useState('');
    const [slogan, setSlogan] = useState('');  
    const [sharepointlink, setsharepointlink] = useState('');
    const [sharepointlinkschaden, setsharepointlinkschaden] = useState('');  
    const [emailvorlagep, setemailvorlagep] = useState('');     
    const [emailvorlagen, setemailvorlagen] = useState(''); 
    const [dauerletzteaktualisierunganzeigen, setdauerletzteaktualisierunganzeigen] = useState('');  
    const [arbeitszeitVollzeitkraft, setarbeitszeitVollzeitkraft] = useState('');  
    const [UrlaubstageVollzeitkraft, setUrlaubstageVollzeitkraft] = useState('');
    const [taeglicheGutschrift, settaeglicheGutschrift] = useState('');
    const [checkboxkundenaktualisierunganzeigen, setcheckboxkundenaktualisierunganzeigen] = useState(true);
    const [checkboxSchadenbearbeiterinformieren, setcheckboxSchadenbearbeiterinformieren] = useState(false);    
    const [checkboxpersemailberatungsdokuanzeigen, setcheckboxpersemailberatungsdokuanzeigen] = useState(false);
    const [checkboxkurzinfohervorheben, setcheckboxkurzinfohervorheben] = useState(false);    
    const [isUploading, setIsUploading] = useState(false); // Zustand für den Upload-Status
    // const [bemerkungenlogdata, setbemerkungenlogdata] = useState([]); // Zustand für den Upload-Status
    const [openModalBenutzerInfo, setopenModalBenutzerInfo] = useState(false);
    const [rowDATABenutzer, setrowDATABenutzer] = useState('');

    const permissionIcons = {
        Serveradmin: {
            icon: <AdminPanelSettings color="error" sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 300,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Serveradministrator</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Alle Berechtigungen" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>   
        },
        Applikationsadmin: {
            icon: <LocalPoliceSharp sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 300,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Applikationsadministrator</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Terminarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schadenarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Aufgabenarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kunden löschen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kontakthistorie zurücksetzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kundenaktualisierungen zurücksetzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzerpasswörter zurücksetzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzerregistrierung bestätigen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzerberechtigungen setzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Applikationseinstellungen bearbeiten" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schadendaten bearbeiten" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzeraktivität überprüfen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Servernachricht versenden" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>  
        },
        Admin: {
            icon: <SupervisorAccount sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 300,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Administrator</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Terminarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schadenarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Aufgabenarten hinzufügen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kunden löschen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kontakthistorie zurücksetzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Kundenaktualisierungen zurücksetzen" />    
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzerpasswörter zurücksetzen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzerregistrierung bestätigen" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>  
        },
        Zeiterfassungsadmin: {
            icon: <AccessTime sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 300,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Zeiterfassungsadministrator</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Alle Nutzer in der Zeiterfassung anzeigen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Zeiten bearbeiten" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Korrekturen hinzufügen oder entfernen" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>  
        },
        Zeiterfassungsbearbeiter: {
            icon: <Timer sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 400,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Zeiterfassungsbearbeiter</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Alle Nutzer in der Zeiterfassung anzeigen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Zeiten bearbeiten" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Korrekturen hinzufügen oder entfernen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Änderungsanfragen Zeiterfassung erhalten" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>  
        },
        Schadenbearbeiter: {
            icon: <BugReport sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 200,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Schadenbearbeiter</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schäden anlegen" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schäden bearbeiten" />
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Schäden löschen" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>
        },
        Schadenbearbeitergruppe: {
            icon: <MarkEmailRead sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 200,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Schadenbearbeitergruppe</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Mail bei neuem Schaden" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>
        },
        Benutzeraktivitäten: {
            icon: <Assignment sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 400,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Benutzeraktivitätenbetrachter</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<VerifiedSharp />} label="Benutzeraktivitäten betrachten" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>
        }, 
        Urlaubskoordinator: {
            icon: <FlightSharp sx={{width: "20px", height: "20px"}} />,
            tooltip: <Paper
            sx={{
                margin: -1,
                maxWidth: 400,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2} sx={{marginLeft: "1px"}}>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs> 
                    <Typography variant="body2" component="div" gutterBottom>
                        <b>Urlaubskoordinator</b>
                    </Typography>
                    <Typography variant="body2" component="div">
                        <u>Berechtigungen:</u>
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary" sx={{marginBottom: "5px"}}>
                        <Chip size="small" sx={{background: "transparent"}} icon={<FlightSharp />} label="Urlaubsanträge bearbeiten" />
                    </Typography>
                    </Grid>
                </Grid>                   
                </Grid>
            </Grid>              
        </Paper>
        }
    };
    
    const sortPermissions = (permissions) => {
        const priorityPermissions = ['Serveradmin', 'Applikationsadmin', 'Admin'];
        const sortedPermissions = permissions.sort((a, b) => {
          const indexA = priorityPermissions.indexOf(a);
          const indexB = priorityPermissions.indexOf(b);
          if (indexA === -1 && indexB === -1) return 0;
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        });
        return sortedPermissions;
    };

    const getBemerkungenlogdata = async () => {
    //   const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
    //     method: 'POST',
    //     body: JSON.stringify({
    //       access_token: sessionStorage.getItem("access_token"),
    //       selectBemerkungenlog: 1,
    //     }),
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   });
  
    //   const data = await response.json();
  
    //   if (data && data.length > 0) {          
    //     setbemerkungenlogdata(data);
    //   }
    };
  
    const [contentHeight, setContentHeight] = useState(() => {
        const savedHeight = localStorage.getItem('contentHeightSettingsAdminMenu');
        return savedHeight ? parseInt(savedHeight) : 230; // Default to 300 if nothing is stored
    });

    const calculateContentHeightAndPageSize = () => {
        const windowHeight = window.innerHeight - 450;
    
        localStorage.setItem('contentHeightSettingsAdminMenu', windowHeight.toString());
        setContentHeight(windowHeight);
      };
    
      useEffect(() => {
        calculateContentHeightAndPageSize(); // Call on initial load
    
        // Set up event listener for resize
        window.addEventListener('resize', calculateContentHeightAndPageSize);
    
        // Clean-up function
        return () => {
          window.removeEventListener('resize', calculateContentHeightAndPageSize);
        };
    }, []);

    // useEffect(() => {
    //   // Function to start the timer
    //   const interval = setInterval(() => {
    //     getBemerkungenlogdata();
    //   }, 15000); // 15000 ms = 15 seconds
  
    //   // Cleanup function to clear the timer when the component unmounts or dependencies change
    //   return () => clearInterval(interval);
    // }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
     
    //load firmeninfos at start
    useEffect(() => {
        const fetchData = async () => {
          const response = await  fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchgetFirmeninfos: 1,   
            }),
            headers: {
                'Content-Type': 'application/json'
          }});
  
          const data = await response.json();
          if (data) {
            setFirmenName(data.firmenName || '');
            setStrasse(data.strasse || '');
            setPlzStadt(data.plzStadt || '');
            setTelefonnummer(data.telefonnummer || '');
            setWebauftritt(data.webauftritt || '');
            setEmail(data.email || '');
            setEmailausweich(data.emailausweich || '');
            setSlogan(data.slogan || '');     
            setsharepointlink(data.sharepointlink || '');   
            setsharepointlinkschaden(data.sharepointlinkschaden || ''); 
            setemailvorlagep(data.emailvorlagep || 'herzlichen Glückwunsch zu Deinem Geburtstag! 🎉\n\nWir hoffen, dass Du einen wunderbaren Tag voller Freude, Lachen und unvergesslicher Momente verbringst. An diesem besonderen Tag möchten wir Dir unsere besten Wünsche übermitteln und für die Zusammenarbeit und das Vertrauen in unser Unternehmen danken.\n\nGenieße Deinen Tag und lass Dich feiern und verwöhnen. Möge das kommende Jahr Dir viel Glück, Gesundheit und Erfolg bringen.\n\nHerzliche Grüße');
            setemailvorlagen(data.emailvorlagen || 'herzlichen Glückwunsch zu Ihrem Geburtstag! 🎉\n\nWir hoffen, dass Sie einen wunderbaren Tag voller Freude, Lachen und unvergesslicher Momente verbringen. An diesem besonderen Tag möchten wir Ihnen unsere besten Wünsche übermitteln und Ihnen für die Zusammenarbeit und das Vertrauen in unser Unternehmen danken.\n\nGenießen Sie Ihren Tag, lassen Sie sich feiern und verwöhnen. Möge das kommende Jahr Ihnen viel Glück, Gesundheit und Erfolg bringen.\n\nHerzliche Grüße');
            setcheckboxkurzinfohervorheben(Boolean(data.checkboxkurzinfohervorheben) ?? true);
            setcheckboxpersemailberatungsdokuanzeigen(Boolean(data.checkboxpersemailberatungsdokuanzeigen) ?? true);
            setcheckboxkundenaktualisierunganzeigen(Boolean(data.checkboxkundenaktualisierunganzeigen) ?? true);
            setcheckboxSchadenbearbeiterinformieren(Boolean(data.checkboxSchadenbearbeiterinformieren) ?? true);
            setdauerletzteaktualisierunganzeigen(data.dauerletzteaktualisierunganzeigen ?? 365);    
            setarbeitszeitVollzeitkraft(data.arbeitszeitVollzeitkraft ?? 40); 
            setUrlaubstageVollzeitkraft(data.UrlaubstageVollzeitkraft ?? 30); 
            settaeglicheGutschrift(data.taeglicheGutschrift ?? 0); 
            localStorage.setItem('firmenInfos', JSON.stringify(data));
          }
        };
        fetchData();
      }, []);
  
    const handleSubmitFirmeninfos = (event) => {
      event.preventDefault();  // Prevent the default form submission behavior
  
      const firmenInfosData = {
        firmenName,
        strasse,
        plzStadt,
        telefonnummer,
        webauftritt,
        email,
        emailausweich,
        slogan,
        sharepointlink,
        sharepointlinkschaden,
        emailvorlagep,
        emailvorlagen,
        checkboxkurzinfohervorheben,
        checkboxpersemailberatungsdokuanzeigen,
        checkboxkundenaktualisierunganzeigen,
        checkboxSchadenbearbeiterinformieren,
        dauerletzteaktualisierunganzeigen,
        arbeitszeitVollzeitkraft,
        UrlaubstageVollzeitkraft,
        taeglicheGutschrift
      };
  
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchsetFirmeninfos: 1,    
            firmenName: firmenName,
            strasse: strasse,
            plzStadt: plzStadt,
            telefonnummer: telefonnummer,
            webauftritt: webauftritt,
            email: email,
            emailausweich: emailausweich,
            slogan: slogan,
            sharepointlink: sharepointlink,
            sharepointlinkschaden: sharepointlinkschaden,
            emailvorlagep: emailvorlagep,
            emailvorlagen: emailvorlagen,
            checkboxkurzinfohervorheben: checkboxkurzinfohervorheben,
            checkboxpersemailberatungsdokuanzeigen: checkboxpersemailberatungsdokuanzeigen,
            checkboxkundenaktualisierunganzeigen: checkboxkundenaktualisierunganzeigen,
            checkboxSchadenbearbeiterinformieren: checkboxSchadenbearbeiterinformieren,
            dauerletzteaktualisierunganzeigen: dauerletzteaktualisierunganzeigen,
            arbeitszeitVollzeitkraft: arbeitszeitVollzeitkraft,
            UrlaubstageVollzeitkraft: UrlaubstageVollzeitkraft,
            taeglicheGutschrift: taeglicheGutschrift
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
          if (data.success) {
            dispatch(globalAppBarMessage(`Die Firmendaten wurden erfolgreich gespeichert.`));
            // Set the updated data in localStorage after the successful fetch
            localStorage.setItem('firmenInfos', JSON.stringify(firmenInfosData));
          } 
      })    
    };  
    const handleSendServerMessage = () => {
      try {     
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchSetServerMessage: 1,
            message: ServerMessage
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            alert('Nachricht an alle Nutzer gesendet!');
        });   
  
      } catch (error) {
        console.error('Error sending server message:', error);
        alert('Failed to send the message. Please try again.');
      }
    };
      
    //Simple OK Dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
  
    const handleOpenDialog = (message) => {
      setDialogMessage(message);
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    }; 

    //Confirm Dialog
    const [openConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogMessage, setconfirmDialogMessage] = useState('');
    const [confirmDialogAction, setconfirmDialogAction] = useState('');
  
    const handleOpenConfirmDialog = (message, action) => {
      setconfirmDialogMessage(message);
      setShowConfirmDialog(true);
      setconfirmDialogAction(action);
    };
  
    const handleConfirmdialogConfirm = () => {
      if (confirmDialogAction == "Nutzerlöschen")
      {
            if (processingROW.original.berechtigungen.includes("Serveradmin"))
            {
                handleOpenDialog('Dieser Nutzer kann nicht gelöscht werden.')
            } else
            {
                if (hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin'))
                    {
                      fetch(`${fetchdataUrl}fetchdata.php`, {
                      method: 'POST',
                      body: JSON.stringify({
                          access_token: sessionStorage.getItem("access_token"),
                          fetchDeleteNutzer: 1,
                          UserID: processingROW.original.id
                      }),
                      headers: {
                          'Content-Type': 'application/json'
                      }
                      }).then((response) => {
                          return response.json();
                      }).then((data) => {
                          reloadDataUser();
                          // handleOpenDialog(`Nutzerlevel für Benutzer: ${row.getValue('admin')} um +1 erhöht.`)
                      });   
                    }
                    else
                    { 
                        handleOpenDialog('Du hast hierzu keine Berechtigung. Benötigte Berechtigungen: <b>Serveradmin</b> oder <b>Applikationsadmin</b>')
                    }
            }
     
            setShowConfirmDialog(false);
      }   
    };  

    const handleChangeTabs = (event, newValue) => {
        setValueTab(newValue);
    };
  
  const reloadDataUser = () => {
    setrefreshDataUser(!refreshDataUser);
  };
  
  const handlePasswortzurücksetzen = (row) => {
  
    if (hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin'))
    {
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchBenutzerPasswortzurücksetzen: 1,
            UserID: row.original.id
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            reloadDataUser();
            handleOpenDialog(`Zurücksetzen des Passworts für Benutzer: ${row.getValue('username')} erfolgreich. Temporäres Passwort: temporarypass123#`)
        });  
    }
    else
    {
        handleOpenDialog(`Du hast hierzu keine Berechtigung. Bitte wende dich an den Systemadministrator.`)
    }
  };
  
  const handleBenutzerfreischalten = (row) => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchBenutzerFreischalten: 1,
          UserID: row.original.id
      }),
      headers: {
          'Content-Type': 'application/json'
      }
      }).then((response) => {
          return response.json();
      }).then((data) => {
          reloadDataUser();
          handleOpenDialog(`Benutzer: ${row.getValue('username')} erfolgreich freigeschaltet.`)
      });   
  };
  
//   const handleBenutzerbefördern = (row) => {
//     if (row.getValue('admin') >= user.admin)
//     {
//       handleOpenDialog('Du hast hierzu keine Berechtigung. Dein Nutzerlevel ist zu gering.')
//     }
//     else
//     {
//       var neuesNutzerlevel;
//       if (!isNaN(parseInt(row.original.admin))) {
//         neuesNutzerlevel = parseInt(row.original.admin) + 1;
//       } else {
//         neuesNutzerlevel = 1;
//       }
  
//       fetch(`${fetchdataUrl}fetchdata.php`, {
//       method: 'POST',
//       body: JSON.stringify({
//           access_token: sessionStorage.getItem("access_token"),
//           fetchUpdateNutzerlevel: 1,
//           neuesNutzerlevel: neuesNutzerlevel,
//           UserID: row.original.id
//       }),
//       headers: {
//           'Content-Type': 'application/json'
//       }
//       }).then((response) => {
//           return response.json();
//       }).then((data) => {
//           reloadDataUser();
//           // handleOpenDialog(`Nutzerlevel für Benutzer: ${row.getValue('admin')} um +1 erhöht.`)
//       });   
//     }
//   };
  
//   const handleBenutzerdegradieren = (row) => {
//     if (row.getValue('admin') >= user.admin)
//     {
//       handleOpenDialog('Du hast hierzu keine Berechtigung. Dein Nutzerlevel ist zu gering.')
//     }
//     else
//     {
//       var neuesNutzerlevel;
//       if (!isNaN(parseInt(row.original.admin)) && row.original.admin != 0) {
//         neuesNutzerlevel = parseInt(row.original.admin) - 1;
//       } else {
//         neuesNutzerlevel = 0;
//       }
  
//       fetch(`${fetchdataUrl}fetchdata.php`, {
//       method: 'POST',
//       body: JSON.stringify({
//           access_token: sessionStorage.getItem("access_token"),
//           fetchUpdateNutzerlevel: 1,
//           neuesNutzerlevel: neuesNutzerlevel,
//           UserID: row.original.id
//       }),
//       headers: {
//           'Content-Type': 'application/json'
//       }
//       }).then((response) => {
//           return response.json();
//       }).then((data) => {
//           reloadDataUser();
//           // handleOpenDialog(`Nutzerlevel für Benutzer: ${row.getValue('admin')} um +1 erhöht.`)
//       });   
//     }
//   };
  
  const handleBenutzerlöschen = () => {
      handleOpenConfirmDialog('Soll dieser Benutzer wirklich gelöscht werden?', 'Nutzerlöschen')
  };

  const [loadingDatenteilung, setloadingDatenteilung] = useState(null);
  const [FileDatenteilung, setFileDatenteilung] = useState(null);
  const [DataDatenteilung, setDataDatenteilung] = useState([]);
  const [ExcelColumnsDatenteilung, setExcelColumnsDatenteilung] = useState([]);

  const handleFileUploadDatenteilung = (e) => {
    setloadingDatenteilung(true);
    const file = e.target.files[0];
    setFileDatenteilung(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: "dd.MM.yyyy", });

      setDataDatenteilung(data);
      const headers = data[0];
      setExcelColumnsDatenteilung(headers);
      setloadingDatenteilung(false);
    };

    reader.readAsBinaryString(file);
};

const [loadingFruehwarn, setloadingFruehwarn] = useState(null);
const [FileFruehwarn, setFileFruehwarn] = useState(null);
const [DataFruehwarn, setDataFruehwarn] = useState([]);
const [ExcelColumnsFruehwarn, setExcelColumnsFruehwarn] = useState([]);

const handleFileUploadFruehwarn = (e) => {
    setloadingFruehwarn(true);
    const file = e.target.files[0];
    setFileFruehwarn(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: "dd.MM.yyyy", });

      setDataFruehwarn(data);
      const headers = data[0];
      setExcelColumnsFruehwarn(headers);
      setloadingFruehwarn(false);
    };

    reader.readAsBinaryString(file);
};

  const handleFileUpload = (e) => {
      setloadingExcelTermine(true);
      const file = e.target.files[0];
      setFileTermine(file);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: "dd.MM.yyyy", });
  
        setDataTermine(data);
        const headers = data[0];
        setExcelColumns(headers);
        setloadingExcelTermine(false);
      };
  
      reader.readAsBinaryString(file);
  };
  
  const handleFileUploadKunden = (e) => {
      setloadingExcelKunden(true);
      const file = e.target.files[0];
      setFileTermine(file);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: "dd.MM.yyyy", });
  
        setDataTermine(data);
        const headers = data[0];
        setExcelColumns(headers);
        setloadingExcelKunden(false);
      };
  
      reader.readAsBinaryString(file);
  };
  
  const handleCombineBemerkungen = (e) => {
      setloadingCombineBemerkungen(true);
      const file = e.target.files[0];
      setFileTermine(file);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: "dd.MM.yyyy", });
  
        setdataBemerkungenExcel(data);
        setloadingCombineBemerkungen(false);
      };
  
      reader.readAsBinaryString(file);
  
  }
  
  const handleBemerkungenzusammenführen = () => {
  
      const newArray = dataBemerkungenExcel.map(row => {
        const PNr = row[1];
        const Bemerkungen = row[2] ? row[2].replace(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>").replace(/<br><br>/g, "</p><p>") : "";
        return [row[0], PNr, Bemerkungen];
      });
  
      setloadingCombineBemerkungen(true);
      dataBemerkungenExcel.shift();
  
      fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchBemerkungenzusammen: 1,
                arrayTermine: newArray,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setloadingCombineBemerkungen(false);
        }).catch(() => {
      });
  }

  const handlecheckboxkurzinfohervorheben = (e) => {
    const checked = e.target.checked;
    setcheckboxkurzinfohervorheben(checked);    
 }

 const handlecheckboxpersemailbeiberatungsdokuanzeigen = (e) => {
    const checked = e.target.checked;
    setcheckboxpersemailberatungsdokuanzeigen(checked);
 }

  const handlecheckboxkundenaktualisierunganzeigen = (e) => {
    const checked = e.target.checked;
    setcheckboxkundenaktualisierunganzeigen(checked);
    if (checked && dauerletzteaktualisierunganzeigen === "" || dauerletzteaktualisierunganzeigen === 0)
    {
        setdauerletzteaktualisierunganzeigen(365)
    } else if (!checked)
    {
        setdauerletzteaktualisierunganzeigen(0)
    }
 }

 const handlecheckboxSchadenbearbeiterinformieren = (e) => {
    const checked = e.target.checked;
    setcheckboxSchadenbearbeiterinformieren(checked);  
 }
 
  let allSchadenData = null; // this variable will store the fetched data
  
  const BATCH_SIZE = 50;
  const DELAY = 10000; // delay in ms
  
  const handleRFTtoHTML = async () => {
    if (!allSchadenData) {
      // If data has not been fetched before, fetch it now
      const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchAlleSchäden: 1,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
      });
  
      allSchadenData = await response.json();
    }
  
    let i = 0;
  
    while (i < allSchadenData.length) {
      const batch = allSchadenData.slice(i, i + BATCH_SIZE);
      let batchUpdated = false;
  
      for (const item of batch) {
        const updated = await processItem(item);
        if (updated) {
          batchUpdated = true;
        }
      }
  
      if (batchUpdated) {
        // delay between each batch if any item in the batch was updated
        await new Promise(resolve => setTimeout(resolve, DELAY));
      }
  
      i += BATCH_SIZE;
      // Update progress indicator here
      console.log(`Processed ${i} of ${allSchadenData.length}`);
    }
  };
  
  const processItem = async (item) => {
    if (!item.Bemerkungen.startsWith("{\\rtf")) {
      return false;
    }
  
    const doc = new RTFJS.Document(stringToArrayBuffer(item.Bemerkungen));
    const meta = doc.metadata();
    const htmlElements = await doc.render();
    const container = document.createElement('div');
    container.innerHTML = htmlElements.map((el) => el.outerHTML).join('');
  
    // update Bemerkungen in the database
    const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          updateBemerkungenSchadenRTFtoHTML: 1,
          Schadennummer: item.Schadennummer,
          PNr: item.PNr,
          newBemerkungen: container.innerHTML
      }),
      headers: {
          'Content-Type': 'application/json'
      }
    });
  
    const updateResponse = await response.json();
    return true;
  };
  
  
  const columnsTableUser = React.useMemo(
          () => [        
        {
            header: 'ID',
            accessorKey: 'id',          
            muiTableHeadCellProps: {
            },
            minSize: 60, //min size enforced during resizing
            maxSize: 60, //max size enforced during resizing
            size: 60, //medium column
            hidden: true
        },
        {
            header: 'Benutzername',
            accessorKey: 'username',
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column          
        },
        {
            header: 'Erstellt am',
            accessorKey: 'created_at',
            muiTableHeadCellProps: {
            },
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column
            Cell: ({ cell }) => {
            const newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY");
            return <span>{newdate}</span>;
          },
        },   
          {
            header: 'Berechtigungen',
            accessorKey: 'berechtigungen',
            muiTableHeadCellProps: {
            },
            minSize: 200, //min size enforced during resizing
            size: 155, //medium column
            Cell: ({ cell }) => {
                const value = cell.getValue();
                // Ensure the value is an array
                const permissions = Array.isArray(value) ? value : value ? JSON.parse(value) : [];
                const sortedPermissions = sortPermissions(permissions);
                return (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {sortedPermissions.map((permission, index) => (
                            <Tooltip key={index} title={permissionIcons[permission]?.tooltip || permission}>
                                <Box ml={0.5}>
                                    {permissionIcons[permission]?.icon || <span>{permission}</span>}
                                </Box>
                            </Tooltip>
                        ))}
                    </Box>
                  );
              },
        },
         {
            header: 'Vollständiger Name',
            accessorKey: 'vollstaendigerName',
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column         
        },
          {
            header: 'Kürzel',
            accessorKey: 'kürzel',
            muiTableHeadCellProps: {
            },
            minSize: 60, //min size enforced during resizing
            size: 60, //medium column
        },
        {
            header: 'Registrierung bestätigt',
            accessorKey: 'registerconfirmed',
            muiTableHeadCellProps: {
            },
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column
        },
          ],
          []
  )
  
   useEffect(() => {
          fetch(`${fetchdataUrl}fetchdata.php`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
              fetchAlleUser: 1
          }),
          headers: {
              'Content-Type': 'application/json'
          }
          }).then((response) => {
              return response.json();
          }).then((data) => {
              setdataUser(data);
              setisTableUserLoading(false);
          });   
  }, [refreshDataUser]); 
  
  const filterTableUser = dataUser && dataUser.length > 0 ? dataUser.filter(row => {
        return row;
  }) : [];

    return (
        <div>
            <OkDialog open={openDialog} message={dialogMessage} onClose={handleCloseDialog} />
            <ConfirmDialog
                open={openConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                message={confirmDialogMessage}
                onConfirm={handleConfirmdialogConfirm}
                onCancel={() => setShowConfirmDialog(false)}
            />   
            <Tabs sx={{ml: 4}} value={valueTab} onChange={handleChangeTabs}>
                <Tab label="Benutzerverwaltung" />
                {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Benutzeraktivitäten') ? <Tab label="Benutzeraktivitäten" /> : null }
                {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') ? <Tab label="Voreinstellungen" /> : null }
                {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') ? 
                <Tab label="Servernachricht" /> : null }
                {hasPermission(user, 'Serveradmin') ? 
                 <Tab label="Übertragungsfenster" /> : null }
            </Tabs>
            <div className={`tabContentSettings ${valueTab === 0 ? 'active' : ''}`}>      
            <Card sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardContent>
            <h2 content="div" style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Benutzerverwaltung</h2>  
            <Divider></Divider>
            <div>         
                <MaterialReactTable
                        // key={tableKey}
                        state={{ isLoading: isTableUserLoading }}
                        columns={columnsTableUser}
                        data={filterTableUser}                    
                        initialState={{pagination: {pageSize: 100, pageIndex: 0}, columnVisibility: { id: false }, density: 'compact', sorting: [{ id: "id", asc: true}]}}
                        muiTableHeadRowProps={
                        {
                            sx: {justifyContent: "center"}
                        }
                        }
                        muiTableBodyCellProps={
                        {
                            style: {overFlow: "hidden"}
                        }
                        }
                        muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                        }}
                        muiTablePaperProps={{
                        elevation: 0
                        }}
                        enableMultiRowSelection={false}
                        enableRowSelection
                        enablePagination={true}   
                        autoResetPageIndex={false}  
                        enableStickyHeader                  
                        muiTableContainerProps={{ sx: { maxHeight: contentHeight } }}
                        localization={MRT_Localization_DE}
                        muiTableBodyRowProps={({ row }) => ({
                            onDoubleClick: () => {
                                setrowDATABenutzer(row.original);
                                setopenModalBenutzerInfo(true);
                            }
                        })}
                        renderToolbarInternalActions={({ table }) => (
                        <Box>  
                            {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ? 
                            <Tooltip title="Passwort zurücksetzen">
                                <IconButton
                                onClick={() => {
                                    table.getSelectedRowModel().flatRows.map((row) => {   
                                        handlePasswortzurücksetzen(row)                                                                                               
                                });
                                }}
                            >
                                <PasswordSharp />
                            </IconButton>
                            </Tooltip> : null }
                            <Tooltip title="Benutzer freischalten">
                                <IconButton
                                onClick={() => {
                                    table.getSelectedRowModel().flatRows.map((row) => {   
                                        handleBenutzerfreischalten(row)                                                                                               
                                });
                                }}
                            >
                                <HowToRegSharp />
                            </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Benutzerlevel erhöhen">
                                <IconButton
                                onClick={() => {
                                    table.getSelectedRowModel().flatRows.map((row) => {   
                                        handleBenutzerbefördern(row)                                                                                               
                                });
                                }}
                            >
                                <KeyboardDoubleArrowUpSharp />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Benutzer verringern">
                                <IconButton
                                onClick={() => {
                                    table.getSelectedRowModel().flatRows.map((row) => {   
                                        handleBenutzerdegradieren(row)                                                                                               
                                });
                                }}
                            >
                                <KeyboardDoubleArrowDownSharp />
                            </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Benutzer löschen">
                                <IconButton
                                onClick={() => {
                                    table.getSelectedRowModel().flatRows.map((row) => {  
                                    setprocessingROW(row) 
                                    handleBenutzerlöschen()
                                });
                                }}
                            >
                                <DeleteSharp />
                            </IconButton>
                            </Tooltip>                       
                        </Box>
                        )}             
                    />
                    <BenutzerInfoModal openModal={openModalBenutzerInfo} setopenModal={setopenModalBenutzerInfo} rowDATA={rowDATABenutzer} refreshdata={() => reloadDataUser()}></BenutzerInfoModal>
            </div>       
              
            </CardContent>
            </Card>
            
            </div>
            <div className={`tabContentSettings ${valueTab === 1 ? 'active' : ''}`}>  
            <Card>
                <CardContent>
                <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Benutzeraktivitäten</h2>  
                <Divider sx={{marginBottom: "20px"}}></Divider>
                <BenutzerlogsServerside></BenutzerlogsServerside>
                </CardContent>
            </Card>    
                        
            </div>
            <div className={`tabContentSettings ${valueTab === 2 ? 'active' : ''}`}>  
            <Card sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardContent>
            <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Einstellungen</h2>  
            <Divider sx={{marginBottom: "20px"}}></Divider>
                <form onSubmit={handleSubmitFirmeninfos}>
                    <TextField
                        value={firmenName}
                        onChange={e => setFirmenName(e.target.value)}
                        label="Firmenname"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    
                    <TextField
                        value={strasse}
                        onChange={e => setStrasse(e.target.value)}
                        label="Straße und Hausnummer"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    
                    <TextField
                        value={plzStadt}
                        onChange={e => setPlzStadt(e.target.value)}
                        label="PLZ und Stadt"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    
                    <TextField
                        value={telefonnummer}
                        onChange={e => setTelefonnummer(e.target.value)}
                        label="Telefonnummer"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />

                    <TextField
                        value={webauftritt}
                        onChange={e => setWebauftritt(e.target.value)}
                        label="Webauftritt"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />

                    <TextField
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        label="Allgemeine E-Mail-Adresse"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />

                    <TextField
                        value={emailausweich}
                        onChange={e => setEmailausweich(e.target.value)}
                        label="Ausweich E-Mail-Adresse bei Schadenanlage (falls keine Orga-Nummer zugeordnet werden kann)"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />

                    <TextField
                        value={slogan}
                        onChange={e => setSlogan(e.target.value)}
                        label="Firmen-Slogan"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    <Tooltip title={<div>Sollte so aussehen:
                        <br>
                        </br>
                        NameAgentur/Shared%20Documents/....   !! ACHTUNG: OHNE / am Ende!
                    </div>}>
                    <TextField
                        value={sharepointlink}
                        onChange={e => setsharepointlink(e.target.value)}
                        label="Share Point Link"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    </Tooltip>
                    <Tooltip title={<div>Sollte so aussehen:
                        <br>
                        </br>
                        NameAgentur/Shared%20Documents/....   !! ACHTUNG: OHNE / am Ende!
                    </div>}>
                    <TextField
                        value={sharepointlinkschaden}
                        onChange={e => setsharepointlinkschaden(e.target.value)}
                        label="Share Point Link Schadenverzeichnis"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    </Tooltip>
                    <Stack direction="column">
                    <FormControlLabel control={
                    <Checkbox checked={checkboxpersemailberatungsdokuanzeigen}
                    onChange={handlecheckboxpersemailbeiberatungsdokuanzeigen} />} label="Persönliche E-Mail des Bearbeiters beim Druck der Beratungsdokumentation anzeigen" />
                    <FormControlLabel control={
                    <Checkbox checked={checkboxkurzinfohervorheben}
                    onChange={handlecheckboxkurzinfohervorheben} />} label="Kurzinformationen rot hervorheben" />
                    <FormControlLabel control={
                    <Checkbox checked={checkboxSchadenbearbeiterinformieren}
                    onChange={handlecheckboxSchadenbearbeiterinformieren} />} label="Schadenbearbeiter bei Änderungen durch anderen Nutzer informieren" />
                    <FormControlLabel control={
                    <Checkbox checked={checkboxkundenaktualisierunganzeigen}
                    onChange={handlecheckboxkundenaktualisierunganzeigen} />} label="Meldung letzte Kundenaktualisierung anzeigen" />
                    <TextField
                        value={dauerletzteaktualisierunganzeigen}
                        onChange={e => setdauerletzteaktualisierunganzeigen(e.target.value)}
                        label="Zeitpunkt in Tagen, ab wann die Meldung zur letzten Kundenaktualisierung angezeigt werden soll"
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                     <TextField
                        value={arbeitszeitVollzeitkraft}
                        onChange={e => setarbeitszeitVollzeitkraft(e.target.value)}
                        label="Wöchentliche Arbeitszeit in Stunden einer Vollzeitkraft"
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        value={UrlaubstageVollzeitkraft}
                        onChange={e => setUrlaubstageVollzeitkraft(e.target.value)}
                        label="Urlaubstage einer Vollzeitkraft pro Jahr"
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                     <TextField
                        value={taeglicheGutschrift}
                        onChange={e => settaeglicheGutschrift(e.target.value)}
                        label="Tägliche Gutschrift in Minuten für PC hoch- und runterfahren"
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                        size="small"
                    />
                    <Button type="submit" variant="text" color="primary" style={{ marginTop: '10px', width: "100px" }}>
                        Speichern
                    </Button>   
                    </Stack>
                 
                </form>
            </CardContent>
            <CardContent>
            <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Frühwarnliste einpflegen</h2>  
            <Divider sx={{marginBottom: "20px"}}></Divider>
            <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => document.getElementById('fileInputFruehwarn').click()}
                    >
                        Excel mit Frühwarnliste hochladen...
                    </Button>
                    <input
                        id="fileInputFruehwarn"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUploadFruehwarn}
                        hidden
                    />
                    { loadingFruehwarn ? <LinearProgress></LinearProgress> : null }
                    </div>
                    { loadingFruehwarn !== null ? 
                    <DragDropFruehwarn dataExcel={DataFruehwarn}></DragDropFruehwarn> : null }
            </div>
            <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Datenteilung einpflegen</h2>  
            <Divider sx={{marginBottom: "20px"}}></Divider>
            <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => document.getElementById('fileInputDatenteilung').click()}
                    >
                        Excel mit Datenteilung hochladen...
                    </Button>
                    <input
                        id="fileInputDatenteilung"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUploadDatenteilung}
                        hidden
                    />
                    { loadingDatenteilung ? <LinearProgress></LinearProgress> : null }
                    </div>
                    { loadingDatenteilung !== null ? 
                    <DragoDropDatenteilung dataExcel={DataDatenteilung}></DragoDropDatenteilung> : null }
            </div>
            <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>E-Mail Vorlage Geburtstagsbrief</h2>  
            <Divider sx={{marginBottom: "20px"}}></Divider>
                <form onSubmit={handleSubmitFirmeninfos}>              
                    <TextField
                            fullWidth
                            label="E-Mail Body Persönliche Nachricht zum Geburtstag"
                            multiline
                            rows={10}
                            value={emailvorlagep}
                            onChange={e => setemailvorlagep(e.target.value)}
                            variant="filled"
                    />
                    <TextField
                            sx={{marginTop: "10px"}}
                            fullWidth
                            label="E-Mail Body Neutrale Nachricht zum Geburtstag"
                            multiline
                            rows={10}
                            value={emailvorlagen}
                            onChange={e => setemailvorlagen(e.target.value)}
                            variant="filled"
                    />
                    <Button type="submit" variant="text" color="primary" style={{ marginTop: '10px' }}>
                        Speichern
                    </Button>                   
                </form>
            </CardContent>
            </Card>   
            
            </div>         
            <div className={`tabContentSettings ${valueTab === 3 ? 'active' : ''}`}>   
                <SettingsAdminMenüServerNachricht></SettingsAdminMenüServerNachricht>
            </div>
            <div className={`tabContentSettings ${valueTab === 4 ? 'active' : ''}`}> 
            <Card>
            <CardContent>
                <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Datenübertragung (Excel mit Terminen in Datenbank)</h2>  
                <Divider></Divider>
                <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => document.getElementById('fileInputExcelTermine').click()}
                    >
                        Excel Datei mit Terminen hochladen...
                    </Button>
                    <input
                        id="fileInputExcelTermine"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        hidden
                    />
                    { loadingExcelTermine ? <LinearProgress></LinearProgress> : null }
                    </div>
                    { loadingExcelTermine !== null ? 
                    <DragoDropExcel dataExcel={fileDataTermine}></DragoDropExcel> : null }
                </div>
            </CardContent>
            <CardContent>
                <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Datenübertragung (Excel mit Kundendaten in Datenbank)</h2>  
                <Divider></Divider>
                <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => document.getElementById('fileInputExcelKunden').click()}
                    >
                        Excel Datei mit Kundendaten hochladen...
                    </Button>
                    <input
                        id="fileInputExcelKunden"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUploadKunden}
                        hidden
                    />
                    { loadingExcelKunden ? <LinearProgress></LinearProgress> : null }
                    </div>
                    { loadingExcelKunden !== null ? 
                    <DragoDropExcelKunden dataExcel={fileDataTermine}></DragoDropExcelKunden> : null }
                </div>
            </CardContent>
            <CardContent>
                <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Bemerkungen mit Kundendaten zusammenführen</h2>  
                <Divider></Divider>
                <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => document.getElementById('fileInput3').click()}
                    >
                        Excel Daten mit Bemerkungen einlesen...
                    </Button>
                    <input
                        id="fileInput3"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleCombineBemerkungen}
                        hidden
                    />
                    { loadingCombineBemerkungen ? <LinearProgress></LinearProgress> : null }
                    </div>
                    { loadingCombineBemerkungen !== null ? 
                    <div>
                        <span>Daten erfolgreich eingelesen.</span>
                        <Button variant="text" color="primary" onClick={handleBemerkungenzusammenführen} >Übertragung jetzt starten</Button>
                    </div>
                    : null }
                </div>
            </CardContent>
            <CardContent>
                <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>RTF to HTML</h2>  
                <Divider sx={{mb: 2}}></Divider>
                { user.username === "Raphael" ?
                <Button variant="text" color="primary" onClick={handleRFTtoHTML} >Starten</Button> : <span style={{marginLeft: "10px"}}>Keine Berechtigung, Brudiiii :D</span>}         
            </CardContent>    
            </Card>    
            </div>       
        </div>
    )

}