import React, { useEffect, useRef } from 'react';
import { fetchdataUrl } from '../A-config/config';
import moment from 'moment'; 
import { useDispatch, useSelector } from 'react-redux';
import { setSchadenÄnderungCount, refreshDataKundenSchaeden, refreshuserstatus, refreshDataAufgaben, updateEventData, setAufgabenCount, toggleInfoBarAppbar, toggleGlobalServerMessage, setServerInfoMessage} from './reduxactions';
import { useAuth } from "./useAuth";

const GlobalTaskManager = () => {

    const dispatch = useDispatch();
    const { user, logout } = useAuth();
    const aufgabencountfromredux = useSelector(state => state.aufgabencount);

    // Task 1: Check for Beratungsintervall Appointments
    const checkBeratungsterminIntervall = async () => {

        if (!user || !user.vollstaendigerName) {
            return;
        }

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchCheckIntervallTermine:1,
            }),
          })
          .then(response => response.json())
          .then(data => {
                data.map((data, index) => {
                      
                      if (data.betreuer == user.vollstaendigerName)
                      {
                        const newDate = moment(data.datum, 'DD.MM.YYYY').add(data.intervall, 'days');
                        const isToday = moment(newDate).isBefore(moment(), 'day');
                        
                        if (isToday) {
                          if (data.intervall != "0")
                          {
                              const infos = [
                                { datumkundentermin: data.datum },
                                { intervall: data.intervall },
                                { nameuvorname: data.nameuvorname },
                                { pnr: data.pnr }
                                // add more objects as needed
                              ];
    
                              fetch(`${fetchdataUrl}fetchdata.php`, {
                              method: 'POST',
                              body: JSON.stringify({
                                  access_token: sessionStorage.getItem("access_token"),
                                  fetchAddAufgabe: 1,
                                  AufgabenTitel: "Geplanter Beratungstermin für: " +  data.nameuvorname + " - " + data.pnr,
                                  AufgabenArt: "Beratungstermin",
                                  AufgabeFür: data.betreuer,
                                  AufgabeVon: "Automatisch",
                                  Bemerkungen: "Du hast diesen Kunden bereits seit einiger Zeit nicht mehr besucht. Der letzte Kundentermin war am " + data.datum + ". Es wurde ein Beratungsintervall von " + data.intervall + " Tag(en) festgelegt. Bitte setze dich mit dem Kunden in Verbindung.",
                                  Datum: moment().format('YYYY-MM-DD HH:mm:ss'),
                                  Infos: infos
                              }),
                              headers: {
                                  'Content-Type': 'application/json'
                              }
                              }).then((response) => {
                                  return response.json();
                              }).then((data) => {
                                  if (data === "aufgabeautoadded")
                                  {
                                    dispatch(refreshDataAufgaben());
                                  }
                              });
                          }
                        }
                      }
                })
        }).catch(() => {
            console.log("Aktion fehlgeschlagen: Netzwerkfehler beim Abrufen der Beratungstermine");
        })
    }

    const checkAufgaben = async () => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchCheckAufgabenzumStart:1,
                mycurrentuser: user.vollstaendigerName,
            }),
        })
        .then(response => response.json())
        .then(data => {
            //Falls auf einem anderen Medium eingeloggt wurde und das Access-Token geändert wurde, loggen wir hier den Nutzer automatisch aus.
            if (data && data["Fehler"] === "Keine Authorisierung für diese Aktion.")
            {
            logout();
            return;
            }

            const lastCheckedAufgaben = localStorage.getItem('lastCheckAufgaben');
            let countNeueAufgaben = 0;

            data.map((data, index) => {
                //Hier Checken wir nur nach dem Count der neuen Aufgaben
                const isDatumGreaterThanNow = moment(data.Datum).isAfter(moment(lastCheckedAufgaben));
                if (isDatumGreaterThanNow) {
                    countNeueAufgaben++;
                }
            })

            if (countNeueAufgaben > 0)
            {
            if (countNeueAufgaben !== aufgabencountfromredux)
            {
                dispatch(toggleInfoBarAppbar(true));
            }
            dispatch(setAufgabenCount(countNeueAufgaben));
            dispatch(refreshDataAufgaben());
            }
        })
        .catch(() => {
            console.log("Aktion fehlgeschlagen: Netzwerkfehler beim Abrufen der Aufgaben");
        });
    }

    const checkforservermessages = async () => {
            //Check Server Message
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchGetServerMessage:1,
                }),
            })
            .then(response => response.json())
            .then(data => {
                const serverMessageDate = moment(data.Datum, 'YYYY-MM-DD HH:mm:ss');
                const lastReadTime = localStorage.getItem('lastCloseInfoBarServerInfo');
                const lastReadDate = lastReadTime ? moment(lastReadTime, 'YYYY-MM-DD HH:mm:ss') : null;
                if (!lastReadDate || serverMessageDate.isAfter(lastReadDate)) {
                    dispatch(setServerInfoMessage(data.Nachricht))
                    dispatch(toggleGlobalServerMessage(true))
                }
            })
            .catch(() => {
                console.log("Aktion fehlgeschlagen: Netzwerkfehler beim Abrufen der Aufgaben");
            });
    }

    const checkfornewSchadenÄnderungen = async () => {
        const lastCheckTime = localStorage.getItem('lastCheckSchadenänderungen') || '1970-01-01 00:00:00';
    
        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchGetNewSchadenänderungen: 1,
                lastCheckDate: lastCheckTime,
                username: user.vollstaendigerName,
            }),
        });
        const data = await response.json();
        dispatch(setSchadenÄnderungCount(data.new_count));
    };

    return {
        checkBeratungsterminIntervall,
        checkAufgaben,
        checkforservermessages,
        checkfornewSchadenÄnderungen
    };  
}      
       
export default GlobalTaskManager;