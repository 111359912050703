import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEventData, updatePnr, setloading, updateQuillValue} from './reduxactions';
import { useLoaderData, useNavigate  } from "react-router-dom";
import { isMobile, isTablet, isDesktop, ConsoleView } from 'react-device-detect';
import { fetchdataUrl } from '../A-config/config';
import moment from 'moment';

export function useEventData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isModalOpen = useSelector(state => state.isModalOpen);
  const isConfirmDialogBeratungsdokuOpen = useSelector(state => state.isConfirmDialogBeratungsdokuOpen);
  const [pendingDataUpdate, setPendingDataUpdate] = useState(null);
  const pnrprocessed = useSelector(state => state.pnrprocessed);

  const handleMessage = useCallback((event) => {

    if (event.data.pfadkundenablage) {
      localStorage.setItem("pfadkundenablage", event.data.pfadkundenablage)
    }

    if (event.data.BestandChange === false) {
         if (isModalOpen) {
          setPendingDataUpdate(event);
        } else {
          setPendingDataUpdate(null);
          dispatch(setloading(true));
          updateDataInDatabase(event);      
        }
      }
    
    //Bemerkungen übertragen Log
    // if (event.data.UpdateBemerkungen) {      
    //   fetch(`${fetchdataUrl}fetchdata.php`, {
    //     method: 'POST',
    //     body: JSON.stringify({
    //               access_token: sessionStorage.getItem("access_token"),
    //               insertintobemerkungenlog: 1,    
    //               pnr:event.data.pnr,
    //               bemerkung: event.data.bemerkung,
    //               datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     }),
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    //   })
    //   .then(response => response.json())
    //   .then(data => {
    //   })
    // } else if (event.data.ClipboardInvalid)
    // {
    //   fetch(`${fetchdataUrl}sendemail.php`, {
    //     method: 'POST',
    //     body: JSON.stringify({
    //         access_token: sessionStorage.getItem("access_token"),
    //         sendInvalidClipboard: 1,                   
    //     }),
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    //     }).then((response) => {
    //         return console.log(response);
    //     }) 
    // }
    
  }, [isModalOpen, dispatch, navigate, pnrprocessed]);

  const updateDataInDatabase = (event) => {

    if (event.data.name === "ul" && event.data.vorname === "ul")
    {
      return;
    }

    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateUserDataDatabase: 1,    
                pnr:event.data.pnr,
                titel: event.data.titel,
                name: event.data.name,
                vorname: event.data.vorname,
                straße:event.data.straße,
                plzort:event.data.plzort,
                gebdatum:event.data.gebdatum,
                email:event.data.email,
                festnetz:event.data.festnetz,
                handy:event.data.handy,
                telgesch: event.data.telgesch,
                betreuer:event.data.betreuer,
                kürzelbearbeiter:event.data.kürzelBearbeiter
      }),
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      dispatch(updateEventData(data)); 
      navigate(`/dashboard/UserKundenübersicht/${data.PNr}`);
    });
  }

  useEffect(() => {
    if (pendingDataUpdate && !isModalOpen) {
      dispatch(setloading(true));        
      var elements = document.getElementsByClassName("ql-editor");
      if (elements.length > 0) {
        elements[0].innerHTML = "";
      }
      updateDataInDatabase(pendingDataUpdate);
      setPendingDataUpdate(null);
    }
  }, [isModalOpen, pendingDataUpdate, dispatch, navigate]);

  useEffect(() => {
    if (window.chrome && window.chrome.webview && isDesktop) {
      window.chrome.webview.addEventListener('message', handleMessage);
      return () => {
        window.chrome.webview.removeEventListener('message', handleMessage);
      };
    }
  }, [handleMessage]);
}