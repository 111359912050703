import React, { useState, useEffect, useRef} from 'react';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import TextFieldPopup from '../components/TextFieldPopup';
// import CustomMentionsInput from './CustomMentionsInput';
// import '../MentionsEditor.css'
// import { Mention, MentionsInput } from 'react-mentions';

import { fetchdataUrl } from '../A-config/config';

import {
  Alert,
  Snackbar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select, 
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  useScrollTrigger,
  Switch
} from '@mui/material'

import { Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star} from '@mui/icons-material';

const style = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};

// const mentions = [
//   {
//     name: 'Matthew Russell',
//     link: 'https://twitter.com/mrussell247',
//     avatar:
//       'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
//   },
//   {
//     name: 'Julian Krispel-Samsel',
//     link: 'https://twitter.com/juliandoesstuff',
//     avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
//   },
//   {
//     name: 'Jyoti Puri',
//     link: 'https://twitter.com/jyopur',
//     avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
//   },
// ];

export default function AufgabenModal({reloadData, openModalAufgaben, setOpenModalAufgaben}) {

    // const [mentions, setMentions] = useState([]);

    const [konfigurationsModusAktiv, setkonfigurationsModusAktiv] = useState(false);
    const handleCloseAufgaben = () => setOpenModalAufgaben(false);
    const [optionsBearbeiter, setOptionsBearbeiter] = useState([]);
    const [optionsAufgabenArt, setOptionsAufgabenArt] = useState([]);
    const [anchorEl, setAnchorElPopoverArthinz] = React.useState(null);
    const AufgabenArthinzRef = useRef();
    const AufgabenArtSelectRef = useRef();    
    const [refetchData, setRefetchData] = useState(false);
    const { control, handleSubmit, setError, clearErrors, reset, trigger} = useForm({
        reValidateMode: "onChange",
    });
    const open = Boolean(anchorEl);
    const {user, hasPermission} = useAuth();
    const [openTextField, setopenTextField] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [snackbar, setSnackbar] = useState({
            open: false,
            message: "",
            severity: "error"
        });

        const handleSnackbarClose = () => {
            setSnackbar({
            open: false,
            message: "",
            severity: "error"
            });
    }

    const openTextFieldDialog = (value) => {
        setTextFieldValue(value);
        setopenTextField(true);
    };

    const closeTextFieldDialog = () => {
        setopenTextField(false);
    };

    useEffect(() => {
        //Hierdurch wird der useEffect nur ausgelöst, wenn das Modal wirklich geöffnet wird und nicht beim Start der Applikation.
        if (!openModalAufgaben) return;
        reset();

        //Load Bearbeiter
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadBearbeiter: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsBearbeiter(data);
        });   

        //Load Aufgabenart
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadAufgabenArt: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsAufgabenArt(data);
            trigger();
        });     

        //Load        

    },[refetchData, openModalAufgaben]);

    const handleKonfigurationsModusToggle = () => {
        setkonfigurationsModusAktiv((prevValue) => !prevValue);
    };

    const handleClickAufgabeArtPlus = (e) => {
        if (!AufgabenArthinzRef.current.value === "") 
        {
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddAufgabenArt: 1,
                aufgabenart: AufgabenArthinzRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
            });
        }
    }
    
    const handleClickAufgabenArtloeschen = (e) => {

        if (!AufgabenArtSelectRef.current.value == "") 
        {
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchDeleteAufgabenArt: 1,
                aufgabenart: AufgabenArtSelectRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
            });
        }
    }

    const handleClickAufgabenArthinz = (event) => {
        setAnchorElPopoverArthinz(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElPopoverArthinz(null);
    };
     
    const handleOnSubmit = (evt) => {
        if (evt.AufgabenTitel.includes("Geplanter Beratungstermin für:"))
        {
              setSnackbar({
                    open: true,
                    message: "Dieser Titel ist für eine automatisierte Aufgabe reserviert. Bitte wähle einen anderen Titel.",
                    severity: "error"
              });
        } 
        else
        {
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchAddAufgabe: 1,
                    AufgabenTitel: evt.AufgabenTitel,
                    AufgabenArt: evt.selectAufgabenArt,
                    AufgabeFür: evt.selectAufgabeFür, 
                    AufgabeVon: user.vollstaendigerName, 
                    Bemerkungen: evt.TextFieldBemerkungen,
                    Datum: moment().format('YYYY-MM-DD HH:mm:ss'),
                    Infos: "",              
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (data === "error: Aufgabe with same date and title already exists")
                    {
                        setSnackbar({
                        open: true,
                        message: "Es besteht bereits eine Aufgabe mit diesem Titel und Datum.",
                        severity: "error"
                        });
                    }   
                    else
                    {
                        if (reloadData)
                        {
                            reloadData();
                        }
                        handleCloseAufgaben();

                        const selectedUser = optionsBearbeiter.find(user => user.vollstaendigerName === evt.selectAufgabeFür);
                        const userEmail = selectedUser ? selectedUser.email : "";
                        const formattedBemerkungen = evt.TextFieldBemerkungen.replace(/\n/g, "<br>");
                        //Sende Mail an den Bearbeiter:
                        fetch(`${fetchdataUrl}sendemail.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            access_token: sessionStorage.getItem("access_token"),
                            sendMailBro: 1,
                            An: userEmail,
                            Von: user.vollstaendigerName, 
                            Art: "Neue Aufgabe",
                            Titel: evt.AufgabenTitel,
                            AufgabenArt: evt.selectAufgabenArt,
                            Bemerkungen: formattedBemerkungen,                   
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                        }).then((response) => {
                        }) 
                    }

            });   
        }         
    };
   
    return (
    <div>
            <Modal
                        open={openModalAufgaben}
                        onClose={handleCloseAufgaben}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                    <Fade in={openModalAufgaben}>
                        <Box sx={style}>
                        <Snackbar   anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                         <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}><b>Aufgaben</b></div>
                            {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ?
                            <div style={{ display: "flex", alignItems: "center" }}>
                                { konfigurationsModusAktiv ?
                                <div>                                    
                                <Tooltip title='Neue Aufgabenart hinzufügen'>
                                    <IconButton onClick={handleClickAufgabenArthinz} fontSize='small' color="primary">
                                    <NoteAdd />
                                    </IconButton>
                                </Tooltip>

                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <Stack direction="row" spacing={1} sx={{margin: 1}}>
                                    <TextField placeholder='Aufgabenart hinzufügen...' size='small' inputRef={AufgabenArthinzRef}></TextField>
                                    <IconButton onClick={handleClickAufgabeArtPlus} color="primary">
                                    <AddCircle />
                                    </IconButton>                             
                                    </Stack>
                                </Popover>

                                <Tooltip title='Ausgewählte Aufgabenart löschen'>
                                <IconButton sx={{ml: 0}} onClick={handleClickAufgabenArtloeschen} fontSize='small' color="error">
                                <Delete />
                                </IconButton>
                                </Tooltip>                         
                                </div> : null }                                
                                <Tooltip title="Konfigurationsmodus aktivieren">    
                                    <Switch checked={konfigurationsModusAktiv} onChange={handleKonfigurationsModusToggle} />
                                </Tooltip>                                           
                            </div> : null }        
                        </div>                                      
                        <Divider sx={{mt: 1, mb: 2}}></Divider>
                        <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Controller                                                              
                                control={control}
                                name="AufgabenTitel"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field} 
                                    margin="normal"                                     
                                    fullWidth                               
                                    size="small"
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Titel der Aufgabe"
                                    error={error !== undefined}
                                    onChange={(e) => {
                                        field.onChange(e)
                                        if(e.target.value === "") {
                                            setError('AufgabenTitel', { type: 'required'});                                            
                                        } else {
                                            clearErrors("AufgabenTitel");
                                        }
                                    }}
                                    />
                                )}
                            />
                            {/* <Controller                                                              
                                control={control}
                                name="AufgabenTitel"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <CustomMentionsInput 
                                        mentions={mentions}
                                        mentionslabel="Titel der Aufgabe"
                                    />
                            
                                )}
                            /> */}

                           
                            <Stack direction="row" marginTop={2} marginBottom={3} spacing={2}>
                                <Controller                                                              
                                        control={control}
                                        name="selectAufgabenArt"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>                                                
                                            <InputLabel style={{ top: '-5px' }}>Aufgabenart</InputLabel>
                                            <Select
                                                {...field}
                                                inputRef={AufgabenArtSelectRef}
                                                fullWidth
                                                label="Aufgabe für"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    if (e.target.value === "") {
                                                        setError("selectAufgabenArt", { type: "required" });
                                                    } else {
                                                        clearErrors("selectAufgabenArt");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                           {Array.isArray(optionsAufgabenArt) && optionsAufgabenArt.sort().map((Aufgabenartx) => (
                                            <MenuItem key={Aufgabenartx} value={Aufgabenartx}>
                                                {Aufgabenartx}
                                            </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                    />                                
                                <Controller
                                        control={control}
                                        name="selectAufgabeFür"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>
                                            <InputLabel style={{ top: '-5px' }}>Aufgabe für</InputLabel>
                                            <Select
                                                {...field}
                                                fullWidth
                                                label="Aufgabe für"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    if (e.target.value === "") {
                                                        setError("selectAufgabeFür", { type: "required" });
                                                    } else {
                                                        clearErrors("selectAufgabeFür");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                            {Array.isArray(optionsBearbeiter) && optionsBearbeiter.sort().map((user) => (
                                                <MenuItem key={user.id} value={user.vollstaendigerName}>
                                                {user.vollstaendigerName}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                />
                            </Stack>
                            <Controller
                                control={control}
                                name="TextFieldBemerkungen"
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field, fieldState: { error } }) => {
                                    const handleSaveTextFieldDialog = (value) => {
                                        // Update the field value in the parent component
                                        field.onChange(value);
                                        const textField = document.getElementById("TextFieldBemerkungen");
                                        setTimeout(() => {
                                                const textField = document.getElementById("TextFieldBemerkungen");
                                                textField.scrollTop = 0;
                                        }, 0);
                                    };

                                    return (
                                    <>
                                        <TextField
                                        {...field}
                                        label="Bemerkungen"
                                        id="TextFieldBemerkungen"
                                        inputProps={{ style: { fontSize: 14, paddingRight: "20px" } }} // font size of input text
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        rows={6}
                                        fullWidth
                                        onClick={() => {
                                            openTextFieldDialog(field.value); // Call openTextFieldDialog when the TextField is focused
                                        }}
                                        />
                                        <TextFieldPopup
                                        key={openTextField ? "open" : "closed"}
                                        open={openTextField}
                                        value={textFieldValue}
                                        onClose={closeTextFieldDialog}
                                        onSave={handleSaveTextFieldDialog}
                                        />
                                    </>
                                    );
                                }}
                                />                     

                            <Divider sx={{mt: 3, mb: 3}}></Divider>
                            <Stack direction="row" spacing={2}>
                                    <Button
                                        type='submit'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Speichern
                                    </Button>                                        

                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleCloseAufgaben}
                                        >
                                        <Cancel fontSize='small' sx={{ mr: 1}}></Cancel>
                                        Abbrechen
                                    </Button>                                    
                            </Stack>
                
                         

                         </form>
                        </Box>
                        </Fade>
            </Modal>     
    </div>

);
}
