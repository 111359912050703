import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { router } from "./App";
import { Provider } from 'react-redux';
import store from './hooks/reduxstore';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: { main: "#2e3192" },
    success: { main: "#14a37f" },
    info: { main: "#2c387e"},
    error: { main: "#ff6d75" },
    infolight: {
      main: '#6579d7',
      contrastText: "#000",
    },
  }
});

root.render(
<div style={{ maxWidth: '1500px', minWidth: "850px", margin: '0 auto' }}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </div>
);

// localStorage.setItem("currentPNR", "");
