import React, { useState, useEffect, useRef} from 'react';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import TextFieldPopup from '../components/TextFieldPopup';
import { useNavigation } from '../hooks/useNavigateFunction';
import OkDialog from '../hooks/customOkDialog';
import { fetchdataUrl } from '../A-config/config';

import {
  Link,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select, 
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  Snackbar,
  Alert,
  LinearProgress
} from '@mui/material'

import {SaveAsSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star} from '@mui/icons-material';

const style = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
};


export default function AufgabenModalUpdate({rowID, reloadData, openModalAufgaben, setOpenModalAufgaben}) {

    const { navigateToCustomer } = useNavigation();
    const [showloading, setshowloading] = useState(true);
    const handleCloseAufgaben = () => setOpenModalAufgaben(false);
    const [optionsBearbeiter, setOptionsBearbeiter] = useState([]);
    const [optionsAufgabenArt, setOptionsAufgabenArt] = useState([]);
    const [modaldata, setModalData] = useState();
    const [anchorEl, setAnchorElPopoverArthinz] = React.useState(null);
    const AufgabenArthinzRef = useRef();
    const AufgabenArtSelectRef = useRef();    
    const [refetchData, setRefetchData] = useState(false);
    const {user} = useAuth();
    const [openTextField, setopenTextField] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [isAutoMessage, setisAutoMessage] = useState(false);
    const [hasBeenTriggered, setHasBeenTriggered] = useState(false);

    //Simple OK Dialog
    const [openSimpleOkDialog, setOpenSimpleOkDialog] = useState(false);
    const [dialogMessageOkDialog, setDialogMessageOkDialog] = useState('');

    const handleOpenSimpleOKDialog = (message) => {
        setDialogMessageOkDialog(message);
        setOpenSimpleOkDialog(true);
    };

    const handleCloseSimpleOKDialog = () => {
        setOpenSimpleOkDialog(false);
        handleCloseAufgaben();
    };

    const openTextFieldDialog = (value) => {
        setTextFieldValue(value);
        setopenTextField(true);
    };

    const closeTextFieldDialog = () => {
        setopenTextField(false);
    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "error"
    });

    const handleSnackbarClose = () => {
        setSnackbar({
        open: false,
        message: "",
        severity: "error"
        });
    }

    const { control, handleSubmit, setError, clearErrors, reset, trigger} = useForm({
        reValidateMode: "onChange",
    });

    useEffect(() => {
        //Hierdurch wird der useEffect nur ausgelöst, wenn das Modal wirklich geöffnet wird und nicht beim Start der Applikation.
        if (!openModalAufgaben) return;
        setshowloading(true);

        //Load Bearbeiter
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadBearbeiter: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsBearbeiter(data);
        });   

        //Load Aufgabenart
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadAufgabenArt: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsAufgabenArt(data);
        });

        //Load Values into Modal
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadValuesAufgabenUpdate: 1,
            ID: rowID,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data) {
                 setModalData(data)
                 if (data[0].Thema.includes("Geplanter Beratungstermin für:"))
                 {
                    setisAutoMessage(true);
                 }
                 else
                 {
                    setisAutoMessage(false);
                 }

                 reset({
                    AufgabenTitel: data[0].Thema,
                    TextFieldBemerkungen: data[0].Bemerkungen,
                    selectAufgabenArt: data[0].Art,
                    selectAufgabeFür: data[0].Aufgabefür,
                });

                //Trigger all Required Fields on Mount
                trigger();
                setTimeout(() => {
                setshowloading(false);
                }, 200);  
            }
        });

    },[openModalAufgaben, refetchData]);

    const handleErledigtAufgaben = () => {
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchSetAufgabeErledigtUnerledigt: 1,
                    AufgabenID: modaldata[0].ID
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (reloadData)
                    {
                        reloadData();
                    }
                    setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
                    // handleCloseAufgaben();

                    if (hasBeenTriggered) {
                        return;
                    }                
                    setHasBeenTriggered(true);
                    
                    // // //sende E-Mail an Betreuer, dass Aufgabe erledigt ist:
                    // const selectedUser = optionsBearbeiter.find(user => user.vollstaendigerName === modaldata[0].Aufgabevon);
                    // const userEmail = selectedUser ? selectedUser.email : "";
                    // const formattedBemerkungen = modaldata[0].Bemerkungen.replace(/\n/g, "<br>");
                    // //Sende Mail an den Bearbeiter:
                    // fetch(`${fetchdataUrl}sendemail.php`, {
                    // method: 'POST',
                    // body: JSON.stringify({
                    //     access_token: sessionStorage.getItem("access_token"),
                    //     sendAufgabeErledigt: 1,
                    //     An: userEmail,
                    //     Von: user.vollstaendigerName, 
                    //     Art: "Aufgabe erledigt",
                    //     Titel: "Ich habe folgende Aufgabe erledigt - " + modaldata[0].Thema,
                    //     AufgabenArt: modaldata[0].Art,
                    //     Bemerkungen: formattedBemerkungen,                   
                    // }),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    // }).then((response) => {
                    //     return console.log(response);
                    // }) 
            });        
    }

    const handleDeleteAufgabe = () => {
        if (modaldata[0].Aufgabevon !== user.vollstaendigerName && user.admin < 5 && modaldata[0].Aufgabevon !== "Automatisch")
        {
                setSnackbar({
                    open: true,
                    message: 'Nur der Ersteller der Aufgabe kann diese auch löschen.',
                    severity: 'error'
                });
                return;
        }
        else
        {
            if (isAutoMessage)
            {
                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchUpdateBeratungsIntervallAufgabeLöschen: 1,
                        PNr: modaldata[0].Infos[3].pnr,
                        kuerzel: user.kürzel
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    }).then((response) => {
                        return response.json();
                    }).then((data) => {    
                    }); 
            }

            fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchDeleteAufgabe: 1,
                        AufgabenID: modaldata[0].ID
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    }).then((response) => {
                        return response.json();
                    }).then((data) => {
                        if (reloadData)
                        {
                            reloadData();
                        }
                        if (isAutoMessage)
                        {
                            handleOpenSimpleOKDialog('<strong><u>Wichtig, bitte beachten:</strong></u><br/>Der automatische Termin wurde gelöscht. Zusätzlich wurde das Datum des letzten Beratungsgespräches automatisch auf den heutigen Tag gesetzt. Ein neuer Termin wird rechtzeitig zum definierten Intervall erstellt.') 
                        } else
                        {
                            handleCloseAufgaben();
                        }                
            }); 
        }        
    };
    
     
    const handleOnSubmit = (evt) => {   
        if (modaldata[0].Aufgabefür !== evt.selectAufgabeFür)
        {
            const selectedUser = optionsBearbeiter.find(user => user.vollstaendigerName === evt.selectAufgabeFür);
            const userEmail = selectedUser ? selectedUser.email : "";
            const formattedBemerkungen = evt.TextFieldBemerkungen.replace(/\n/g, "<br>");
            //Sende Mail an den Bearbeiter:
            fetch(`${fetchdataUrl}sendemail.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                sendMailBro: 1,
                An: userEmail,
                Von: user.vollstaendigerName, 
                Art: "Neue Aufgabe",
                Titel: evt.AufgabenTitel,
                AufgabenArt: evt.selectAufgabenArt,
                Bemerkungen: formattedBemerkungen,                   
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return console.log(response);
            }) 
        }     

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateAufgabe: 1,
                Aufgabefür: evt.selectAufgabeFür,
                Bemerkungen: evt.TextFieldBemerkungen,
                ID: modaldata[0].ID              
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                 if (reloadData)
                {
                    reloadData();
                }
                handleCloseAufgaben();
        });  
        
    };
   
    return (
    <div>

    <Modal
                        open={openModalAufgaben}
                        onClose={handleCloseAufgaben}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                    <Fade in={openModalAufgaben}>
                        {showloading ? (
                        <Box sx={style2}>
                         <Stack direction="column" >
                            <Alert icon={false} style={{justifyContent: "center"}} severity="info"><b>Die Aufgabe wird geladen...</b></Alert>
                            <LinearProgress></LinearProgress>
                         </Stack>
                        </Box>       
                        ) : (
                        <Box sx={style}>
                        <OkDialog open={openSimpleOkDialog} message={dialogMessageOkDialog} onClose={handleCloseSimpleOKDialog} />
                        <Snackbar   anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                        <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}><b>Aufgaben</b></div>                           
                        <Divider sx={{mt: 1, mb: 2}}></Divider>
                        <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Controller                                                                 
                                control={control}
                                name="AufgabenTitel"
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}
                                    disabled
                                    margin="normal"                                     
                                    fullWidth                               
                                    size="small"
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Titel der Aufgabe"      
                                    InputLabelProps={{ shrink: true }}
                                    error={error !== undefined}
                                    onChange={(e) => {
                                        field.onChange(e)
                                        if(e.target.value === "") {
                                            setError('AufgabenTitel', { type: 'required'});                                            
                                        } else {
                                            clearErrors("AufgabenTitel");
                                        }
                                    }}
                                    />
                                )}
                            />
                      
                            <Stack direction="row" marginTop={2} marginBottom={3} spacing={2}>
                                <Controller                                                              
                                        control={control}
                                        name="selectAufgabenArt"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>                                                
                                            <InputLabel style={{ top: '-5px' }}>Aufgabenart</InputLabel>
                                            <Select
                                                {...field}
                                                disabled
                                                inputRef={AufgabenArtSelectRef}
                                                fullWidth
                                                label="Aufgabe für"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    if (e.target.value === "") {
                                                        setError("selectAufgabenArt", { type: "required" });
                                                    } else {
                                                        clearErrors("selectAufgabenArt");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                           {Array.isArray(optionsAufgabenArt) && optionsAufgabenArt.sort().map((Aufgabenartx) => (
                                            <MenuItem key={Aufgabenartx} value={Aufgabenartx}>
                                                {Aufgabenartx}
                                            </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                    />                                
                                <Controller
                                        control={control}
                                        name="selectAufgabeFür"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>
                                            <InputLabel style={{ top: '-5px' }}>Aufgabe für</InputLabel>
                                            <Select
                                                {...field}
                                                fullWidth
                                                label="Aufgabe für"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    if (e.target.value === "") {
                                                        setError("selectAufgabeFür", { type: "required" });
                                                    } else {
                                                        clearErrors("selectAufgabeFür");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                            {Array.isArray(optionsBearbeiter) && optionsBearbeiter.sort().map((user) => (
                                                <MenuItem key={user.id} value={user.vollstaendigerName}>
                                                {user.vollstaendigerName}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                />
                            </Stack>
                           <Controller
                                control={control}
                                name="TextFieldBemerkungen"
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field, fieldState: { error } }) => {
                                    const handleSaveTextFieldDialog = (value) => {
                                        // Update the field value in the parent component
                                        field.onChange(value);
                                        const textField = document.getElementById("TextFieldBemerkungen");
                                        setTimeout(() => {
                                                const textField = document.getElementById("TextFieldBemerkungen");
                                                textField.scrollTop = 0;
                                        }, 0);
                                    };

                                    return (
                                    <>
                                        <TextField
                                        {...field}
                                        label="Bemerkungen"
                                        id="TextFieldBemerkungen"
                                        inputProps={{ style: { fontSize: 14, paddingRight: "20px" } }} // font size of input text
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        rows={6}
                                        fullWidth
                                        onClick={() => {
                                            openTextFieldDialog(field.value); // Call openTextFieldDialog when the TextField is focused
                                        }}
                                        />
                                        <TextFieldPopup
                                        key={openTextField ? "open" : "closed"}
                                        open={openTextField}
                                        value={textFieldValue}
                                        onClose={closeTextFieldDialog}
                                        onSave={handleSaveTextFieldDialog}
                                        />
                                    </>
                                    );
                                }}
                            />              
                            <Grid sx={{pt: 1, pl: 0.5}} container spacing={2}>
                            <Grid item xs={2.3}>
                                <span style={{fontSize: "14px"}}>Erstellt am:</span>
                            </Grid>
                            <Grid item xs={9}>
                                {modaldata && modaldata[0].Datum ?
                                <span style={{maxWidth: "400px", display: "inline-block", textOverflow: "ellipsis", fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden"}}>
                                 <b>{moment(modaldata[0].Datum, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY - HH:mm:ss')}</b>&nbsp;&nbsp;-&nbsp; 
                                 (Ersteller: <b>{modaldata[0].Aufgabevon}</b>)
                                </span> :
                                <span style={{ fontSize: "14px" }}>
                                 <b>Keine Daten</b>
                                </span>}
                            </Grid>
                            </Grid>
                            {!isAutoMessage ? (
                                <Grid sx={{pt: 1, pl: 0.5}} container spacing={2}>
                                    <Grid item xs={2.3}>
                                        <span style={{fontSize: "14px"}}>Erledigt am:</span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <span style={{ fontSize: "14px" }}>
                                        {modaldata && modaldata[0].Erledigtam
                                            ? <b>{moment(modaldata[0].Erledigtam, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY - HH:mm:ss')}</b>
                                            : <b>Erledigung steht noch aus</b>}
                                        </span>
                                    </Grid>
                                </Grid>                          
                            ) : null}
                           
                            {isAutoMessage && isAutoMessage ? (
                                    <div>
                                    <Grid sx={{pt: 1, pl: 0.5}} container spacing={2}>
                                        <Grid item xs={2.3}>
                                            <span style={{fontSize: "14px"}}>Direktlink:</span>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Link  
                                            onClick={() => {
                                                setOpenModalAufgaben(false);  
                                                navigateToCustomer(modaldata[0].Infos[3].pnr);  
                                            }}
                                            sx={{cursor: "pointer", fontSize: "14px"}}>{modaldata[0].Infos[2].nameuvorname} - {modaldata[0].Infos[3].pnr}</Link>       
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{pt: 1, pl: 0.5}} container spacing={2}>
                                        <Grid item xs={2.3}>
                                            <span style={{fontSize: "14px"}}>Kundentermin:</span>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <span style={{ fontSize: "14px" }}>
                                            <b>{modaldata[0].Infos[0].datumkundentermin}</b> (letzter Termin mit VN)</span>       
                                        </Grid>
                                    </Grid>    
                                    <Grid sx={{pt: 1, pl: 0.5}} container spacing={2}>
                                        <Grid item xs={2.3}>
                                            <span style={{fontSize: "14px"}}>Intervall:</span>
                                        </Grid>
                                       <Grid item xs={9}>
                                            <span style={{ fontSize: "14px" }}>
                                            <b>{modaldata[0].Infos[1].intervall}</b> Tag(e)</span>       
                                        </Grid>
                                    </Grid>  
                                    </div>
                                  
                            ) : null}
                           
                            <Divider sx={{mt: 3, mb: 3}}></Divider>
                            <Stack direction="row" spacing={2}>
                                    {modaldata && !isAutoMessage ? 
                                    (<Button
                                        type='submit'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        >
                                        <SaveAsSharp fontSize='small' sx={{ mr: 1}}></SaveAsSharp>
                                        Speichern
                                    </Button> ) : null }
                                   {modaldata && !isAutoMessage && (
                                    modaldata[0].Erledigtam ? (
                                        <Button
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleErledigtAufgaben}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Unerledigt
                                        </Button>
                                    ) : (
                                        <Button
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleErledigtAufgaben}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Erledigt
                                        </Button>
                                    )
                                    )}
                                    {modaldata && !isAutoMessage ?                         
                                    ( <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleDeleteAufgabe}
                                        >
                                        <Delete fontSize='small' sx={{ mr: 1}}></Delete>
                                        Löschen
                                    </Button> ) : (
                                        <Button
                                        color='primary'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleDeleteAufgabe}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Beratung durchgeführt
                                        </Button>
                                    )} 
                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleCloseAufgaben}
                                        >
                                        <Cancel fontSize='small' sx={{ mr: 1}}></Cancel>
                                        Abbrechen
                                    </Button>                                    
                            </Stack>
                
                         

                         </form>
                        </Box>
                        )}
                        </Fade>
    </Modal>    
    </div> 
);
}
