import React, { useRef, useEffect, useState, useMemo, useCallback} from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { DialogContentText, InputLabel, Select, ToggleButtonGroup, ToggleButton, Stack, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, Menu, MenuItem, Typography, Chip, TextField, Box, IconButton, Tooltip, Checkbox, Divider } from '@mui/material';
import { ThumbDownAlt, ThumbUpAltSharp, EventRepeatSharp, PrintSharp, SettingsAccessibilitySharp, ThumbUpSharp, ThumbDownSharp, EditCalendarSharp, EventSharp, MoreHoriz, RotateRight, ReceiptSharp, RefreshSharp, DoNotDisturbAltSharp, CheckCircleSharp, UnpublishedSharp, ThumbsUpDown } from "@mui/icons-material";            
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { fetchdataUrl } from '../A-config/config';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import moment from 'moment';
import { debounce } from 'lodash';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import AdapterDayjs from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAuth } from "../hooks/useAuth";
import TermineModalUpdate from '../modals/TermineModalUpdate';
import * as XLSX from 'xlsx';
import { globalAppBarMessage } from '../hooks/reduxactions';

const ModalUrlaubServerSide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alleUser, user, logout, hasPermission } = useAuth();
  const { navigateToCustomer } = useNavigation();
  const [selectedyear, setselectedyear] = useState(dayjs());
  const [selectedRow, setSelectedRow] = useState([]);
  const [refreshModalUrlaubTable, setrefreshModalUrlaubTable] = useState(false);
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const [currentRequestedRowData, setcurrentRequestedRowData] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [nurunerledigteanzeigen, setnurunerledigteanzeigen] = useState(localStorage.getItem('setnurunerledigteanzeigen') === 'true');
  const hasAdminPermissions = hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Urlaubskoordinator');

  const handleToggle = () => {
    const newValue = !nurunerledigteanzeigen;
    setnurunerledigteanzeigen(newValue);
    localStorage.setItem('setnurunerledigteanzeigen', newValue);
  };  

  const [columnVisibility, setColumnVisibility] = useState(() => {
    try {
      const visibilityString = localStorage.getItem('columnVisibilityModalUrlaub');
      return JSON.parse(visibilityString) || { ID: false,  TerminPartnernummer: false, TerminArt: false};
    } catch (e) {
      console.error(e);
      return { ID: false,  TerminPartnernummer: false, TerminArt: false };
    }
  });

  useEffect(() => {
    localStorage.setItem('columnVisibilityModalUrlaub', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleColumnVisibilityChange = (updater) => {
    setColumnVisibility(updater);
  };

  const defaultColumnWidthsModalUrlaub = 90;
  let storageItemModalUrlaub = localStorage.getItem('columnWidthsModalUrlaub');
  let columnWidthsFromLocalStorageModalUrlaub;

  if (storageItemModalUrlaub === "undefined" || !storageItemModalUrlaub) {
    columnWidthsFromLocalStorageModalUrlaub = defaultColumnWidthsModalUrlaub;
  } else {
    columnWidthsFromLocalStorageModalUrlaub = JSON.parse(storageItemModalUrlaub);
  }

  const [columnSizingStateModalUrlaub, setcolumnSizingStateModalUrlaub] = useState(columnWidthsFromLocalStorageModalUrlaub);

  const handleColumnSizingChange = (newColumnSizingState) => {
    const newState = newColumnSizingState(columnSizingStateModalUrlaub);
    localStorage.setItem('columnWidthsModalUrlaub', JSON.stringify(newState));
    setcolumnSizingStateModalUrlaub(newState);
  };


  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      nurunerledigteanzeigen, 
      selectedyear,
      hasAdminPermissions
    ],
    queryFn: async () => {
      const requestBody = {
        access_token: sessionStorage.getItem("access_token"),
        fetchAlleUrlaubsanträge: 1,
        start: pagination.pageIndex * pagination.pageSize,
        size: pagination.pageSize,
        filters: columnFilters,
        globalFilter: globalFilter,
        sorting: sorting,
        selectedyear: selectedyear,
        nurunerledigteanzeigen: nurunerledigteanzeigen,
        vollstaendigerName: user.vollstaendigerName,
        hasAdminPermissions: hasAdminPermissions
      };

      const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    refetch();
  }, [columnFilters, globalFilter, pagination, sorting, refreshModalUrlaubTable]);

  const renderConflicts = (conflicts) => {
    if (!conflicts || conflicts === null) return <span style={{ color: "#14a37f" }}><b>0</b> Konflikte</span>;
    const conflictData = JSON.parse(conflicts);
    const conflictCount = Object.keys(conflictData).length;
    if (conflictCount === 0) return <span style={{ color: "#14a37f" }}><b>0</b> Konflikte</span>;

    return (
      <Tooltip title={
        <div>
          {Object.keys(conflictData).map(user => (
          <div key={user} style={{ marginBottom: '8px' }}>
              <b>{user}:</b>
              {conflictData[user].map((range, index) => (
                <div key={index}>
                  {moment(range.start).format("DD.MM.YYYY")} - {moment(range.end).format("DD.MM.YYYY")}
                </div>
              ))}
            </div>
          ))}
        </div>
      }>
        <span style={{ color: "#ff6d75", cursor: "pointer" }}><b>{conflictCount}</b> Konflikt{conflictCount !== 1 ? 'e' : ''}</span>
      </Tooltip>
    );
  };

  const approveRequest = (rowdata) => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchUpdateUrlaubsAnträge: 1,
          id: rowdata.id,
          success: true,
          kuerzel: user.kürzel
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success) {
          refetch();
          const user = alleUser.find(user => user.vollstaendigerName === rowdata.username);

          if (user) {
            const userEmail = user.email;
            //Sende Mail an den Nutzer
            fetch(`${fetchdataUrl}sendemail.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                sendUrlaubInformation: 1,
                An: userEmail,
                Von: user.vollstaendigerName, 
                Titel: "Dein Urlaubsantrag vom " + moment(rowdata.startdate).format("DD.MM.YYYY") + " bis " + moment(rowdata.enddate).format("DD.MM.YYYY") + " wurde genehmigt.",
                ablehnungsgrund: "Keine zusätzlichen Informationen notwendig.",                   
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
              const startDate = moment(rowdata.startdate, "YYYY-MM-DD");
              const endDate = moment(rowdata.enddate, "YYYY-MM-DD");
              const dates = [];

              for (let m = startDate; m.isSameOrBefore(endDate); m.add(1, 'days')) {
                dates.push(m.format("dddd - DD.MM.YYYY"));
              }

              const requests = dates.map(date => {
                return fetch(`${fetchdataUrl}fetchdata.php`, {
                  method: 'POST',
                  body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchupdateTimeTrackerUrlaub: 1,
                    username: rowdata.username, 
                    date: date,
                    feiertaginfo: "Urlaub"
                  }),
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }).then(response => response.json());
              });

              Promise.all(requests).then(results => {
              }).catch(error => {
                console.error("Error updating time_tracker:", error);
              });
            }) 
          }
        }
      });
  };

  const denyRequest = (rowdata) => {
    setcurrentRequestedRowData(rowdata);
    setOpenDenyDialog(true)
  };
  
  const handleCloseDenyDialog = () => {
    setOpenDenyDialog(false);
    setDenyReason('');
    setcurrentRequestedRowData(null);
  };
  
  const handleDenyReasonChange = (event) => {
    setDenyReason(event.target.value);
  };

  const handleDenyRequest = () => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchUpdateUrlaubsAnträge: 1,
        id: currentRequestedRowData.id,
        success: false,
        kuerzel: user.kürzel,
        ablehnungsgrund: denyReason
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        refetch();
        setOpenDenyDialog(false);

        const user = alleUser.find(user => user.vollstaendigerName === currentRequestedRowData.username);
        if (user) {
          const userEmail = user.email;
          //Sende Mail an den Nutzer
          fetch(`${fetchdataUrl}sendemail.php`, {
          method: 'POST',
          body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              sendUrlaubInformation: 1,
              An: userEmail,
              Von: user.vollstaendigerName, 
              Titel: "Dein Urlaubsantrag vom " + moment(currentRequestedRowData.startdate).format("DD.MM.YYYY") + " bis " + moment(currentRequestedRowData.enddate).format("DD.MM.YYYY") + " wurde abgelehnt.",
              ablehnungsgrund: denyReason,                   
          }),
          headers: {
              'Content-Type': 'application/json'
          }
          }).then((response) => {
          }) 
        }
      }
    });
  };

  const columns = useMemo(() => {
  
    const baseColumns = [
      { accessorKey: 'ID', header: 'ID', size: 40, minSize: 40, enableHiding: false, enableSorting: false, enableGlobalFilter: false },
      {
        accessorKey: 'startdate', header: 'Beginn', size: 60, minSize: 60, maxSize: 200, enableSorting: true,
        Cell: ({ cell }) => {
          const date = cell.row.original.startdate;
          let formattedDate;
          if (moment(date).isValid())
          {
            formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
          }
          else
          {
            formattedDate = "";
          }
          return <span>{formattedDate}</span>;
        },      
      },
      {
        accessorKey: 'enddate', header: 'Ende', size: 60, minSize: 60, maxSize: 200, enableSorting: true,
        Cell: ({ cell }) => {
          const date = cell.row.original.enddate;
          let formattedDate;
          if (moment(date).isValid())
          {
            formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
          }
          else
          {
            formattedDate = "";
          }
          return <span>{formattedDate}</span>;
        },      
      },
      { accessorKey: 'username', header: 'Benutzername', size: 80, minSize: 80, maxSize: 200 },  
      { accessorKey: 'genehmigt', header: 'Genehmigt', size: 80, minSize: 80, maxSize: 200,
        Cell: ({ cell }) => {
          const cellvalue = cell.row.original.genehmigt;
          const ablehnungsgrund = cell.row.original.ablehnungsgrund;
      
          if (cellvalue && cellvalue.includes("Nein")) {
            return (
              <Tooltip title={ablehnungsgrund}>
                <span style={{cursor: "pointer"}}>
                  {cellvalue}
                </span>
              </Tooltip>
            );
          } else {
            return (
              <span>
                {cellvalue}
              </span>
            );
          }
        },
      },      
      { accessorKey: 'erledigt', header: 'Erledigt', size: 80, minSize: 80, maxSize: 200,
        Cell: ({ cell }) => {
          const cellvalue = cell.row.original.erledigt;
          let formattedDate;
          if (cellvalue === 1)
          {
            formattedDate = "Ja"
          }
          else
          {
            formattedDate = "Nein";
          }
          return <span>{formattedDate}</span>;
        },    
      },
      {
        accessorKey: 'konfliktedata', header: 'Konflikte', size: 80, minSize: 80, maxSize: 200,
        Cell: ({ cell }) => renderConflicts(cell.row.original.konfliktedata)
      },
    ];
  
    if (hasAdminPermissions) {
      baseColumns.push({
        id: 'actions', // Add an id for the action column
        header: 'Genehmigung', // Set your custom header title here
        size: 80,
        minSize: 80,
        maxSize: 200,
        Cell: ({ row }) => {
          const rowdata = row.original;
          const isDisabled = row.original.erledigt === 1; 
          return (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <Tooltip title="Genehmigen">
              <span>
                <IconButton
                  disabled={isDisabled}
                  size='small'
                  color="success"
                  onClick={() =>
                    approveRequest(rowdata)
                  }
                >
                  <ThumbUpSharp sx={{ height: "20px", width: "20px" }}></ThumbUpSharp>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Ablehnen">
              <span>
                <IconButton
                  disabled={isDisabled}
                  size='small'
                  color="error"
                  onClick={() => {
                    denyRequest(rowdata)
                  }}
                >
                  <ThumbDownAlt sx={{ height: "22px", width: "22px" }} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          );
        }
      });
    }
  
    return baseColumns;
  }, []);
  
  const tableInstance = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row.id,
    state: {
      columnSizing: columnSizingStateModalUrlaub,
      columnVisibility: columnVisibility,
      isLoading,
      isError,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      showProgressBars: isRefetching,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
      columnVisibility: columnVisibility,
    },
    enableRowActions: false,
    enableFilterMatchHighlighting: false,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableStickyHeader: true,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',
    onColumnSizingChange: handleColumnSizingChange,
    manualFilters: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: meta?.totalRowCount ?? 0,
    localization: MRT_Localization_DE, 
    muiTableBodyRowProps: ({ row }) => ({    
    }),
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: { margin: 1 }
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <TextField
          autoFocus
          size="small"
          onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
          spellCheck={false}
          placeholder="Suchen..."
          sx={{ minWidth: '300px' }}
          variant="outlined"
        />
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box sx={{display: "flex", justifyContent: "center"}}>      
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),    
  });

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", padding: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2, justifyContent: "space-between" }}>
              <Stack direction="row" sx={{display: "flex", alignItems: "center"}}>
              <Typography component="div" sx={{ fontSize: "20px", fontWeight: "bold" }}>Eingereichte Urlaubsanträge</Typography>  
              {/* <Tooltip title="Als Exceldatei exportieren">
                    <IconButton onClick={handleExportasExcel}>
                        <PrintSharp />
                    </IconButton>
              </Tooltip>  */}
              </Stack>
              <Stack direction="row">
              <FormControl
                sx={{
                  pr: 1,
                  width: "150px",
                  '& .MuiInputBase-input': {
                    fontSize: "14px",
                    height: "22px"
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: "14px",
                  }
                }}
                size="small"
              >
 
              </FormControl>
              <ToggleButtonGroup
                  sx={{pr: 1}}
                  value={nurunerledigteanzeigen ? 'true' : 'false'}
                  exclusive
                  onChange={handleToggle}
                  aria-label="show own entries"
                >
                  <ToggleButton size='small' value="true" aria-label="show own">
                    <SettingsAccessibilitySharp sx={{pr: 1}} />Nur unerledigte anzeigen
                  </ToggleButton>
              </ToggleButtonGroup>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
                    <DesktopDatePicker
                        views={['year']}
                        openTo="year"
                        label="Jahr auswählen"
                        value={selectedyear}
                        onChange={(newValue) => setselectedyear(newValue)}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                sx={{
                                    width: "120px",
                                    '& .MuiInputBase-input': {
                                        fontSize: "14px",
                                        height: "22px"
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: "14px",
                                    }
                                }} 
                                size="small" 
                            />
                        )}
                    />
              </LocalizationProvider> 
              </Stack>
          </Box>
          <Divider sx={{mb: 1}}></Divider>        
          <div>
            <MaterialReactTable table={tableInstance} />
          </div>       
      </Box>      
      <Dialog open={openDenyDialog} onClose={handleCloseDenyDialog}>
        <DialogTitle>Ablehnungsgrund</DialogTitle>
        <DialogContent>
          <TextField
            sx={{minWidth: "400px"}}
            rows={4}
            multiline
            autoFocus
            margin="dense"
            label="Ablehnungsgrund"
            type="text"
            fullWidth
            value={denyReason}
            onChange={handleDenyReasonChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDenyRequest} color="primary">
            Absenden
          </Button>
          <Button onClick={handleCloseDenyDialog} color="primary">
            Abbrechen
          </Button>

        </DialogActions>
      </Dialog>
    </div>    
  );
};

const queryClient = new QueryClient();

const ModalUrlaubServerSideWithQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <ModalUrlaubServerSide/>
    </QueryClientProvider>
);

export default ModalUrlaubServerSideWithQueryProvider;