import { useEffect, useState } from "react";
import { Card, Tabs, Tab, Typography } from '@mui/material';
import Versicherungsanalysen from "../components/Versicherungsanalysen";

export const VersicherungsanalysenPage = () => {


  return (
    <div>
      <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
      <Versicherungsanalysen></Versicherungsanalysen>
      </Card>
    </div>
  );
};