import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';

const calculateNetIncome = (grossIncome, taxClass, kirchensteuer) => {
  const annualGrossIncome = grossIncome * 12;

  // Beitragsbemessungsgrenzen 2024
  const BBG_RV_West = 90600; // Rentenversicherung West
  const BBG_KV = 62100; // Krankenversicherung und Pflegeversicherung

  // Sozialversicherungsbeiträge
  const rentenversicherung = Math.min(annualGrossIncome, BBG_RV_West) * 0.093; // Rentenversicherung 9,3%
  const arbeitslosenversicherung = Math.min(annualGrossIncome, BBG_RV_West) * 0.015; // Arbeitslosenversicherung 1,5%
  const krankenversicherung = Math.min(annualGrossIncome, BBG_KV) * 0.073; // Krankenversicherung 7,3%
  const kvzusatzbeitrag = Math.min(annualGrossIncome, BBG_KV) * 0.017; // Krankenversicherung-Zusatzbeitrag Schnitt 1,7%
  const pflegeversicherung = Math.min(annualGrossIncome, BBG_KV) * 0.01525; // Pflegeversicherung 1,525%

  const sozialversicherung = rentenversicherung + arbeitslosenversicherung + krankenversicherung + kvzusatzbeitrag + pflegeversicherung;
  const zuVersteuerndesEinkommen = annualGrossIncome - sozialversicherung;

  // Vereinfachte Einkommensteuerberechnung
  let einkommensteuer = 0;
  switch (taxClass) {
    case '1':
      if (zuVersteuerndesEinkommen <= 11604) {
        einkommensteuer = 0;
      } else if (zuVersteuerndesEinkommen <= 17005) {
        const y = (zuVersteuerndesEinkommen - 11604) / 10000;
        einkommensteuer = (922.98 * y + 1400) * y;
      } else if (zuVersteuerndesEinkommen <= 66760) {
        const z = (zuVersteuerndesEinkommen - 17005) / 10000;
        einkommensteuer = (181.19 * z + 2397) * z + 1025.38;
      } else if (zuVersteuerndesEinkommen <= 277825) {
        einkommensteuer = 0.42 * zuVersteuerndesEinkommen - 10602.13;
      } else {
        einkommensteuer = 0.45 * zuVersteuerndesEinkommen - 18936.88;
      }
      break;
    case '2':
      if (zuVersteuerndesEinkommen <= 11604) {
        einkommensteuer = 0;
      } else if (zuVersteuerndesEinkommen <= 17005) {
        const y = (zuVersteuerndesEinkommen - 11604) / 10000;
        einkommensteuer = (922.98 * y + 1400) * y;
      } else if (zuVersteuerndesEinkommen <= 66760) {
        const z = (zuVersteuerndesEinkommen - 17005) / 10000;
        einkommensteuer = (181.19 * z + 2397) * z + 1025.38;
      } else if (zuVersteuerndesEinkommen <= 277825) {
        einkommensteuer = 0.42 * zuVersteuerndesEinkommen - 10602.13;
      } else {
        einkommensteuer = 0.45 * zuVersteuerndesEinkommen - 18936.88;
      }
      break;
    case '3':
      if (zuVersteuerndesEinkommen <= 19008) einkommensteuer = 0;
      else if (zuVersteuerndesEinkommen <= 28340) einkommensteuer = (zuVersteuerndesEinkommen - 19008) * 0.14;
      else if (zuVersteuerndesEinkommen <= 111912) einkommensteuer = (zuVersteuerndesEinkommen - 28340) * 0.24 + 1300;
      else einkommensteuer = (zuVersteuerndesEinkommen - 111912) * 0.42 + 32400;
      break;
    case '4':
      if (zuVersteuerndesEinkommen <= 11604) {
        einkommensteuer = 0;
      } else if (zuVersteuerndesEinkommen <= 17005) {
        const y = (zuVersteuerndesEinkommen - 11604) / 10000;
        einkommensteuer = (922.98 * y + 1400) * y;
      } else if (zuVersteuerndesEinkommen <= 66760) {
        const z = (zuVersteuerndesEinkommen - 17005) / 10000;
        einkommensteuer = (181.19 * z + 2397) * z + 1025.38;
      } else if (zuVersteuerndesEinkommen <= 277825) {
        einkommensteuer = 0.42 * zuVersteuerndesEinkommen - 10602.13;
      } else {
        einkommensteuer = 0.45 * zuVersteuerndesEinkommen - 18936.88;
      }
      break;
    case '5':
      if (zuVersteuerndesEinkommen <= 11604) einkommensteuer = 0;
      else if (zuVersteuerndesEinkommen <= 17005) {
        const y = (zuVersteuerndesEinkommen - 11604) / 10000;
        einkommensteuer = Math.floor((922.98 * y + 1400) * y);
      } else if (zuVersteuerndesEinkommen <= 66760) {
        const z = (zuVersteuerndesEinkommen - 17005) / 10000;
        einkommensteuer = Math.floor((181.19 * z + 2397) * z + 1025.38);
      } else if (zuVersteuerndesEinkommen <= 277825) {
        einkommensteuer = Math.floor(0.42 * zuVersteuerndesEinkommen - 10602.13);
      } else {
        einkommensteuer = Math.floor(0.45 * zuVersteuerndesEinkommen - 18936.88);
      }
      break; 
    default:
      break;
  }

  let kirchensteuerabzug = 0;
  if (kirchensteuer) {
    kirchensteuerabzug = einkommensteuer * 0.08; // 8% Kirchensteuer auf Einkommenssteuer für Baden-Württemberg
  }

  // Solidaritätszuschlag
  let soli = 0;
  if (einkommensteuer > 18130) {
    soli = einkommensteuer * 0.055;
  } 

  const netIncome = annualGrossIncome - sozialversicherung - einkommensteuer - soli - kirchensteuerabzug;

  console.log(`Monatliches Einkommensteuer: ${(einkommensteuer / 12).toFixed(2)} €`);
  console.log(`Monatlicher Solidaritätszuschlag: ${(soli / 12).toFixed(2)} €`);
  console.log(`Monatliche Sozialversicherungsabgaben: ${(sozialversicherung / 12).toFixed(2)} €`);

  return netIncome / 12;
};

const Berufsunfaehigkeit = ({ formData, handleBack, handleNext }) => {
  const { einkommen, taxClass, kirchensteuer } = formData;
  const grossIncome = parseFloat(einkommen) || 0;
  const [netIncome, setNetIncome] = useState(0);

  useEffect(() => {
    const calculatedNetIncome = calculateNetIncome(grossIncome, taxClass, kirchensteuer);
    setNetIncome(calculatedNetIncome);
  }, [grossIncome, taxClass, kirchensteuer]);

  const netIncomeAnimation = useSpring({
    height: `${(netIncome / grossIncome) * 100}%`,
    from: { height: '0%' },
    config: { duration: 2000 }
  });

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Berufsunfähigkeit
      </Typography>    

      <Box display="flex" flexDirection="column" alignItems="center" marginTop="20px">
        <Box
          width="100%"
          height="200px"
          position="relative"
          backgroundColor="#e0e0e0"
          borderRadius="10px"
          overflow="hidden"
        >
          <Typography
            variant="caption"
            position="absolute"
            top="10px"
            left="10px"
          >
            Bruttoeinkommen: 100%
          </Typography>
          <animated.div
            style={{
              ...netIncomeAnimation,
              width: '100%',
              backgroundColor: '#76adff',
              position: 'absolute',
              bottom: 0,
              borderRadius: '10px 10px 0 0'
            }}
          />
          <Typography
            variant="caption"
            position="absolute"
            top="calc(70% - 10px)"
            left="10px"
            color="white"
          >
            Netto: {((netIncome / grossIncome) * 100).toFixed(2)}%<br></br>
            Netto in €: {netIncome.toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" marginTop="20px">
          <Button variant="contained" color="primary" onClick={handleBack}>
            Zurück
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Weiter
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Berufsunfaehigkeit;
