import { useEffect, useState } from "react";
import { Card, Tabs, Tab, Typography } from '@mui/material';
import SchadenÄnderungTableServersideQueryProvider from "./SchadenÄnderungTableServerside";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { setSchadenÄnderungCount, refreshDataKundenSchaeden, refreshuserstatus, refreshDataAufgaben, setloading, updateEventData, toggleInfoBarAppbar, setAufgabenCount, toggleGlobalServerMessage} from '../hooks/reduxactions';

export const SchadenÄnderungPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSchadenÄnderungCount(0));
      localStorage.setItem('lastCheckSchadenänderungen', moment().format('YYYY-MM-DD HH:mm:ss'));
    };
  }, []); 

  return (
    <div>
      <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
        <SchadenÄnderungTableServersideQueryProvider></SchadenÄnderungTableServersideQueryProvider>
      </Card>
    </div>
  );
};