import { useNavigate } from "react-router-dom";
import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import { AuthProvider, useAuth } from "../hooks/useAuth";
import { Link } from "@mui/material";
import { useEventData } from "../hooks/useEventData";

export const AuthLayout = () => {
  const outlet = useOutlet();
  const { userPromise } = useLoaderData();
  // WICHTIG NICHT ENTFERNEN! - Wir laden hier den hook.
  const eventdata = useEventData();
  
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        
        errorElement={
          <Alert severity="error" ><Link>Da ist wohl etwas schief gelaufen. Bitte logge dich erneut ein.</Link>           
          </Alert> 
        }
        children={(user) => (
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};