import { useEffect, useState } from "react";
import { isDesktop } from 'react-device-detect';
import { Typography, CircularProgress, Card, CardContent, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { FolderOpenSharp } from "@mui/icons-material";
import { fetchdataUrl } from '../A-config/config';

export const DateiablageKundePage = () => {
  const [isDateiablageLoaded, setIsDateiablageLoaded] = useState(false);
  const [isWebview, setIsWebview] = useState(isDesktop);
  const [sharepointlinkx, setsharepointlinkx] = useState("");
  const Kundencardheight = useSelector(state => state.Kundencardheight);
  const pnrprocessed = useSelector(state => state.pnrprocessed);
  const dispatch = useDispatch();

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightDateiablage');
    return savedHeight ? parseInt(savedHeight, 10) : 300; 
  });  

function generateSharePointLink() {
    const storedData = localStorage.getItem('firmenInfos');
    const firmenInfos = JSON.parse(storedData);

    const baseUrl = firmenInfos.sharepointlink;
    // Convert local path to URL path
    setsharepointlinkx(`${baseUrl}/${encodeURIComponent(pnrprocessed)}`);
}

useEffect(() => {
  generateSharePointLink();
}, [pnrprocessed]);

  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 500;
    localStorage.setItem('contentHeightDateiablage', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  const handleChromeWebviewMessage = event => {
    if (event.data.dateiablageloaded) {
      setIsDateiablageLoaded(true);
    }
  }

  useEffect(() => {
    if (window.chrome && window.chrome.webview && isDesktop) {   
      setIsWebview(true);   
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchgetfilenames: 1,
            pnr: pnrprocessed,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response.json();
    }).then((data) => {
      window.chrome.webview.postMessage(['ShowDateiablage', Kundencardheight, data.files]);
      window.chrome.webview.addEventListener('message', handleChromeWebviewMessage);
    }).catch((error) => {
      window.chrome.webview.postMessage(['ShowDateiablage', Kundencardheight, null]);
      window.chrome.webview.addEventListener('message', handleChromeWebviewMessage);
        console.error('Error:', error);
    });

    } else {
      setIsWebview(false);
    }

    return () => {
      if (window.chrome && window.chrome.webview && isDesktop) {
        window.chrome.webview.postMessage(['HideDateiablage', '']);
        window.chrome.webview.removeEventListener('message', handleChromeWebviewMessage);
      }
    };
  }, []);

  return (
    !isWebview ? 
    <Card sx={{ minHeight: "412px" }}>
    <CardContent>    
      <Box sx={{ width: '100%', display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: contentHeight, overflowY: "auto", bgcolor: 'background.paper' }}>
          <Typography sx={{textAlign: "center", color: "rgb(0 0 0 / 60%)"}}>Die Dateiablage ist in der Webansicht nicht verfügbar. Bitte nutze die PHX-Applikation auf den AXA-Rechnern.</Typography>
          <Box sx={{ marginLeft: "20px", display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: "pointer"}}>
            <Link 
                sx={{
                cursor: "pointer", 
                display: 'flex', 
                alignItems: 'center',
                textDecoration: 'none'
                }} 
                href={sharepointlinkx} 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <FolderOpenSharp sx={{ marginRight: 1, }} /> 
                <Typography variant="body2" sx={{marginTop: "2px"}}>
                Dateiablage öffnen (nur über ein von AXA verwaltetes Gerät möglich)
                </Typography>
            </Link>
            </Box>   
          </Box>
    </CardContent>
    </Card> :
    !isDateiablageLoaded ? 
    <Card sx={{ minHeight: "412px" }}>
      <CardContent>    
        <Box sx={{ width: '100%', display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: contentHeight, overflowY: "auto", bgcolor: 'background.paper' }}>
          <CircularProgress />
          <Typography sx={{ padding: "10px" }}>Die Dateiablage wird geladen...</Typography>
        </Box>
      </CardContent>
    </Card> : null    
  )
};
