import { PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Height } from '@mui/icons-material';
import {
    Link,
    CircularProgress,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Divider,
    Modal,
    Typography,
    Select, 
    InputLabel,
    FormControl,
    Fade,
    Backdrop,
    FormHelperText,
    Grid,
    Popover,
    Switch,
    Toolbar
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { useDispatch, useSelector } from 'react-redux';

const style = {
    width: '500px',
    maxHeight: '500px',
    overflowY: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none !important',
    boxShadow: 24,
    p: 4,

};

const contentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    flexWrap: 'wrap',
    wordBreak: 'break-word'
};

const infosContainerStyle = {
    marginTop: '20px',
    maxHeight: '450px',
    paddingBottom: '15px',
    overflowY: 'auto',
};

export default function LogInfoModal({ openModalLogInfo, setopenModalLogInfo, rowDATA }) {

    const handleCloseLogInfo = () => setopenModalLogInfo(false);
    const { navigateToCustomer } = useNavigation();
    const navigate = useNavigate();
    const pnrprocessed = useSelector((state) => state.pnrprocessed);

    return (
        <Modal
            open={openModalLogInfo}
            onClose={handleCloseLogInfo}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModalLogInfo}>
                    <Box sx={style}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                                <span><b>Übersicht:</b></span>
                            </div>
                        </div>
                        <Divider sx={{ marginTop: "5px" }}></Divider>
                        <div style={infosContainerStyle}>
                            <div style={contentStyle}>
                                <span><b>ID:</b></span><span>{rowDATA.id}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Benutzername:</b></span><span>{rowDATA.user_name}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Datenbankaktion:</b></span><span>{rowDATA.operation_type}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Datenbanktable:</b></span><span>{rowDATA.table_name}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Uhrzeit:</b></span><span>{rowDATA.timestamp}</span>
                            </div>
                            <div style={contentStyle}>
                                <span><b>Aktionsbeschreibung:</b></span><span>{rowDATA.description}</span>
                            </div>
                            <div style={contentStyle}>                    
                                <span><b>Partnernummer:</b></span>
                                <span>                
                                <Link
                                sx={{cursor: "pointer"}}
                                onClick={() => {
                                    if (pnrprocessed !== rowDATA.partnernummer) {
                                    navigateToCustomer(rowDATA.partnernummer);
                                    } else {
                                    navigate(`/dashboard/UserKundenübersicht/${rowDATA.partnernummer}`);
                                    }
                                }}
                                >
                                {rowDATA.partnernummer}
                                </Link>
                                </span>
                            </div>
                            <Typography sx={{ overflowWrap: "anywhere", overflowX: "hidden" }} variant="body1" gutterBottom>
                                {rowDATA.infos}
                            </Typography>
                        </div>                        
                    </Box>
            </Fade>
        </Modal>
    );
}
