import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Grid, Tooltip, Box } from '@mui/material';
import moment from 'moment';
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from '../hooks/useAuth'; // Assuming the useAuth hook provides alleUser data

const Jahresuebersicht = ({ year }) => {
    const [userVacations, setUserVacations] = useState([]);
    const [vacationDays, setVacationDays] = useState({});
    const { alleUser } = useAuth(); // Fetch alleUser data from useAuth hook

    // Fetch vacation data on component mount
    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchGetalleUrlaubimJahr: 1,
                selectedyear: year
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                setUserVacations(data.data);
            }
        });
    }, [year]);

    // Transform vacation data into a dictionary format
    useEffect(() => {
        const vacationDict = {};
        userVacations.forEach(vacation => {
            const date = moment(vacation.TagundDatum.split(' - ')[1], 'DD.MM.YYYY').format('YYYY-MM-DD');
            if (!vacationDict[date]) {
                vacationDict[date] = [];
            }
            vacationDict[date].push(vacation.username);
        });
        setVacationDays(vacationDict);
    }, [userVacations]);

    // Generate an array of days in the selected year
    const daysInYear = useMemo(() => {
        const days = [];
        for (let i = 1; i <= moment(`${year}-12-31`).dayOfYear(); i++) {
            days.push(i);
        }
        return days;
    }, [year]);

    // Generate unique colors for users
    const userColors = useMemo(() => {
        const colors = [
            '#61caaf', '#76adff', '#ff6d75', '#c497ff', '#007fbb', '#a0c4ff', '#ff9f1c', '#d4a5a5',
            '#b39ddb', '#ffab91', '#81c784', '#64b5f6', '#ffd54f', '#4db6ac', '#ff8a65', '#7986cb', 
            '#f06292', '#4dd0e1', '#ba68c8', '#aed581', '#fff176', '#a1887f', '#90a4ae'
        ];
        const colorMap = {};
        alleUser.forEach((user, index) => {
            colorMap[user.vollstaendigerName] = colors[index % colors.length];
        });
        return colorMap;
    }, [alleUser]);

    // Get Kürzel by username
    const getKuerzel = (username) => {
        const user = alleUser.find(user => user.vollstaendigerName === username);
        return user ? user.kürzel : '';
    };

    // Filter out unique users for the legend
    const uniqueUsers = useMemo(() => {
        const seen = new Set();
        return alleUser.filter(user => {
            if (seen.has(user.vollstaendigerName)) {
                return false;
            }
            seen.add(user.vollstaendigerName);
            return true;
        });
    }, [alleUser]);

    const renderDayCell = (dayDate, usersOnVacation) => {
        const isWeekend = moment(dayDate).day() === 0 || moment(dayDate).day() === 6;
        const cellStyle = {
            border: '1px solid #ccc',
            padding: '2px',
            minHeight: '60px',
            position: 'relative',
            backgroundColor: isWeekend ? '#f0f0f0' : '#fff'
        };
    
        // Use a Set to ensure unique users
        const uniqueUsersOnVacation = Array.from(new Set(usersOnVacation));
        const maxUsersToShow = 4;
    
        return (
            <Grid item xs={1.5} key={dayDate} style={cellStyle}>
                <div style={{ border: '1px solid #ccc', padding: '1px', backgroundColor: '#fff', position: 'absolute', top: '1px', left: '1px' }}>
                    <Typography sx={{ lineHeight: 1 }} variant="caption" display="block">{moment(dayDate).format('DD')}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '22px', left: '1px', right: '1px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ cursor: "pointer", display: 'flex', flexDirection: 'column', width: '45%' }}>
                            {uniqueUsersOnVacation.slice(0, 2).map(user => (
                                <Tooltip key={user} title={user} placement="top">
                                    <div style={{ backgroundColor: userColors[user], height: '10px', marginBottom: '1px', color: '#fff', fontSize: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {getKuerzel(user)}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                        <div style={{ cursor: "pointer", display: 'flex', flexDirection: 'column', width: '45%' }}>
                            {uniqueUsersOnVacation.slice(2, 4).map(user => (
                                <Tooltip key={user} title={user} placement="top">
                                    <div style={{ backgroundColor: userColors[user], height: '10px', marginBottom: '1px', color: '#fff', fontSize: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {getKuerzel(user)}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                    {uniqueUsersOnVacation.length > maxUsersToShow && (
                        <Tooltip title={uniqueUsersOnVacation.slice(maxUsersToShow).map(user => user).join(', ')} placement="top">
                            <div style={{ cursor: "pointer",backgroundColor: '#ccc', height: '10px', color: '#fff', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2px' }}>
                                ...
                            </div>
                        </Tooltip>
                    )}
                </div>
            </Grid>
        );
    };
    
    

    const renderMonth = (month) => {
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
        const monthName = moment(`${year}-${month}`, 'YYYY-MM').format('MMMM');

        return (
            <div key={month}>
                <div style={{ fontSize: '16px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px", marginBottom: "20px", marginTop: "20px" }}>
                        <span><b>{monthName}</b></span>
                </div>
                <Grid container spacing={1}>
                    {Array.from({ length: daysInMonth }, (_, i) => {
                        const dayDate = moment(`${year}-${month}-01`).add(i, 'days').format('YYYY-MM-DD');
                        const usersOnVacation = vacationDays[dayDate] || [];
                        return renderDayCell(dayDate, usersOnVacation);
                    })}
                </Grid>
            </div>
        );
    };

    return (
        <div>
            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px", marginBottom: "10px" }}>
                <span><b>Jahresübersicht ({year}):</b></span>
            </div>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
                {uniqueUsers.map(user => (
                    <div key={user.vollstaendigerName} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        <div style={{ backgroundColor: userColors[user.vollstaendigerName], borderRadius: '3px', width: '12px', height: '12px', marginRight: '5px' }}></div>
                        <Typography variant="caption">{user.vollstaendigerName}</Typography>
                    </div>
                ))}
            </Box> */}
            <Grid container spacing={3}>
                {Array.from({ length: 12 }, (_, i) => (
                    <Grid item xs={4} key={i}>
                        {renderMonth(i + 1)}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Jahresuebersicht;
