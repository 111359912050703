import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Editor, createEditor, Transforms} from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { Button, Typography, Box } from '@mui/material';
import { HoveringToolbar } from './HoveringToolbar';
import { useDispatch, useSelector } from 'react-redux';

export const CustomSlateEditor = ({ id, text, initialText, onContentChange }) => {
    const editor = useMemo(() => withReact(createEditor()), []);
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editorKey, setEditorKey] = useState(0); 
    const [isToolbarVisible, setIsToolbarVisible] = useState(false);
    const AXADBVColor = useSelector(state => state.axaDbvColor);
    const [AXAKunde, setAXA] = useState(true);
    // const [DBVColor, setDBVColor] = useState('');

    useEffect(() => {
        if (AXADBVColor === "#2e3192")
        {
            setAXA(true);
        } else
        {
            setAXA(false);
        }
    }, [AXADBVColor]);

  
    const [value, setValue] = useState(() => {
        // Attempt to parse initialText assuming it's a JSON string of a Slate document
        try {
            const parsedInitialText = JSON.parse(initialText);
            if (Array.isArray(parsedInitialText)) {
                return parsedInitialText;
            }
        } catch (error) {
            // If parsing fails or initialText is not an array,
            // treat initialText as plain text and format it for Slate.
        }
        // Fallback for plain text or if initialText is undefined
        return [{ type: 'paragraph', children: [{ text: initialText || '' }] }];
    });

    const ref = useRef();

    // Handlers for onFocus and onBlur of the Editable
    const handleFocus = () => {
        setIsToolbarVisible(true);
    };
    
    const handleBlur = () => {
        setIsToolbarVisible(false);
    };

    useEffect(() => {
        const content = JSON.stringify(value);
        if (id && typeof id !== 'undefined') { // Ensure text is truthy and not undefined
            onContentChange(id, content);
        }
    }, [value, id]);

    useEffect(() => {
        // Check if initialText is a JSON string representing a Slate document
        try {
            const parsed = JSON.parse(initialText);
            // If parsing succeeds and parsed is an array (Slate document structure),
            // it's assumed to be a valid Slate object.
            if (Array.isArray(parsed)) {
                setValue(parsed);
            }
        } catch (error) {
            // If parsing fails or initialText is not an array,
            // treat initialText as plain text and format it for Slate.
            setValue([{ type: 'paragraph', children: [{ text: initialText || '' }] }]);
        }
        // Also, reset the editor key to force re-render
        setEditorKey(prevKey => prevKey + 1);
    }, [initialText]);

    const Leaf = ({ attributes, children, leaf }) => {

        if (leaf.bold) {
          children = <strong>{children}</strong>;
        }
      
        if (leaf.italic) {
          children = <em>{children}</em>;
        }

        if (leaf.underline) {
            children = <u>{children}</u>;
        }
        if (leaf.color) {
            children = <span style={{ color: leaf.color }}>{children}</span>;
        }

        return <span {...attributes}>{children}</span>;
    };

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsEditing(true)}
            onBlur={() => setIsEditing(false)}
            sx={{
                position: 'relative',
                backgroundColor: isEditing ? AXAKunde ? '#efefef' : '#ffedd6' : (isHovered ?  AXAKunde ?'#e0e0e0' : '#f9ead6' : AXAKunde ? '#efefef' : '#ffedd6'),
                border: '0px solid lightgray',
                borderBottom: isEditing ? '2px solid' : '1px solid',
                borderColor: isEditing ? AXAKunde ? '#2e3192' : '#ff9200' : (isHovered ? AXAKunde ? '#2e3192' : '#ff9200' : 'lightgray'),
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px', 
                borderBottomRightRadius: '0px', 
                borderBottomLeftRadius: '0px', 
                p: 1,
                mb: 2,
            }}
        >
          <Typography id={text} sx={{ mb: 1, color: AXAKunde ? '#2e3192' : '#e88401', fontSize: '0.78rem' }}>{text}</Typography>
          <Slate editor={editor} key={editorKey} initialValue={value} onChange={newValue => setValue(newValue)}>
            <HoveringToolbar editorID={text} isVisible={isToolbarVisible} />
            <Editable
              scrollSelectionIntoView={(editor, domRange) => {
                    // Do nothing to prevent automatic scrolling
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              renderLeaf={props => <Leaf {...props} />}
              style={{
                minHeight: '100px', 
                paddingLeft: '0px', 
                paddingRight: '10px', 
                paddingBottom: '10px', 
                paddingTop: '0px',
                border: 'none',
                outline: 'none',
              }}
              onKeyDown={(event) => {
                if (event.key === 'Tab') {
                  event.preventDefault();
                  Transforms.insertText(editor, '\t');
                }
            }}
            />
          </Slate>
        </Box>
    );
};
