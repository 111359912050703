import { useAuth} from "../hooks/useAuth";
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import {Chip, Box, Tooltip, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Card, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table';

import { MRT_Localization_DE } from 'material-react-table/locales/de';
import {ReceiptSharp, ArrowCircleDownSharp, ArrowCircleUpSharp, CheckCircleSharp, UnpublishedSharp, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star} from '@mui/icons-material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalSchaden } from '../hooks/reduxactions';
import AddSchadenModal from '../modals/AddSchadenModal';
import { fetchdataUrl } from '../A-config/config';

const customLocalization = {
  ...MRT_Localization_DE,
  noResultsFound: "Es wurden keine Schäden gefunden", 
  noRecordsToDisplay: "Es wurden keine Schäden gefunden" 
};

export const KundeSchäden = ({data, schadenData, refetchdata}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tableKeySchäden, settableKeySchäden] = useState(0);
    const [isTableSchädenLoading, setisTableSchädenLoading] = useState(true)
    const { user, hasPermission} = useAuth();
    const [openSchadenModal, setopenSchadenModal] = useState(false);
    const handleOpenSchadenModal = () => {
        setopenSchadenModal(true);  
        dispatch(toggleModalSchaden(true));   
    }
    const [columnVisibility, setColumnVisibility] = useState(() => {
        try {
          const visibilityString = localStorage.getItem('columnVisibilitySchädenPartner');
          return JSON.parse(visibilityString) || { ID: false };
        } catch (e) {
          console.error(e);
          return { ID: false };
        }
    });

    const [contentHeight, setContentHeight] = useState(() => {
      const savedHeight = localStorage.getItem('contentHeightUserSchäden');
      return savedHeight ? parseInt(savedHeight) : 300; // Default to 300 if nothing is stored
    });
  
  
    const calculateContentHeightAndPageSize = () => {
      const windowHeight = window.innerHeight - 578;
  
      localStorage.setItem('contentHeightUserSchäden', windowHeight.toString());
      setContentHeight(windowHeight);
    };
  
    useEffect(() => {
      calculateContentHeightAndPageSize(); // Call on initial load
  
      // Set up event listener for resize
      window.addEventListener('resize', calculateContentHeightAndPageSize);
  
      // Clean-up function
      return () => {
        window.removeEventListener('resize', calculateContentHeightAndPageSize);
      };
    }, []);

    const handleClick = (row) => {  
         fetch(`${fetchdataUrl}fetchdata.php`, {
                          method: 'POST',
                          body: JSON.stringify({
                              access_token: sessionStorage.getItem("access_token"),
                              fetchSucheSchadendatenbyID: 1,
                              ID: row.ID
                          }),
                          headers: {
                              'Content-Type': 'application/json'
                          }
                          })
                          .then(response => response.json())
                          .then(data => {   
                              if (window.chrome && window.chrome.webview && isDesktop) {
                                  const dataarray = ["OPENSchaden", data[0]];
                                  window.chrome.webview.postMessage(dataarray);
                                  dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });                           
                              } 
                              else  { 
                                  dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });                          
                                  navigate('/dashboard/SchadenübersichtPage', { state: { data: data[0] } });
                              }
                          }).catch((error) => {
                              console.error('There was a problem with the fetch operation:', error);          
          });     
    };
 

    useEffect(() => {
      localStorage.setItem('columnVisibilitySchädenPartner', JSON.stringify(columnVisibility));
      // Update the key to force re-mount and re-render of the table
      settableKeySchäden(key => key + 1);
    }, [columnVisibility]);

    useEffect(() => {
        setisTableSchädenLoading(false)
    }, []);

    const handleColumnVisibilityChange = (updater) => {
    // Update the visibility state of the columns
    setColumnVisibility(updater);
    };

    const columnsTableSchäden = React.useMemo(
            () => [
          {
            accessorKey: 'Status', header: 'Status', size: 80, minSize: 40, maxSize: 80,
            Cell: ({ cell }) => cell.row.original.Status === "ERLEDIGT" ?
              cell.row.original.Sparte === "KV Belege" ?
              <Chip sx={{ bgcolor: '#6579d7', color: "white" }} size='small' icon={<ReceiptSharp color="white" />} label="Belege" /> : 
              <Chip color='success' sx={{color: "white"}} size='small' icon={<CheckCircleSharp></CheckCircleSharp>} label="Erledigt"></Chip> : 
              <Chip color='error' sx={{color: "white"}} size='small' icon={<UnpublishedSharp></UnpublishedSharp>} label="Offen"></Chip>,
              muiTableBodyCellProps: { align: 'left' }
          },
          {
                header: 'Schadendatum',
                accessorKey: 'Schadendatum',
                enableSorting: true,
                Cell: ({ cell }) => {
                  const date = cell.row.original.Schadendatum;
                  if (date === '0000-00-00' || !date) {
                    return null;
                  }
                  const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                  return <span>{formattedDate}</span>;
                },
                filterFn: (row, id, filterValue) => {
                    const date = row.getValue(id);
                    if (date === '0000-00-00' || !date) {
                      return false;
                    }
                    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                    return formattedDate.includes(filterValue);
                },
                muiTableHeadCellProps: {
                },
                minSize: 90, //min size enforced during resizing
                maxSize: 90, //max size enforced during resizing
                size: 90, //medium column
          },  
          {
              header: 'Schadenart',
              accessorKey: 'Sparte',
              muiTableHeadCellProps: {
              },
              minSize: 90, //min size enforced during resizing
              maxSize: 90, //max size enforced during resizing
              size: 90, //medium column
          }, 
                  {
              header: 'AST/Freitext',
              accessorKey: 'Schadenart',
              muiTableHeadCellProps: {
              },
              minSize: 90, //min size enforced during resizing
              maxSize: 90, //max size enforced during resizing
              size: 90, //medium column
          }, 
          {
              header: 'Schadennummer',
              accessorKey: 'Schadennummer',
              muiTableHeadCellProps: {
              },
              minSize: 90, //min size enforced during resizing
              maxSize: 90, //max size enforced during resizing
              size: 90, //medium column
          }, 
          {
              header: 'Bearbeiter',
              accessorKey: 'Bearbeiter',
              muiTableHeadCellProps: {
              },
              minSize: 90, //min size enforced during resizing
              maxSize: 90, //max size enforced during resizing
              size: 90, //medium column
          }, 
          {
                header: 'Wiedervorlage',
                accessorKey: 'Wiedervorlage',
                enableSorting: true,
                Cell: ({ cell }) => {
                  const date = cell.row.original.Wiedervorlage;
                  if (date === '0000-00-00' || !date) {
                    return null;
                  }
                  const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                  return <span>{formattedDate}</span>;
                },
                filterFn: (row, id, filterValue) => {
                    const date = row.getValue(id);
                    if (date === '0000-00-00' || !date) {
                      return false;
                    }
                    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                    return formattedDate.includes(filterValue);
                },
                muiTableHeadCellProps: {
                },
                minSize: 90, //min size enforced during resizing
                maxSize: 90, //max size enforced during resizing
                size: 90, //medium column
          }, 
          {      
            header: 'Priorität',
            accessorKey: 'Priority',
            Cell: ({ cell }) => 
            cell.row.original.Sparte === "KV Belege" ? null :
            cell.row.original.Priority === "HIGH" ?            
            <Chip color='error' sx={{color: "white"}} size='small' icon={<ArrowCircleUpSharp></ArrowCircleUpSharp>} label="Hoch"></Chip> : 
            <Chip color='success' sx={{color: "white"}} size='small' icon={<ArrowCircleDownSharp></ArrowCircleDownSharp>} label="Niedrig"></Chip>,
            muiTableBodyCellProps: { align: 'left' },
            minSize: 80, 
            maxSize: 80,
            size: 80,
          },               
          ],
          []
    )

    const tableKundeSchäden = useMaterialReactTable ({
      state: { 
        isLoading: isTableSchädenLoading,
        columnVisibility: columnVisibility
       },
      columns: columnsTableSchäden,
      data: schadenData,                          
      initialState: {
         pagination: { pageSize: 20, pageIndex: 0 },
         density: 'compact', columnVisibility: columnVisibility, sorting: [{ id: "Schadendatum", desc: true}]
      },
      onColumnVisibilityChange: handleColumnVisibilityChange,
      muiTableHeadRowProps :
      {
        sx: {justifyContent: "center"}
      },      
      muiTableBodyCellProps:
      {
          style: {overFlow: "hidden"}
      },      
      muiTableProps: 
      {
        sx: {
          tableLayout: 'fixed',
        },
      },
      muiTablePaperProps: {
        elevation: 0
      },
      enableMultiRowSelection: false,
      muiTableBodyRowProps: ({ row }) => ({
          onClick: () => {  
            handleClick(row.original)
          }
      }),
      enablePagination: true,    
      autoResetPageIndex: false, 
      muiTableContainerProps: ({ table }) => ({
        sx: { 
          height: table.getState().isFullScreen ? '100%' : contentHeight,
          minHeight: '250px' 
        }
      }),
      localization: customLocalization,  
      renderToolbarInternalActions : ({ table }) => (
        <Box>
          <MRT_ToggleGlobalFilterButton table={table} />
          { hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Schadenbearbeiter') ?
          <Tooltip title="Neuen Schaden anlegen">
            <IconButton
            onClick={handleOpenSchadenModal}>
              <NoteAddSharp />
            </IconButton>
          </Tooltip> : null }                                 
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </Box>
      )           
    })


    return (
        <Card>
          <CardContent>
            <AddSchadenModal Kundendaten={data} openSchadenModal={openSchadenModal} setopenSchadenModal={setopenSchadenModal} refetchdataSchäden={refetchdata}></AddSchadenModal>
              <div style={{margin: "5px"}}>              
              <MaterialReactTable table={tableKundeSchäden} />
               </div>
          </CardContent>
        </Card>      
      
    );
};