import React, { useRef, useEffect } from 'react';
import { useSlate } from 'slate-react';
import { Transforms, Editor, Range } from 'slate';
import { ToggleButton, ToggleButtonGroup, IconButton, Portal, Button } from '@mui/material';
import { ColorLens, FormatItalic, FormatBoldSharp, FormatUnderlinedSharp } from '@mui/icons-material'; 
import { red, green, blue } from '@mui/material/colors'; // Farben

export const HoveringToolbar = ({ editorID, isVisible }) => {
  const ref = useRef();
  const editor = useSlate();

      const toggleMark = (editor, format, value) => {
        // Handhabung für Farbmarkierungen
        if (format === 'color') {
            // Prüfen, ob bereits eine Farbmarkierung existiert
            const isActive = Editor.marks(editor)?.[format];
            // Entfernen der aktuellen Farbmarkierung, wenn sie existiert und gleich dem neuen Wert ist
            if (isActive && isActive === value) {
                Editor.removeMark(editor, format);
            } else {
                // Entfernen aller vorhandenen Farbmarkierungen, unabhängig vom Wert
                Editor.removeMark(editor, format);
                // Hinzufügen der neuen Farbmarkierung
                Editor.addMark(editor, format, value);
            }
        } else {
            // Standardverhalten für andere Formatierungen (nicht Farbmarkierungen)
            const isActive = Editor.marks(editor)?.[format] === true;
            if (isActive) {
                Editor.removeMark(editor, format);
            } else {
                Editor.addMark(editor, format, true);
            }
        }

        Editor.normalize(editor, { force: true });
    };

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;
    const targetDiv = document.getElementById(editorID);
  
    // Ensure the element and targetDiv exists and isVisible is true
    if (!el || !targetDiv || !isVisible) {
      return;
    }
  
    if (!selection || Range.isCollapsed(selection)) {
      el.style.display = 'none';
      el.style.opacity = '0';
      return;
    }
  
    const { top, left } = targetDiv.getBoundingClientRect();
    el.style.display = 'flex';
    el.style.opacity = '1';
    el.style.top = `${top + 5}px`;
    el.style.left = `${left + 100}px`;
  
    return () => {
      if (el) {
        el.style.display = 'none'; 
        el.style.opacity = '0';
      }
    };
  
  }, [editor.selection, editorID, isVisible]);

  useEffect(() => {
    if (!isVisible) {
      const { selection } = editor;
      if (selection) {
        Transforms.deselect(editor);
      }
    }
  }, [isVisible]);
  
  return (
    <Portal>
      <div
        key={isVisible ? 'visible' : 'hidden'} 
        ref={ref}
        id='hovering-toolbar'
        style={{
          position: 'absolute',
          zIndex: 9999,
          background: '#ffffff', 
          opacity: isVisible ? '1' : '0', 
          display: isVisible ? 'flex' : 'none',
          borderRadius: '4px',
          transition: 'opacity 0.75s',
          padding: '8px 7px 6px',
          marginTop: '-35px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)', 
          gap: '8px', 
        }}
      >
        <IconButton
          onMouseDown={e => {
            e.preventDefault(); 
            toggleMark(editor, 'bold');
          }}
          size="small">
          <FormatBoldSharp />
        </IconButton>

        <IconButton onMouseDown={e => {
          e.preventDefault(); toggleMark(editor, 'italic')
          }}
          size="small">
          <FormatItalic />
        </IconButton>
        <IconButton onMouseDown={e => {
          e.preventDefault(); toggleMark(editor, 'underline')
          }}
          size="small">
          <FormatUnderlinedSharp />
        </IconButton>
        <IconButton 
          style={{color: "black"}}
          onMouseDown={e => {
            e.preventDefault(); toggleMark(editor, 'color', 'black'); // Verwenden Sie 'color' mit dem Wert 'blue'
          }}
          size="small">
          <ColorLens />
        </IconButton>

        <IconButton
          style={{color: red[500]}}
          onMouseDown={e => {
            e.preventDefault(); // Verhindert, dass der Editor den Fokus verliert
            toggleMark(editor, 'color', '#ff6d75'); // Verwenden Sie 'color' als Markierung mit dem Wert 'red'
          }}
          size="small">
          <ColorLens />
        </IconButton>

        <IconButton 
          style={{color: green[500]}}
          onMouseDown={e => {
            e.preventDefault(); toggleMark(editor, 'color', '#14a37f'); // Verwenden Sie 'color' mit dem Wert 'green'
          }}
          size="small">
          <ColorLens />
        </IconButton>

   
        
      </div>
    </Portal>
  );
};
