import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { MoreVertSharp, AnalyticsSharp, MinorCrash, HourglassBottomOutlined, AccessTime, CakeSharp, BlockSharp, PhoneEnabledSharp, PhoneDisabledSharp, NotificationImportantSharp, ConstructionOutlined, ExitToApp, EggRounded, HistoryRounded, SignalCellularNoSimOutlined, PeopleAlt, CheckCircleSharp, CheckCircleOutlineSharp } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton, Grid, Tabs, Tab, Card, Popover, LinearProgress, Badge, Avatar, Tooltip, Stack, Snackbar, Alert, Link, Divider} from '@mui/material';
import Button from "@mui/material/Button";
import myPhoenix from '../img/Phoenix.png';
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { matchRoutes, useNavigate, useLocation } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { useAuth } from "../hooks/useAuth";
import { useState, useEffect, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setSchadenÄnderungCount, refreshDataKundenSchaeden, refreshuserstatus, refreshDataAufgaben, setloading, updateEventData, toggleInfoBarAppbar, setAufgabenCount, toggleGlobalServerMessage} from '../hooks/reduxactions';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { fetchdataUrl } from '../A-config/config';
import { globalAppBarMessage} from '../hooks/reduxactions';
import ActivityUpdater from "../hooks/activityupdater";
import UserActivityPopover from "./UserActivityPopover";
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import { GeburtstagslisteAppbar } from "./GeburtstagslisteAppbar";
import Zeiterfassung from "./Zeiterfassung";
import CurrentVacationÜbersicht from "./CurrentVactionÜbersicht";

function stringToArrayBuffer(string) {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
}
RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

  moment.updateLocale('de', {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: 'gerade eben',
      ss: 'vor %d Sekunden',
      m: "vor einer Minute",
      mm: "vor %d Minuten",
      h: "vor einer Stunde",
      hh: "vor %d Stunden",
      d: "vor einem Tag",
      dd: "vor %d Tagen",
      M: "vor einem Monat",
      MM: "vor %d Monaten",
      y: "vor einem Jahr",
      yy: "vor %d Jahren"
    }
});

export const AppBar = ({ pages, currentPath, setCurrentPath }) => {

  const { updateUserStats } = useUpdateUserStats();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, logout } = useAuth();
  const location = useLocation();
  const [infoAufgabevon, setAufgabevon] = useState("");
  const setloadingKunde = useSelector(state => state.loading);
  const [anchorElGeburtstagslistePopover, setAnchorElGeburtstagslistePopover] = useState(null);
  const [anchorElZeiterfassungPopover, setanchorElZeiterfassungPopover] = useState(null);
  const openGeburtstagsliste = Boolean(anchorElGeburtstagslistePopover);
  const openZeiterfassung = Boolean(anchorElZeiterfassungPopover);
  const [anchorElHistoriePopover, setanchorElHistoriePopover] = useState(null);
  const [anchorElUserActivityPopover, setanchorElUserActivityPopover] = useState(null);
  const openHistoriePopover = Boolean(anchorElHistoriePopover);
  const [openUserActivityPopover, setOpenUserActivityPopover] = useState(false);
  const [valueTabControlBearbeitungshistorie, setvalueTabControlBearbeitungshistorie] = useState(parseInt(localStorage.getItem('setvalueTabControlBearbeitungshistorie')) || 0);
  const visitedCustomers = useSelector((state) => state.visitedCustomers);
  const visitedSchaden = useSelector((state) => state.visitedSchaden);
  const pnrprocessed = useSelector((state) => state.pnrprocessed);
  const openInfoBar = useSelector((state) => state.isInfoBarOpen);
  const aufgabencount = useSelector((state) => state.aufgabencount);
  const openServerInfo = useSelector((state) => state.isGlobalMessageOpen);
  const ServerInfoMessage = useSelector((state) => state.servermessage);
  const useractivitydata = useSelector(state => state.currentuserstatus);
  const newChangesCount = useSelector(state => state.änderungcount);
  const [anchorElMore, setAnchorElMore] = useState(null);

  const handleMoreClick = (event) => {
    setAnchorElMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorElMore(null);
  };

  const [sortedUsers, setsortedUsers] = useState([]);
  const ref = useRef(null);

  const { navigateToCustomer } = useNavigation();
  const globalAppBarMessageGet = useSelector(state => state.globalAppBarMessage);
  const handleBearbeitungsHistorieTabChange = (event, newValue) => {
    setvalueTabControlBearbeitungshistorie(newValue);
    localStorage.setItem('setvalueTabControlBearbeitungshistorie', newValue);
  };
  const [onlineUserCount, setOnlineUserCount] = useState(0);

  const [snackbar, setSnackbar] = useState({
      open: false,
      message: "",
      severity: "error"
  });

  const handleSnackbarClose = () => {
      setSnackbar({
      open: false,
      message: "",
      severity: "info",
      });
      dispatch(globalAppBarMessage('')); 
  }

  const setStatus = (status) => {
    const lastActiveTime = moment(status.lastActive);
    const twentySecondsAgo = moment().subtract(60, 'seconds');

    if (!lastActiveTime || lastActiveTime.isBefore(twentySecondsAgo)) {
        return "offline"; 
    } else {
        switch (status.onlinestatus) {
            case 'verfügbar':
                return "verfügbar";
            case 'afk':
                return "abwesend";
            case 'im Gespräch':
                  return "im Gespräch";
            default:
                return "N/A";
        }
    }
  };


  useEffect(() => {
    if (useractivitydata && Array.isArray(useractivitydata)) {
      const visibleUsers = useractivitydata.filter(user => !user.invisible);

      // Calculate the count of online users
      const countOnlineUsers = visibleUsers.reduce((acc, user) => {
        const lastActiveDate = new Date(user.lastActive);
        const timeDiff = (new Date() - lastActiveDate) / 1000;
  
        if (timeDiff <= 60) {
          return acc + 1;
        }
        return acc;
      }, 0);
  
      // Sort the useractivitydata
      const sorted = visibleUsers.sort((a, b) => {
        const now = moment();
        const oneMinuteAgo = now.clone().subtract(1, 'minutes');
  
        // Determine online, AFK, and offline status
        const isOnlineA = moment(a.lastActive).isAfter(oneMinuteAgo) && a.lastActivebeforeAfk === null;
        const isOnlineB = moment(b.lastActive).isAfter(oneMinuteAgo) && b.lastActivebeforeAfk === null;
  
        const isAFKA = a.lastActivebeforeAfk !== null && moment(a.lastActive).isAfter(oneMinuteAgo);
        const isAFKB = b.lastActivebeforeAfk !== null && moment(b.lastActive).isAfter(oneMinuteAgo);
  
        const isOfflineA = moment(a.lastActive).isBefore(oneMinuteAgo);
        const isOfflineB = moment(b.lastActive).isBefore(oneMinuteAgo);
  
        // Prioritize online users
        if (isOnlineA && !isOnlineB) return -1;
        if (!isOnlineA && isOnlineB) return 1;
  
        // Sort AFK users by lastActivebeforeAfk if applicable
        if (isAFKA && isAFKB) {
          return moment(b.lastActivebeforeAfk).diff(moment(a.lastActivebeforeAfk));
        } else if (isAFKA && !isAFKB) {
          return -1;
        } else if (!isAFKA && isAFKB) {
          return 1;
        }
  
        // Sort offline users by lastActive in descending order
        if (isOfflineA && isOfflineB) {
          return moment(b.lastActive).diff(moment(a.lastActive));
        } else if (isOfflineA) {
          return 1;
        } else if (isOfflineB) {
          return -1;
        }
  
        return 0;  // Fallback if none of the above conditions apply
      });
  
      // Update state with the sorted data and online user count
      setsortedUsers(sorted);
      setOnlineUserCount(countOnlineUsers);
    } else {
      // Handle the case where useractivitydata is not an array, reset both state values
      setsortedUsers([]);
      setOnlineUserCount(0);
    }
  }, [useractivitydata]);  // Depend on useractivitydata
  
  //Listen for Global App Message
  useEffect(() => {
    // Check if globalAppBarMessage is not empty before showing the snackbar
    if (globalAppBarMessageGet) {
      setSnackbar({
        open: true,
        message: globalAppBarMessageGet,
        severity: 'info'
      });
    }
  }, [globalAppBarMessageGet]);

  useEffect(() => {

    //Set Default Values on Start if not set yet.
    if (localStorage.getItem('UsingApp') === null) {
      localStorage.setItem('UsingApp', false);
    } 

    if (localStorage.getItem('verbindungleftright') === null) {
        localStorage.setItem('verbindungleftright', true);
    } 

    if (localStorage.getItem('setcheckboxEVTaufrufen') === null) {
        localStorage.setItem('setcheckboxEVTaufrufen', true);
    } 

    if (localStorage.getItem('setcheckboxEVTSchadenaufrufen') === null) {
        localStorage.setItem('setcheckboxEVTSchadenaufrufen', false);
    } 

    if (localStorage.getItem('setshowonlyownTermine') === null) {
        localStorage.setItem('setshowonlyownTermine', true);
    } 

    if (localStorage.getItem('setshowonlyownAufgaben') === null) {
        localStorage.setItem('setshowonlyownAufgaben', true);
    } 

    if (localStorage.getItem('setshowonlyUnerledigte') === null) {
        localStorage.setItem('setshowonlyUnerledigte', true);
    } 

    if (window.chrome && window.chrome.webview && isDesktop)
    {
        const handleMessage = event => {

          //Der LoadingScreen wird erst entfernt, wenn wir auch die Daten von dem EVT-Portal erhalten haben.
          async function fetchData(url, options, retryCount = 0) {
              try {
                  const response = await fetch(url, options);
                  const data = await response.json();
                  return data;
              } catch (error) {
                  if (retryCount < 3) {
                      console.log(`Retrying (${retryCount + 1})...`);
                      return fetchData(url, options, retryCount + 1);
                  } else {
                      throw error;
                  }
              }
          }

          if (event.data.BestandChange == true) {

              if (event.data.name === "ul" && event.data.vorname === "ul")
              {
                return;
              }

              const fetchOptions = {
                  method: 'POST',
                  body: JSON.stringify({
                      access_token: sessionStorage.getItem("access_token"),
                      fetchJustUpdateUserDatainDB: 1,
                      pnr: event.data.pnr,
                      titel: event.data.titel,
                      name: event.data.name,
                      vorname: event.data.vorname,
                      straße: event.data.straße,
                      plzort: event.data.plzort,
                      gebdatum: event.data.gebdatum,
                      email: event.data.email,
                      festnetz: event.data.festnetz,
                      handy: event.data.handy,
                      telgesch: event.data.telgesch,
                      betreuer: event.data.betreuer,
                      kürzelbearbeiter: event.data.kürzelBearbeiter
                  }),
                  headers: {
                      'Content-Type': 'application/json'
                  }
              };

              fetchData(`${fetchdataUrl}fetchdata.php`, fetchOptions)
              .then(data => {
                  const fetchSecondOptions = {
                      method: "POST",
                      body: JSON.stringify({
                          access_token: sessionStorage.getItem("access_token"),
                          fetchUserDataDatabase: 1,
                          PNr: event.data.pnr,
                      }),
                      headers: {
                          "Content-Type": "application/json",
                      }
                  };

                  return fetchData(`${fetchdataUrl}fetchdata.php`, fetchSecondOptions);
              })
              .then(datax => {
                  dispatch(updateEventData(datax));
                  dispatch(setloading(false));
              })
              .catch(error => {
                  console.error('Request failed after 2 retries', error);
                  dispatch(setloading(true));
              });
          }  
          else if (event.data.SchadenChange == true)
          {       
                  const storedData = localStorage.getItem('firmenInfos');
                  const firmenInfos = JSON.parse(storedData);
                  let date = new Date();
                  let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

                  if (user.vollstaendigerName !== event.data.Bearbeiter)
                  {
                    if (firmenInfos.checkboxSchadenbearbeiterinformieren === true)
                    {
                      fetch(`${fetchdataUrl}fetchdata.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            access_token: sessionStorage.getItem("access_token"),
                            fetchUpdateSchadenänderung: 1,
                            data: event.data,
                            changeDate: formattedDate,
                            BearbeiterChange: user.vollstaendigerName,
                            Aenderung: "Schadendaten geändert"
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                        })
                        .then(response => response.json())
                        .then(data => {
    
                        })
                      }
                  }

                  const EventDataString = JSON.stringify(event.data, null, 2);
                  updateUserStats(user.vollstaendigerName, "update", "Schaden", "Schadendaten bearbeitet", event.data.Partnernummer, "");

                  fetch(`${fetchdataUrl}fetchdata.php`, {
                      method: 'POST',
                      body: JSON.stringify({
                          access_token: sessionStorage.getItem("access_token"),
                          fetchUpdateSchadendaten: 1,
                          data: event.data,
                          changeDate: formattedDate,
                          BearbeiterChange: user.vollstaendigerName
                      }),
                      headers: {
                          'Content-Type': 'application/json'
                      }
                      })
                      .then(response => response.json())
                      .then(data => {
                        if (window.chrome && window.chrome.webview && isDesktop) {
                              const dataarray = ["SchadenExist", data, event.data.WindowIdentifier];
                              window.chrome.webview.postMessage(dataarray);
                        }

                        if (event.data.StatusOld === "OFFEN" && event.data.Status === "ERLEDIGT" && event.data.Sparte !== "KV Belege")
                        {
                              //Fetch Bearbeiter
                              fetch(`${fetchdataUrl}fetchdata.php`, {
                                  method: 'POST',
                                  body: JSON.stringify({
                                      access_token: sessionStorage.getItem("access_token"),
                                      fetchLoadBearbeiter: 1
                                  }),
                                  headers: {
                                      'Content-Type': 'application/json'
                                  }
                              }).then((response) => response.json()).then((dataBearbeiter) => {
                                  //Convert RTF to Html first
                                  if (event.data.Bemerkungen.startsWith("{\\rtf")) {
                                      const doc = new RTFJS.Document(stringToArrayBuffer(event.data.Bemerkungen));
                                      const meta = doc.metadata();
                                      doc.render().then(htmlElements => {
                                          const container = document.createElement('div');
                                          container.innerHTML = htmlElements.map((el) => el.outerHTML).join('');                           

                                          //Mail an Betreuer, wenn Schaden geschlossen wurde
                                          const selectedUser = dataBearbeiter.find(user => user.vollstaendigerName === event.data.Betreuer);
                                          const userEmail = selectedUser ? selectedUser.email : firmenInfos.emailausweich;
                                          const Schadendaten = {
                                                  Name: event.data.Name,
                                                  Vorname: event.data.Vorname,
                                                  Straße: event.data.Straße,
                                                  PLZOrt: event.data.PLZOrt,
                                                  Schadennummer: event.data.Schadennummer,
                                                  Schadenart: event.data.Sparte,
                                                  ASTFreitext: event.data.ASTFreitext,
                                                  Schadendatum: moment(event.data.Schadendatum).format('DD.MM.YYYY'),
                                                  Bearbeiter: event.data.Bearbeiter,
                                                  Status: event.data.Status,
                                                  Regulierungsart: event.data.Regulierungsart,
                                                  Bemerkungen: container.innerHTML,
                                          };

                                          fetch(`${fetchdataUrl}sendemail.php`, {
                                              method: 'POST',
                                              body: JSON.stringify({
                                                  access_token: sessionStorage.getItem("access_token"),
                                                  sendSchadenerstellt: 1,
                                                  An: userEmail,
                                                  Art: "Schadenbearbeitung abgeschlossen",
                                                  Schadendaten: Schadendaten,
                                              }),
                                              headers: {
                                                  'Content-Type': 'application/json'
                                              }
                                          }).then((response) => {
                                              return console.log(response);
                                          })
                                      });
                                  }
                              })
                         }
                      });
          }
          else if (event.data.SchadenDelete === true)
          {
            updateUserStats(user.vollstaendigerName, "update", "Schaden", "Schadendaten gelöscht.", "-", "-");

            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchdeleteSchaden: 1,
                    ID: event.data.ID,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                })
                .then(response => response.json())
                .then(data => {
                  if (window.chrome && window.chrome.webview && isDesktop) {
                        const dataarray = ["SchadenExist", data, event.data.WindowIdentifier];
                        window.chrome.webview.postMessage(dataarray);
                        dispatch(refreshDataKundenSchaeden());
                  }                      
                })
              
          }
          else if (event.data.SchadenDragDrop === true)
          {
            const storedData = localStorage.getItem('firmenInfos');
            const firmenInfos = JSON.parse(storedData);
            let date = new Date();
            let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

            if (user.vollstaendigerName !== event.data.Bearbeiter)
            {
              if (firmenInfos.checkboxSchadenbearbeiterinformieren === true)
              {
                fetch(`${fetchdataUrl}fetchdata.php`, {
                  method: 'POST',
                  body: JSON.stringify({
                      access_token: sessionStorage.getItem("access_token"),
                      fetchUpdateSchadenänderung: 1,
                      data: event.data,
                      changeDate: formattedDate,
                      BearbeiterChange: user.vollstaendigerName,
                      Aenderung: "Dateien abgelegt"
                  }),
                  headers: {
                      'Content-Type': 'application/json'
                  }
                  })
                  .then(response => response.json())
                  .then(data => {

                  })
                }
            }
          }
          else if (event.data.Partnerzusammenführung == true)
          {

              if (event.data.Status === true)
              {
                  try {  
                  
                  const EventDataString = JSON.stringify(event.data, null, 2);
                  updateUserStats(user.vollstaendigerName, "update", "kunden", "Partnerzusammenführung durchgeführt", "Neue Partner-Nr: " + event.data.NeuePartnernummer + "Alte PNr: "+ event.data.AltePartnernummer, "");
                 
                   fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchZusammenfuehren: 1,
                        altePartnernummer: event.data.AltePartnernummer,
                        neuePartnernummer: event.data.NeuePartnernummer,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    }).then((response) => {
                        return response.json();
                    }).then((data) => {
                          setSnackbar({
                            open: true,
                            message: "Die Zusammenführung war erfolgreich.",
                            severity: "info"
                          });
                    });   

                  } catch (error) {
                    console.error('Error sending server message:', error);
                    alert('Failed to send the message. Please try again.');
                  }
              } else if (event.data.Status === false)
              {
                  setSnackbar({
                    open: true,
                    message: "Zusammenführung nicht erfolgreich. Datei(en) im Ordner sind gesperrt und können nicht verschoben werden.",
                    severity: "error"
                  });
              }
          }
          else if (event.data.Namesänderung === true)
          {
              if (event.data.Status === true)
              {
                  setSnackbar({
                    open: true,
                    message: "Die Namensänderung war erfolgreich.",
                    severity: "info"
                  });                  
              } else if (event.data.Status === false)
              {
                  setSnackbar({
                    open: true,
                    message: "Namensänderung nicht erfolgreich. Datei(en) im Ordner sind gesperrt und können nicht verschoben werden.",
                    severity: "error"
                  });
              }
          }
          else if (event.data.VerbindungLeftRight === true)
          {
              if (event.data.mychecked === true)
              {
                  localStorage.setItem('verbindungleftright', true);                
              } else if (event.data.mychecked === false)
              {
                  localStorage.setItem('verbindungleftright', false);               
              }
          }
          else if (event.data.ChangeDateiAblage === true)
          {
                fetch(`${fetchdataUrl}fetchdata.php`, {
                  method: 'POST',
                  body: JSON.stringify({
                      access_token: sessionStorage.getItem("access_token"),
                      fetchinsertfilenames: 1,
                      pnr: event.data.PNr,
                      files: event.data.FileUserMapping
                  }),
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }).then((response) => {
                  // console.log(response); // Log the response here
                  return response.json();
              }).then((data) => {
                  // console.log(data);
              }).catch((error) => {
                  console.error('Error:', error);
              });
          }           
        };

        window.chrome.webview.addEventListener('message', handleMessage);
        return () => {
         window.chrome.webview.removeEventListener('message', handleMessage);
        };
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('lastCloseInfoBarAufgaben', moment().format('YYYY-MM-DD HH:mm:ss'));
    localStorage.setItem('lastCloseInfoBarServerInfo', moment().format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  const handleOpenSchadenausHistorie = (e) => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            access_token: sessionStorage.getItem("access_token"),
                            fetchSucheSchadendatenbyID: 1,
                            ID: e.ID
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                        })
                        .then(response => response.json())
                        .then(data => {
                          if (window.chrome && window.chrome.webview && isDesktop) {
                            const dataarray = ["OPENSchaden", data[0]];
                            window.chrome.webview.postMessage(dataarray);
                            dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });                            
                            if (localStorage.getItem("setcheckboxEVTSchadenaufrufen") === "true")
                            {
                                  if (pnrprocessed !== e.PNr)
                                  {
                                    navigateToCustomer(e.PNr);
                                  }
                            }  
                          } else {         
                            dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });   
                            navigate('/dashboard/SchadenübersichtPage', { state: { data: data[0] } });
                          }                                
                        }).catch((error) => {
                            console.error('There was a problem with the fetch operation:', error);          
        });     
        
  }

  const handleOpenKundenausHistorie = (e) => {
        if (pnrprocessed !== e.PNr)
        {
          navigateToCustomer(e.PNr);
          dispatch({ type: "ADD_VISITED_CUSTOMER", payload: e });
        }
        else 
        {
          navigate(`/dashboard/UserKundenübersicht/${pnrprocessed}`);
        }
  }
  const handleopenZeiterfassung = (event) => {
    navigate('/dashboard/ZeiterfassungPage');
  };

  const handleopenSchadenänderungen = (event) => {
    navigate('/dashboard/SchadenÄnderungPage');
  };
  
  const handleOpenGeburtstagsliste = (event) => {
    navigate('/dashboard/GeburtstagslistePage');
    handleMoreClose();
  };

  const handleopenVertriebsaktionen = (event) => {
    navigate('/dashboard/VertriebsaktionenPage');
    handleMoreClose();
  };

  const handleopenVersorgungsstrecke = (event) => {
    navigate('/dashboard/VersorgungsstreckePage');
    handleMoreClose();
  };

  const handleBearbeitungshistorieanzeigen = (e) => {
      setanchorElHistoriePopover(e.currentTarget);
  }

  const handleUserActivityanzeigen = (e) => {
    setanchorElUserActivityPopover(e.currentTarget);
    setOpenUserActivityPopover(true);
  }

  const handlePopoverClose = () => {
      setanchorElUserActivityPopover(null);
      setOpenUserActivityPopover(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  }; 
  
  const handleCloseopenServerInfo = () => {
    dispatch(toggleGlobalServerMessage(false));    
    localStorage.setItem('lastCloseInfoBarServerInfo', moment().format('YYYY-MM-DD HH:mm:ss'));
  };

  const handleCloseInfoBar = () => {
    dispatch(toggleInfoBarAppbar(false));
    localStorage.setItem('lastCloseInfoBarAufgaben', moment().format('YYYY-MM-DD HH:mm:ss'));
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    if (path) {
      navigate(path);
    }
  };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //   };
  // }, []);

  const alertUser = (e) => {
    e.preventDefault();
  };

  return (
    <div className="app-bar-content">
    {user && <ActivityUpdater user={user}/>}
    <MuiAppBar sx={{height: "64px", position: "relative"}} position="static">
          <Snackbar sx={{position: "absolute"}} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                  <div className="alert-content">
                    <Typography variant="body1" sx={{fontWeight: "bold"}}>Information</Typography>
                    <Divider sx={{mb: 1}}></Divider>
                    <span dangerouslySetInnerHTML={{ __html: snackbar.message }}></span>
                  </div>
                </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openInfoBar}
            onClose={handleCloseInfoBar}
            ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
            TransitionProps={{ unmountOnExit: true }}
            sx={{ '& .MuiAlert-message': { padding: 0 }, position: "absolute"}}>
            <Alert icon={<NotificationImportantSharp></NotificationImportantSharp>}
             onClose={handleCloseInfoBar} severity="info" sx={{ width: '100%'}}>
            <div className="alert-content">
              <Typography variant="body1" sx={{fontWeight: "bold"}}>Benachrichtigung</Typography>
              <Divider sx={{mb: 1}}></Divider>
              <span>Du hast {aufgabencount} neue <Link onClick={() => {
                  const now = moment().format('YYYY-MM-DD HH:mm:ss');
                  localStorage.setItem('lastCheckAufgaben', now);
                  dispatch(setAufgabenCount(0));
                  dispatch(toggleInfoBarAppbar(false));
                  navigate('/dashboard/UserStart');
                }} sx={{color: "#014361", cursor: "pointer"}}><b> Aufgabe(n)</b>
               </Link>
               </span>
            </div>
        </Alert>
      </Snackbar>
       <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={openServerInfo}
            onClose={handleCloseopenServerInfo}
            ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
            TransitionProps={{ unmountOnExit: true }}
            sx={{ '& .MuiAlert-message': { padding: 0 }, position: "absolute" }}>
            <Alert icon={<NotificationImportantSharp></NotificationImportantSharp>}
             onClose={handleCloseopenServerInfo} severity="error" sx={{ width: '100%'}}>
            <div className="alert-content">
              <Typography variant="body1" sx={{fontWeight: "bold"}}>Wichtige Servernachricht</Typography>
              <Divider sx={{mb: 1}}></Divider>
              <span dangerouslySetInnerHTML={{ __html: ServerInfoMessage }}></span>
            </div>
        </Alert>
      </Snackbar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div style={{display:"flex", alignItems: "center", width: "140px"}}>
            <img style={{width: "25%", height: "25%"}} src={myPhoenix} />
            <Typography
              variant="h6"
              noWrap
              sx={{
                ml: 1,
                mr: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              PHX |
            </Typography>
          </div>         
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {pages?.map((page, index) => {
              const currentPath = decodeURIComponent(location.pathname).substring("/dashboard/".length);
              const baseCurrentPath = currentPath.split('/')[0];
              const basePagePath = page.path.split('/')[0];
              const isActive = baseCurrentPath === basePagePath;

              return (
                <Button
                  key={page.label}
                  onClick={() => {
                    setCurrentPath(page.path);
                    handleCloseNavMenu(page.path);
                    if (index === 0) {
                      const now = moment().format("YYYY-MM-DD HH:mm:ss");
                      localStorage.setItem("lastCheckAufgaben", now);
                      dispatch(setAufgabenCount(0));
                      dispatch(toggleInfoBarAppbar(false));
                    }                    
                  }}
                  className="activeMenuButton"
                 sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontWeight: isActive ? "bold" : "normal",
                  textDecoration: isActive ? "underline" : "none",
                }}
                >
                  {page.label}
                  {index === 0 && (
                    <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        color: 'white',       
                        backgroundColor: '#ff6d75',
                        position: "absolute", 
                        top: -12, 
                        right: -4
                      },
                    }}
                    badgeContent={aufgabencount}>
                  </Badge>                  
                  )}
                </Button>
              );
            })}
          </Box>
           <Box sx={{ flexGrow: 0 }}>
              <div style={{display: "flex", alignItems: "center"}}>
               {!!user && (
                <div>                
                <Tooltip title="Zeiterfassung öffnen">
                <IconButton sx={{color: "white"}} onClick={handleopenZeiterfassung}>
                      <HourglassBottomOutlined/>                     
                </IconButton>
                </Tooltip>             
                {(() => {
                      const storedData = localStorage.getItem('firmenInfos');
                      const firmenInfos = JSON.parse(storedData);

                      if (firmenInfos && firmenInfos.checkboxSchadenbearbeiterinformieren === true) { 
                        return (
                          <>
                            <Tooltip title="Schadenänderungen öffnen">
                              <IconButton sx={{ color: "white", marginBottom: "3px" }} onClick={handleopenSchadenänderungen}>
                              <Badge
                                sx={{
                                  '& .MuiBadge-badge': {
                                    color: 'white',       // Text color
                                    backgroundColor: '#ff6d75', // Background color
                                  },
                                }}
                                badgeContent={newChangesCount}
                              >
                                <MinorCrash sx={{ color: "white" }} />
                              </Badge>
                            </IconButton>
                            </Tooltip>
                          </>
                        );
                      }
                      return null;
                    })()}
                    <Tooltip title={
                      <div>
                        <div style={{ maxWidth: '550px' }}>
                          <table style={{ borderCollapse: "collapse", width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "left", paddingRight: "10px", borderBottom: "1px solid #fff" }}>Benutzer</th>
                                <th style={{ textAlign: "center", borderBottom: "1px solid #fff" }}>Status</th>
                                <th style={{ paddingLeft: "10px", textAlign: "center", borderBottom: "1px solid #fff" }}>Zuletzt aktiv</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sortedUsers && sortedUsers.map(user => (
                                <tr key={user.vollstaendigerName}>
                                  <td style={{ paddingRight: "20px", whiteSpace: 'nowrap' }}>{user.vollstaendigerName}</td>
                                  <td style={{ textAlign: "center", whiteSpace: 'nowrap' }}>
                                    <div style={{ display: 'inline-block', color: setStatus(user) === "verfügbar" ? "#61caaf"
                                      : setStatus(user) === "abwesend" ? "#76adff" 
                                      : setStatus(user) === "offline" ? "#ff6d75" 
                                      : setStatus(user) === "im Gespräch" ? "#c497ff"
                                      : "#fff" }}>
                                      {setStatus(user)}
                                    </div>
                                  </td>
                                  <td style={{ paddingLeft: "10px", textAlign: "center", whiteSpace: 'nowrap' }}>
                                    {moment().diff(moment(user.lastActive), 'minutes') < 1 ?
                                      (user.lastActivebeforeAfk === null ?
                                        moment(user.lastActive).fromNow() : 
                                        moment(user.lastActivebeforeAfk).subtract(60, 'seconds').fromNow()) : 
                                      moment(user.lastActive).fromNow() 
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <CurrentVacationÜbersicht />
                      </div>
                    }>
                  <IconButton onClick={handleUserActivityanzeigen} sx={{ color: 'white', pt: 1.5}}>
                    <Badge
                      color="success"
                      badgeContent={onlineUserCount}>
                      <PeopleAlt></PeopleAlt>
                    </Badge>
                  </IconButton>
                </Tooltip>
                 <Tooltip title="Bearbeitungshistorie">
                 <IconButton onClick={handleBearbeitungshistorieanzeigen} sx={{color: 'white', ml: 0.5}}>
                  <HistoryRounded />
                </IconButton>
                </Tooltip>
                <Tooltip title="Weitere Optionen">
                  <IconButton sx={{ color: "white", ml: -1}} onClick={handleMoreClick}>
                    <MoreVertSharp />
                  </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorElMore}
                    open={Boolean(anchorElMore)}
                    onClose={handleMoreClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleOpenGeburtstagsliste}>
                      <CakeSharp sx={{ marginRight: 1 }} />
                      Geburtstagsliste
                    </MenuItem>
                    <MenuItem onClick={handleopenVertriebsaktionen}>
                      <AnalyticsSharp sx={{ marginRight: 1 }} />
                      Vertriebsaktionen
                    </MenuItem>
                    <MenuItem onClick={handleopenVersorgungsstrecke}>
                      <AnalyticsSharp sx={{ marginRight: 1 }} />
                      Versorgungsstrecke
                    </MenuItem>
                  </Menu>

                  <UserActivityPopover 
                    open={openUserActivityPopover} 
                    anchorEl={anchorElUserActivityPopover} 
                    onClose={handlePopoverClose} 
                    useractivitydata={useractivitydata}
                  />                  
                 <Popover
                        open={openHistoriePopover}
                        anchorEl={anchorElHistoriePopover}
                        onClose={() => setanchorElHistoriePopover(null)} // Pass a function instead

                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <div style={{minWidth: "500px", display: "flex", flexDirection: "column", height: "705px"}}>
                            <div style={{display: "flex", alignItems: "center", height: "30px", justifyContent: "space-between"}}>
                            <span style={{fontSize: "14px", marginLeft: "10px"}}><b>Bearbeitungshistorie</b></span>
                          </div>
                          <Divider></Divider>
                          <div style={{maxHeight: "600px"}}>
                              <Tabs value={valueTabControlBearbeitungshistorie} onChange={handleBearbeitungsHistorieTabChange}>
                                <Tab label="Kunden"/>
                                <Tab label="Schaden" />
                              </Tabs>
                              <Typography component="div" sx={{mt: 1.2}} variant="body1">
                              <Divider></Divider>
                                <div style={{maxHeight: "600px", overflowY: 'auto'}}>
                                  {valueTabControlBearbeitungshistorie === 0 &&
                                    (visitedCustomers && visitedCustomers.length > 0 ? (
                                      visitedCustomers.map((value, index) => (
                                          <div 
                                              key={value.ID}
                                              style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px"}}>
                                            <div>
                                            <div>{value.Anrede}</div>
                                            <Link onClick={() => handleOpenKundenausHistorie(value)}  sx={{cursor: "pointer"}}  style={{fontWeight: "bold"}}>{value.Vorname} {value.Nachname} ({value.PNr})</Link>
                                            <div style={{fontSize: "14px"}}>{value.Straße}, {value.PLZOrt}</div>
                                            <div style={{fontSize: "14px"}}><b>{value.gebdatum}</b></div>
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <div style={{margin: "20px"}}>Keine Daten vorhanden.</div>
                                    ))
                                  }

                                  {valueTabControlBearbeitungshistorie === 1 &&
                                    (visitedSchaden && visitedSchaden.length > 0 ? (
                                      visitedSchaden.map((value, index) => (
                                        <div 
                                            key={value.ID} 
                                            style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px"}}>
                                          <div>
                                            <div>{value.Anrede}</div>
                                            <Link onClick={() => handleOpenSchadenausHistorie(value)}
                                              sx={{cursor: "pointer"}}  style={{fontWeight: "bold"}}>{value.Vorname} {value.Name} ({value.PNr})</Link>
                                            {moment(value.Schadendatum).isValid() ?
                                            <div style={{fontSize: "14px"}}>
                                              Schadendatum: <b>{moment(value.Schadendatum, 'YYYY-MM-DD').format('DD.MM.YYYY')}</b></div> : null}
                                            <div style={{fontSize: "14px"}}>
                                              Schadenart: <b>{value.Sparte}</b><br></br>Schadennummer: <b>{value.Schadennummer}</b>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <div style={{margin: "20px"}}>Keine Daten vorhanden.</div>
                                    ))
                                  }
                                </div>
                              </Typography>
                          </div>
                        </div>
                </Popover>
                </div>
              )}
              <Tooltip title="Profil und Einstellungen">
                <IconButton onClick={() => handleCloseNavMenu("settings")} sx={{ pt: 1}}>
                  {!!user && (
                    <Avatar sx={{ border: '2px solid white' }} src={`${fetchdataUrl}profilepicture/${user.profilepicturepath}`} />
                  )}
                </IconButton>
              </Tooltip>
              </div>
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
    </div>

  );
};
