import { useEffect, useState } from "react";
import { Card, Tabs, Tab, Typography } from '@mui/material';
import Zeiterfassung from "../components/Zeiterfassung";

export const ZeiterfassungPage = () => {


  return (
    <div>
      <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
      <Zeiterfassung></Zeiterfassung>
      </Card>
    </div>
  );
};