import { Help, MoreTime, NightsStay, AccessTime, HourglassFull, CloseSharp, PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Height } from '@mui/icons-material';
import {
    LinearProgress,
    Badge,
    CircularProgress,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Divider,
    Modal,
    Typography,
    Select, 
    InputLabel,
    FormControl,
    Fade,
    Backdrop,
    FormHelperText,
    Grid,
    Popover,
    Switch,
    Toolbar,
    CardContent, Table, Paper, TableContainer, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import moment, { months } from 'moment';
import { useAuth } from "../hooks/useAuth";
import { useState, useEffect, useRef } from "react";
import { fetchdataUrl } from '../A-config/config';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import AdapterDayjs from '@date-io/date-fns';
import { de } from 'date-fns/locale'; // import the locale you want to use
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";


export default function Versicherungsanalysen() {
    const { user, logout, hasPermission} = useAuth();
    const [users, setusers] = useState([]);
    const [loading, setisloading] = useState(true);
    const componentRef = useRef();
    const [firstMount, setfirstMount] = useState(true);
    
    const { control, handleSubmit, setError, clearErrors, reset, trigger, setValue, watch} = useForm({
        reValidateMode: "onChange",
    });


   useEffect(() => {
        setisloading(true);
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchLoadBearbeiter: 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
        .then((data) => {
            setusers(data);
            setisloading(false);
        });
    }, []);

    // useEffect(() => {
    //     if (firstMount)
    //     {
    //         setisloading(true)
    //         setfirstMount(false)
    //     }

    //     const fetchData = async () => {
    //         try {
    //             setBreakTimes([]);
    //             const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
    //                 method: 'POST',
    //                 body: JSON.stringify({
    //                     access_token: sessionStorage.getItem("access_token"),
    //                     fetchInsertAndCheckZeiterfassung: 1,
    //                     vollstaendigerName: selectedUser,
    //                 }),
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 }
    //             });
    //             const data = await response.json();
    //             const SecondsArbeitsZeitSaldiert = data.data.reduce((total, entry) => {
    //                 const az = entry.arbeitszeitsaldiert;
    //                 if (!az) {
    //                     return total;  // Skip null or empty entries
    //                 }
    //                 const isNegative = az.startsWith('-');
    //                 const duration = moment.duration(az.replace('-', ''));
    //                 return isNegative ? total - duration.asSeconds() : total + duration.asSeconds();
    //             }, 0);
    //             setTotalArbeitszeit(formatSecondstoHHMMSSPositiveandnegative(SecondsArbeitsZeitSaldiert));
    
    //             const today = moment().format('DD.MM.YYYY');
    //             const todayData = data.data.find(entry => {
    //                 const entryDate = entry.TagundDatum.split(' - ')[1];
    //                 const formattedToday = moment(today, 'DD.MM.YYYY');
    //                 const formattedEntryDate = moment(entryDate, 'DD.MM.YYYY');
    //                 return formattedEntryDate.isSame(formattedToday, 'day');
    //             });
    
    //             if (todayData) {
    //                 settodayData(todayData);
    //                 setStartTime(todayData.start_time);
    //                 setendTime(todayData.end_time);
    
    //                 if (todayData.break_times) {
    //                     const breaks = JSON.parse(todayData.break_times);
    //                     setBreakTimes(breaks);
    //                     const lastBreak = breaks[breaks.length - 1];
    //                     if (lastBreak && !lastBreak.end) {
    //                         setBreakStatus('pauseend');
    //                     } else {
    //                         setBreakStatus('pausestart');
    //                     }
    //                 } else {
    //                     setBreakStatus('pausestart');
    //                 }
    //             } else {
    //                 setStartTime(null);
    //                 setendTime(null);
    //                 setBreakStatus('pausestart');
    //             }

    //             const firmenInfos = JSON.parse(localStorage.getItem('firmenInfos'));
    //             setfirmenInfos(firmenInfos)
    //             const Wochenarbeitszeit = firmenInfos.arbeitszeitVollzeitkraft;
    //             const UrlaubstageVollzeitkraft = firmenInfos.UrlaubstageVollzeitkraft;
    //             let ArbeitszeitIndividuell;
        
    //             let prozentualearbeitszeit;
    //             if (users.length > 0 && selectedUser) {
    //                 const user = users.find(user => user.vollstaendigerName === selectedUser);
    //                 if (user) {
    //                     ArbeitszeitIndividuell = user.arbeitsstunden;
    //                     prozentualearbeitszeit = ((ArbeitszeitIndividuell / Wochenarbeitszeit) * 100).toFixed(2);
    //                     setarbeitszeitProzentundGesamt(`${ArbeitszeitIndividuell} / ${Wochenarbeitszeit} Stunden (${prozentualearbeitszeit}%)`);
    //                 }
    //             }
        
    //             if (prozentualearbeitszeit) {
    //                 const monthparsedtocompare = month ? moment(month).startOf('month') : null;
    //                 const yearparsedtocompare = month ? moment(month).startOf('year') : null;
        
    //                 const totalSecondsGesamtArbeitsZeitaktuellerMonat = data.data.reduce((total, entry) => {
    //                     const arbeitszeitgesamt1 = entry.arbeitszeitgesamt;
    //                     const datum = entry.TagundDatum;
    //                     const datePart = datum.split(' - ')[1];
    //                     const date = moment(datePart, 'DD.MM.YYYY');
        
    //                     if (!monthparsedtocompare || !date.isSame(monthparsedtocompare, 'month')) {
    //                         return total;
    //                     }
        
    //                     if (!arbeitszeitgesamt1) {
    //                         return total;
    //                     }
        
    //                     const duration = moment.duration(arbeitszeitgesamt1, 'seconds');
    //                     return total + duration.asSeconds();
    //                 }, 0);
        
    //                 const totalSecondsGesamtArbeitsZeitaktuellesJahr = data.data.reduce((total, entry) => {
    //                     const arbeitszeitgesamt1 = entry.arbeitszeitgesamt;
    //                     const datum = entry.TagundDatum;
    //                     const datePart = datum.split(' - ')[1];
    //                     const date = moment(datePart, 'DD.MM.YYYY');
        
    //                     if (!yearparsedtocompare || !date.isSame(yearparsedtocompare, 'year') && (entry.KorrekturDatum === null || entry.KorrekturDatum === "")) {
    //                         return total;
    //                     }
        
    //                     if (!arbeitszeitgesamt1) {
    //                         return total;
    //                     }
        
    //                     const duration = moment.duration(arbeitszeitgesamt1, 'seconds');
    //                     return total + duration.asSeconds();
    //                 }, 0);
        
    //                 const totalArbeitsTageimJahr = data.data.reduce((total, entry) => {
    //                     const Feiertaginfox = entry.Feiertaginfo;
    //                     const datum = entry.TagundDatum;
    //                     const datePart = datum.split(' - ')[1];
    //                     const date = moment(datePart, 'DD.MM.YYYY');
        
    //                     if (!yearparsedtocompare || !date.isSame(yearparsedtocompare, 'year')) {
    //                         return total;
    //                     }
        
    //                     if (Feiertaginfox === "Sonntag" || Feiertaginfox === "Samstag" || (Feiertaginfox && Feiertaginfox.startsWith("Feiertag"))) {
    //                         return total;
    //                     }
    //                     return total + 1;
    //                 }, 0);
        
    //                 const ArbeitsTageimJahr_AbzugUrlaub = totalArbeitsTageimJahr - UrlaubstageVollzeitkraft;
    //                 const ArbeitsstundenimJahr = (Wochenarbeitszeit / 5) * ArbeitsTageimJahr_AbzugUrlaub;
    //                 const ArbeitsstundenimMonat = ArbeitsstundenimJahr / 12;
    //                 const ArbeitsstundenimJahrtotalSeconds = (Math.round(ArbeitsstundenimJahr * 3600) * prozentualearbeitszeit) / 100;
    //                 const ArbeitsstundenimMonattotalSeconds = (Math.round(ArbeitsstundenimMonat * 3600) * prozentualearbeitszeit) / 100;
    //                 const offeneArbeitszeitimJahr = totalSecondsGesamtArbeitsZeitaktuellesJahr - ArbeitsstundenimJahrtotalSeconds;
    //                 const offeneArbeitszeitimMonat = totalSecondsGesamtArbeitsZeitaktuellerMonat - ArbeitsstundenimMonattotalSeconds;
        
    //                 setnochoffeneArbeitszeitimJahr(formatSecondstoHHMMSSPositiveandnegative(offeneArbeitszeitimJahr));
    //                 setnochoffeneArbeitszeitimMonat(formatSecondstoHHMMSSPositiveandnegative(offeneArbeitszeitimMonat));
    //                 setarbeitszeitMonatTeilzeitGesamtJahr(`${formatSecondstoHHMMSSPositiveandnegative(totalSecondsGesamtArbeitsZeitaktuellesJahr)} / ${formatSecondstoHHMMSSPositiveandnegative(ArbeitsstundenimJahrtotalSeconds)}`);
    //                 setarbeitszeitMonatTeilzeitGesamtMonat(`${formatSecondstoHHMMSSPositiveandnegative(totalSecondsGesamtArbeitsZeitaktuellerMonat)} / ${formatSecondstoHHMMSSPositiveandnegative(ArbeitsstundenimMonattotalSeconds)}`);
    //             }
    //             setisloading(false);

    //         } catch (error) {
    //             console.error("Error fetching data", error);
    //             setisloading(false);
    //         }
    //     };           
    
    //     fetchData();
    // }, [selectedUser, users, month, refreshInfo, updateZeiterfassung]);
    
    return (
        loading ? <LinearProgress/> :
        <Box ref={componentRef} sx={{ display: "flex", flexDirection: "column", height: "100%", padding: 2 }}>           
                {/* <div>test</div>
                <Stack direction="column">
                
                <TextField 
                    fullWidth      
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant='standard'
                    autoComplete="off"
                    type="text"                                    
                    label="Sparte"
                    onChange={(e) => {
                        // field.onChange(e)                                                                                   
                    }}
                    />
   
                <TextField 
                        fullWidth                       
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        variant='standard'
                        autoComplete="off"
                        type="text"                                    
                        label="Sparte"
                        onChange={(e) => {
                            // field.onChange(e)                                                                                   
                        }}
                />

                </Stack> */}
             
        </Box>

    );
};
