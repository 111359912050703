import React, { useRef, useEffect, useState, useMemo, useCallback} from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { InputLabel, Select, ToggleButtonGroup, ToggleButton, Stack, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, Menu, MenuItem, Typography, Chip, TextField, Box, IconButton, Tooltip, Checkbox, Divider } from '@mui/material';
import { EventRepeatSharp, PrintSharp, SettingsAccessibilitySharp, ThumbUpSharp, ThumbDownSharp, EditCalendarSharp, EventSharp, MoreHoriz, RotateRight, ReceiptSharp, RefreshSharp, DoNotDisturbAltSharp, CheckCircleSharp, UnpublishedSharp } from "@mui/icons-material";            
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { fetchdataUrl } from '../A-config/config';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import moment from 'moment';
import { debounce } from 'lodash';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import AdapterDayjs from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAuth } from "../hooks/useAuth";
import TermineModalUpdate from '../modals/TermineModalUpdate';
import * as XLSX from 'xlsx';
import { globalAppBarMessage } from '../hooks/reduxactions';

const VertriebsaktionenServerSide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useAuth();
  const { navigateToCustomer } = useNavigation();
  const [openModalTermineUpdate, setopenModalTermineUpdate] = useState(false);
  const [selectedyear, setselectedyear] = useState(dayjs());
  const [currentRowId, setCurrentRowId] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowDataTermine, setSelectedRowDataTermine] = useState([]);
  const [valueSelectAktion, setvalueSelectAktion] = useState(() => {
    const savedSelectAktion = localStorage.getItem('selectAktionVertriebsAktivitätenSpeicher');
    return savedSelectAktion || 'Ausbildung';
  });
  const [refreshVertriebsaktionenTable, setrefreshVertriebsaktionenTable] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(() => {
    const savedGlobalFilter = localStorage.getItem('globalFilterVertriebsAktivitätenSpeicher');
    return savedGlobalFilter || '';
  });

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  useEffect(() => {
    const savedColumnFilters = localStorage.getItem('columnFiltersVertriebsAktivitätenSpeicher');
    const savedGlobalFilter = localStorage.getItem('globalFilterVertriebsAktivitätenSpeicher');
    const savedSorting = localStorage.getItem('sortingVertriebsAktivitätenSpeicher');
    const savedPagination = localStorage.getItem('paginationVertriebsAktivitätenSpeicher');

    if (savedColumnFilters) {
      setColumnFilters(JSON.parse(savedColumnFilters));
    }
    if (savedGlobalFilter) {
      setGlobalFilter(savedGlobalFilter);
    }
    if (savedSorting) {
      setSorting(JSON.parse(savedSorting));
    }
    if (savedPagination) {
      setPagination(JSON.parse(savedPagination));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('columnFiltersVertriebsAktivitätenSpeicher', JSON.stringify(columnFilters));
  }, [columnFilters]);

  useEffect(() => {
    localStorage.setItem('globalFilterVertriebsAktivitätenSpeicher', globalFilter);    
  }, [globalFilter]);

  useEffect(() => {
    localStorage.setItem('sortingVertriebsAktivitätenSpeicher', JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    localStorage.setItem('paginationVertriebsAktivitätenSpeicher', JSON.stringify(pagination));
  }, [pagination]);


  const handleChangeSelectAktion = (event) => {
    setvalueSelectAktion(event.target.value);
    localStorage.setItem('selectAktionVertriebsAktivitätenSpeicher', event.target.value);   
  };

  const handleMenuOpen = (event, rowId, row) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setCurrentRowId(rowId);
    setSelectedRow(row);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchor(null);
    setCurrentRowId(null);
  };

  const [nureigeneanzeigen, setnureigeneanzeigen] = useState(localStorage.getItem('setnureigeneanzeigen') === 'true');

  const handleToggle = () => {
    const newValue = !nureigeneanzeigen;
    setnureigeneanzeigen(newValue);
    localStorage.setItem('setnureigeneanzeigen', newValue);
  };

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightUserVertriebsaktionen');
    return savedHeight ? parseInt(savedHeight) : 300;
  });

  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 315;
    localStorage.setItem('contentHeightUserVertriebsaktionen', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  useEffect(() => {
    calculateContentHeightAndPageSize();
    window.addEventListener('resize', calculateContentHeightAndPageSize);
    return () => {
      window.removeEventListener('resize', calculateContentHeightAndPageSize);
    };
  }, []);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    try {
      const visibilityString = localStorage.getItem('columnVisibilityVertriebsaktionen');
      return JSON.parse(visibilityString) || { ID: false,  TerminPartnernummer: false, TerminArt: false};
    } catch (e) {
      console.error(e);
      return { ID: false,  TerminPartnernummer: false, TerminArt: false };
    }
  });

  useEffect(() => {
    localStorage.setItem('columnVisibilityVertriebsaktionen', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleColumnVisibilityChange = (updater) => {
    setColumnVisibility(updater);
  };

  const defaultColumnWidthsVertriebsaktionen = 90;
  let storageItemVertriebsaktionen = localStorage.getItem('columnWidthsVertriebsaktionen');
  let columnWidthsFromLocalStorageVertriebsaktionen;

  if (storageItemVertriebsaktionen === "undefined" || !storageItemVertriebsaktionen) {
    columnWidthsFromLocalStorageVertriebsaktionen = defaultColumnWidthsVertriebsaktionen;
  } else {
    columnWidthsFromLocalStorageVertriebsaktionen = JSON.parse(storageItemVertriebsaktionen);
  }

  const [columnSizingStateVertriebsaktionen, setcolumnSizingStateVertriebsaktionen] = useState(columnWidthsFromLocalStorageVertriebsaktionen);

  const handleColumnSizingChange = (newColumnSizingState) => {
    const newState = newColumnSizingState(columnSizingStateVertriebsaktionen);
    localStorage.setItem('columnWidthsVertriebsaktionen', JSON.stringify(newState));
    setcolumnSizingStateVertriebsaktionen(newState);
  };

  const handleExportasExcel = () => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchLoadVertriebsaktionenforexcel: 1, 
        Bearbeiter: user.vollstaendigerName,
        year: selectedyear,
        aktion: valueSelectAktion,
        nureigene: nureigeneanzeigen
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data2 => {
      const allData = data2.data;
  
      // Filter out the ID column
      const columnsToExport = columns.filter(col => col.accessorKey !== 'ID');
  
      // Extract the data from the fetched data
      const data = allData.map(row => {
        return columnsToExport.map(col => {
          let cell = row[col.accessorKey || col.id];
  
          if (col.accessorKey === "TerminDateText" && cell) {
            let date = new Date(cell);
            let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            cell = date.toLocaleDateString('de-DE', options);
          }
  
          return cell;
        });
      });
  
      // Add the headers to the data
      const headers = columnsToExport.map(col => col.header);
      data.unshift(headers);
  
      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(data);
  
      // Set the width of the columns
      worksheet['!cols'] = columnsToExport.map(col => ({ wch: 20 }));
  
      // Create a new workbook and add the worksheet to it
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabelle 1');
  
      // Write the workbook to an Excel file
      XLSX.writeFile(workbook, 'Exportierte_Termine.xlsx');
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  };  

  const UpdateRowStatus = async (status, Notizen, id) => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchVertriebsaktionenUpdateRowStatus: 1, 
            id: id,
            status: status,
            Notizen: Notizen
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setrefreshVertriebsaktionenTable(!refreshVertriebsaktionenTable);
    });              
  };

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      nureigeneanzeigen, 
      selectedyear,
      valueSelectAktion
    ],
    queryFn: async () => {
      const requestBody = {
        access_token: sessionStorage.getItem("access_token"),
        fetchAlleAktionenServerSide: 1,
        start: pagination.pageIndex * pagination.pageSize,
        size: pagination.pageSize,
        filters: columnFilters,
        globalFilter: globalFilter,
        sorting: sorting,
        selectedyear: selectedyear,
        nureigeneanzeigen: nureigeneanzeigen,
        vollstaendigerName: user.vollstaendigerName,
        aktionauswählenSelect: valueSelectAktion
      };

      const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    refetch();
  }, [columnFilters, globalFilter, pagination, sorting, refreshVertriebsaktionenTable]);

  const columns = useMemo(() => [
    { accessorKey: 'ID', header: 'ID', size: 40, minSize: 40, enableHiding: false, enableSorting: false, enableGlobalFilter: false },
    {
      accessorKey: 'Status', header: 'Status', size: 200, minSize: 100,
      Cell: ({ cell }) =>         
            cell.row.original.Status === "Offen" ?
            <Chip color='error' sx={{color: "white"}} size='small' icon={<UnpublishedSharp></UnpublishedSharp>} label="Offen"></Chip> :
            cell.row.original.Status === "In Bearbeitung" ?
           <Chip sx={{ bgcolor: '#6579d7', color: "white" }} size='small' icon={<RotateRight color="white" />} label="In Bearbeitung" /> :
           cell.row.original.Status === "Kundentermin vereinbart" ?
           <Chip sx={{ bgcolor: '#f1801f', color: "white" }} size='small' icon={<EventSharp color="white" />} label="Kundentermin vereinbart"/> : 
           cell.row.original.Status === "Erledigt mit Erfolg" ?
           <Chip color='success' sx={{color: "white"}} size='small' icon={<ThumbUpSharp></ThumbUpSharp>} label="Erledigt mit Erfolg"></Chip> : 
           cell.row.original.Status === "Erledigt Termin hochgesetzt" ?
           <Chip sx={{ bgcolor: '#108165', color: "white" }} size='small' icon={<EditCalendarSharp color="white" />} label="Erledigt Termin hochgesetzt"/> : 
           cell.row.original.Status === "Erledigt ohne Erfolg" ?
           <Chip sx={{ bgcolor: '#e43740', color: "white" }} size='small' icon={<ThumbDownSharp color="white"></ThumbDownSharp>} label="Erledigt ohne Erfolg"></Chip> :
           cell.row.original.Status === "Terminierung durch ID" ?
           <Chip sx={{bgcolor: "#8b3e21", color: "white"}} size='small' icon={<EventRepeatSharp color="white"></EventRepeatSharp>} label="Terminierung durch ID"></Chip> :
           null,      
        muiTableBodyCellProps: { align: 'left' }
    },
    { accessorKey: 'TerminTitleText', header: 'Titel', size: 150, minSize: 80, maxSize: 200 },  
    { accessorKey: 'TerminPartnernummer', header: 'Partnernummer', size: 60, minSize: 60, maxSize: 120 },
    { accessorKey: 'TerminNameVN', header: 'Name', size: 80, minSize: 80, maxSize: 200 },
    { accessorKey: 'TerminVornameVN', header: 'Vorname', size: 80, minSize: 80, maxSize: 200 },
    { accessorKey: 'TerminArt', header: 'Terminart', size: 80, minSize: 80, maxSize: 200 },
    {
      accessorKey: 'TerminDateText', header: 'Datum', size: 80, minSize: 80, maxSize: 200, enableSorting: true,
      Cell: ({ cell }) => {
        const date = cell.row.original.TerminDateText;
        let formattedDate;
        if (moment(date).isValid())
        {
          formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
        }
        else
        {
          formattedDate = "";
        }
        return <span>{formattedDate}</span>;
      },      
    },
    {
      accessorKey: 'BemerkungenTermin', header: 'Bemerkungen', size: 80, minSize: 80, maxSize: 200,    
    },
    { accessorKey: 'TerminBearbeiterText', header: 'Bearbeiter', size: 80, minSize: 80, maxSize: 200, enableGlobalFilter: false },
  ], []);

  const tableInstance = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row.id,
    state: {
      columnSizing: columnSizingStateVertriebsaktionen,
      columnVisibility: columnVisibility,
      isLoading,
      isError,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      showProgressBars: isRefetching,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
      columnVisibility: columnVisibility,
    },
    enableRowActions: true,
    enableFilterMatchHighlighting: false,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableStickyHeader: true,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',
    onColumnSizingChange: handleColumnSizingChange,
    manualFilters: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: meta?.totalRowCount ?? 0,
    localization: MRT_Localization_DE,
    muiTableContainerProps: ({ table }) => ({
      sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px'}
    }),     
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {  
        setSelectedRow(row.original);      
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              fetchLoadTerminDatainModal: 1,
              TerminID: row.original.TermineID
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
           .then(data => {
            if (!data) {
              dispatch(globalAppBarMessage(`Öffnen fehlgeschlagen. Der Termin wurde bereits gelöscht.`));
            } else
            {
              setSelectedRowDataTermine(data);
              setopenModalTermineUpdate(true);
            }  
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      }
    }),
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: { margin: 1 }
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
      <TextField
          autoFocus
          size="small"
          defaultValue={globalFilter}
          onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
          spellCheck={false}
          placeholder="Suchen..."
          sx={{ minWidth: '300px' }}
          variant="outlined"
        />
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box sx={{display: "flex", justifyContent: "center"}}> 
      <ToggleButtonGroup
                  sx={{pl: 1, pr: 2}}
                  value={nureigeneanzeigen ? 'true' : 'false'}
                  exclusive
                  onChange={handleToggle}
                  aria-label="show own entries"
                >
                  <ToggleButton size='small' value="true" aria-label="show own">
                    <SettingsAccessibilitySharp sx={{pr: 1}} />Nur eigene anzeigen
                  </ToggleButton>
        </ToggleButtonGroup>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
    renderRowActions: ({ row }) => {
      const rowId = row.original.ID;
      return (
        <Box sx={{ display: 'flex' }}>
            <IconButton
                onClick={(event) => handleMenuOpen(event, rowId, row.original)}
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor) && currentRowId === rowId}
                onClose={handleMenuClose}
            >     
                <MenuItem
                    key={0}
                    onClick={(event) => {
                        UpdateRowStatus("Offen", "", rowId);                        
                        handleMenuClose(event);
                    }}
                    sx={{ m: 0, color: '#ff6d75' }}
                >
                    <Chip color='error' sx={{color: "white"}} size='small' icon={<UnpublishedSharp></UnpublishedSharp>} label="Offen"></Chip>
                </MenuItem>                                            
                <MenuItem
                    key={1}
                    onClick={(event) => {
                        UpdateRowStatus("In Bearbeitung", "", rowId);     
                        handleMenuClose(event);                   
                    }}
                    sx={{ m: 0, color: '#6579d7' }}
                >
                   <Chip sx={{ bgcolor: '#6579d7', color: "white" }} size='small' icon={<RotateRight color="white" />} label="In Bearbeitung" />
                </MenuItem>   
                <MenuItem
                    key={2}
                    onClick={(event) => {
                        UpdateRowStatus("Kundentermin vereinbart", "", rowId);
                        handleMenuClose(event);                       
                    }}
                    sx={{ m: 0, color: '#f1801f' }}
                >
                <Chip sx={{ bgcolor: '#f1801f', color: "white" }} size='small' icon={<EventSharp color="white" />} label="Kundentermin vereinbart"/>
                </MenuItem>                                 
                <MenuItem
                    key={3}
                    onClick={(event) => {
                        UpdateRowStatus("Terminierung durch ID", "", rowId); 
                        handleMenuClose(event);                     
                    }}
                    sx={{ m: 0, color: '#8b3e21' }}
                >
                   <Chip sx={{bgcolor: "#8b3e21", color: "white"}} size='small' icon={<EventRepeatSharp color="white"></EventRepeatSharp>} label="Terminierung durch ID"></Chip>
                </MenuItem>                                 
                <MenuItem
                    key={3}
                    onClick={(event) => {
                        UpdateRowStatus("Erledigt mit Erfolg", "", rowId); 
                        handleMenuClose(event);                     
                    }}
                    sx={{ m: 0, color: '#14a37f' }}
                >
                   <Chip color='success' sx={{color: "white"}} size='small' icon={<ThumbUpSharp></ThumbUpSharp>} label="Erledigt mit Erfolg"></Chip>
                </MenuItem>   
                <MenuItem
                    key={4}
                    onClick={(event) => {
                        UpdateRowStatus("Erledigt Termin hochgesetzt", "", rowId);  
                        handleMenuClose(event);                    
                    }}
                    sx={{ m: 0, color: '#108165' }}
                >
                   <Chip sx={{ bgcolor: '#108165', color: "white" }} size='small' icon={<EditCalendarSharp color="white" />} label="Erledigt Termin hochgesetzt"/>
                </MenuItem>       
                <MenuItem
                    key={5}
                    onClick={(event) => {
                        UpdateRowStatus("Erledigt ohne Erfolg", "", rowId);  
                        handleMenuClose(event);                    
                    }}
                    sx={{ m: 0, color: '#e43740' }}
                >
                   <Chip sx={{ bgcolor: '#e43740', color: "white" }} size='small' icon={<ThumbDownSharp color="white"></ThumbDownSharp>} label="Erledigt ohne Erfolg"></Chip>
                </MenuItem> 
                                             
            </Menu>          
        </Box>
      )    
      }             
  });

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", padding: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2, justifyContent: "space-between" }}>
              <Stack direction="row" sx={{display: "flex", alignItems: "center"}}>
              <Typography component="div" sx={{ fontSize: "20px", fontWeight: "bold", marginRight: 2 }}>Vertriebsaktionen</Typography>  
              <Tooltip title="Als Exceldatei exportieren">
                    <IconButton onClick={handleExportasExcel}>
                        <PrintSharp />
                    </IconButton>
              </Tooltip> 
              </Stack>
              <Stack direction="row">
              <FormControl
            sx={{
              pr: 1,
              width: "150px",
              '& .MuiInputBase-input': {
                fontSize: "14px",
                height: "22px"
              },
              '& .MuiInputLabel-root': {
                fontSize: "14px",
              }
            }}
            size="small"
          >
            <InputLabel id="aktionauswählen-label">Aktion auswählen</InputLabel>
            <Select
              label="Aktion auswählen"
              labelId="aktionauswählen-label"
              value={valueSelectAktion}
              onChange={handleChangeSelectAktion}         
            >
              <MenuItem selected key={0} value="Ausbildung">
                Ausbildung
              </MenuItem>
              <MenuItem key={1} value="PHV-Aktion">
                PHV-Aktion
              </MenuItem>
              <MenuItem key={2} value="MVP-Aktion">
                MVP-Aktion
              </MenuItem>
              <MenuItem key={3} value="Telefonakquise">
                Telefonakquise
              </MenuItem>
            </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
                    <DesktopDatePicker
                        views={['year']}
                        openTo="year"
                        label="Jahr auswählen"
                        value={selectedyear}
                        onChange={(newValue) => setselectedyear(newValue)}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                sx={{
                                    width: "120px",
                                    '& .MuiInputBase-input': {
                                        fontSize: "14px",
                                        height: "22px"
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: "14px",
                                    }
                                }} 
                                size="small" 
                            />
                        )}
                    />
              </LocalizationProvider> 
              </Stack>
          </Box>
          <Divider sx={{mb: 1}}></Divider>        
          <div>
            <MaterialReactTable table={tableInstance} />
          </div>       
      </Box>
      <TermineModalUpdate 
        openModalTermine={openModalTermineUpdate} 
        handleRefetchData={() => {
          refetch();
        }} 
        setopenModalTermine={setopenModalTermineUpdate} 
        rowDATA={selectedRowDataTermine}
        openfromVertrieb={true} 
        rowDATAVertrieb={selectedRow}
      />
    </div>    
  );
};

const queryClient = new QueryClient();

const VertriebsaktionenServerSideWithQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <VertriebsaktionenServerSide />
  </QueryClientProvider>
);

export default VertriebsaktionenServerSideWithQueryProvider;