import React, { useState, useEffect, useRef} from 'react';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import TextFieldPopup from '../components/TextFieldPopup';
import { useNavigation } from '../hooks/useNavigateFunction';
import { jsPDF } from "jspdf";
import { fetchdataUrl } from '../A-config/config';
import { useDispatch } from 'react-redux';
import { globalAppBarMessage, setloading } from '../hooks/reduxactions';

import {
  LinearProgress,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  Link,
  ClickAwayListener, 
  Chip
} from '@mui/material'
import { UnpublishedSharp,
    RotateRight,
    EventSharp,
    EventRepeatSharp,
    ThumbUpSharp,
    EditCalendarSharp,
    ThumbDownSharp, PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star} from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import DateFnsAdapter from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import ConfirmDialog from '../hooks/confirmDialog';

const style = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
};

const statusesVertrieb = [
    { key: 0, label: "Offen", color: 'error', bgColor: '#ff6d75', icon: <UnpublishedSharp color="white" /> },
    { key: 1, label: "In Bearbeitung", color: 'primary', bgColor: '#6579d7', icon: <RotateRight color="white"/> },
    { key: 2, label: "Kundentermin vereinbart", color: 'warning', bgColor: '#f1801f', icon: <EventSharp color="white"/> },
    { key: 3, label: "Terminierung durch ID", color: 'secondary', bgColor: '#8b3e21', icon: <EventRepeatSharp color="white"/> },
    { key: 4, label: "Erledigt mit Erfolg", color: 'success', bgColor: '#14a37f', icon: <ThumbUpSharp color="white"/> },
    { key: 5, label: "Erledigt Termin hochgesetzt", color: 'info', bgColor: '#108165', icon: <EditCalendarSharp color="white"/> },
    { key: 6, label: "Erledigt ohne Erfolg", color: 'error', bgColor: '#e43740', icon: <ThumbDownSharp color="white"/> }
];

export default function TermineModalUpdate({openModalTermine, setopenModalTermine, dataImported, handleRefetchData, rowDATA, openfromVertrieb, rowDATAVertrieb}) {
    const dispatch = useDispatch();
    const { navigateToCustomer } = useNavigation();
    const [modaldata, setModalData] = useState();
    const handleCloseTermine = () => setopenModalTermine(false);
    const [optionsBearbeiter, setOptionsBearbeiter] = useState([]);
    const [optionsTerminArt, setOptionsTerminArt] = useState([]);
    const [optionsTerminArtChange, setoptionsTerminArtChange] = useState("");
    const [terminFeldList, setTerminFeldList] = useState([]);
    const [PartnerKontaktdaten, setPartnerKontaktdaten] = useState([]);
    const [showloading, setshowloading] = useState(true);
    const [inputFieldValues, setInputFieldValues] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [openTextField, setopenTextField] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const handyInputRef = useRef(null);
    const festnetzInputRef = useRef(null);
    const telgeschInputRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState("");

    useEffect(() => {
        if (rowDATAVertrieb && rowDATAVertrieb.Status) {
            setSelectedStatus(rowDATAVertrieb.Status);
        }
    }, [rowDATAVertrieb]);

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        setSelectedStatus(newStatus);
        const selectedStatusObject = statusesVertrieb.find(status => status.label === newStatus);
        // if (selectedStatusObject) {
        //     UpdateRowStatus(newStatus, "", currentRowId);
        // }
    };

    const handleShowDialogDelete = () => setShowDialog(true);
    const handleHideDialogDelete = () => setShowDialog(false);
    const handleConfirmDialogDelete = () =>
    {
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchDeleteTermin: 1,
            TerminID: modaldata.ID
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
                handleRefetchData();
                handleCloseTermine();
        });
        setShowDialog(false);
    }

    const TerminArtSelectRef = useRef();
    const { control, handleSubmit, setError, clearErrors, reset, trigger, getValues } = useForm({
    reValidateMode: 'onChange',
    });

    const openTextFieldDialog = (value) => {
        setTextFieldValue(value);
        setopenTextField(true);
    };

    const closeTextFieldDialog = () => {
        setopenTextField(false);
    };
   
    const [tooltipOpen, setTooltipOpen] = useState({
    Handy: false,
    Festnetz: false,
    telgesch: false,
    });

    const handleTooltipClose = (field) => {
    setTooltipOpen((prev) => ({ ...prev, [field]: false }));
    };

    const handleTooltipOpen = (field, text, ref) => {
        if (ref.current) {
            const range = document.createRange();
            range.selectNodeContents(ref.current);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }

        navigator.clipboard.writeText(text).then(() => {
            setTooltipOpen((prev) => ({ ...prev, [field]: true }));
            setTimeout(() => {
                setTooltipOpen((prev) => ({ ...prev, [field]: false }));
            }, 2000);
        });
    };  

        
    useEffect(() => {
        //Hierdurch wird der useEffect nur ausgelöst, wenn das Modal wirklich geöffnet wird und nicht beim Start der Applikation.
        if (!openModalTermine) return;

        if (!rowDATA) {
            return;
        }

        setshowloading(true);
        setModalData(rowDATA);
        reset({
                TerminDatum: rowDATA.TerminDateText,
                TerminTitel: rowDATA.TerminTitleText,
                TextFieldBemerkungen: rowDATA.BemerkungenTermin,
                selectTerminArt: rowDATA.TerminArt ? rowDATA.TerminArt : '',
                selectTerminFür: rowDATA.TerminBearbeiterText,
        });

        //Trigger all Required Fields on Mount
        trigger();
        // // Cleanup function to remove added text fields and reset all entries
        reset();
        setoptionsTerminArtChange("");
        setInputFieldValues("");

        //Load Kunde Kontaktdaten
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchLoadKundeKontaktdaten: 1,
                pnr: rowDATA.TerminPartnernummer,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setPartnerKontaktdaten(data)
        });

        //Load Bearbeiter
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadBearbeiter: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsBearbeiter(data);
        });

        //Load TerminArt
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadTerminArten: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            const updatedOptions = ["Planmäßiger Beratungstermin", ...data];
            setOptionsTerminArt(updatedOptions);

            const terminArt = typeof rowDATA.TerminArt !== 'undefined' && rowDATA.TerminArt !== null && rowDATA.TerminArt !== '' ? rowDATA.TerminArt : '';
            setoptionsTerminArtChange(terminArt);
            fetchTerminFeldList(terminArt);

            const initialFieldValues = {};
            if (rowDATA.TerminFeldermitValue)
            {
                rowDATA.TerminFeldermitValue.forEach(({ terminFeld, value }) => {
                    initialFieldValues[terminFeld] = value || "";
                });  
            }          
            // Set the state
            setInputFieldValues(initialFieldValues);

            setTimeout(() => {
                setshowloading(false);
            }, 200);
        });
    },[openModalTermine]);

    const fetchTerminFeldList = async (terminArt) => {
        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchTerminFeldList: 1,
                TerminArt: terminArt,
            }),
            headers: {
                'Content-Type': 'application/json'
            }}).then((response) => {
                return response.json();
            }).then((data) => {
                setTerminFeldList(data)
        }).catch(() => {
            console.log("Terminfeldliste konnte nicht geladen werden. Serverfehler.")
        })
    };

    // Update the state when the TerminArt selection changes or new TerminFeld values are added
    useEffect(() => {
        fetchTerminFeldList(optionsTerminArtChange)
    }, [optionsTerminArtChange]);

    const handleOnSubmit = (evt) => {

        const terminFelder = [];
        terminFeldList.forEach((terminFeldValue, index) => {
        const value = inputFieldValues[terminFeldValue] || "";
        terminFelder.push({ terminFeld: terminFeldValue, value });
        });

        let formattedDate = null; 

        if (evt.TerminDatum === "" || evt.TerminDatum === null)
        {
            formattedDate = null;
        } 
        else if (moment(evt.TerminDatum).isValid()) {
            if (moment(evt.TerminDatum).year() < 2000) {
                dispatch(globalAppBarMessage(`Ungültiges Datum. Bitte berichtige das Jahr.`));
                return;
            } else if (moment(evt.TerminDatum).year() > 2999)
            {
                dispatch(globalAppBarMessage(`Ungültiges Datum. Bitte berichtige das Jahr.`));
                return;
            } else
            {
                formattedDate = moment(evt.TerminDatum).format('YYYY-MM-DD HH:mm:ss'); 
            }
        }
        else
        {
            dispatch(globalAppBarMessage(`Ungültiges Datum.`));
            return;
        }

        //Update Termin Status first (Vertriebsaktionen)
        if (rowDATAVertrieb && rowDATAVertrieb.Status) {
                fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchVertriebsaktionenUpdateRowStatus: 1, 
                    id: rowDATAVertrieb.ID,
                    status: selectedStatus,
                    Notizen: ""
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                });  
        };
    
           
        //Now Update rest
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateTermin: 1,
                TerminID: modaldata.ID,
                TerminDateText: formattedDate,
                TerminBearbeiterText: evt.selectTerminFür,
                TerminTitleText: evt.TerminTitel,
                TerminArt: evt.selectTerminArt,
                BemerkungenTermin: evt.TextFieldBemerkungen,
                TerminFelder: terminFelder
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                handleRefetchData();
                handleCloseTermine();
        });
    };

   const handlePrint = () => {
     const doc = new jsPDF();

        const terminTitel = getValues("TerminTitel") || '';
        const terminBearbeiter = getValues("selectTerminFür") || '';
        const terminArt = getValues("selectTerminArt") || '';
        const terminDatum = getValues("TerminDatum") || '';
        const bemerkungen = getValues("TextFieldBemerkungen") || '';  // use empty string if value is null

        // Adding Headline
        doc.setFontSize(20);
        let nameundvorname = " ohne Kundenbezug";
        if (modaldata.TerminNameVN && modaldata.TerminNameVN !== "-") {
             nameundvorname = modaldata.TerminNameVN + ", " + modaldata.TerminVornameVN;
        }
        doc.setFont('helvetica', 'bold');
        doc.text('Termin ' + nameundvorname, 20, 20); // Center alignment
        doc.line(20, 21, 190, 21); // underline
        doc.setFont('helvetica', 'normal');

        // Adding vertical text
        const xOffset = 10; // Change according to your requirements
        const yOffset = 60; // Change according to your requirements

        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'bold');
        doc.text(nameundvorname, xOffset, yOffset, {angle: 90});
        doc.line(12, 10, 12, 290);
        doc.setFont('helvetica', 'normal');

        // Add space
        doc.setFontSize(10);
        doc.text('\n', 20, 30);

        doc.text('Druckdatum: ', 20, 40);
        doc.line(20, 41, 40, 41); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(moment().format('DD.MM.YYYY'), 60, 40);
                doc.setFont('helvetica', 'normal');

        doc.text('Bearbeiter: ', 20, 45);
        doc.line(20, 46, 37, 46); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(terminBearbeiter, 60, 45);
                doc.setFont('helvetica', 'normal');

        doc.text('Fälligkeit des Termins: ', 20, 50);
        doc.line(20, 51, 55, 51); // underline
                doc.setFont('helvetica', 'bold');
                let formattedDate = " ";
                if (moment(terminDatum).isValid()) {
                    formattedDate = moment(terminDatum).format('DD.MM.YYYY');
                }
                doc.text(formattedDate, 60, 50);
                doc.setFont('helvetica', 'normal');

        doc.text('Terminart: ', 20, 55);
        doc.line(20, 56, 36, 56); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(terminArt, 60, 55);
                doc.setFont('helvetica', 'normal');

        // Adding "Titel des Termins" with underline
        doc.setFontSize(12);
        doc.text('Titel des Termins: ', 20, 80);
        doc.line(20, 81, 190, 81); // underline

        // Adding value of Title with Bold
        doc.setFont('helvetica', 'bold');
        doc.text(terminTitel, 20, 90);
        doc.setFont('helvetica', 'normal');

        // Add space
        doc.setFontSize(10);
        doc.text('\n', 20, 100);

        // Adding "Bemerkungen" with underline
        doc.setFontSize(12);
        doc.text('Bemerkungen: ', 20, 110);
        doc.line(20, 111, 190, 111); // underline

        // Adding value of Bemerkungen with a new line
        const bemerkungenLines = doc.splitTextToSize(bemerkungen, 180); // width = 180 to consider margins
        let y = 120;
        const lineHeight = 7;  // adjust this as needed
        bemerkungenLines.forEach(line => {
            if (y + lineHeight > 280) {  // 280 is just before the end of the page
                doc.addPage();
                y = 20;  // reset y to the top of the new page
            }
            doc.text(line, 20, y);
            y += lineHeight;
        });

        // Prepare dynamicFieldsText
        let dynamicFieldsText = '';
        terminFeldList.forEach((terminFeldValue, index) => {
            dynamicFieldsText += `${terminFeldValue}: ${inputFieldValues[terminFeldValue] || ''}\n`;
        });

        // Add the "Weitere Informationen:" header to the PDF document
        if (y + lineHeight > 280) {
            doc.addPage();
            y = 20;
        }
        doc.setFontSize(12);
        doc.text('Weitere Informationen:', 20, y + 10);
        doc.line(20, y + 11, 190, y + 11); // underline
        y += 3*lineHeight;

        // Split the dynamic fields text into lines
        const dynamicFieldsLines = doc.splitTextToSize(dynamicFieldsText, 180); // adjust this as needed

        // Add the dynamic fields text to the PDF document, with automatic page breaks
        dynamicFieldsLines.forEach(line => {
            if (y + lineHeight > 280) {
                doc.addPage();
                y = 20;
            }
            doc.text(line, 20, y);
            y += lineHeight;
        });

        doc.save('Neuer Termin.pdf');
    };

    return (

    <Modal
                        open={openModalTermine}
                        onClose={handleCloseTermine}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                    <Fade in={openModalTermine}>
                        {showloading ? (
                        <Box sx={style2}>
                         <Stack direction="column" >
                            <Alert icon={false} style={{justifyContent: "center"}} severity="info"><b>Der Termin wird geladen...</b></Alert>
                            <LinearProgress></LinearProgress>
                         </Stack>
                        </Box>

                        ) : (
                        <Box sx={style}>
                        <div>
                        <Stack direction="row" spacing={3}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                                <span><b>Termine</b></span>
                                <Tooltip sx={{ml: 2}} title='Termin drucken'>
                                    <IconButton onClick={handlePrint} fontSize='small' color="primary">
                                    <PrintSharp />
                                </IconButton>
                            </Tooltip>
                            </div>
                        </Stack>
                        <Divider sx={{mt: 1, mb: 2}}></Divider>
                        <form onSubmit={handleSubmit(handleOnSubmit)}>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <Controller
                                        control={control}
                                        name="TerminTitel"
                                        defaultValue=""
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoComplete="off"
                                            type="text"
                                            label="Titel des Termins"
                                            error={error !== undefined}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                if(e.target.value === "") {
                                                    setError('TerminTitel', { type: 'required'});
                                                } else {
                                                    clearErrors("TerminTitel");
                                                }
                                            }}
                                            />
                                        )}
                                    />
                                    <Controller
                                            control={control}
                                            name="TerminDatum"
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={de}>
                                                <DesktopDatePicker
                                                    label="Datum"
                                                    format="dd.MM.yyyy"
                                                    value={value}
                                                    onChange={(date) => {
                                                        onChange(date);
                                                        if (!date) {
                                                            onChange("");
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                          size="small"
                                                          {...params}
                                                          error={!!error}
                                                          helperText={error ? error.message : null}
                                                          style={{ marginTop: "16px", marginBottom: "8px", marginLeft: "15px" }}
                                                        />
                                                      )}
                                                />
                                                </LocalizationProvider>
                                            )}
                                    />
                            </div>
                            <Stack direction="row" marginTop={2} marginBottom={3} spacing={2}>
                                <Controller
                                        control={control}
                                        name="selectTerminArt"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>
                                            <InputLabel style={{ top: '-5px' }}>Terminart</InputLabel>
                                            <Select
                                                {...field}
                                                inputRef={TerminArtSelectRef}
                                                fullWidth
                                                label="Terminart"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setoptionsTerminArtChange(e.target.value);
                                                    setInputFieldValues({});
                                                    if (e.target.value === "") {
                                                        setError("selectTerminArt", { type: "required" });
                                                    } else {
                                                        clearErrors("selectTerminArt");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                           {Array.isArray(optionsTerminArt) && optionsTerminArt.sort().map((Aufgabenartx) => (
                                            <MenuItem key={Aufgabenartx} value={Aufgabenartx}>
                                                {Aufgabenartx}
                                            </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                    />
                                <Controller
                                        control={control}
                                        name="selectTerminFür"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>
                                            <InputLabel style={{ top: '-5px' }}>Bearbeiter</InputLabel>
                                            <Select
                                                {...field}
                                                fullWidth
                                                label="Bearbeiter"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    if (e.target.value === "") {
                                                        setError("selectTerminFür", { type: "required" });
                                                    } else {
                                                        clearErrors("selectTerminFür");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                            {Array.isArray(optionsBearbeiter) && optionsBearbeiter.sort().map((user) => (
                                                <MenuItem key={user.id} value={user.vollstaendigerName}>
                                                {user.vollstaendigerName}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                />
                            </Stack>
                            <div>
                                {terminFeldList.length > 0 &&
                                    terminFeldList.map((terminFeldValue, index) => (
                                        index % 2 === 0 && (
                                            <Stack sx={{mb: 3}} direction="row" key={index} spacing={2}>
                                                <TextField
                                                    autoComplete='off'
                                                    inputProps={{ autoComplete: 'new-password' }}
                                                    autoCorrect='off'
                                                    sx={{width: "243px"}}
                                                    size="small"
                                                    label={terminFeldValue}
                                                    id={`terminfeld-${terminFeldValue}`}
                                                    value={inputFieldValues[terminFeldValue] || ""}
                                                    onChange={(e) => {
                                                        setInputFieldValues((prevState) => ({
                                                            ...prevState,
                                                            [terminFeldValue]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                                {index + 1 < terminFeldList.length && (
                                                    <TextField
                                                        autoComplete='off'
                                                        inputProps={{ autoComplete: 'new-password' }}
                                                        autoCorrect='off'
                                                        sx={{width: "243px"}}
                                                        size="small"
                                                        label={terminFeldList[index + 1]}
                                                        id={`terminfeld-${terminFeldList[index + 1]}`}
                                                        value={inputFieldValues[terminFeldList[index + 1]] || ""}
                                                        onChange={(e) => {
                                                            setInputFieldValues((prevState) => ({
                                                                ...prevState,
                                                                [terminFeldList[index + 1]]: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        )
                                    ))
                                }
                            </div>
                        <Controller
                        control={control}
                        name="TextFieldBemerkungen"
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field, fieldState: { error } }) => {
                            const handleSaveTextFieldDialog = (value) => {
                                // Update the field value in the parent component
                                field.onChange(value);
                                const textField = document.getElementById("TextFieldBemerkungen");
                                setTimeout(() => {
                                          const textField = document.getElementById("TextFieldBemerkungen");
                                          textField.scrollTop = 0;
                                }, 0);
                            };

                            return (
                            <>
                                <TextField
                                {...field}
                                label="Bemerkungen"
                                id="TextFieldBemerkungen"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { fontSize: 14, paddingRight: "20px" } }} // font size of input text
                                multiline
                                rows={6}
                                fullWidth
                                onClick={() => {
                                    openTextFieldDialog(field.value); // Call openTextFieldDialog when the TextField is focused
                                }}
                                />
                                <TextFieldPopup
                                key={openTextField ? "open" : "closed"}
                                open={openTextField}
                                value={textFieldValue}
                                onClose={closeTextFieldDialog}
                                onSave={handleSaveTextFieldDialog}
                                />
                            </>
                            );
                        }}
                        />
                            <Grid sx={{pt: 2}} container spacing={2}>
                                <Grid item xs={2.2}>
                                    <span style={{fontSize: "14px"}}>Direktlink:</span>
                                </Grid>
                                <Grid item xs={9}>
                                    {modaldata.TerminNameVN !== "-" ? (
                                    <Link
                                    onClick={() => {
                                        setopenModalTermine(false);
                                        navigateToCustomer(modaldata.TerminPartnernummer);
                                    }}
                                    sx={{cursor: "pointer", fontSize: "14px"}}>{modaldata.TerminNameVN}, {modaldata.TerminVornameVN} - {modaldata.TerminPartnernummer}</Link>
                                    ) : <span style={{fontSize: "14px"}}><b>Allgemeiner Termin ohne Kundenbezug</b></span> }
                                </Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                            <Grid sx={{overflow: "hidden"}} container spacing={0}>
                                <Grid item xs={2.2}>
                                    <span style={{ fontSize: "14px" }}>Mobil:</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <ClickAwayListener onClickAway={() => handleTooltipClose('Handy')}>
                                        <Tooltip
                                            title="Kopiert"
                                            open={tooltipOpen.Handy}
                                            onClose={() => handleTooltipClose('Handy')}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                        >
                                            <Typography
                                                ref={handyInputRef}
                                                onClick={() => handleTooltipOpen('Handy', PartnerKontaktdaten.Handy, handyInputRef)}
                                                style={{ cursor: "pointer", fontSize: "14px", textDecoration: "underline", textDecorationStyle: "dotted", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                                >
                                                {PartnerKontaktdaten.Handy}
                                            </Typography>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </Grid>
                                <Grid item xs={2.2}>
                                    <span style={{ fontSize: "14px" }}>E-Mail:</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Link href={`mailto:${PartnerKontaktdaten.Email}`} sx={{ cursor: "pointer", fontSize: "14px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                        {PartnerKontaktdaten.Email}
                                    </Link>
                                </Grid>                           
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={2.2}>
                                    <span style={{ fontSize: "14px" }}>Privat:</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <ClickAwayListener onClickAway={() => handleTooltipClose('Festnetz')}>
                                        <Tooltip
                                            title="Kopiert"
                                            open={tooltipOpen.Festnetz}
                                            onClose={() => handleTooltipClose('Festnetz')}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                        >
                                            <Typography
                                                    ref={festnetzInputRef}
                                                    onClick={() => handleTooltipOpen('Festnetz', PartnerKontaktdaten.Festnetz, festnetzInputRef)}
                                                    style={{ cursor: "pointer", fontSize: "14px", textDecoration: "underline", textDecorationStyle: "dotted", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                                >
                                                    {PartnerKontaktdaten.Festnetz}
                                                </Typography>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </Grid>
                                <Grid item xs={2.2}>
                                    <span style={{ fontSize: "14px" }}>Geschäftlich:</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <ClickAwayListener onClickAway={() => handleTooltipClose('telgesch')}>
                                        <Tooltip
                                            title="Kopiert"
                                            open={tooltipOpen.telgesch}
                                            onClose={() => handleTooltipClose('telgesch')}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                        >
                                            <Typography
                                                ref={telgeschInputRef}
                                                onClick={() => handleTooltipOpen('telgesch', PartnerKontaktdaten.telgesch, telgeschInputRef)}
                                                style={{ cursor: "pointer", fontSize: "14px", textDecoration: "underline", textDecorationStyle: "dotted", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                                >
                                                {PartnerKontaktdaten.telgesch}
                                            </Typography>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </Grid>
                                
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                            {openfromVertrieb ? (
                            <Grid sx={{mb: 1}} container spacing={2}>
                                
                                    <Grid item xs={12}>
                                        <Select
                                            size='small'
                                            value={selectedStatus}
                                            onChange={handleStatusChange}
                                            displayEmpty
                                            fullWidth
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return <Typography>Select a status</Typography>;
                                                }
                                                const selectedStatusObject = statusesVertrieb.find(status => status.label === selected);
                                                return (
                                                    <Chip
                                                        sx={{ bgcolor: selectedStatusObject.bgColor, color: "white" }}
                                                        icon={selectedStatusObject.icon}
                                                        label={selectedStatusObject.label}
                                                    />
                                                );
                                            }}
                                        >
                                            {statusesVertrieb.map((status) => (
                                                <MenuItem
                                                    key={status.key}
                                                    value={status.label}
                                                    sx={{ m: 0, color: status.color }}
                                                >
                                                    <Chip
                                                        sx={{ bgcolor: status.bgColor, color: "white" }}
                                                        size='small'
                                                        icon={status.icon}
                                                        label={status.label}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>                             
                            </Grid>
                            ) : (
                                null
                            )}
                            <Stack direction="row" spacing={2}>
                                    <Button
                                        type='submit'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Speichern
                                    </Button>
                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleShowDialogDelete}
                                        >
                                        <Delete fontSize='small' sx={{ mr: 1}}></Delete>
                                        Löschen
                                    </Button>
                                    <ConfirmDialog
                                        open={showDialog}
                                        onClose={() => setShowDialog(false)}
                                        message="Möchtest du den Termin wirklich löschen?"
                                        onConfirm={handleConfirmDialogDelete}
                                        onCancel={handleHideDialogDelete}
                                    />
                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleCloseTermine}
                                        >
                                        <Cancel fontSize='small' sx={{ mr: 1}}></Cancel>
                                        Abbrechen
                                    </Button>
                            </Stack>
                         </form>
                        </div>
                        </Box>
                         )}
                        </Fade>
    </Modal>
);
}
