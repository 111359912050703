import React, { useEffect, useState, useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFullScreenButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
} from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { fetchdataUrl } from '../A-config/config';
import { TextField, Popover, Stack, Box, Tooltip, IconButton, Checkbox } from '@mui/material';
import { PlaylistAddCheckSharp, CarCrashRounded, UnpublishedSharp, PrintSharp, EventAvailableRounded, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star, DeleteSharp} from '@mui/icons-material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { DateRangePickerCustom } from '../components/DateRangePickerCustom';
import TermineModal from "../modals/TermineModalNeu";
import TermineModalUpdate from '../modals/TermineModalUpdate';
import ConfirmDialog from '../hooks/confirmDialog';
import * as XLSX from 'xlsx';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { useNavigation } from '../hooks/useNavigateFunction';
import { debounce } from 'lodash';

const queryClient = new QueryClient();

const DashboardTermineTableServerside = () => {
    const { user, hasPermission } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { navigateToCustomer } = useNavigation();
    const [refreshTermine, setrefreshTermine] = useState(false);
    const [openModalTermineUpdate, setopenModalTermineUpdate] = useState(false);
    const [openModalTermine, setopenModalTermine] = useState(false);
    const [enableRowSelection, setEnableRowSelection] = useState(false);
    const [openPopoverDatePicker, setopenPopoverDatePicker] = useState(false);
    const [anchorPopoverDate, setanchorPopoverDate] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [rowID, setRowID] = useState('');
    const handleShowDialogDelete = () => setShowDialog(true);
    const handleHideDialogDelete = () => setShowDialog(false);

    const reloadDataTermine = () => {
        setrefreshTermine(!refreshTermine);
    };

    const [dateRange, setDateRange] = useState([{
        startDate: moment(1950, 'year').toDate(),
        endDate: moment(),
        key: 'selection'
    }]);

    const handleDateRangeChange = (range) => {
        setDateRange([range]);
    };

    const [columnVisibilityTermineStart, setcolumnVisibilityTermineStart] = useState(() => {
        try {
            const visibilityString = localStorage.getItem('columnVisibilityTermineStart');
            return JSON.parse(visibilityString) || { ID: false, TerminDateText: true, TerminTitleText: true, TerminVornameVN: true, TerminNameVN: true, TerminBearbeiterText: true, TerminBemerkungenText: false };
        } catch (e) {
            console.error(e);
            return { ID: false, TerminDateText: true, TerminTitleText: true, TerminVornameVN: true, TerminNameVN: true, TerminBearbeiterText: true, TerminBemerkungenText: false };
        }
    });

    useEffect(() => {
        localStorage.setItem('columnVisibilityTermineStart', JSON.stringify(columnVisibilityTermineStart));
    }, [columnVisibilityTermineStart]);

    const handleColumnVisibilityChangeTermine = (updater) => {
        setcolumnVisibilityTermineStart(updater);
    };

    const defaultColumnWidthsTermine = 90;
    let storageItemTermine = localStorage.getItem('columnWidths');
    let columnWidthsFromLocalStorageTermine;

    if (storageItemTermine === "undefined" || !storageItemTermine) {
        columnWidthsFromLocalStorageTermine = defaultColumnWidthsTermine;
    } else {
        columnWidthsFromLocalStorageTermine = JSON.parse(storageItemTermine);
    }

    const [columnSizingStateTermine, setColumnSizingStateTermine] = useState(columnWidthsFromLocalStorageTermine);

    const handleColumnSizingChange = (newColumnSizingState) => {
        const newState = newColumnSizingState(columnSizingStateTermine);
        localStorage.setItem('columnWidths', JSON.stringify(newState));
        setColumnSizingStateTermine(newState);
    };

    const [contentHeight, setContentHeight] = useState(() => {
        const savedHeight = localStorage.getItem('contentHeightUserStart');
        return savedHeight ? parseInt(savedHeight) : 230;
    });

    const calculateContentHeightAndPageSize = () => {
        const windowHeight = window.innerHeight - 720;
        localStorage.setItem('contentHeightUserStart', windowHeight.toString());
        setContentHeight(windowHeight);
    };

    useEffect(() => {
        calculateContentHeightAndPageSize();
        window.addEventListener('resize', calculateContentHeightAndPageSize);
        return () => {
            window.removeEventListener('resize', calculateContentHeightAndPageSize);
        };
    }, []);

    const handleConfirmDialogDelete = async () => {
      const deletePromises = tableInstance.getSelectedRowModel().flatRows.map((row) => {
          return fetch(`${fetchdataUrl}fetchdata.php`, {
              method: 'POST',
              body: JSON.stringify({
                  access_token: sessionStorage.getItem("access_token"),
                  fetchDeleteTermin: 1,
                  TerminID: row.getValue('ID')
              }),
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then((response) => {
              return response.json();
          });
      });
    
      // Wait for all delete operations to complete
      await Promise.all(deletePromises);
      setShowDialog(false);
      setrefreshTermine(!refreshTermine);
    };

    const handleExportasExcel = () => {
      // Extract the data from the table
      const data = tableInstance.getFilteredRowModel().rows.map(row => row.original)
        .sort((a, b) => new Date(a.TerminDateText) - new Date(b.TerminDateText))
        .map(row => {
          return columns.slice(2).map(col => {
            let cell = row[col.accessor || col.id];
            if (col.id === "TerminDateText" && cell) {  
              let date = new Date(cell);
              let options = {year: 'numeric', month: '2-digit', day: '2-digit' };
              cell = date.toLocaleDateString('de-DE', options);
            }
            return cell;
          });
      });

      // Add the headers to the data
      const headers = ['Datum', 'Titel', 'Terminart', 'Vorname', 'Nachname', 'Bearbeiter'];  
      data.unshift(headers);

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Set the width of the second column
      worksheet['!cols'] = [
          { wch: 10 },  
          { wch: 25 }, 
          { wch: 8 },  
          { wch: 8 },  
          { wch: 10 },  
      ];
     
      // Create a new workbook and add the worksheet to it
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabelle 1');

      // Write the workbook to an Excel file
      XLSX.writeFile(workbook, 'Exportierte Termine.xlsx');
    }

    const [showonlyownTermine, setshowonlyownTermine] = useState(localStorage.getItem('setshowonlyownTermine') === 'true');
    const handleShowonlymyTermine = (event) => {
        const isChecked = event.target.checked;
        setshowonlyownTermine(isChecked);
        localStorage.setItem('setshowonlyownTermine', isChecked);
    };

    const [showonlySchäden, setshowonlySchäden] = useState(localStorage.getItem('setshowonlySchäden') === 'true');
    const handleShowonlySchäden = (event) => {
        const isChecked = event.target.checked;
        setshowonlySchäden(isChecked);
        localStorage.setItem('setshowonlySchäden', isChecked);
    };

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });

    const {
        data: { data = [], meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            dateRange,
            showonlyownTermine,
            showonlySchäden,
            refreshTermine,
        ],
        queryFn: async () => {
            const startDate = dateRange[0].startDate ? moment(dateRange[0].startDate).format('YYYY-MM-DD') : null;
            const endDate = dateRange[0].endDate ? moment(dateRange[0].endDate).format('YYYY-MM-DD') : null;

            const requestBody = {
                access_token: sessionStorage.getItem("access_token"),
                fetchAlleTermineServerSide: 1,
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters,
                globalFilter: globalFilter,
                sorting: sorting,
                dateRangeStart: startDate,
                dateRangeEnd: endDate,
                showonlyeigeneTermine: showonlyownTermine,
                showonlyschaeden: showonlySchäden,
                vollstaendigerName: user.vollstaendigerName
            };

            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const json = await response.json();
            return json;
        },
        placeholderData: keepPreviousData,
    });

    const columns = useMemo(() => [
        {
            accessorKey: 'Überfälligkeit',
            header: '',
            minSize: 35,
            maxSize: 35,
            size: 35,
            Cell: ({ row }) => {
                if (row.original.TerminDateText) {
                    const dateTermin = row.original.TerminDateText.substring(0, 10).split("-");
                    const DateNowArray = moment().format('DD.MM.yyyy').split(".");
                    const DateAk = new Date(dateTermin[0], dateTermin[1], dateTermin[2]);
                    const DateNow = new Date(DateNowArray[2], DateNowArray[1], DateNowArray[0]);
                    const daysBetween = (DateNow - DateAk) / (1000 * 3600 * 24);

                    if (daysBetween > 3) {
                        return (
                            <div style={{ textAlign: 'center' }}>
                                <Stack direction="row">
                                    <Tooltip title="Überfälliger Termin (älter als 3 Tage)">
                                        <IconButton size='small' style={{ color: '#f14668', padding: 0 }}>
                                            <WarningAmberOutlined />
                                        </IconButton>
                                    </Tooltip>
                                    {row.original.TerminArt === "Schaden" && (
                                        <Tooltip title="Schadenfall">
                                            <IconButton size='small' style={{ color: '#f14668', padding: 0 }}>
                                                <CarCrashRounded />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Stack>
                            </div>
                        );
                    } else if (daysBetween === 0) {
                        return (
                            <div style={{ textAlign: 'center' }}>
                                <Tooltip title="Tagesaktueller Termin">
                                    <IconButton size='small' style={{ color: '#2e3192', padding: 0 }}>
                                        <EventAvailableRounded />
                                    </IconButton>
                                </Tooltip>
                                {row.original.TerminArt === "Schaden" && (
                                    <Tooltip title="Schadenfall">
                                        <IconButton size='small' style={{ color: '#f14668', padding: 0 }}>
                                            <CarCrashRounded />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ textAlign: 'center' }}>
                                {row.original.TerminArt === "Schaden" && (
                                    <Tooltip title="Schadenfall">
                                        <IconButton size='small' style={{ color: '#f14668', padding: 0 }}>
                                            <CarCrashRounded />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        );
                    }
                } else {
                    return null;
                }
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableResizing: false,
        },
        { accessorKey: 'ID', header: 'ID' },
        {
            header: 'Datum',
            accessorKey: 'TerminDateText',
            enableResizing: false,
            minSize: 80,
            maxSize: 90,
            size: 80,
            Cell: ({ cell }) => {
                const newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY");
                return <span>{newdate}</span>;
            },
        },
        {
            header: 'Thema',
            accessorKey: 'TerminTitleText',
            minSize: 90,
            size: 150,
        },
        {
            header: 'Terminart',
            accessorKey: 'TerminArt',
            minSize: 40,
            size: 90,
        },
        {
            header: 'Vorname',
            accessorKey: 'TerminVornameVN',
            minSize: 40,
            size: 90,
        },
        {
            header: 'Name',
            accessorKey: 'TerminNameVN',
            minSize: 40,
            size: 90,
        },
        {
            header: 'Bearbeiter',
            accessorKey: 'TerminBearbeiterText',
            minSize: 40,
            size: 90,
        },
    ], []);

    const customLocalization = {
        ...MRT_Localization_DE,
        noResultsFound: "Es wurden keine Termine gefunden", 
        noRecordsToDisplay: "Es wurden keine Termine gefunden" 
    };

    const tableInstance = useMaterialReactTable({
        columns,
        data,
        state: {
            columnSizing: columnSizingStateTermine,
            columnVisibility: columnVisibilityTermineStart,
            isLoading,
            isError,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
            showProgressBars: isRefetching,
        },
        initialState: {
            showGlobalFilter: false,
            density: 'compact',
            columnSizing: columnSizingStateTermine,
            columnVisibility: columnVisibilityTermineStart,
        },
        onColumnVisibilityChange: handleColumnVisibilityChangeTermine,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableRowSelection: enableRowSelection,
        enableStickyHeader: true,
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        layoutMode: 'grid',
        onColumnSizingChange: handleColumnSizingChange,
        manualFilters: true,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        rowCount: meta?.totalRowCount ?? 0,
        localization: customLocalization,
        muiTableContainerProps: ({ table }) => ({
            sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px'}
        }),        
        muiTableHeadRowProps: {
            sx: { justifyContent: "center" },
        },
        muiTableBodyCellProps: {
            style: { overflow: "hidden", fontSize: "13px" },
        },
        muiTableProps: {
            sx: { tableLayout: 'fixed' },
        },
        muiTablePaperProps: {
            elevation: 0,
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: enableRowSelection ? row.getToggleSelectedHandler() : undefined,
            sx: { cursor: 'pointer' },
            onDoubleClick: () => {
                if (row.original.TerminArt === "Schaden") {
                    fetch(`${fetchdataUrl}fetchdata.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            access_token: sessionStorage.getItem("access_token"),
                            fetchSucheSchadendatenbyID: 1,
                            ID: row.original.ID
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (window.chrome && window.chrome.webview && isDesktop) {
                                const dataarray = ["OPENSchaden", data[0]];
                                window.chrome.webview.postMessage(dataarray);
                                dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
                                if (localStorage.getItem("setcheckboxEVTSchadenaufrufen") === "true") {
                                    navigateToCustomer(data[0].PNr)
                                }
                            }
                            else {
                                dispatch({ type: "ADD_VISITED_SCHADEN", payload: data[0] });
                                navigate('/dashboard/SchadenübersichtPage', { state: { data: data[0] } });
                            }
                        }).catch((error) => {
                            console.error('There was a problem with the fetch operation:', error);
                        });
                } else {
                    setopenModalTermineUpdate(true);
                    setRowID(row.original);
                }
            },
        }),
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
              <TextField
                size="small"
                onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
                spellCheck={false}
                placeholder="Suchen..."
                sx={{ minWidth: '300px' }}
                variant="outlined"
              />
            </Box>
          ),
        renderToolbarInternalActions: () => (
            <Box>
                <Tooltip title="Mehrere Reihen auswählen aktivieren/deaktivieren">
                    <IconButton
                        onClick={() => {
                            setEnableRowSelection(!enableRowSelection);
                        }}
                    >
                        <PlaylistAddCheckSharp />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Neuen Termin hinzufügen">
                    <IconButton
                        onClick={() => {
                            setopenModalTermine(true);
                        }}
                    >
                        <NoteAddSharp />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Datum auswählen">
                    <IconButton
                        onClick={(event) => {
                            setopenPopoverDatePicker(true);
                            setanchorPopoverDate(event.currentTarget);
                        }}>
                        <DateRangeSharp />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={openPopoverDatePicker}
                    anchorEl={anchorPopoverDate}
                    onClose={() => { setopenPopoverDatePicker(false) }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div>
                        <DateRangePickerCustom dateRange={dateRange} onDateRangeChange={handleDateRangeChange} />
                    </div>
                </Popover>
                <Tooltip title="Als Exceldatei exportieren">
                    <IconButton onClick={handleExportasExcel}>
                        <PrintSharp />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Ausgewählte Termine löschen">
                    <IconButton onClick={handleShowDialogDelete}>
                        <DeleteSharp />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Nur eigene Termine anzeigen">
                    <Checkbox onChange={handleShowonlymyTermine} checked={showonlyownTermine} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
                </Tooltip>
                <Tooltip title="Nur Schäden anzeigen">
                    <Checkbox onChange={handleShowonlySchäden} checked={showonlySchäden} size="small" sx={{ height: "35px", width: "35px" }} color="default" />
                </Tooltip>
                <MRT_ShowHideColumnsButton table={tableInstance} />
                <MRT_ToggleFiltersButton table={tableInstance} />
                <MRT_ToggleFullScreenButton table={tableInstance} />
                <ConfirmDialog
                    open={showDialog}
                    onClose={() => setShowDialog(false)}
                    message="Möchtest du den/die Termin(e) wirklich löschen?"
                    onConfirm={handleConfirmDialogDelete}
                    onCancel={handleHideDialogDelete}
                />
            </Box>
        )
    });

    return (
        <div>
            <MaterialReactTable table={tableInstance} />
            <TermineModal openModalTermine={openModalTermine} setopenModalTermine={setopenModalTermine} dataImported={null} handleRefetchData={reloadDataTermine} />
            <TermineModalUpdate openModalTermine={openModalTermineUpdate} setopenModalTermine={setopenModalTermineUpdate} handleRefetchData={reloadDataTermine} rowDATA={rowID} openfromVertrieb={false} rowDATAVertrieb={null}/>
        </div>
    );
};

const DashboardTermineTableServersideWithQueryProvider = () => (
    <QueryClientProvider client={queryClient}>
        <DashboardTermineTableServerside />
    </QueryClientProvider>
);

export default DashboardTermineTableServersideWithQueryProvider;
