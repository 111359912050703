import React, { useState, useEffect, useRef} from 'react';
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalSchaden} from '../hooks/reduxactions'
import PDFMerger from 'pdf-merger-js/browser';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import DateFnsAdapter from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { fetchdataUrl } from '../A-config/config';
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

import {
  Radio,
  RadioGroup,
  Switch,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select, 
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  Input, 
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Snackbar,
  Alert,
  LinearProgress
} from '@mui/material'

import {ArrowCircleUpSharp, ArrowCircleDownSharp, ExpandMoreSharp, PrintSharp, BookmarkSharp, SaveAltSharp, CloseSharp, SaveAsSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Co2Sharp, ConstructionOutlined, SaveSharp, ExpandMore} from '@mui/icons-material';

const style = {
    overflow: "hidden",
    overflowY: "auto",
    maxWidth: '980px',
    minWidth: "680px",
    position: 'absolute',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none !important',
    boxShadow: 24,
    p: 4,
    zIndex: 998,
};

const HighPriorityRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.error.main,
    opacity: '100%',
  },
  '&:not(.Mui-checked)': {
    opacity: '50%',
  },
}));

const LowPriorityRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
    opacity: '100%',
  },
  '&:not(.Mui-checked)': {
    opacity: '50%',
  },
}));

export default function AddSchadenModal({Kundendaten, refetchdataSchäden}) {
    const { updateUserStats } = useUpdateUserStats();
    const isModalSchadenOpen = useSelector(state => state.isModalSchadenOpen);
    const [konfigurationsModusAktiv, setkonfigurationsModusAktiv] = useState(false);
    const [anchorElSchadenArtHinzufügen, setanchorElSchadenArtHinzufügen] = useState(null);
    const [SchadenArtPflichtFeld, setSchadenArtPflichtFeld] = useState(false);
    const [refetchdata, setrefetchdata] = useState(false);
    const SchadenArthinzufügenRef = useRef();
    const openanchorELSchadenArt = Boolean(anchorElSchadenArtHinzufügen);
    const [selectedValuePriority, setselectedValuePriority] = useState("LOW");
    const [selectedSchadenart, setSelectedSchadenart] = useState('');
    const [optionsSchadenart, setoptionsSchadenart] = useState([]);
    const [optionsBearbeiter, setoptionsBearbeiter] = useState([]);
    const [alleUser, setalleUser] = useState([]);
    const [OrgaundName, setOrgaundName] = useState([]);
    const navigate = useNavigate();

    const SchadenArtSelectRef = useRef();    
    const BearbeiterSelectRef = useRef(); 
    const TextFieldASTInputref = useRef(); 

    const dispatch = useDispatch();
    const {user, hasPermission} = useAuth();

    const { control, handleSubmit, setError, clearErrors, reset, trigger, setValue, watch} = useForm({
        reValidateMode: "onChange",
    });

    const isValidDate = (dateString) => {
        const date = moment(dateString, "YYYY-MM-DD", true);
        const isValidFormat = date.isValid();
        const isYearAfter1900 = date.year() >= 1900;
        return isValidFormat && isYearAfter1900;
    };

    const isValidDateWV = (dateString) => {
        if (!dateString)
        {
            return true;
        }
        else
        {
            const date = moment(dateString, "YYYY-MM-DD", true);
            const isValidFormat = date.isValid();
            const isYearAfter1900 = date.year() >= 1900;
            return isValidFormat && isYearAfter1900;
        }
    };

    const handleRadioPriorityChange = (event) => {
        setselectedValuePriority(event.target.value);
    };

    const handleKonfigurationsModusToggle = () => {
        setkonfigurationsModusAktiv((prevValue) => !prevValue);
    };
    
    const handleChangePflichtFeldCheckbox = () => {
        setSchadenArtPflichtFeld((prevValue) => !prevValue);
    };

    const handleCloseSchadenModal = () => {
        dispatch(toggleModalSchaden(false));  
    }

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "error"
    });

    const handleSnackbarClose = () => {
        setSnackbar({
        open: false,
        message: "",
        severity: "error"
        });
    }
    
    const handleSchadenArthinzufügen = (event) => {
        setanchorElSchadenArtHinzufügen(event.currentTarget);
    };

    const handleCloseSchadenArthinzufügen = () => {
        setanchorElSchadenArtHinzufügen(null);
    };

    const handleClickFeldSchadenArtPlus = (e) => {
       if (SchadenArthinzufügenRef.current.value != "" && SchadenArthinzufügenRef.current.value != "") 
        {
            //Terminfeld in Datenbank speichern           
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddSchadenArt: 1,
                Schadenart: SchadenArthinzufügenRef.current.value,
                Pflichtfeld: SchadenArtPflichtFeld,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setrefetchdata(!refetchdata);
            });
        }
        else
        {
            console.log("Terminart auswählen ... fehlt")
        }
    }  

    const handleClickFeldSchadenArtLöschen = (e) => {

        if (!SchadenArtSelectRef.current.value === "" || SchadenArtSelectRef.current.value === "Bitte auswählen") 
        {
            setSnackbar({
                    open: true,
                        message: (
                            <div>
                                <span>Es wurde keine Schadenart ausgewählt.</span>
                            </div>
                        ),
                        severity: "error"
                    });
        } else {
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchDeleteSchadenArt: 1,
                Schadenart: SchadenArtSelectRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setrefetchdata(!refetchdata); 
            });
        } 
    }

    function convertToRtf(plain) {
        let rtf = "{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang2057{\\fonttbl{\\f0\\fnil\\fcharset0 Segoe UI;}}\n\\viewkind4\\uc1\\pard\\f0\\fs18 ";
        for (let i = 0; i < plain.length; i++) {
            let char = plain.charAt(i);
            if (char === '\n') {
                rtf += "\\par\n";
            } else if (char.match(/[a-zA-Z0-9\s,.]/)) {
                rtf += char;
            } else {
                rtf += `\\u${char.charCodeAt(0)}?`;
            }
        }
        rtf += "\\par\n}";
        return rtf;
    }

    const handleOnSubmit = (mybasedataForm, e) => {
        
        const GruppeSchadenbearbeiter = alleUser.filter(user => 
            user.berechtigungen && user.berechtigungen.includes('Schadenbearbeitergruppe') && user.altaccount === 'no'
        )
        const emailSchadenbearbeiter = GruppeSchadenbearbeiter.map(user => user.email);

        if (mybasedataForm.selectBearbeiter === "Schadenbearbeiter_Gruppe")
        {
            if (emailSchadenbearbeiter.length === 0)
            {
                setSnackbar({
                    open: true,
                    message: "Es wurde keinem Nutzer die Berechtigung -Schadenbearbeitergruppe- vergeben. Bitte wende dich an einen Administrator.",
                    severity: "error"
                    });
                return;
            }
        }

        let counterrors = 0;
        
        // Remove any leading or trailing whitespace
        const trimmedSchadennummer = mybasedataForm.Schadennummer.trim();

        // Check if the trimmed value differs from the original (indicating whitespace at the beginning or end)
        if (trimmedSchadennummer !== mybasedataForm.Schadennummer) {
            setSnackbar({
            open: true,
            message: "Es darf kein Leerzeichen vor oder nach der Schadennummer geben.",
            severity: "error"
            });
            return;
        }

        // Check if Schadennummer starts or ends with a period
        if (trimmedSchadennummer.startsWith('.') || trimmedSchadennummer.endsWith('.')) {
            setSnackbar({
            open: true,
            message: "Es darf kein \".\" vor oder nach der Schadennummer geben.",
            severity: "error"
            });
            return;
        }

        // Check if Schadennummer contains other invalid characters
        const invalidChars = /[<>:"/\\|?*]/;
        if (invalidChars.test(trimmedSchadennummer)) {
            setSnackbar({
            open: true,
            message: "Die Schadennummer enthält ungültige Zeichen. Bitte verwende keine der folgenden Zeichen: < > : \" / \\ | ? *.",
            severity: "error"
            });
            return;
        }

        if (mybasedataForm.selectBearbeiter === "Bitte auswählen")
        {
            setError('selectBearbeiter', { type: 'required'});  
            counterrors++;                                          
        }
        if (mybasedataForm.selectRegulierungsArt === "Bitte auswählen")
        {
            setError('selectRegulierungsArt', { type: 'required'});  
            counterrors++;                                                                             
        }
        if (mybasedataForm.selectStatus === "Bitte auswählen")
        {
            setError('selectStatus', { type: 'required'});                                            
            counterrors++;                                                                             
        }
        if (mybasedataForm.selectSchadenArt === "Bitte auswählen")
        {
            setError('selectSchadenArt', { type: 'required'});                                            
            counterrors++;                                                                             
        }

        if (!isValidDate(mybasedataForm.WVDatum) && mybasedataForm.WVDatum !== "" && mybasedataForm.WVDatum !== null && mybasedataForm.WVDatum !== undefined && mybasedataForm.WVDatum !== "0000-00-00")
        {
            setError('WVDatum', { type: 'required'});                                            
            counterrors++;                                                                             
        }

        if (!isValidDate(mybasedataForm.SchadenDatum) && mybasedataForm.SchadenDatum !== "" && mybasedataForm.SchadenDatum !== null && mybasedataForm.SchadenDatum !== undefined && mybasedataForm.SchadenDatum !== "0000-00-00")
        {
            setError('SchadenDatum', { type: 'required'});                                            
            counterrors++;                                                                             
        }

        if (counterrors === 0)
        {
                // Convert WVDatum to YYYY-MM-DD
                if (mybasedataForm.WVDatum) {
                    let WVDatum = moment(mybasedataForm.WVDatum);
                    mybasedataForm.WVDatum = WVDatum.format('YYYY-MM-DD');
                } else {
                    mybasedataForm.WVDatum = null;
                }

                // Convert SchadenDatum to YYYY-MM-DD
                if (mybasedataForm.SchadenDatum) {
                    let SchadenDatum = moment(mybasedataForm.SchadenDatum);
                    mybasedataForm.SchadenDatum = SchadenDatum.format('YYYY-MM-DD');
                } 

                //Check if Schadennummer exists
                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        checkIfSchadennummerExistsWithoutID: 1,    
                        Schadennummer: mybasedataForm.Schadennummer,
                        PNr: Kundendaten.PNr,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    }).then((response) => {
                        return response.json();
                    }).then((data1) => {  
                        if (data1 === "EntryDoesNotExist")           
                        {
                                const dataToSend = { ...mybasedataForm };
                                dataToSend.Bemerkungen = convertToRtf(dataToSend.Bemerkungen);
                                const KundendatenString = JSON.stringify(Kundendaten, null, 2);
                                updateUserStats(user.vollstaendigerName, "update", "Schaden", "Neuer Schaden angelegt", Kundendaten.PNr, "");

                                fetch(`${fetchdataUrl}fetchdata.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    access_token: sessionStorage.getItem("access_token"),
                                    addNeuerSchaden: 1,                                        
                                    data: dataToSend,
                                    dataKunde: Kundendaten,                                 
                                    Priority: selectedValuePriority,
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                                }).then((response) => {
                                    return response.json();
                                }).then((data3) => { 
                                    fetch(`${fetchdataUrl}fetchdata.php`, {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        access_token: sessionStorage.getItem("access_token"),
                                                        fetchSucheSchadendatenbyID: 1,
                                                        ID: data3
                                                    }),
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                    })
                                                    .then(response => response.json())
                                                    .then(data4 => {
                                                        // handleCloseSchadenModal();
                                                        if (window.chrome && window.chrome.webview && isDesktop) {
                                                            const dataarray = ["OPENSchaden", data4[0]];
                                                            window.chrome.webview.postMessage(dataarray);
                                                            dispatch({ type: "ADD_VISITED_SCHADEN", payload: data4[0] });                             
                                                        }             
                                                        refetchdataSchäden();     
                                                         //Sende Mail an den Betreuer:
                                                        if (mybasedataForm.selectSchadenArt === "KV Belege")
                                                        {
                                                            return;
                                                        }

                                                        const storedData = localStorage.getItem('firmenInfos');
                                                        const firmenInfos = JSON.parse(storedData);

                                                        const selectedUser = optionsBearbeiter.find(user => user.vollstaendigerName === mybasedataForm.selectBetreuer);
                                                        const userEmail = selectedUser ? selectedUser.email : firmenInfos.emailausweich;

                                                        let SchadenDatum = moment(mybasedataForm.SchadenDatum);
                                                        const Schadendaten = {
                                                                Name: Kundendaten.Nachname,
                                                                Vorname: Kundendaten.Vorname,
                                                                PLZOrt: Kundendaten.PLZOrt,
                                                                Schadennummer: mybasedataForm.Schadennummer,
                                                                Schadenart: mybasedataForm.selectSchadenArt,
                                                                ASTFreitext: mybasedataForm.ASTFreitext,
                                                                Schadendatum: SchadenDatum.format('DD.MM.YYYY'),
                                                                Bearbeiter: mybasedataForm.selectBearbeiter,
                                                                Status: mybasedataForm.selectStatus,
                                                                Regulierungsart: mybasedataForm.selectRegulierungsArt,
                                                                Bemerkungen: mybasedataForm.Bemerkungen,
                                                                User: user.vollstaendigerName
                                                        };

                                                        fetch(`${fetchdataUrl}sendemail.php`, {
                                                        method: 'POST',
                                                        body: JSON.stringify({
                                                            access_token: sessionStorage.getItem("access_token"),
                                                            sendSchadenerstellt: 1,
                                                            An: userEmail,
                                                            Art: "Neuer Schaden",
                                                            Schadendaten: Schadendaten,
                                                        }),
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        }
                                                        }).then((response) => {
                                                        }) 

                                                        //Send Additional Mail to Schadenbearbeitergruppe
                                                        if (mybasedataForm.selectBearbeiter === "Schadenbearbeiter_Gruppe")
                                                        {
                                                            fetch(`${fetchdataUrl}sendemail.php`, {
                                                            method: 'POST',
                                                            body: JSON.stringify({
                                                                access_token: sessionStorage.getItem("access_token"),
                                                                sendSchadenGruppeerstellt: 1,
                                                                An: emailSchadenbearbeiter,
                                                                Art: "Neuer Schaden an Schadenbearbeitergruppe",
                                                                Schadendaten: Schadendaten,
                                                            }),
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            }
                                                            }).then((response) => {
                                                            }) 
                                                        }

                                                    }).catch((error) => {
                                                        console.error('There was a problem with the fetch operation:', error);          
                                    });            
                            });  
                        }  else
                        {
                            setSnackbar({
                            open: true,
                                message: (
                                    <div>
                                        <span>Für diesen Kunden besteht bereits ein Schaden mit der Schadennummer <b>{mybasedataForm.Schadennummer}</b>.</span>
                                        <span style={{display: 'block'}}>Bitte wähle eine andere Schadennummer.</span>
                                    </div>
                                ),
                                severity: "error"
                            });
                        } 
                });            
        }       
    }

    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
                fetchAlleUser: 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json()).then((data) => {
            setalleUser(data);
        })
    },[]);

    useEffect(() => {
        Promise.all([
            //Fetch SchadenArten
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchLoadSchadenArten: 1
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json()).then((data) => {
                setoptionsSchadenart(data)
            }),   

            //Fetch Bearbeiter
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchLoadBearbeiter: 1
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json()).then((data) => {
                setoptionsBearbeiter(data);
            }), 
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                  access_token: sessionStorage.getItem("access_token"),
                    fetchAlleUser: 1
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json()).then((data) => {
                setalleUser(data);
            }),  
        ])        
    },[refetchdata]);

    useEffect(() => {   
            if(optionsSchadenart && optionsBearbeiter && Kundendaten) {
                let betreuerValue = "Bitte auswählen";
                if (Kundendaten.Betreuer !== "")
                {
                    const matchingBetreuer = optionsBearbeiter.find(betreuer => betreuer.Organummer === Kundendaten.Betreuer);                
                    betreuerValue = matchingBetreuer ? matchingBetreuer.vollstaendigerName : 'Bitte auswählen';
                }

                reset({
                        selectBetreuer: betreuerValue,
                        selectBearbeiter: optionsBearbeiter.map(item => item.vollstaendigerName).includes(user.vollstaendigerName) ? user.vollstaendigerName : 'Bitte auswählen',
                        selectStatus: "OFFEN"
            });
            //Trigger all Required Fields on Mount
            trigger(); 
        }
    }, [optionsSchadenart, optionsBearbeiter]); 

    useEffect(() => {  
        reset();                                         
    }, [isModalSchadenOpen]); 
    
    return (
        <div>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
            </Alert>
        </Snackbar>
        <Modal
                        open={isModalSchadenOpen}
                        onClose={handleCloseSchadenModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                    <Fade in={isModalSchadenOpen}>                     
                        <Box sx={style}>                                                 
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                                <span><b>Neuen Schaden anlegen</b></span>                        
                            </div>
                            {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') || hasPermission(user, 'Schadenbearbeiter') ?
                            <div style={{ display: "flex", alignItems: "center" }}>
                                { konfigurationsModusAktiv ?
                                <div>                                    
                                <Tooltip title='Neue Schadenart hinzufügen'>
                                    <IconButton onClick={handleSchadenArthinzufügen} fontSize='small' color="primary">
                                    <NoteAdd />
                                    </IconButton>
                                </Tooltip>

                                <Popover
                                    open={openanchorELSchadenArt}
                                    anchorEl={anchorElSchadenArtHinzufügen}
                                    onClose={handleCloseSchadenArthinzufügen}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <Stack direction="row" spacing={1} sx={{margin: 1}}>
                                    <TextField placeholder='Schadenart hinzufügen...' size='small' inputRef={SchadenArthinzufügenRef}></TextField>
                                        <FormControlLabel control={<Checkbox
                                        onChange={handleChangePflichtFeldCheckbox}
                                         />} label="Pflichtfeld" />
                                        <IconButton onClick={handleClickFeldSchadenArtPlus} color="primary">
                                    <AddCircle />
                                    </IconButton>                             
                                    </Stack>
                                </Popover>

                                <Tooltip title='Ausgewählte Schadenart löschen'>
                                <IconButton sx={{ml: 0}} onClick={handleClickFeldSchadenArtLöschen} fontSize='small' color="error">
                                <Delete />
                                </IconButton>
                                </Tooltip>     
                                </div> : null }                                
                                <Tooltip title="Konfigurationsmodus aktivieren">    
                                    <Switch checked={konfigurationsModusAktiv} onChange={handleKonfigurationsModusToggle} />
                                </Tooltip>                                           
                            </div> : null }        
                        </div>
                        <Divider sx={{mt: 1, mb: 2}}></Divider>   
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <span>
                            {Kundendaten.Titel}<br></br>
                            <b>{Kundendaten.Nachname}{Kundendaten.Vorname ? `, ${Kundendaten.Vorname}` : ''}</b>
                            <br></br>
                            {Kundendaten.Straße}
                            <br></br>
                            {Kundendaten.PLZOrt}
                            </span>  
                            <div style={{marginTop: "5px"}}>
                                    <span style={{color: "rgba(0, 0, 0, 0.6)", fontSize: "0.7rem", paddingLeft: "20px"}}>Priorität</span>
                                    <RadioGroup row>
                                        <Tooltip title="Priorität hoch">
                                            <HighPriorityRadio
                                                checked={selectedValuePriority === 'HIGH'}
                                                onChange={handleRadioPriorityChange}
                                                value="HIGH"
                                                icon={<ArrowCircleUpSharp />}
                                                checkedIcon={<ArrowCircleUpSharp />}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Priorität gering">
                                        <LowPriorityRadio
                                            checked={selectedValuePriority === 'LOW'}
                                            onChange={handleRadioPriorityChange}
                                            value="LOW"
                                            icon={<ArrowCircleDownSharp />}
                                            checkedIcon={<ArrowCircleDownSharp />}
                                        />
                                        </Tooltip>
                                    </RadioGroup>  
                            </div> 
                        </div>

                        <Divider sx={{mt: 2, mb: 2}}></Divider>
                        <form id="DataSubmitForm" onSubmit={handleSubmit(handleOnSubmit)}>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller                                                              
                                            control={control}
                                                        name="Schadennummer"
                                                        defaultValue=""
                                                        rules={{ required: selectedSchadenart !== "KV Belege" }}
                                                        render={({ field, fieldState: { error } }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth 
                                                            disabled={selectedSchadenart === 'KV Belege'}                                         
                                                            sx={{
                                                            height: '24px',
                                                            '& .MuiInputLabel-root': {
                                                            fontSize: '0.9rem',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                            fontSize: '0.9rem',
                                                            },}}
                                                            InputLabelProps={{ shrink: true }}
                                                            size="small"
                                                            variant='standard'
                                                            autoComplete="off"
                                                            type="text"                                    
                                                            label="Schadennummer"
                                                            error={error !== undefined}
                                                            onChange={(e) => {
                                                                field.onChange(e)
                                                                if(e.target.value === "") {
                                                                    setError('Schadennummer', { type: 'required'});                                            
                                                                }                                          
                                                                else
                                                                {
                                                                clearErrors("Schadennummer");
                                                                }                                                
                                                            }}
                                                            />
                                                )}
                                />     
                            </Grid>
                            <Grid item xs={6}>
                                <Controller  
                                        control={control}
                                        name="selectSchadenArt"
                                        defaultValue="Bitte auswählen"
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>                                                
                                            <InputLabel sx={{fontSize: "0.9rem", left: "-15px", top: "7px"}}>Schadenart</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '250px',
                                                        },
                                                        },
                                                }}
                                                inputRef={SchadenArtSelectRef}
                                                fullWidth
                                                variant="standard"
                                                label="Schadenart"
                                                size='small'
                                                sx={{fontSize: "0.9rem"}}
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)     
                                                    if (e.target.value === "" || e.target.value === "Bitte auswählen" ) {
                                                        setError("selectSchadenArt", { type: "required" });
                                                    } else {
                                                        clearErrors("selectSchadenArt");
                                                    }

                                                    // Handling logic directly in onChange
                                                    setSelectedSchadenart(e.target.value);
                                                    const schadennummerCurrentValue = watch("Schadennummer");
                                                    const defaultValue = e.target.value === 'KV Belege' ? 'KV-S' : schadennummerCurrentValue;
                                                    const defaultValueStatus = e.target.value === 'KV Belege' ? 'ERLEDIGT' : 'OFFEN';
                                                    const defaultValueRegArt = e.target.value === 'KV Belege' ? 'Selbstregulierungsschaden' : 'Selbstregulierungsschaden';

                                                    setValue('Schadennummer', defaultValue);
                                                    setValue('selectStatus', defaultValueStatus);
                                                    setValue('selectRegulierungsArt', defaultValueRegArt);
                                                    trigger();
                                                }}
                                            >
                                            <MenuItem sx={{fontSize: "0.9rem"}} disabled value="Bitte auswählen">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>         
                                            {   
                                                ["KV Belege", ...Object.keys(optionsSchadenart)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((Schadenart) => (
                                                    <MenuItem 
                                                        sx={{fontSize: "0.9rem"}} key={Schadenart} value={Schadenart}>
                                                        {Schadenart}
                                                    </MenuItem>
                                                ))
                                            }
                                            </Select>
                                            </FormControl>
                                        )}
                                />    
                            </Grid>
                            <Grid item xs={6}>                           
                                    <Controller                                                              
                                                control={control}
                                                        name="ASTFreitext"
                                                        defaultValue=""
                                                        rules={{
                                                                required: optionsSchadenart[selectedSchadenart] ? optionsSchadenart[selectedSchadenart][0] : false
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                        <TextField 
                                                            {...field} 
                                                            fullWidth
                                                            disabled={selectedSchadenart === 'KV Belege'}                                        
                                                            InputLabelProps={{ shrink: true }}                                            
                                                            sx={{
                                                            height: '24px',
                                                            '& .MuiInputLabel-root': {
                                                            fontSize: '0.9rem',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                            fontSize: '0.9rem',
                                                            },}}
                                                            size="small"
                                                            variant='standard'
                                                            autoComplete="off"
                                                            type="text"                                    
                                                            label="AST/Freitext"
                                                            inputRef={TextFieldASTInputref}
                                                            error={error !== undefined}
                                                            onChange={(e) => {
                                                                field.onChange(e)
                                                                if(e.target.value === "" && optionsSchadenart[selectedSchadenart][0]) {
                                                                    setError('ASTFreitext', { type: 'required'});                                            
                                                                } else {
                                                                    clearErrors("ASTFreitext");
                                                                }
                                                            }}
                                                            />
                                                )}
                                />    
                            </Grid>
                            <Grid item xs={6}>                           
                                <Controller                                                              
                                    control={control}
                                            name="VSNummer"
                                            defaultValue=""
                                            rules={{
                                                required: false
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                            <TextField 
                                                {...field}  
                                                InputLabelProps={{ shrink: true }}   
                                                fullWidth  
                                                disabled={selectedSchadenart === 'KV Belege'}                                                  
                                                sx={{                                                            
                                                height: '24px',
                                                '& .MuiInputLabel-root': {
                                                fontSize: '0.9rem',
                                                },
                                                '& .MuiInputBase-input': {
                                                fontSize: '0.9rem',
                                                },}}
                                                size="small"
                                                variant='standard'
                                                autoComplete="off"
                                                type="text"                                    
                                                label="VS-Nummer"
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)                                               
                                                }}
                                                />
                                    )}
                                />               
                            </Grid>
                            <Grid item xs={6} sx={{mt: 2}}>
                             <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={de}>
                                    <Controller
                                        control={control}
                                        name="SchadenDatum"
                                        defaultValue=""
                                        rules={{ required: selectedSchadenart !== "KV Belege" }}
                                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <DesktopDatePicker
                                                value={value}
                                                format="dd.MM.yyyy"                                
                                                onChange={date => {
                                                    onChange(date);
                                                    if (date === null || !isValidDate(date)) {
                                                        setError('SchadenDatum', { type: 'required'});
                                                    } else {
                                                        clearErrors("SchadenDatum");
                                                    }
                                                }}
                                                renderInput={(props) => (
                                                    <TextField
                                                        {...props}
                                                        fullWidth
                                                        disabled={selectedSchadenart === 'KV Belege'}
                                                        label="Schadendatum"
                                                        sx={{ 
                                                            '& input': { fontSize: "0.9rem" }
                                                        }}
                                                        variant='standard'
                                                        size='small'
                                                        error={error !== undefined}                                     
                                                        InputLabelProps={{ shrink: true, sx: { fontSize: "0.9rem"} }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />      
                                </LocalizationProvider> 
                            </Grid>
                            <Grid item xs={6} sx={{mt: 2}}>
                                <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={de}> 
                                    <Controller
                                        control={control}
                                        name="WVDatum"
                                        defaultValue=""
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <DesktopDatePicker
                                                value={value}
                                                format="dd.MM.yyyy"                                
                                                onChange={date => {
                                                    onChange(date);
                                                    if (!isValidDateWV(date)) {
                                                        setError('WVDatum', { type: 'required'});
                                                    } else {
                                                        clearErrors("WVDatum");
                                                    }
                                                }}
                                                renderInput={(props) => (
                                                    <TextField
                                                        {...props}  
                                                        fullWidth                                      
                                                        label="Wiedervorlage"
                                                        sx={{ 
                                                            '& input': { fontSize: "0.9rem" }
                                                        }}
                                                        variant='standard'
                                                        size='small'
                                                        error={error !== undefined}
                                                        InputLabelProps={{ shrink: true, sx: { fontSize: "0.9rem"} }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </LocalizationProvider> 
                            </Grid>
                            <Grid item xs={6}>
                                <Controller  
                                            control={control}
                                            name="selectBearbeiter"
                                            defaultValue="Bitte auswählen"
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={error !== undefined}>                                                
                                                <InputLabel sx={{fontSize: "0.9rem", left: "-15px", top: "7px"}}>Bearbeiter</InputLabel>
                                                <Select 
                                                    {...field}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '250px',
                                                        },
                                                        },
                                                    }}
                                                    inputRef={BearbeiterSelectRef}
                                                    fullWidth
                                                    variant="standard"
                                                    label="Bearbeiter"
                                                    size='small'
                                                    sx={{fontSize: "0.9rem"}}
                                                    error={error !== undefined}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e.target.value === "" || e.target.value === "Bitte auswählen") {
                                                            setError("selectBearbeiter", { type: "required" });
                                                        } else {
                                                            clearErrors("selectBearbeiter");
                                                        }
                                                    }}
                                                >
                                                <MenuItem sx={{fontSize: "0.9rem"}} value="Bitte auswählen">
                                                <em>Bitte auswählen...</em>
                                                </MenuItem>
                                                <MenuItem sx={{fontSize: "0.9rem"}} key="Schadenbearbeiter" value="Schadenbearbeiter_Gruppe">
                                                    Schadenbearbeiter_Gruppe
                                                </MenuItem>
                                                {Array.isArray(optionsBearbeiter) && optionsBearbeiter.sort().map((Bearbeiter) => (
                                                <MenuItem sx={{fontSize: "0.9rem"}} key={Bearbeiter.vollstaendigerName} value={Bearbeiter.vollstaendigerName}>
                                                    {Bearbeiter.vollstaendigerName}
                                                </MenuItem>
                                                ))}
                                                </Select>
                                                </FormControl>
                                            )}
                                    />     
                            </Grid>
                            <Grid item xs={6}>
                                 <Controller  
                                            control={control}
                                            name="selectBetreuer"
                                            defaultValue="Bitte auswählen"
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={error !== undefined}>                                                
                                                <InputLabel sx={{fontSize: "0.9rem", left: "-15px", top: "7px"}}>Betreuer</InputLabel>
                                                <Select 
                                                    {...field}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '250px',
                                                        },
                                                        },
                                                    }}
                                                    inputRef={BearbeiterSelectRef}
                                                    fullWidth
                                                    variant="standard"
                                                    label="Betreuer"
                                                    size='small'
                                                    sx={{fontSize: "0.9rem"}}
                                                    error={error !== undefined}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e.target.value === "" || e.target.value === "Bitte auswählen") {
                                                            setError("selectBetreuer", { type: "required" });
                                                        } else {
                                                            clearErrors("selectBetreuer");
                                                        }
                                                    }}
                                                >
                                                <MenuItem sx={{fontSize: "0.9rem"}} value="Bitte auswählen">
                                                <em>Bitte auswählen...</em>
                                                </MenuItem>
                                                {Array.isArray(optionsBearbeiter) && optionsBearbeiter.sort().map((Bearbeiter) => (
                                                <MenuItem sx={{fontSize: "0.9rem"}} key={Bearbeiter.vollstaendigerName} value={Bearbeiter.vollstaendigerName}>
                                                    {Bearbeiter.vollstaendigerName}
                                                </MenuItem>
                                                ))}
                                                </Select>
                                                </FormControl>
                                            )}
                                    />    
                                  
                            </Grid>
                            <Grid item xs={6}>
                                   <Controller  
                                            control={control}
                                            name="selectStatus"
                                            defaultValue="Bitte auswählen"
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={error !== undefined}>                                                
                                                <InputLabel sx={{fontSize: "0.9rem", left: "-15px", top: "7px"}}>Status</InputLabel>
                                                <Select 
                                                    {...field}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '250px',
                                                        },
                                                        },
                                                    }}  
                                                    disabled={selectedSchadenart === 'KV Belege'} 
                                                    variant="standard"
                                                    label="Status"
                                                    size='small'
                                                    sx={{fontSize: "0.9rem"}}
                                                    error={error !== undefined}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e.target.value === "" || e.target.value === "Bitte auswählen") {
                                                            setError("selectStatus", { type: "required" });
                                                        } else {
                                                            clearErrors("selectStatus");
                                                        }
                                                    }}
                                                >
                                                    <MenuItem sx={{fontSize: "0.9rem"}} disabled value="Bitte auswählen">
                                                    <em>Bitte auswählen...</em>
                                                    </MenuItem>
                                                    <MenuItem sx={{fontSize: "0.9rem"}} value="OFFEN">
                                                    OFFEN
                                                    </MenuItem>                   
                                                    <MenuItem sx={{fontSize: "0.9rem"}} value="ERLEDIGT">
                                                    ERLEDIGT
                                                    </MenuItem>                               
                                                </Select>
                                                </FormControl>
                                            )}
                                    />   
                            </Grid>
                            <Grid item xs={6}>
                                <Controller  
                                            control={control}
                                            name="selectRegulierungsArt"
                                            defaultValue="Bitte auswählen"
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={error !== undefined}>                                                
                                                <InputLabel sx={{fontSize: "0.9rem", left: "-15px", top: "7px"}}>Regulierungsart</InputLabel>
                                                <Select 
                                                    {...field}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '250px',
                                                        },
                                                        },
                                                    }}   
                                                    variant="standard"
                                                    label="Regulierungsart"
                                                    disabled={selectedSchadenart === 'KV Belege'}
                                                    size='small'
                                                    sx={{fontSize: "0.9rem"}}
                                                    error={error !== undefined}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e.target.value === "") {
                                                            setError("selectRegulierungsArt", { type: "required" });
                                                        } else {
                                                            clearErrors("selectRegulierungsArt");
                                                        }
                                                    }}
                                                >
                                                    <MenuItem sx={{fontSize: "0.9rem"}} disabled value="Bitte auswählen">
                                                    <em>Bitte auswählen...</em>
                                                    </MenuItem>
                                                    <MenuItem sx={{fontSize: "0.9rem"}} value="Selbstregulierungsschaden">
                                                    Selbstregulierungsschaden
                                                    </MenuItem>                   
                                                    <MenuItem sx={{fontSize: "0.9rem"}} value="Meldeschaden">
                                                    Meldeschaden
                                                    </MenuItem>                               
                                                </Select>
                                                </FormControl>
                                            )}
                                    />       
                            </Grid>
                            <Grid item xs={12}>
                                 <Controller                                                              
                                    control={control}
                                            name="Bemerkungen"
                                            defaultValue=""
                                            rules={{
                                                required: false
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                            <TextField 
                                                {...field}  
                                                InputLabelProps={{ shrink: true }}   
                                                fullWidth 
                                                multiline
                                                rows={6} 
                                                sx={{                                                            
                                                '& .MuiInputLabel-root': {
                                                fontSize: '0.9rem',
                                                },
                                                '& .MuiInputBase-input': {
                                                fontSize: '0.9rem',
                                                },}}
                                                size="small"
                                                variant='standard'
                                                autoComplete="off"
                                                type="text"                                    
                                                label="Bemerkungen"
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)                                               
                                                }}
                                                 onDoubleClick={(e) => {
                                                    const formattedDate = moment().format('DD.MM.YYYY - HH:mm');
                                                    const newValue = field.value ? `${formattedDate + " " + user.vollstaendigerName + ":"}\n${field.value}` : formattedDate + " " + user.vollstaendigerName + ":";
                                                    field.onChange({ target: { value: newValue } });
                                                }}
                                                />
                                    )}
                                />          
                            </Grid>
                            </Grid>
                            <Divider sx={{mt: 4, mb: 4}}></Divider>
                            <Stack direction="row" spacing={2}>
                                    <Button
                                        type='submit'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Schaden anlegen
                                    </Button>                                        

                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleCloseSchadenModal}
                                        >
                                        <Cancel fontSize='small' sx={{ mr: 1}}></Cancel>
                                        Abbrechen
                                    </Button>                                    
                            </Stack>       

                         </form>
                        </Box>                          
                    </Fade>
        </Modal>
        </div>                      
    )
}