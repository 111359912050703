import React, { useRef, useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import { Transforms, Editor, Range } from 'slate';
import { Card, Badge, TextField, Stack, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Link, Divider, Popover, ToggleButton, ToggleButtonGroup, IconButton, Portal, Button, Tooltip, Typography, LinearProgress } from '@mui/material';
import { Celebration, Favorite, SentimentNeutral, Email, PrintSharp, Star, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, ColorLens, FormatItalic, FormatBoldSharp, FormatUnderlinedSharp, CheckBox } from '@mui/icons-material'; 
import { fetchdataUrl } from '../A-config/config';
import moment from "moment";
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '../hooks/useNavigateFunction';
import { matchRoutes, useNavigate, useLocation } from "react-router-dom";

export const GeburtstagslistePage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [month, setMonth] = useState('currentmonth');
  const [onlyrundeanzeigen, setonlyrundeanzeigen] = useState(localStorage.getItem('setValueonlyrundeanzeigen') === 'true');
  const [agerange, setagerange] = useState(parseInt(localStorage.getItem('setValueRundeanzeigenAgeRange'), 10) || '');
  const [mindestbewertung, setMindestbewertung] = useState(parseInt(localStorage.getItem('setValueMindestbewertung'), 10) || '');
  const [foundbirthdays, setfoundbirthdays] = useState([]);
  const [isloading, setisloading] = useState(true);
  const todayRef = useRef(null);
  const pnrprocessed = useSelector((state) => state.pnrprocessed);
  const { navigateToCustomer } = useNavigation();

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightGeburtstagslistePage');
    return savedHeight ? parseInt(savedHeight) : 230;
  });

  const calculateContentHeightAndPageSize = () => {
      const windowHeight = window.innerHeight - 100;

      localStorage.setItem('contentHeightGeburtstagslistePage', windowHeight.toString());
      setContentHeight(windowHeight);
    };

    useEffect(() => {
      calculateContentHeightAndPageSize(); // Call on initial load

      // Set up event listener for resize
      window.addEventListener('resize', calculateContentHeightAndPageSize);

      // Clean-up function
      return () => {
        window.removeEventListener('resize', calculateContentHeightAndPageSize);
      };
  }, []);

  useEffect(() => { 
    setisloading(true)
    let start, end;
    if (month === 'currentmonth') {
      start = moment().startOf('month').format('YYYY-MM-DD');
      end = moment().endOf('month').format('YYYY-MM-DD');
    } else if (month === 'previousmonth') {
      start = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      end = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    } else if (month === 'nextmonth') {
      start = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
      end = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    }


    try 
    {
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem("access_token"),
          fetchalleGeburtstage: 1,
          start: start,
          end: end,
  
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        setfoundbirthdays(data)
        setisloading(false)
      });
    }
    catch (error) {
      console.error('Error loading data:', error);
    }
    
  }, [month, onlyrundeanzeigen, agerange, mindestbewertung]);

  const handleOpenKunde = (e) => {
    if (pnrprocessed !== e.PNr)
    {
      navigateToCustomer(e.PNr);
      dispatch({ type: "ADD_VISITED_CUSTOMER", payload: e });
    }
    else 
    {
      navigate(`/dashboard/UserKundenübersicht/${pnrprocessed}`);
    }
  }

  const handleSelectChange = (event) => {
    setMonth(event.target.value);
  };

  const handleRundeCheckboxchanged = (event) => {
    localStorage.setItem('setValueonlyrundeanzeigen', event.target.checked);
    setonlyrundeanzeigen(event.target.checked)
    if (event.target.checked === false)
    {
      localStorage.setItem('setValueRundeanzeigenAgeRange', 0);    
      setagerange(0);
    }
  };

  const handleTextfieldagechange = (event) => {
    const age = parseInt(event.target.value, 10) || 0;
    localStorage.setItem('setValueRundeanzeigenAgeRange', age);
    setagerange(age);
  };

  const handleTextfieldMindestbewertungchange = (event) => {
    const rating = parseInt(event.target.value, 10);
    if (rating >= 0 && rating <= 5) {
      localStorage.setItem('setValueMindestbewertung', rating);
      setMindestbewertung(rating);
    } else {
      setMindestbewertung(0);
    }
  };

  const handleOpenMailGeburtstagpersonal = (data) => {
    const storedData = localStorage.getItem('firmenInfos');
    const firmenInfos = JSON.parse(storedData);

    const recipient = data.EMail;
    const subject = `Herzlichen Glückwunsch zum ${calculateAge(data.gebdatum)}. Geburtstag`;
  
    const greeting = data.Titel === "Herr" ? `Lieber ${data.Vorname}` :
                     data.Titel === "Frau" ? `Liebe ${data.Vorname}` :
                     `Guten Tag ${data.Vorname}`;
  
    const body = `${greeting},\n\n${firmenInfos.emailvorlagep}`;
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.location.href = mailtoLink;
  };

  const handleOpenMailGeburtstagneutral = (data) => {
    const storedData = localStorage.getItem('firmenInfos');
    const firmenInfos = JSON.parse(storedData);

    const recipient = data.EMail;
    const subject = `Herzlichen Glückwunsch zum ${calculateAge(data.gebdatum)}. Geburtstag`;
  
    const greeting = data.Titel === "Herr" ? `Sehr geehrter Herr ${data.Nachname}` :
                     data.Titel === "Frau" ? `Sehr geehrte Frau ${data.Nachname}` :
                     `Guten Tag`;
  
    const body = `${greeting},\n\n${firmenInfos.emailvorlagen}`;
  
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.location.href = mailtoLink;
  };  

  const handleExportasExcel = () => {
    const data = [...past, ...todayList, ...upcoming].map(row => {
      return [
        row.gebdatum,
        calculateAge(row.gebdatum),
        row.Vorname,
        row.Nachname,
        row.Bewertung,
        row.Straße,
        row.PLZOrt,
        row.EMail,
        row.Handy, 
        row.Festnetz,
        row.Betreuer
      ];
    });

    // Add headers
    const headers = ['Geburtsdatum', 'Alter', 'Vorname', 'Nachname', 'Bewertung', 'Straße', 'PLZOrt', 'EMail', 'Handy', 'Festnetz', 'Betreuer'];
    data.unshift(headers);

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet['!cols'] = [
      { wch: 15 },  
      { wch: 15 },  
      { wch: 15 },  
      { wch: 15 },  
      { wch: 10 },  
      { wch: 20 },  
      { wch: 15 },
      { wch: 20 },  
      { wch: 20 }, 
      { wch: 20 },  
      { wch: 20 },  
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Geburtstagsliste');

    // Export file
    XLSX.writeFile(workbook, 'Geburtstagsliste.xlsx');
  };
  

  const calculateAge = (gebdatum) => {
    const birthDate = moment(gebdatum, 'DD.MM.YYYY');
    const endOfYear = moment().endOf('year');
    return endOfYear.diff(birthDate, 'years');
  };

  const renderStars = (bewertung) => {
    if (bewertung == null || bewertung === 0) {
      return null;
    }
    return [...Array(bewertung)].map((_, index) => (
      <Star key={index} sx={{ fontSize: '12px', color: '#ff6d75' }} />
    ));
  };

  const sortBirthdaysByDate = (birthdays) => {
    return birthdays.sort((a, b) => {
      const dateA = moment(a.gebdatum, 'DD.MM.');
      const dateB = moment(b.gebdatum, 'DD.MM.');
      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    });
  };

  const filterBirthdays = (birthdays) => {
    return birthdays.filter(data => {
      const age = calculateAge(data.gebdatum);
      const rating = data.Bewertung || 0;

      if (rating < mindestbewertung) {
        return false;
      }

      if (onlyrundeanzeigen) {
        if (age % 10 !== 0 || age < agerange) {
          return false;
        }
      } else {
        // Check for agerange if onlyrundeanzeigen is false
        if (age < agerange) {
          return false;
        }
      }
      return true;
    });
  };


   const categorizeBirthdays = (birthdays) => {
    const today = moment();
    const past = [];
    const todayList = [];
    const upcoming = [];

    birthdays.forEach(data => {
      const birthDate = moment(data.gebdatum, 'DD.MM.YYYY').year(today.year());
      if (birthDate.isBefore(today, 'day')) {
        past.push(data);
      } else if (birthDate.isSame(today, 'day')) {
        todayList.push(data);
      } else {
        upcoming.push(data);
      }
    });

    return { past: sortBirthdaysByDate(past), todayList: sortBirthdaysByDate(todayList), upcoming: sortBirthdaysByDate(upcoming) };
  };

  const categorizedBirthdays = categorizeBirthdays(filterBirthdays(foundbirthdays));
  const { past, todayList, upcoming } = categorizedBirthdays;

  useEffect(() => {
      setTimeout(() => {
        if (todayRef.current) {
          todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);   
  }, [foundbirthdays, month, onlyrundeanzeigen, agerange, mindestbewertung]);

  return (
    <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
    <div style={{minWidth: "500px", display: "flex", flexDirection: "column", height: contentHeight}}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography sx={{ fontSize: "14px", marginLeft: "10px", fontWeight: "bold" }}>Geburtstagsliste</Typography>   
                          <Tooltip title="Als Exceldatei exportieren">
                            <IconButton onClick={handleExportasExcel}>
                              <PrintSharp />
                            </IconButton>
                          </Tooltip>  
                        </div>
                          <Divider></Divider>
                          <Stack sx={{marginTop: "10px"}} direction="row" alignItems="center" spacing={2}>
                            <Tooltip title="Nur runde Geburtstage anzeigen">
                              <Checkbox checked={onlyrundeanzeigen} onChange={handleRundeCheckboxchanged} size="small" />
                            </Tooltip>
                            {/* <Tooltip title="Nur runde Geburtstage ab 50 anzeigen">
                              <Checkbox onChange={handleRundeab50Checkboxchanged} size="small" />
                            </Tooltip> */}
                            <FormControl variant="standard">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <TextField sx={{width: "120px"}} focused value={agerange} onChange={handleTextfieldagechange} size="small" label="ab Alter..." variant="standard" />
                                <TextField sx={{width: "120px"}} focused value={mindestbewertung} onChange={handleTextfieldMindestbewertungchange} size="small" label="Mindestbewertung *" variant="standard" />
                                <Select
                                sx={{height: "45px"}}
                                variant='standard'
                                  value={month}
                                  onChange={handleSelectChange}
                                >
                                  <MenuItem value="previousmonth">Vorheriger Monat</MenuItem>
                                  <MenuItem value="currentmonth">Aktueller Monat</MenuItem>
                                  <MenuItem value="nextmonth">Nächster Monat</MenuItem>
                                </Select>
                              </Stack>
                            </FormControl>
                          </Stack>
                          <Divider sx={{marginBottom: "5px", marginTop: "10px"}}></Divider>
                          { isloading ? <LinearProgress></LinearProgress> :
                          <div style={{ overflowY: "auto", marginBottom: "15px" }}>
                          {past.length > 0 && (
                          <>
                            {past.map(data => (
                              <div
                                key={data.ID}
                                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px", paddingTop: "5px" }}
                              >
                                <div>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Link onClick={() => handleOpenKunde(data)} sx={{ fontSize: "14px", cursor: "pointer", fontWeight: "bold" }}>
                                    {data.Vorname} {data.Nachname} (<b>{calculateAge(data.gebdatum)} Jahre</b>)
                                  </Link>
                                    {data.Bewertung > 0 && (
                                    <>
                                      <Typography sx={{ mx: 1 }}>-</Typography>
                                      <div>{renderStars(data.Bewertung)}</div>
                                    </>
                                  )}                                  
                                   {(() => {
                                        return data.EMail && data.EMail !== "Keine E-Mail vorhanden" && data.EMail !== null ? (
                                          <Stack direction="row">
                                          <Tooltip title="Persönliche Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0, paddingRight: "5px"}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagpersonal(data)}>
                                            <Badge badgeContent={<Favorite sx={{height: "12px", width: "12px"}}></Favorite>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Neutrale Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagneutral(data)}>
                                            <Badge badgeContent={<SentimentNeutral sx={{height: "12px", width: "12px"}}></SentimentNeutral>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>                                          
                                          </Stack>                                          
                                        ) : null;
                                      })()}
                                </Stack>                
                                  <div style={{ fontSize: "14px" }}>
                                    geb. <b>{data.gebdatum}</b> - {data.Straße}, {data.PLZOrt}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {todayList.length > 0 && (
                          <>                          
                            <Divider sx={{marginTop: "20px"}}></Divider>
                            <Stack sx={{justifyContent: "center", backgroundColor: "#2e3192"}} direction="row">
                              <KeyboardDoubleArrowUp sx={{color: "white"}}></KeyboardDoubleArrowUp>
                              <Typography sx={{color: "white"}}>vergangene Geburtstage</Typography>
                              <KeyboardDoubleArrowUp sx={{color: "white"}}></KeyboardDoubleArrowUp>
                            </Stack>
                            <Divider sx={{marginBottom: "10px"}}></Divider>

                            <Divider sx={{marginTop: "5px"}}></Divider>
                            <Stack ref={todayRef} sx={{justifyContent: "center", backgroundColor: "#2e3192"}} direction="row">
                              <Celebration sx={{color: "white"}}></Celebration>
                              <Typography sx={{color: "white", paddingLeft: "5px", paddingRight: "5px"}}>heutige Geburtstage</Typography>
                              <Celebration sx={{color: "white"}}></Celebration>
                            </Stack>
                            <Divider sx={{marginBottom: "10px"}}></Divider>
                            {todayList.map(data => (
                              <div                               
                                key={data.ID}
                                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px", paddingTop: "5px" }}
                              >
                                 <div>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Link onClick={() => handleOpenKunde(data)} sx={{ fontSize: "14px", cursor: "pointer", fontWeight: "bold" }}>
                                    {data.Vorname} {data.Nachname} (<b>{calculateAge(data.gebdatum)} Jahre</b>)
                                  </Link>
                                    {data.Bewertung > 0 && (
                                    <>
                                      <Typography sx={{ mx: 1 }}>-</Typography>
                                      <div>{renderStars(data.Bewertung)}</div>
                                    </>
                                  )}
                                      {(() => {
                                        return data.EMail && data.EMail !== "Keine E-Mail vorhanden" && data.EMail !== null ? (
                                          <Stack direction="row">
                                          <Tooltip title="Persönliche Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0, paddingRight: "5px"}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagpersonal(data)}>
                                            <Badge badgeContent={<Favorite sx={{height: "12px", width: "12px"}}></Favorite>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Neutrale Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagneutral(data)}>
                                            <Badge badgeContent={<SentimentNeutral sx={{height: "12px", width: "12px"}}></SentimentNeutral>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>                                          
                                          </Stack>                                          
                                        ) : null;
                                      })()}            
                                  </Stack>                
                                  <div style={{ fontSize: "14px" }}>
                                    geb. <b>{data.gebdatum}</b> - {data.Straße}, {data.PLZOrt}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {upcoming.length > 0 && (
                          <>
                            <Divider sx={{marginTop: "20px"}}></Divider>
                            <Stack sx={{justifyContent: "center", backgroundColor: "#2e3192"}} direction="row">
                              <KeyboardDoubleArrowDown sx={{color: "white"}}></KeyboardDoubleArrowDown>
                              <Typography sx={{color: "white"}}>zukünftige Geburtstage</Typography>
                              <KeyboardDoubleArrowDown sx={{color: "white"}}></KeyboardDoubleArrowDown>
                            </Stack>                 
                            <Divider sx={{marginBottom: "10px"}}></Divider>
                          
                            {upcoming.map(data => (
                              <div
                                key={data.ID}
                                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px", paddingTop: "5px" }}
                              >
                                <div>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Link onClick={() => handleOpenKunde(data)} sx={{ fontSize: "14px", cursor: "pointer", fontWeight: "bold" }}>
                                    {data.Vorname} {data.Nachname} (<b>{calculateAge(data.gebdatum)} Jahre</b>)
                                  </Link>
                                    {data.Bewertung > 0 && (
                                    <>
                                      <Typography sx={{ mx: 1 }}>-</Typography>
                                      <div>{renderStars(data.Bewertung)}</div>
                                    </>
                                  )}
                                     {(() => {
                                        return data.EMail && data.EMail !== "Keine E-Mail vorhanden" && data.EMail !== null ? (
                                          <Stack direction="row">
                                          <Tooltip title="Persönliche Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0, paddingRight: "5px"}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagpersonal(data)}>
                                            <Badge badgeContent={<Favorite sx={{height: "12px", width: "12px"}}></Favorite>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Neutrale Geburtstagsmail an Kunden erstellen">
                                            <IconButton sx={{padding: 0}} size='small' color="primary" onClick={() => handleOpenMailGeburtstagneutral(data)}>
                                            <Badge badgeContent={<SentimentNeutral sx={{height: "12px", width: "12px"}}></SentimentNeutral>}>
                                              <Email fontSize='small'></Email>
                                            </Badge>
                                            </IconButton>
                                          </Tooltip>                                          
                                          </Stack>                                          
                                        ) : null;
                                      })()}
                                      </Stack>                
                                  <div style={{ fontSize: "14px" }}>
                                    geb. <b>{data.gebdatum}</b> - {data.Straße}, {data.PLZOrt}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )} 
                          </div> }
    </div>
    
    </Card>  
  );
};