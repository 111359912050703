import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, Grid, IconButton, Tooltip, Typography, Modal, Box, Fade, Divider, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from '@mui/material';
import moment from 'moment';
import { fetchdataUrl } from '../A-config/config';
import { EditSharp, ArrowCircleUpSharp, ArrowCircleDownSharp, ExpandMoreSharp, PrintSharp, BookmarkSharp, SaveAltSharp, CloseSharp, SaveAsSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Co2Sharp, ConstructionOutlined, SaveSharp, ExpandMore} from '@mui/icons-material';
import { useAuth } from "../hooks/useAuth";
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { globalAppBarMessage } from '../hooks/reduxactions';
import { de } from 'react-date-range/dist/locale';
import ModalUrlaubServerSideWithQueryProvider from '../pages/ModalUrlaubServerSide';
import Jahresuebersicht from '../components/JahresÜbersicht';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
  };

export default function ZeiterfassungModalUrlaub({ openModal, setopenModal, selectedUser, firmeninfos, selectedyear, refreshInfo }) {
    const handleClose = () => setopenModal(false);
    const [UrlaubstageproJahr, setUrlaubstageproJahr] = useState(0);
    const [Urlaubsdata, setUrlaubsdata] = useState([]);
    const [UrlaubsdatainRage, setUrlaubsdatainRage] = useState([]);
    const [Urlaubsanträge, setUrlaubsanträge] = useState([]);
    const [uebertragVorjahrTage, setUebertragVorjahrTage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [filterYear, setFilterYear] = useState(false);
    const [filterunerledigt, setfilterunerledigt] = useState(false);
    const [userVacations, setUserVacations] = useState([]);
    const [vacationDays, setVacationDays] = useState({});

    const dispatch = useDispatch();

    const { alleUser, user, logout, hasPermission } = useAuth();

    const vacationDaysTaken = Array.isArray(Urlaubsdata) ? Urlaubsdata.length : 0;
    const remainingVacationDays = UrlaubstageproJahr - vacationDaysTaken + uebertragVorjahrTage;

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    const handleUebertragVorjahrTageChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setUebertragVorjahrTage(isNaN(value) ? 0 : value);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogSave = () => {
    
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchSaveUrlaubstageÜbertrag: 1,
                username: selectedUser.vollstaendigerName,
                year: year,
                übertrag: uebertragVorjahrTage
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {                
            if (data.success) {
                refreshInfo();
                dispatch(globalAppBarMessage(`Die Daten wurden erfolgreich gespeichert.`));
                handleDialogClose();
            }
        });
    };

    useEffect(() => {
        if (state.length > 0) {
          const startdate = moment(state[0].startDate).format('YYYY-MM-DD');
          const enddate = moment(state[0].endDate).format('YYYY-MM-DD');
    
          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              fetchGetUrlaubinRange: 1,
              startdate: startdate,
              enddate: enddate
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            return response.json();
          }).then((data) => {
            if (data.success) {
                setUrlaubsdatainRage(data.data)
            }
          });
        }
    }, [state]);

    const year = moment(selectedyear).format('YYYY');
    // Fetch vacation data on component mount
    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchGetalleUrlaubimJahr: 1,
                selectedyear: year
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                setUserVacations(data.data);
            }
        });
    }, [year]);

    // Transform vacation data into a dictionary format
    useEffect(() => {
        const vacationDict = {};
        userVacations.forEach(vacation => {
            const date = moment(vacation.TagundDatum.split(' - ')[1], 'DD.MM.YYYY').format('YYYY-MM-DD');
            if (!vacationDict[date]) {
                vacationDict[date] = [];
            }
            vacationDict[date].push(vacation.username);
        });
        setVacationDays(vacationDict);
    }, [userVacations]);


    // Generate an array of days in the selected year
    const daysInYear = [];
    for (let i = 1; i <= moment(`${year}-12-31`).dayOfYear(); i++) {
        daysInYear.push(i);
    }

    const groupVacationData = (data) => {
        const groupedData = {};
        data.forEach(item => {
            const { username, TagundDatum } = item;
            if (!groupedData[username]) {
            groupedData[username] = [];
            }
            groupedData[username].push(moment(TagundDatum, 'DD.MM.YYYY'));
        });

        Object.keys(groupedData).forEach(username => {
            groupedData[username].sort((a, b) => a - b);
            groupedData[username] = mergeConsecutiveDates(groupedData[username]);
        });

        return groupedData;
    };

    const mergeConsecutiveDates = (dates) => {
        if (dates.length === 0) return [];
        const merged = [{ start: dates[0], end: dates[0] }];
        for (let i = 1; i < dates.length; i++) {
          if (dates[i].diff(dates[i - 1], 'days') === 1) {
            merged[merged.length - 1].end = dates[i];
          } else {
            merged.push({ start: dates[i], end: dates[i] });
          }
        }
        return merged;
      };
    
    const vacationData = groupVacationData(UrlaubsdatainRage);
    const vacationUsers = Object.keys(vacationData);

    const getUrlaubstageuser = async () => {
        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchgetUrlaubstageuser: 1,
                username: selectedUser.vollstaendigerName,
                selectedyear: selectedyear.getFullYear()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
            if (data.success) {
                setUrlaubsdata(data.data)
            }                
    };


    const handleUrlaubbeantragen = () => {
 
        if (state.length > 0) {
            const startdate = moment(state[0].startDate);
            const enddate = moment(state[0].endDate);

            if (!startdate.isValid() || !enddate.isValid()) {
                dispatch(globalAppBarMessage(`Ungültiger Zeitraum. Bitte wähle im Kalender einen gültigen Zeitraum aus.`));
                return; 
            }

            const formattedVacationData = {};
            Object.keys(vacationData).forEach(user => {
                formattedVacationData[user] = vacationData[user].map(range => ({
                    start: range.start.format('YYYY-MM-DD'),
                    end: range.end.format('YYYY-MM-DD')
                }));
            });

            fetch(`${fetchdataUrl}fetchdata.php`, {
              method: 'POST',
              body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUrlaubBeantragen: 1,
                username: selectedUser.vollstaendigerName,
                startdate: startdate.format('YYYY-MM-DD'),
                enddate: enddate.format('YYYY-MM-DD'),
                konfliktedata: JSON.stringify(formattedVacationData) 
            }),
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((response) => {
              return response.json();
            }).then((data) => {                
              if (data.success) {

                const adminEmails = alleUser
                .filter(user => {
                    const berechtigungen = user.berechtigungen;
                    if (!berechtigungen) return false;
                    return Array.isArray(berechtigungen) ? berechtigungen.includes("Urlaubskoordinator") : berechtigungen.includes("Urlaubskoordinator");
                })
                .map(user => user.email); 

                //Send the array of admin emails to sendemail.php
                fetch(`${fetchdataUrl}sendemail.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            access_token: sessionStorage.getItem("access_token"),
                            sendUrlaubbeantragt: 1,
                            array: adminEmails,
                            username: user.vollstaendigerName,
                            von: startdate.format('DD.MM.YYYY'),
                            bis: enddate.format('DD.MM.YYYY'),
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                })
                .then(response => response.json())
                .then(data => {
                    dispatch(globalAppBarMessage(`Der Urlaubsantrag wurde erfolgreich eingereicht.`));
                })   

              }
            });
          }
    }

    useEffect(() => {
        getUrlaubstageuser();
    }, [openModal]);

    useEffect(() => {
        if (selectedUser) {
            const Wochenarbeitszeit = firmeninfos.arbeitszeitVollzeitkraft;
            const UrlaubstageVollzeitkraft = firmeninfos.UrlaubstageVollzeitkraft;

            let uebertragForSelectedYear = 0;
            if (selectedUser.urlaubstageübertrag) {
                const uebertragData = JSON.parse(selectedUser.urlaubstageübertrag);
                uebertragForSelectedYear = parseInt(uebertragData[year] || 0, 10);
            }
            setUebertragVorjahrTage(uebertragForSelectedYear);            

            let ArbeitszeitIndividuell;
            let prozentualearbeitszeit;

            if (selectedUser) {
                ArbeitszeitIndividuell = selectedUser.arbeitsstunden;
                prozentualearbeitszeit = ((ArbeitszeitIndividuell / Wochenarbeitszeit) * 100).toFixed(2);
                let UrlaubstageproJahrprozentualearbeitszeit = UrlaubstageVollzeitkraft / 100 * prozentualearbeitszeit;
                setUrlaubstageproJahr(UrlaubstageproJahrprozentualearbeitszeit)
            }
        }
    }, [selectedUser]);

    const sortedUrlaubsdata = [...Urlaubsdata].sort((a, b) => {
        const dateA = moment(a.TagundDatum.split(" - ")[1], "DD.MM.YYYY");
        const dateB = moment(b.TagundDatum.split(" - ")[1], "DD.MM.YYYY");
        return dateA - dateB;
      });

      const renderOverlay = () => (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10
        }}>
            <Typography variant="h1" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Vorschau</Typography>
        </div>
    );

      return (
        <Modal
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={openModal}>
                <Box sx={style}>
                    <div style={{position: "relative"}}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                            <span><b>Urlaubsplanung ({year}):</b></span>
                        </div>
                        <Button onClick={() => setopenModal(false)}>
                            <CloseSharp />
                        </Button>
                    </div>
                    <Divider sx={{ marginTop: "5px", mb: 2}} />
                    <Grid container>
                        <Grid xs={4}>
                            Benutzer:
                        </Grid>
                        <Grid xs={8}>
                            <Typography >{selectedUser.vollstaendigerName}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            Betrachtetes Jahr:
                        </Grid>
                        <Grid xs={8}>
                            <Typography >{selectedyear.getFullYear()}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            Urlaubstage pro Jahr:
                        </Grid>
                        <Grid xs={8}>
                            <Typography >{UrlaubstageproJahr}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            Übertrag Vorjahr:
                        </Grid>
                        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', position:"relative" }}>
                            <Typography>{uebertragVorjahrTage}</Typography>
                            {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Urlaubskoordinator') ? (
                                <IconButton size="small" sx={{position: "absolute", left: "25px"}} onClick={handleDialogOpen}>
                                    <EditSharp size="small" />
                                </IconButton>
                            ) : null}
                        </Grid>
                        <Grid xs={4}>
                            Abgegoltene Urlaubstage:
                        </Grid>
                        <Grid xs={8}>
                            <Typography color="error">{Urlaubsdata.length}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            Übrige Urlaubstage:
                        </Grid>
                        <Grid xs={8}>
                            <Typography sx={{color: "#14a37f"}}>{remainingVacationDays}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title={
                                <div style={{ maxWidth: '550px' }}>
                                    <table style={{ borderCollapse: "collapse", width: '100%' }}>
                                    <thead>
                                        <tr>
                                        <th style={{ textAlign: "left", paddingRight: "10px", borderBottom: "1px solid #fff" }}>Datum</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedUrlaubsdata.map((urlaub, index) => (
                                        <tr key={index} style={{ height: '10px' }}>
                                            <td style={{ padding: '1px 0px' }}>{urlaub.TagundDatum}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                            }>
                                <Typography variant="button" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                    Bisherige Urlaubstage
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: "5px", mb: 2}} />
                    <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px", marginBottom: "10px" }}>
                        <span><b>Urlaub beantragen:</b></span>
                    </div>
                    <Stack direction="row">
                        <Stack direction="column">
                            <DateRange
                            locale={de}
                            editableDateInputs={true}
                            onChange={item => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                            rangeColors={['#2e3192']}
                            />
                            <Button onClick={handleUrlaubbeantragen}>Urlaub beantragen</Button>
                            <Divider sx={{ mt: 2, mb: 2}} />
                        </Stack>
                        <Grid sx={{ml: 2}}>
                            <Typography variant="body1" sx={{mb: 1}}>
                            <b>{vacationUsers.length}</b> Benutzer haben in diesem Zeitraum Urlaub:
                            </Typography>
                            {vacationUsers.map(username => (
                            <div key={username}>
                                <Typography variant="subtitle1"><b>{username}</b></Typography>
                                {vacationData[username].map((range, index) => (
                                <Typography key={index}>
                                    von {range.start.format('DD.MM.YYYY')} bis {range.end.format('DD.MM.YYYY')}
                                </Typography>
                                ))}
                            </div>
                            ))}
                        </Grid>
                    </Stack>
                    <Divider sx={{ mt: 2, mb: 2}} />
                    <ModalUrlaubServerSideWithQueryProvider/>
                    <Divider sx={{ mt: 2, mb: 2}} />
                    <Jahresuebersicht year={year}></Jahresuebersicht>
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle>Übertrag Vorjahr Tage ändern</DialogTitle>
                        <DialogContent>
                            <TextField
                                type="number"
                                value={uebertragVorjahrTage}
                                onChange={handleUebertragVorjahrTageChange}
                                inputProps={{ min: 0 }}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogSave} color="primary">
                                Speichern
                            </Button>
                            <Button onClick={handleDialogClose} color="primary">
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {(!hasPermission(user, 'Serveradmin') || !hasPermission(user, 'Urlaubskoordinator')) && renderOverlay()}
                    </div>  
                </Box>
            </Fade>
        </Modal>
    );
}