import { DateRangePicker, createStaticRanges  } from 'react-date-range';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { de } from 'react-date-range/dist/locale';
import moment from 'moment';

const sideBarOptions = () => {
  const customDateObjects = [
      {
          label: 'Alles anzeigen',
          range: () => ({
                startDate: moment(1950, 'year').toDate(),
                endDate: moment(3000, 'year').toDate(),
          })
      },
      {
          label: 'Alles bis heute',
          range: () => ({
                startDate: moment(1950, 'year').toDate(),
                endDate: moment().endOf('day').toDate()
          })
      },
      {
          label: 'Aktuelles Jahr',
          range: () => ({
                startDate: moment().startOf('year').toDate(),
                endDate: moment().endOf('year').toDate()
          })
      },
      {
          label: 'Aktueller Monat',
          range: () => ({
                startDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate()
          })
      },
      {
          label: 'Aktuelle Woche',
          range: () => ({
                startDate: moment().startOf('week').toDate(),
                endDate: moment().endOf('week').toDate()
          })
      },
      {
          label: 'Heute',
          range: () => ({
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
          })
      },
      {
          label: 'Letzten 7 Tage',
          range: () => ({
                startDate: moment().subtract(7, 'days').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
          })
      },
      {
          label: 'Letzten 30 Tage',
          range: () => ({
              startDate: moment().subtract(30, 'days').startOf('day').toDate(),
              endDate: moment().endOf('day').toDate()
          })
      },
      {
          label: 'Letzten 90 Tage',
          range: () => ({
              startDate: moment().subtract(90, 'days').startOf('day').toDate(),
              endDate: moment().endOf('day').toDate()
          })
      }, 
  ];

  return customDateObjects;
};

export const DateRangePickerCustom = ({dateRange, onDateRangeChange}) => {

  const sideBar = sideBarOptions();

  const [selectedRange, setSelectedRange] = useState(dateRange);
  
  const handleRangeChange = (ranges) => {
    const newRange = ranges.selection;
    setSelectedRange([newRange]);  // Set local state
    onDateRangeChange(newRange);   // Pass new range to parent via callback
  };

  const staticRanges = [
      ...createStaticRanges(sideBar)
  ];

return (
        <DateRangePicker
        showMonthAndYearPickers={true}
        locale={de}
        onChange={handleRangeChange}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        staticRanges={staticRanges}
        rangeColors={['#2e3192']}
        inputRanges={[]}
      />
    
  );
};
