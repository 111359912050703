import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventData, setloading, updateQuillValue} from './reduxactions';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { useEffect } from 'react';
import { updatePnr } from './reduxactions';
import { useAuth} from "../hooks/useAuth";
import { fetchdataUrl } from '../A-config/config';
import { previous } from 'slate';

export const useNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, hasPermission} = useAuth();

  const navigateToCustomer = async (partnernummer) => { 
    dispatch(setloading(true));

    var elements = document.getElementsByClassName("ql-editor");
    if (elements.length > 0) {
        elements[0].innerHTML = "";
    }

    // if (partnernummer !== pnrprocessed) {
    //     dispatch(updatePnr(partnernummer));        

        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
        method: "POST",
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchUserDataDatabase: 1,
            PNr: partnernummer,
        }),
        headers: {
            "Content-Type": "application/json",
        },
        });

        const data = await response.json();
        dispatch(updateEventData(data));
        // dispatch(updateQuillValue(data.Bemerkungen));

        if (window.chrome && window.chrome.webview && isDesktop) {
            const dataarray = localStorage.getItem("setcheckboxEVTaufrufen") === "true" && localStorage.getItem("verbindungleftright") === "true"?
            ["PNRChanged", data.PNr, data.Nachname, data.Vorname, data.Straße, data.PLZOrt, data.gebdatum, data.Titel] :
            ["PNRChangedWithoutEVTChange", data.PNr, data.Nachname, data.Vorname, data.Straße, data.PLZOrt, data.gebdatum, data.Titel];
            window.chrome.webview.postMessage(dataarray);
        } 

        // if (localStorage.getItem("setcheckboxEVTaufrufen") === "false" || !window.chrome || !window.chrome.webview ||! isDesktop || localStorage.getItem("verbindungleftright") === "false")
        // {
        //     setTimeout(() => {
        //     dispatch(setloading(false));
        //     }, 500);
        // }

    // } else {
    //     dispatch(setloading(false));
    // }
    navigate(`/dashboard/UserKundenübersicht/${partnernummer}`);
  };

  return { navigateToCustomer };
};