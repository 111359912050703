import { Chip, List, ListSubheader, ListItem, ListItemIcon, ListItemText, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, Tab, Tabs, Box, Tooltip, Card, CardHeader, Avatar, IconButton, CardMedia, Divider, CardContent, CardActions, Typography, Button, TextField, Container, Stack, Grid, Alert, Hidden, LinearProgress} from "@mui/material";
import { Help, LocalPoliceSharp, AdminPanelSettings, SupervisorAccount, Build, AccessTime, Timer, BugReport, Assignment, AddTaskSharp, VerifiedSharp, Upload, PasswordSharp, FavoriteSharp, EditRounded, HowToRegSharp, DeleteSharp, KeyboardDoubleArrowUpSharp, KeyboardDoubleArrowDownSharp, Favorite, Share, ExpandMore, ExpandMoreIcon, AutoAwesome, MilitaryTech, Verified, Grade, Flare, StarSharp, Star, Padding, LocalPolice, MarkEmailRead} from "@mui/icons-material";

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { fetchdataUrl } from '../A-config/config';

export const SettingsAdminMenüServerNachricht = () =>  {
    const [ServerMessage, setServerMessage] = useState('');
    
    const handleSendServerMessage = () => {
      try {     
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchSetServerMessage: 1,
            message: ServerMessage
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            alert('Nachricht an alle Nutzer gesendet!');
        });   
  
      } catch (error) {
        console.error('Error sending server message:', error);
        alert('Failed to send the message. Please try again.');
      }
    };     
   

    return (          
        <Card>
        <CardContent>
            <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Globale Servernachricht versenden</h2>  
            <Divider></Divider>
            <Box sx={{display: "flex"}}>
                        <Typography sx={{marginTop: 2}}><b><u>Achtung:</u></b> Diese Nachricht wird an <b>alle</b> Nutzer versendet. Formatierungen möglich analog HTML Struktur.</Typography>
                        <Tooltip
                        title={
                            <div>
                                Mögliche Formatierungen:<br /><br/>
                                <b>&lt;b&gt; Fett &lt;/b&gt;</b><br />
                                <i>&lt;i&gt; Kursiv &lt;/i&gt;</i><br />
                                <u>&lt;u&gt; Unterstrichen &lt;/u&gt;</u><br />
                                <br /> Neue Zeile: &lt;br /&gt;<br />
                                <p>&lt;p&gt; Absatz &lt;/p&gt;</p>
                            </div>
                        }
                        >
                            <Help sx={{height: "14px", width: "14px", paddingLeft: "2px", paddingTop: "10px"}}></Help>
                        </Tooltip>
            </Box>
            <TextField
            multiline
            rows={5}
            value={ServerMessage}
            onChange={e => setServerMessage(e.target.value)}
            sx={{ mt: 2, width: '100%' }}
            />
            <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleSendServerMessage()}
                >
                    Senden
            </Button>
        </CardContent>
        </Card>           
    )

}