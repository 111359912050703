import { useState } from "react";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { useAuth } from "../hooks/useAuth";

const TextFieldPopup = ({ open, value, onClose, onSave }) => {
    
  const [textValue, setTextValue] = useState(value || ""); // set to an empty string if value is undefined or null
  const {user} = useAuth();

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleSave = () => {
    onSave(textValue);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();  // prevent the focus move to the next interactive element
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      
      // insert tab character
      setTextValue(textValue.substring(0, start) + "\t" + textValue.substring(end));
      
      // set cursor position
      setTimeout(() => e.target.setSelectionRange(start + 1, start + 1), 0);
    }
  };
  
  const addLine = () => {
    const date = moment().format("DD.MM.YYYY - HH:mm:ss");
    const value = `${date} - ${user.vollstaendigerName}\n\n\n${textValue}`;

    // set the text field value
    setTextValue(value);

    // set the focus to the end of the new line
    const newLineIndex = value.indexOf("\n") + 1;
    const textField = document.getElementById("TextFieldBemerkungen");
    textField.focus();
    setTimeout(() => {
      textField.setSelectionRange(newLineIndex, newLineIndex);
    }, 20);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    let text = event.clipboardData.getData('text/plain'); // Get pasted text
    const cursorPos = event.target.selectionStart;
    const textBeforeCursor = textValue.substring(0, cursorPos);
    const textAfterCursor = textValue.substring(event.target.selectionEnd);
    setTextValue(textBeforeCursor + text + textAfterCursor);
  };

  return (
    <Dialog open={open} onClose={handleClose} onExited={() => setTextValue(value)}>
      <DialogContent>
        <TextField
          style={{width: "500px", height: "500px"}} 
          id="TextFieldBemerkungen"
          value={textValue}
          onChange={handleTextChange}          
          fullWidth
          multiline
          autoFocus
          onKeyDown={handleKeyDown}
          rows={20}
          onDoubleClick={() => addLine(textValue, user.vollstaendigerName)}
          onPaste={handlePaste}
        />
      </DialogContent>
      <DialogActions sx={{mr: 2}}>
        <Button onClick={handleSave}>Speichern</Button>
        <Button onClick={handleClose}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextFieldPopup;