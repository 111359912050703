import React, {useRef, useEffect, useState, useMemo } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { TextField, Box, Checkbox, Tooltip, IconButton } from "@mui/material";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigateFunction';
import { fetchdataUrl } from '../A-config/config';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { debounce } from 'lodash';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';

const UserSucheServerSide = () => {
  const textFieldRef = useRef(null);
  const navigate = useNavigate();
  const { navigateToCustomer } = useNavigation();
  const pnrprocessed = useSelector(state => state.pnrprocessed);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(() => {
    const savedGlobalFilter = localStorage.getItem('globalFilterUserSuche');
    return savedGlobalFilter || '';
  });
  
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });


  useEffect(() => {
    const savedColumnFilters = localStorage.getItem('columnFiltersUserSuche');
    const savedGlobalFilter = localStorage.getItem('globalFilterUserSuche');
    const savedSorting = localStorage.getItem('sortingUserSuche');
    const savedPagination = localStorage.getItem('paginationUserSuche');

    if (savedColumnFilters) {
      setColumnFilters(JSON.parse(savedColumnFilters));
    }
    if (savedGlobalFilter) {
      setGlobalFilter(savedGlobalFilter);
    }
    if (savedSorting) {
      setSorting(JSON.parse(savedSorting));
    }
    if (savedPagination) {
      setPagination(JSON.parse(savedPagination));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('columnFiltersUserSuche', JSON.stringify(columnFilters));
  }, [columnFilters]);

  useEffect(() => {
    localStorage.setItem('globalFilterUserSuche', globalFilter);    
  }, [globalFilter]);

  useEffect(() => {
    localStorage.setItem('sortingUserSuche', JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    localStorage.setItem('paginationUserSuche', JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, []);

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightUserSuche');
    return savedHeight ? parseInt(savedHeight) : 300;
  });

  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 300;
    localStorage.setItem('contentHeightUserSuche', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  useEffect(() => {
    calculateContentHeightAndPageSize();
    window.addEventListener('resize', calculateContentHeightAndPageSize);
    return () => {
      window.removeEventListener('resize', calculateContentHeightAndPageSize);
    };
  }, []);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    try {
      const visibilityString = localStorage.getItem('columnVisibilityKundenSuche');
      return JSON.parse(visibilityString) || { ID: false };
    } catch (e) {
      console.error(e);
      return { ID: false };
    }
  });

  useEffect(() => {
    localStorage.setItem('columnVisibilityKundenSuche', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleColumnVisibilityChange = (updater) => {
    setColumnVisibility(updater);
  };

  const defaultColumnWidthsKundensuche = 90;
  let storageItemKundensuche = localStorage.getItem('columnWidthsKundensuche');
  let columnWidthsFromLocalStorageKundensuche;

  if (storageItemKundensuche === "undefined" || !storageItemKundensuche) {
    columnWidthsFromLocalStorageKundensuche = defaultColumnWidthsKundensuche;
  } else {
    columnWidthsFromLocalStorageKundensuche = JSON.parse(storageItemKundensuche);
  }

  const [columnSizingStateKundensuche, setcolumnSizingStateKundensuche] = useState(columnWidthsFromLocalStorageKundensuche);

  const handleColumnSizingChange = (newColumnSizingState) => {
    const newState = newColumnSizingState(columnSizingStateKundensuche);
    localStorage.setItem('columnWidthsKundensuche', JSON.stringify(newState));
    setcolumnSizingStateKundensuche(newState);
  };

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
} = useQuery({
    queryKey: [
        'table-data',
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ],
    queryFn: async () => {

        const requestBody = {
            access_token: sessionStorage.getItem("access_token"),
            fetchAlleKundenServerSide: 1,
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: columnFilters,
            globalFilter: globalFilter,
            sorting: sorting,           
        };

        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const json = await response.json();
        return json;
    },
    placeholderData: keepPreviousData,
});  

  const columns = useMemo(() => [
    { accessorKey: 'ID', header: 'ID', size: 40, minSize: 40, enableHiding: false },
    { accessorKey: 'Titel', header: 'Anrede', size: 30, minSize: 30 },
    { accessorKey: 'PNr', header: 'PNr', size: 40, minSize: 40 },
    { accessorKey: 'Nachname', header: 'Nachname', size: 80, minSize: 40 },
    { accessorKey: 'Vorname', header: 'Vorname', size: 80, minSize: 40 },
    { accessorKey: 'gebdatum', header: 'Geburtsdatum', size: 45, minSize: 40 },
    { accessorKey: 'Straße', header: 'Straße', size: 80, minSize: 40 },
    { accessorKey: 'PLZOrt', header: 'PLZ und Ort', size: 80, minSize: 40 }
  ], []);

  const tableInstance = useMaterialReactTable({
    columns,
    data,
    state: {
      columnSizing: columnSizingStateKundensuche,
      columnVisibility: columnVisibility,
      isLoading,
      isError,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      showProgressBars: isRefetching,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
      columnVisibility: columnVisibility,
    },
    enableFilterMatchHighlighting: false,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableStickyHeader: true,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',
    onColumnSizingChange: handleColumnSizingChange,
    manualFiltering: true,
    manualFilters: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: meta?.totalRowCount ?? 0,
    localization: MRT_Localization_DE,
    muiTableContainerProps: ({ table }) => ({
      sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px'}
    }),     
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <TextField
          inputRef={textFieldRef}
          autoFocus
          size="small"
          defaultValue={globalFilter}
          onChange={e => debounce(() => setGlobalFilter(e.target.value), 500)()}
          spellCheck={false}
          placeholder="Suchen..."
          sx={{ minWidth: '300px' }}
          variant="outlined"
        />
      </Box>
    ),
    renderToolbarInternalActions: () => (
      <Box>
        <MRT_ShowHideColumnsButton table={tableInstance} />
        <MRT_ToggleFiltersButton table={tableInstance} />
        <MRT_ToggleFullScreenButton table={tableInstance} />
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (pnrprocessed !== row.original.PNr) {
          navigateToCustomer(row.original.PNr);
        } else {
          navigate(`/dashboard/UserKundenübersicht/${row.original.PNr}`);
        }
      }
    }),
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: { margin: 1 }
    },
  });

  return (
    <div>
      <MaterialReactTable table={tableInstance} />
    </div>
  );
};

const queryClient = new QueryClient();

const UserSucheServerSideWithQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <UserSucheServerSide />
  </QueryClientProvider>
);

export default UserSucheServerSideWithQueryProvider;