import { Snackbar, FormControl, InputLabel, Select, MenuItem, Icon, Avatar, Tooltip, Link, Alert, Skeleton, Tabs, Tab, Typography, Grid, Box, TextField, Stack, LinearProgress, IconButton, Divider, Card, Button, CardContent, RadioGroup, Radio} from '@mui/material';
import { useState, useEffect, useRef, useMemo } from 'react';
import {PrintRounded, WorkHistoryRounded, NotificationImportantSharp, LowPrioritySharp, ArrowCircleDownSharp, ArrowCircleUpSharp, SaveRounded, FolderOpenSharp} from '@mui/icons-material';
import moment from 'moment'
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import DateFnsAdapter from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { fetchdataUrl } from '../A-config/config';

export const SchadenübersichtPageContent = ({ alleschadendaten }) => {
    const dispatch = useDispatch();
    const [selectedValuePriority, setselectedValuePriority] = useState(alleschadendaten.Priority || "LOW");
    const [oldAlleschadendaten, setOldAlleschadendaten] = useState();
    const [selectedSchadenart, setSelectedSchadenart] = useState(alleschadendaten && alleschadendaten.Sparte);
    const [optionsSchadenart, setoptionsSchadenart] = useState([]);
    const [optionsBearbeiter, setoptionsBearbeiter] = useState([]);
    const [sharepointlinkschadenx, setsharepointlinkschadenx] = useState([]);

    const SchadenArtSelectRef = useRef();
    const BearbeiterSelectRef = useRef();
    const TextFieldASTInputref = useRef();

    const gridItemStyle = {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        lineClamp: 2,
        textOverflow: 'ellipsis',
        marginRight: "10px",
        overflowWrap: "anywhere",
        height: "20px"
    };

    const gridItemStyle2 = {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        lineClamp: 2,
        textOverflow: 'ellipsis',
        marginRight: "10px",
        overflowWrap: "anywhere",
        height: "40px"
    };

    // Function to sanitize file names by removing invalid characters and replacing empty strings with '%20'
    function sanitizeFileName(name) {
      if (!name) {
          return '%20';
      }
      const invalidChars = /[<>:"\/\\|?*]/g;
      return name.replace(invalidChars, '');
    }

    function generateSharePointLink() {
      const storedData = localStorage.getItem('firmenInfos');
      const firmenInfos = JSON.parse(storedData);
  
      const baseUrl = firmenInfos.sharepointlinkschaden;
  
      // Sanitize and encode the name and first name
      const sanitizedName = sanitizeFileName(alleschadendaten.Name);
      const sanitizedVorname = sanitizeFileName(alleschadendaten.Vorname);
  
      // Ensure the name and vorname are replaced with %20 if empty
      const encodedName = encodeURIComponent(sanitizedName === '%20' ? ' ' : sanitizedName);
      const encodedVorname = encodeURIComponent(sanitizedVorname === '%20' ? ' ' : sanitizedVorname);
  
      const encodedYear = encodeURIComponent(alleschadendaten.ZJahr);
      const encodedDate = encodeURIComponent(moment(alleschadendaten.Schadendatum).format("DD.MM.YYYY"));
      const encodedSchadennummer = encodeURIComponent(alleschadendaten.Schadennummer);
      const encodedPNr = encodeURIComponent(alleschadendaten.PNr);
   
      const makeLink = `${encodedYear}/` +
          `${encodedName},${encodedVorname === ' ' ? '%20' : encodedVorname}%20-%20${encodedPNr}/` +
          `${encodedDate}/` +
          `${encodedSchadennummer}`;
    
      setsharepointlinkschadenx(`${baseUrl}/${makeLink}`);
  }
  


    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "error"
    });

    const handleSnackbarClose = () => {
        setSnackbar({
        open: false,
        message: "",
        severity: "error"
        });
    }

    const { control, handleSubmit, setError, clearErrors, reset, trigger, getValues} = useForm({
        reValidateMode: "onChange",
    });

    const isValidDate = (dateString) => {
        const date = moment(dateString, "YYYY-MM-DD", true);
        const isValidFormat = date.isValid();
        const isYearAfter1900 = date.year() >= 1900;
        return isValidFormat && isYearAfter1900;
    };

    const isValidDateWV = (dateString) => {
        if (!dateString)
        {
            return true;
        }
        else
        {
            const date = moment(dateString, "YYYY-MM-DD", true);
            const isValidFormat = date.isValid();
            const isYearAfter1900 = date.year() >= 1900;
            return isValidFormat && isYearAfter1900;
        }
    };

    const handleRadioPriorityChange = (event) => {
        setselectedValuePriority(event.target.value);
    };

   const handleOpenAdressLink = () => {
    // Replace spaces in the address with plus signs
    const address = `${alleschadendaten.Straße}, ${alleschadendaten.PLZ} ${alleschadendaten.Wohnort}`.replace(/ /g, '+');

    // Open a new window with the Google Maps URL
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${address}`);
    };

    const handleOnSubmit = (mybasedataForm, e) => {

        let counterrors = 0;
        if (mybasedataForm.selectBearbeiter === "Bitte auswählen")
        {
            setError('selectBearbeiter', { type: 'required'});
            counterrors++;
        }
        if (mybasedataForm.selectRegulierungsArt === "Bitte auswählen")
        {
            setError('selectRegulierungsArt', { type: 'required'});
            counterrors++;
        }
        if (mybasedataForm.selectStatus === "Bitte auswählen")
        {
            setError('selectStatus', { type: 'required'});
            counterrors++;
        }
        if (mybasedataForm.selectSchadenArt === "Bitte auswählen")
        {
            setError('selectSchadenArt', { type: 'required'});
            counterrors++;
        }
        if (!isValidDate(mybasedataForm.WVDatum) && mybasedataForm.WVDatum !== "" && mybasedataForm.WVDatum !== null && mybasedataForm.WVDatum !== undefined && mybasedataForm.WVDatum !== "0000-00-00")
        {
            setError('WVDatum', { type: 'required'});
            counterrors++;
        }

        if (!isValidDate(mybasedataForm.SchadenDatum) && mybasedataForm.SchadenDatum !== "" && mybasedataForm.SchadenDatum !== null && mybasedataForm.SchadenDatum !== undefined && mybasedataForm.SchadenDatum !== "0000-00-00")
        {
            setError('SchadenDatum', { type: 'required'});
            counterrors++;
        }

        if (counterrors === 0)
        {
                
                //Check if Schadennummer exists
                fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        checkIfSchadennummerExists: 1,
                        Schadennummer: mybasedataForm.Schadennummer,
                        PNr: alleschadendaten.PNr,
                        ID: alleschadendaten.ID
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    }).then((response) => {
                        return response.json();
                    }).then((data1) => {
                        if (data1 === "EntryDoesNotExist")
                        {
                                let date = new Date();
                                let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
                                fetch(`${fetchdataUrl}fetchdata.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    access_token: sessionStorage.getItem("access_token"),
                                    updateSchadenDaten: 1,
                                    data: mybasedataForm,
                                    setChangedDate: formattedDate,
                                    ID: alleschadendaten.ID,
                                    Priority: selectedValuePriority
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    // updateTabData(tabId, mybasedataForm.Schadennummer);
                                    // shouldSave(!shouldSave);
                                    setSnackbar({
                                    open: true,
                                        message: (
                                            <div>
                                                <span>Änderungen erfolgreich gespeichert.</span>
                                            </div>
                                        ),
                                        severity: "success"
                                    });

                                    //Dateiablage Folder ändern, wenn sich Schadennummer oder Schadendatum geändert haben
                                    setOldAlleschadendaten(JSON.parse(JSON.stringify(alleschadendaten)));
                                    let SchadenDatum = moment(mybasedataForm.SchadenDatum);
                                    mybasedataForm.SchadenDatum = SchadenDatum.format('YYYY-MM-DD');
                                    // updateSchadenData(mybasedataForm.Schadennummer, mybasedataForm.SchadenDatum, null, formattedDate, mybasedataForm.Bearbeiter);

                                    if (mybasedataForm.selectStatus === "ERLEDIGT" && mybasedataForm.selectSchadenArt !== "KV Belege")
                                    {
                                        let transformedData = alleschadendaten.map(item => {
                                        return {
                                            ...item,
                                            Nachname: item.Name,
                                            PLZOrt: `${item.PLZ} ${item.Wohnort}`,
                                        }
                                        });
                                        //Mail an Betreuer, wenn Schaden geschlossen wurde
                                        const selectedUser = optionsBearbeiter.find(user => user.vollstaendigerName === mybasedataForm.selectBetreuer);
                                        const userEmail = selectedUser ? selectedUser.email : "raphael.rieber@axa.de";
                                        const Schadendaten = {
                                                Schadennummer: mybasedataForm.Schadennummer,
                                                Schadenart: mybasedataForm.selectSchadenArt,
                                                ASTFreitext: mybasedataForm.ASTFreitext,
                                                Schadendatum: SchadenDatum.format('DD.MM.YYYY'),
                                                Bearbeiter: mybasedataForm.selectBearbeiter,
                                                Status: mybasedataForm.selectStatus,
                                                Regulierungsart: mybasedataForm.selectRegulierungsArt,
                                        };

                                        fetch(`${fetchdataUrl}sendemail.php`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            access_token: sessionStorage.getItem("access_token"),
                                            sendSchadenerstellt: 1,
                                            An: userEmail,
                                            Art: "Schadenbearbeitung abgeschlossen",
                                            Kundendaten: transformedData[0],
                                            Schadendaten: Schadendaten,
                                            Bemerkungen: alleschadendaten.Bemerkungen,
                                        }),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                        }).then((response) => {
                                            return console.log(response);
                                        })
                                    }
                            });
                        }  else
                        {
                            setSnackbar({
                            open: true,
                                message: (
                                    <div>
                                        <span>Für diesen Kunden besteht bereits ein Schaden mit der Schadennummer <b>{mybasedataForm.Schadennummer}</b>.</span>
                                        <span style={{display: 'block'}}>Bitte wähle eine andere Schadennummer.</span>
                                    </div>
                                ),
                                severity: "error"
                            });
                        }
                });
        }
    };

    useEffect(() => {
        if (oldAlleschadendaten && alleschadendaten)
        {
            if (window.chrome && window.chrome.webview && isDesktop)
            {
                window.chrome.webview.postMessage(['MoveFolderOnSchadennummerSchadendatumChange',  oldAlleschadendaten, alleschadendaten]);
            }
        }
    }, [alleschadendaten]);


    useEffect(() => {
        generateSharePointLink();

        const fetchSchadenArten = () => {
            if (alleschadendaten && alleschadendaten.Sparte === "KV Belege") {
                return new Promise(resolve => {
                    setoptionsSchadenart({'KV Belege': [false]});
                    resolve();
                });
            } else {
                return fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchLoadSchadenArten: 1
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json()).then((data) => {
                    setoptionsSchadenart(data);
                });
            }
        };

        const fetchBearbeiter = () => {
            return fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchLoadBearbeiter: 1
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json()).then((data) => {
                setoptionsBearbeiter(data);
            });
        };

        Promise.all([fetchSchadenArten(), fetchBearbeiter()]);
    }, [alleschadendaten]);

    useEffect(() => {
        if(optionsSchadenart && optionsBearbeiter) {
            reset({
                Schadennummer: alleschadendaten.Schadennummer,
                selectSchadenArt: optionsSchadenart.hasOwnProperty(alleschadendaten.Sparte) ? alleschadendaten.Sparte : 'Bitte auswählen',
                SchadenDatum: alleschadendaten.Schadendatum,
                WVDatum: alleschadendaten.Wiedervorlage,
                selectBearbeiter: optionsBearbeiter.map(item => item.vollstaendigerName).includes(alleschadendaten.Bearbeiter) ? alleschadendaten.Bearbeiter : 'Bitte auswählen',
                selectRegulierungsArt: alleschadendaten.Regulierungsart ? alleschadendaten.Regulierungsart : 'Selbstregulierungsschaden',
                selectStatus: alleschadendaten.Status,
                VSNummer: alleschadendaten.Versicherungsnummer,
                ASTFreitext: alleschadendaten.Schadenart,
            });
            //Trigger all Required Fields on Mount
            trigger();

        }
    }, [optionsSchadenart, optionsBearbeiter]);


    useEffect(() => {
        if (selectedSchadenart && optionsSchadenart[selectedSchadenart] && optionsSchadenart[selectedSchadenart][0] && TextFieldASTInputref.current.value === "") {
            setError('ASTFreitext', { type: 'required'});
        } else {
            clearErrors("ASTFreitext");
        }
    }, [selectedSchadenart]);

  return (
    <div style={{ overflow: "hidden", maxWidth: "900px" }}>
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    <Box sx={{ mt: 1, mr: 2, height: "220px" }}>
      <Stack direction="row">
        <Stack direction="column" sx={{ ml: 1, mt: 2 }}>
          <Stack direction="row">
            <Stack sx={{ mt: 0.5, ml: 2, mr: 1, width: "250px" }} orientation="vertical">
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {alleschadendaten.Titel ? alleschadendaten.Titel.length > 30 ? alleschadendaten.Titel.substring(0, 30) + '...' : alleschadendaten.Titel : ''}
              </span>
              <Tooltip title={<span>{alleschadendaten.Vorname} {alleschadendaten.Name}</span>}>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <b>{alleschadendaten.Vorname}<br />{alleschadendaten.Name}</b>
                </span>
              </Tooltip>
              <Tooltip title={<span>{alleschadendaten.Straße}<br />{alleschadendaten.PLZ} {alleschadendaten.Wohnort}</span>}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link style={{ cursor: "pointer", fontSize: "14px" }} onClick={handleOpenAdressLink}>
                    {alleschadendaten.Straße}
                    <br />
                    {alleschadendaten.PLZ} {alleschadendaten.Wohnort}
                  </Link>
                </div>
              </Tooltip>
              <div style={{ height: "5px" }}></div>
              <Grid container spacing={0} sx={{ width: '600px' }}>
                <Grid item xs={2.4}>
                <Typography variant="caption" sx={{ fontWeight: 'bold'}}>Letzte Änderung:</Typography>
                </Grid>
                <Grid item xs={9}>
                <Typography variant="caption">{alleschadendaten.Bearbeitungsdatum && moment(alleschadendaten.Bearbeitungsdatum, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY - HH:mm')} Uhr</Typography>
                </Grid>
                <Grid item xs={2.4}>
                <Typography variant="caption" sx={{ fontWeight: 'bold'}}>Letzter Bearbeiter:</Typography>
                </Grid>
                <Grid item xs={9}>
                <Typography variant="caption">{alleschadendaten.CBearbeiter}</Typography>
                </Grid> 
              </Grid>              
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ ml: 3, mt: 1, mb: 3, mr: 3}} orientation="vertical" flexItem />
        <Stack direction="column" sx={{ mt: 2}}>
        <Grid container spacing={1} sx={{ width: '600px' }}>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Schadendatum:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Schadendatum && moment(alleschadendaten.Schadendatum).format("DD.MM.YYYY")}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Wiedervorlage:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Wiedervorlage && moment(alleschadendaten.Wiedervorlage).format("DD.MM.YYYY")}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>VS-Nummer:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Versicherungsnummer}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Bearbeiter:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Bearbeiter}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Regulierungsart:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Regulierungsart}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Status:</Typography>
                <Typography variant="body2" sx={gridItemStyle}>{alleschadendaten.Status}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Schadennummer:</Typography>
                <Typography variant="body2" sx={gridItemStyle2}>{alleschadendaten.Schadennummer}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>AST/Freitext:</Typography>
                <Typography variant="body2" sx={gridItemStyle2}>{alleschadendaten.Schadenart}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Schadenart:</Typography>
                <Typography variant="body2" sx={gridItemStyle2}>{alleschadendaten.Sparte}</Typography>
              </Grid> 
             
        </Grid>
          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: "pointer" }}>
            <Link
              sx={{
                cursor: "pointer",
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none'
              }}
              href={sharepointlinkschadenx}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FolderOpenSharp sx={{ marginRight: 1 }} />
              <Typography variant="body2" sx={{ marginTop: "2px" }}>
                Dateiablage öffnen (nur über ein von AXA verwaltetes Gerät möglich)
              </Typography>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Box>
  </div>
    )
};