import React, { useEffect, useState, useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFullScreenButton,
    MRT_ShowHideColumnsButton,
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
  } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { fetchdataUrl } from '../A-config/config';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography
} from '@mui/material';
import { MoreHoriz, AutoAwesome, ManageHistory, FlightTakeoff, Coronavirus, SportsTennis, Scoreboard, School, PlaylistAddCheckSharp, CarCrashRounded, UnpublishedSharp, PrintSharp, EventAvailableRounded, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star, DeleteSharp} from '@mui/icons-material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { DateRangePickerCustom } from '../components/DateRangePickerCustom';
import ConfirmDialog from '../hooks/confirmDialog';
import * as XLSX from 'xlsx';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import { useNavigation } from '../hooks/useNavigateFunction';
import { debounce, startsWith } from 'lodash';
import ZeiterfassungModalUser from "../modals/ZeiterfassungModalUser";

const ZeiterfassungTableServerside = ({datechange, selectedUser, refreshInfo, refreshChildComp}) => {
    const { user, hasPermission} = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [enableRowSelection, setEnableRowSelection] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [rowDATA, setrowDATA] = useState('');
    const [refreshZeiterfassungTable, setrefreshZeiterfassungTable] = useState(false);

    const [menuAnchor, setMenuAnchor] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [hoursWorked, setHoursWorked] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    
    const [dialogOpenUeberstunden, setdialogOpenUeberstunden] = useState(false);
    const [ueberstunden, setueberstunden] = useState('');

    const handleDialogOpen = () => {
        setDialogOpen(true);
        handleMenuClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setHoursWorked('');
    };

    const handleConfirm = () => {
        const hoursWorkedInSeconds = moment.duration(hoursWorked, 'hours').asSeconds();
        if (selectedRow.original.Feiertaginfo === "Krank") {
            UpdateRowStatus(null, selectedRow.original.id, hoursWorkedInSeconds);
        } else {
            UpdateRowStatus("Krank", selectedRow.original.id, hoursWorkedInSeconds);
        }
        refreshInfo();
        handleDialogClose();
    };

    const handleDialogOpenueberstunden = () => {
        setdialogOpenUeberstunden(true);
        handleMenuClose();
    };

    const handleDialogUeberstundenClose = () => {
        setdialogOpenUeberstunden(false);
        setueberstunden('');
    };

    const handleConfirmUeberstunden = () => {
        const ueberstundenInSeconds = moment.duration(ueberstunden, 'hours').asSeconds();
        const formattedUeberstunden = `-${moment.utc(ueberstundenInSeconds * 1000).format('HH:mm:ss')}`;
        if (selectedRow.original.Feiertaginfo === "Frei auf Überstunden") {
            UpdateRowStatus(null, selectedRow.original.id, 0, null);
        } else {
            UpdateRowStatus("Frei auf Überstunden", selectedRow.original.id, 0, formattedUeberstunden);
        }
        refreshInfo();
        handleDialogUeberstundenClose();
    };

    const handleMenuOpen = (event, rowId, row) => {
        event.stopPropagation();
        setMenuAnchor(event.currentTarget);
        setCurrentRowId(rowId);
        setSelectedRow(row);
    };
    
    const handleMenuClose = () => {
        setMenuAnchor(null);
        setCurrentRowId(null);
    };

    const [dateRange, setDateRange] = useState([{
        startDate: moment(1950, 'year').toDate(),  
        endDate: moment() ,    
        key: 'selection'
      }]);
    
    const [columnVisibilityZeiterfassungTableStart, setcolumnVisibilityZeiterfassungTableStart] = useState(() => {
        try {
          const visibilityString = localStorage.getItem('columnVisibilityZeiterfassungTableStart');
          return JSON.parse(visibilityString) || { id: false };
        } catch (e) {
          console.error(e);
          return { id: false };
        }
    });
    useEffect(() => {
        localStorage.setItem('columnVisibilityZeiterfassungTableStart', JSON.stringify(columnVisibilityZeiterfassungTableStart));
      }, [columnVisibilityZeiterfassungTableStart]);
    
      const handleColumnVisibilityChangeZeiterfassungTable = (updater) => {
      // Update the visibility state of the columns
        setcolumnVisibilityZeiterfassungTableStart(updater);    
    };

    const defaultColumnWidthsZeiterfassungTable = 90;
    let storageItemZeiterfassungTable = localStorage.getItem('columnWidthsZeiterfassungTable');
    let columnWidthsFromLocalStorageZeiterfassungTable;
  
    if (storageItemZeiterfassungTable === "undefined" || !storageItemZeiterfassungTable) {
          columnWidthsFromLocalStorageZeiterfassungTable = defaultColumnWidthsZeiterfassungTable;
    } else {
      columnWidthsFromLocalStorageZeiterfassungTable = JSON.parse(storageItemZeiterfassungTable);
    }
  
    const [columnSizingStateZeiterfassungTable, setColumnSizingStateZeiterfassungTable] = React.useState(columnWidthsFromLocalStorageZeiterfassungTable);
  
    const handleColumnSizingChange = (newColumnSizingState) => {
      const newState = newColumnSizingState(columnSizingStateZeiterfassungTable);
      localStorage.setItem('columnWidthsZeiterfassungTable', JSON.stringify(newState));
      setColumnSizingStateZeiterfassungTable(newState);
    };

    const [contentHeight, setContentHeight] = useState(() => {
        const savedHeight = localStorage.getItem('contentHeightUserStart');
        return savedHeight ? parseInt(savedHeight) : 230; // Default to 300 if nothing is stored
    });

    const calculateContentHeightAndPageSize = () => {
        const windowHeight = window.innerHeight - 550;
    
        localStorage.setItem('contentHeightUserStart', windowHeight.toString());
        setContentHeight(windowHeight);
      };
    
      useEffect(() => {
        calculateContentHeightAndPageSize(); // Call on initial load
    
        // Set up event listener for resize
        window.addEventListener('resize', calculateContentHeightAndPageSize);
    
        // Clean-up function
        return () => {
          window.removeEventListener('resize', calculateContentHeightAndPageSize);
        };
    }, []);

    // Data state for the table
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // Table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const debouncedSetColumnFilters = useMemo(() => debounce((newFilters) => {
      setColumnFilters(newFilters);
    }, 200), []);
  
    const handleColumnFilterChange = (filters) => {
      debouncedSetColumnFilters(filters);
    };

    const setDebouncedGlobalFilter = useMemo(() => debounce((filter) => {
      setGlobalFilter(filter);
    }, 200), []);  

    const handleglobalFilterChange = (newfilters) => {
      setDebouncedGlobalFilter(newfilters);
    };

    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100, 
    }) 

    // Fetch data
    const UpdateRowStatus = async (status, id, arbeitszeitgesamt, arbeitszeitsaldiert) => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchZeiterfassungUpdateRowStatus: 1, 
                id: id,
                status: status,
                arbeitszeitgesamt: arbeitszeitgesamt,
                arbeitszeitsaldiert: arbeitszeitsaldiert
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setrefreshZeiterfassungTable(!refreshZeiterfassungTable);
        });              
    };

    // Fetch data
    const fetchData = async () => {    
        setIsLoading(true);
        const requestBody = {
            access_token: sessionStorage.getItem("access_token"),
            fetchAlleDatenZeiterfassung: 1, 
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: columnFilters,
            globalFilter: globalFilter,
            sorting: sorting,
            vollstaendigerName: selectedUser,
            selecteddate: datechange ? moment(datechange).format('YYYY-MM-DD') : null
        };

        try {
            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
    
            const json = await response.json();
            setData(json.data);
            setRowCount(json.meta.totalRowCount); 
            setIsError(false);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [columnFilters, globalFilter, pagination, sorting, dateRange, datechange, selectedUser, refreshZeiterfassungTable, refreshChildComp]);

    const columns = useMemo(() => [
        { accessorKey: 'id', header: 'ID' },
        {
            header: 'Datum',
            accessorKey: 'TagundDatum',
            enableResizing: false,
            minSize: 100,
            size: 100,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                const [dayName, date] = value.split(' - ');
                const shortenedDayName = dayName.substring(0, 2) + '.';
                return `${shortenedDayName} - ${date}`;
            },
            muiTableHeadCellProps: {
                title: 'Datum' 
            },
            enableColumnActions: false,
        },        
        {
            header: 'Eingestempelt',
            accessorKey: 'start_time',
            muiTableHeadCellProps: {
                title: 'Eingstempelt' 
            },
            minSize: 60,
            size: 60,
            Cell: ({ cell }) => {
                const cellValue = cell.getValue();
                const isValidDate = moment(cellValue, moment.ISO_8601, true).isValid();
                if (isValidDate) {
                    return moment(cellValue).format('HH:mm:ss');
                } else {
                    return null;
                }
            },
            enableColumnActions: false,
        },
        {
            header: 'Ausgestempelt',
            accessorKey: 'end_time',
            muiTableHeadCellProps: {
                title: 'Ausgestempelt',               
            },
            minSize: 60,
            size: 60,
            Cell: ({ cell }) => {
                const cellValue = cell.getValue();
                const isValidDate = moment(cellValue, moment.ISO_8601, true).isValid();
                if (isValidDate) {
                    return moment(cellValue).format('HH:mm:ss');
                } else {
                    return null;
                }
            },
            enableColumnActions: false,
        },
        {
            header: 'Gesamte Pause',
            accessorKey: 'pausegesamt',
            muiTableHeadCellProps: {
                title: 'Gesamte Pause'
            },
            minSize: 60,
            size: 60,
            Cell: ({ cell }) => {
                const cellValue = cell.getValue();
                if (cellValue) {
                    const duration = moment.duration(cellValue, 'seconds');
                    const formattedValue = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
                    return <span>{formattedValue}</span>;
                } else {
                    return null;
                }
            },
        },
        {
            header: 'Arbeitszeit',
            accessorKey: 'arbeitszeitsaldiert',
            muiTableHeadCellProps: {
                title: 'Arbeitszeit (Vollzeit-MA)\nMit Abzug zu leistende Stunden am Tag' 
            },
            minSize: 60,
            size: 60,
            Cell: ({ cell }) => {
                const cellValue = cell.getValue();
                if (cellValue && cellValue.startsWith("-")) {
                    return <span style={{ color: "#ff6d75", fontWeight: "bold" }}>{cellValue}</span>;
                } else {
                    return <span style={{ color: "#14a37f", fontWeight: "bold" }}>{cellValue}</span>;
                }
            },
            enableColumnActions: false,
        },
        {
            header: 'Arbeitszeit',
            accessorKey: 'arbeitszeitgesamt',
            muiTableHeadCellProps: {
                title: 'Arbeitszeit (Teilzeit-MA)\nSaldiert ohne Abzug zu leistende Stunden am Tag' 
            },
            minSize: 60,
            size: 60,
            Cell: ({ cell }) => {
                const cellValue = Number(cell.getValue());
                if (!isNaN(cellValue) && cellValue > 0) {
                    const duration = moment.duration(cellValue, 'seconds');
                    const hours = Math.floor(duration.asHours());
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();
                    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                } else {
                    return null;
                }
            },
            enableColumnActions: false,
        },
        {
            header: 'Zusatzinformation',
            accessorKey: 'Feiertaginfo',
            muiTableHeadCellProps: {
                title: 'Zusatzinformation' 
            },
            minSize: 60,
            size: 90,
            enableColumnActions: false,
        },
        {
            header: 'Name',
            accessorKey: 'username',
            muiTableHeadCellProps: {
                title: 'Name'
            },
            minSize: 60,
            size: 90,
            enableColumnActions: false,
        },
        
    ], []);

      const tableInstance = useMaterialReactTable({
        columns,
        data,
        getRowId: (row) => row.id,
        state: {
            columnSizing: columnSizingStateZeiterfassungTable,
            columnVisibility: columnVisibilityZeiterfassungTableStart,
            isLoading,
            isError,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
        },
        initialState: {
            density: 'compact',
            columnSizing: columnSizingStateZeiterfassungTable,
            columnVisibility: columnVisibilityZeiterfassungTableStart,
          },
        enableRowActions: true,
        onColumnVisibilityChange: handleColumnVisibilityChangeZeiterfassungTable,
        onColumnFiltersChange: handleColumnFilterChange,
        onGlobalFilterChange: handleglobalFilterChange,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableRowSelection: enableRowSelection,
        enableStickyHeader: true,
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        layoutMode: 'grid',
        onColumnSizingChange: handleColumnSizingChange,
        manualFilters: true,
        manualPagination: true,
        manualSorting: true,
        rowCount: rowCount,
        muiTableHeadRowProps: {
            sx: { justifyContent: "center" },
          },
          muiTableBodyCellProps: {
            style: { overflow: "hidden", fontSize: "13px" },
          },
          muiTableProps: {
            sx: { tableLayout: 'fixed' },
          },
          muiTablePaperProps: {
            elevation: 0,
        },
        muiTableContainerProps: ({ table }) => ({
            sx: { height: table.getState().isFullScreen ? '100%' : '100%', minHeight: '230px', maxHeight: "100%" }
          }),
        localization: MRT_Localization_DE,
        muiTableBodyRowProps: ({ row }) => ({
            onDoubleClick: () => { 
                setrowDATA(row.original);  
                setopenModal(true); 
            },
            sx: {
                background: 
                    row.original.Feiertaginfo === "Samstag" || 
                    row.original.Feiertaginfo === "Sonntag" || 
                    (row.original.Feiertaginfo && row.original.Feiertaginfo.startsWith("Feiertag"))
                        ? 'repeating-linear-gradient(45deg, #f6f6f6, #f6f6f6 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : row.original.Feiertaginfo === "Krank" 
                        ? 'repeating-linear-gradient(45deg, #ffe7e7c2, #ffe7e7c2 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : row.original.Feiertaginfo === "Urlaub" 
                        ? 'repeating-linear-gradient(45deg, #ece7ffc2, #ece7ffc2 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : row.original.Feiertaginfo === "Schule" 
                        ? 'repeating-linear-gradient(45deg, #e7ffedc2, #e7ffedc2 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : row.original.Feiertaginfo === "Frei" 
                        ? 'repeating-linear-gradient(45deg, #fff8e7c2, #fff8e7c2 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : row.original.Feiertaginfo === "Frei auf Überstunden" 
                        ? 'repeating-linear-gradient(45deg, #fff8e7c2, #fff8e7c2 10px, rgba(255, 255, 255, 0.1) 10px, rgba(255, 255, 255, 0.1) 20px)' 
                        : 'inherit',
            },
        }),        

        renderToolbarInternalActions: () => (
            <Box>   
                        <MRT_ToggleGlobalFilterButton table={tableInstance} />                                    
                        <MRT_ShowHideColumnsButton table={tableInstance} />
                        <MRT_ToggleFiltersButton table={tableInstance} />
                        <MRT_ToggleFullScreenButton table={tableInstance}/>                                       
            </Box>
          ),
          displayColumnDefOptions: {
            'mrt-row-actions': {
              size: 20,
              header: ""            
            },
          },
          renderRowActions: ({ row }) => {
           const rowId = row.id;
            if ((row.original.start_time === null || row.original.start_time === "") &&
                !row.original.Feiertaginfo.startsWith("Feiertag") &&
                !row.original.Feiertaginfo.startsWith("Samstag") &&
                !row.original.Feiertaginfo.startsWith("Sonntag")) {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <IconButton
                            onClick={(event) => handleMenuOpen(event, rowId, row)}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor) && currentRowId === rowId}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                key={0}
                                onClick={() => {
                                    if (row.original.Feiertaginfo === "Urlaub") {
                                        UpdateRowStatus(null, row.original.id);
                                    } else {
                                        UpdateRowStatus("Urlaub", row.original.id);
                                    }
                                    // refreshInfo();
                                    handleMenuClose();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <FlightTakeoff />
                                </ListItemIcon>
                                Urlaub
                            </MenuItem>
                            <MenuItem
                                key={1}
                                onClick={() => {
                                    if (row.original.Feiertaginfo === "Krank") {
                                        UpdateRowStatus(null, row.original.id, 0);
                                        refreshInfo();
                                        handleMenuClose();
                                    } else {
                                        handleDialogOpen()
                                    }
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <Coronavirus />
                                </ListItemIcon>
                                Krank
                            </MenuItem>
                            <MenuItem
                                key={2}
                                onClick={() => {
                                    if (row.original.Feiertaginfo === "Schule") {
                                        UpdateRowStatus(null, row.original.id);
                                    } else {
                                        UpdateRowStatus("Schule", row.original.id);
                                    }
                                    // refreshInfo();
                                    handleMenuClose();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <School />
                                </ListItemIcon>
                                Schule
                            </MenuItem>
                            <MenuItem
                                key={3}
                                onClick={() => {
                                    if (row.original.Feiertaginfo === "Frei") {
                                        UpdateRowStatus(null, row.original.id);
                                    } else {
                                        UpdateRowStatus("Frei", row.original.id);
                                    }
                                    // refreshInfo();
                                    handleMenuClose();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <SportsTennis />
                                </ListItemIcon>
                                Frei
                            </MenuItem>
                            <MenuItem
                                key={4}
                                onClick={() => {
                                    if (row.original.Feiertaginfo === "Frei auf Überstunden") {
                                        UpdateRowStatus(null, row.original.id, 0);
                                        handleMenuClose(); 
                                        refreshInfo();
                                    } else {
                                        handleDialogOpenueberstunden();
                                    }
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <Scoreboard />
                                </ListItemIcon>
                                Frei auf Überstunden
                            </MenuItem>                            
                        </Menu>
                        <Dialog open={dialogOpen} onClose={handleDialogClose}>
                            <DialogTitle>Arbeitsstunden</DialogTitle>
                            <DialogContent>
                                <Typography>Wieviele Stunden hättest du an diesem Tag gearbeitet? Die Stundenzahl wird dir gutgeschrieben.</Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Arbeitsstunden"
                                    type="number"
                                    fullWidth
                                    value={hoursWorked}
                                    onChange={(e) => setHoursWorked(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirm}>Bestätigen</Button>
                                <Button onClick={handleDialogClose}>Abbrechen</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={dialogOpenUeberstunden} onClose={handleDialogUeberstundenClose}>
                            <DialogTitle>Arbeitsstunden</DialogTitle>
                            <DialogContent>
                                <Typography>Wieviele Stunden hättest du an diesem Tag gearbeitet? Die Stundenzahl wird dir gutgeschrieben.</Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Arbeitsstunden"
                                    type="number"
                                    fullWidth
                                    value={ueberstunden}
                                    onChange={(e) => setueberstunden(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirmUeberstunden}>Bestätigen</Button>
                                <Button onClick={handleDialogUeberstundenClose}>Abbrechen</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                );
            }
            return null;
        }          
    });

    return (
        <div>
            <MaterialReactTable table={tableInstance} />    
            <ZeiterfassungModalUser openModal={openModal} setopenModal={setopenModal} rowDATA={rowDATA} refreshZeiterfassungTable={() => setrefreshZeiterfassungTable(!refreshZeiterfassungTable)}></ZeiterfassungModalUser>     
        </div>
    );
};

export default ZeiterfassungTableServerside;
