import React, { useState, useEffect } from 'react';
import { Stack, Typography, Modal, Box, Fade, Divider, TextField, Button } from '@mui/material';
import { CloseSharp } from '@mui/icons-material';
import moment from 'moment';
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from "../hooks/useAuth";
import { useDispatch, useSelector } from 'react-redux';
import { globalAppBarMessage } from '../hooks/reduxactions';
import { setupdateZeiterfassung } from '../hooks/reduxactions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const infosContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '15px',
};

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

export default function ZeiterfassungModalUser({ openModal, setopenModal, rowDATA, refreshZeiterfassungTable }) {
    const handleClose = () => setopenModal(false);
    const [valueCheckinTextfield, setvalueCheckinTextfield] = useState('');
    const [valueCheckoutTextfield, setvalueCheckoutTextfield] = useState('');
    const [valuePauseTextfield, setvaluePauseTextfield] = useState('');
    const [alleuser, setalleuser] = useState([]);
    const updateZeiterfassung = useSelector(state => state.updateZeiterfassung);

    const [error, setError] = useState('');
    const { user, logout, hasPermission } = useAuth();
    const dispatch = useDispatch();

    const calculateTotalBreakTime = (breaktime) => {
        const duration = moment.duration(breaktime, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const isValidTime = (time) => {
        return moment(time, 'HH:mm:ss', true).isValid();
    };

    const isValidDateTime = (time) => {
        return moment(time, 'YYYY-MM-DD HH:mm:ss', true).isValid();
    };

    useEffect(() => {
        if (rowDATA) {
            setError('');
            isValidDateTime(rowDATA.start_time) ? 
            setvalueCheckinTextfield(moment(rowDATA.start_time).format("HH:mm:ss")) : setvalueCheckinTextfield("")
            isValidDateTime(rowDATA.end_time) ? 
            setvalueCheckoutTextfield(moment(rowDATA.end_time).format("HH:mm:ss")) : setvalueCheckoutTextfield("")
            setvaluePauseTextfield(calculateTotalBreakTime(rowDATA.pausegesamt));

            
        }
    }, [rowDATA]);

    useEffect(() => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchLoadBearbeiter: 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setalleuser(data)
        })
    }, []);
   


    const handleTextFieldCheckinChange = (e) => {
        setvalueCheckinTextfield(e.target.value);
    };

    const handleTextFieldCheckoutChange = (e) => {
        setvalueCheckoutTextfield(e.target.value);
    };

    const handleTextFieldPauseChange = (e) => {
        setvaluePauseTextfield(e.target.value);
    };



    const convertPauseToSeconds = (pause) => {
        const duration = moment.duration(pause, 'HH:mm:ss');
        return duration.asSeconds();
    };

    const handleOnSubmit = (evt) => {
        evt.preventDefault();
        setError(''); 

        if (!isValidTime(valueCheckinTextfield)) {
            setError('Check-in ungültig. Bitte nutze folgendes Format hh:mm:ss.');
            return;
        }
        if (!isValidTime(valueCheckoutTextfield)) {
            setError('Check-out ungültig. Bitte nutze folgendes Format hh:mm:ss.');
            return;
        }
        if (!moment(valueCheckoutTextfield, 'HH:mm:ss').isAfter(moment(valueCheckinTextfield, 'HH:mm:ss'))) {
            setError('Check-out muss nach dem Check-in sein.');
            return;
        }
        if (!isValidTime(valuePauseTextfield)) {
            setError('Pause ungültig. Bitte nutze folgendes Format hh:mm:ss.');
            return;
        }

        if (hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Zeiterfassungsadmin') || hasPermission(user, 'Zeiterfassungsbearbeiter'))
        {    
            //Convert back to datetime   
            const datePart = rowDATA.TagundDatum.split(' - ')[1];
            const formattedDate = moment(datePart, 'DD.MM.YYYY').format('YYYY-MM-DD'); 
    
            const checkin = `${formattedDate} ${valueCheckinTextfield}`;
            const checkout = `${formattedDate} ${valueCheckoutTextfield}`;        
            const pauseInSeconds = convertPauseToSeconds(valuePauseTextfield);
            const firmenInfos = JSON.parse(localStorage.getItem('firmenInfos'));
            let Tagesarbeitszeit = firmenInfos.arbeitszeitVollzeitkraft / 5;
            const Tagesarbeitszeitduration = moment.duration(Tagesarbeitszeit, 'hours');
    
    
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchUpdateZeiterfassungüberModal: 1,
                    id: rowDATA.id,
                    checkin: checkin,
                    checkout: checkout,
                    pause: pauseInSeconds,
                    taeglicheArbeitszeitinSekunden: Tagesarbeitszeitduration.asSeconds()
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                refreshZeiterfassungTable();
                dispatch(setupdateZeiterfassung(!updateZeiterfassung));
                dispatch(globalAppBarMessage(`Die Änderung wurde gespeichert.`));
                setopenModal(false);
            });    
        }
        else
        {  
            const adminEmails = alleuser
            .filter(user => {
                const berechtigungen = user.berechtigungen;
                if (!berechtigungen) return false;
                return Array.isArray(berechtigungen) ? berechtigungen.includes("Zeiterfassungsbearbeiter") : berechtigungen.includes("Zeiterfassungsbearbeiter");
            })
            .map(user => user.email);

            //Send the array of admin emails to sendemail.php
            fetch(`${fetchdataUrl}sendemail.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        sendArbeitszeitänderung: 1,
                        array: adminEmails,
                        username: user.vollstaendigerName,
                        checkin: valueCheckinTextfield,
                        checkout: valueCheckoutTextfield,
                        pause: valuePauseTextfield,
                        datum: rowDATA.TagundDatum
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
            })
            .then(response => response.json())
            .then(data => {
                dispatch(globalAppBarMessage(`Die Anfrage wurde an einen Administrator weitergeleitet.`));
                setopenModal(false);
            })   
        }     
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={openModal}>
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                            <span><b>Arbeitszeitkorrektur:</b></span>
                        </div>
                        <Button onClick={() => setopenModal(false)}>
                        <CloseSharp />
                        </Button>
                    </div>
                    <Divider sx={{ marginTop: "5px" }} />
                    <Typography sx={{marginTop: "10px"}}>{rowDATA.TagundDatum}</Typography>
                    <form onSubmit={handleOnSubmit}>
                        <div style={infosContainerStyle}>
                            <div style={contentStyle}>
                                <TextField
                                    label="Check-in"
                                    variant="outlined"
                                    value={valueCheckinTextfield}
                                    onChange={handleTextFieldCheckinChange}
                                />
                            </div>
                            <div style={contentStyle}>
                                <TextField
                                    label="Check-out"
                                    variant="outlined"
                                    value={valueCheckoutTextfield}
                                    onChange={handleTextFieldCheckoutChange}
                                />
                            </div>
                            <div style={contentStyle}>
                                <TextField
                                    label="Gesamte Pause"
                                    variant="outlined"
                                    value={valuePauseTextfield}
                                    onChange={handleTextFieldPauseChange}
                                />
                            </div>
                        </div>
                        {error && <Typography sx={{fontSize: "12px", marginTop: "5px"}} color="error">{error}</Typography>}
                        {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Zeiterfassungsadmin') || hasPermission(user, 'Zeiterfassungsbearbeiter')?
                        <Button sx={{marginTop: "10px"}} type='submit'>Speichern</Button> :
                        <Stack direction="column">
                        <Typography sx={{fontSize: "12px", marginTop: "5px"}}>Du hast keine Berechtigung, die Arbeitszeit direkt zu ändern. Du kannst allerdings eine Korrektur beantragen. Trage hierfür einfach die Änderung ein.</Typography>
                        <Button type='submit' sx={{marginTop: "10px"}}>Änderung beantragen</Button>
                        </Stack> }
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}