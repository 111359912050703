import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Navigate
} from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { RegisterPage } from "./pages/Register";
import { UserStartPage } from "./pages/UserStart";
import { KundenSuchePage } from "./pages/KundenSuchePage";
import { UserKundenübersichtPage } from "./pages/UserKundenübersicht";
import { SchadenübersichtPage } from "./pages/SchadenübersichtPage";
import { ZeiterfassungPage } from "./pages/ZeiterfassungPage";
import { VersicherungsanalysenPage } from "./pages/VersicherungsanalysenPage";
import { VertriebsaktionenPage } from "./pages/VertriebsaktionenPage";
import { VersorgungsstreckePage } from "./pages/VersorgungsstreckePage";
import { SchadenÄnderungPage } from "./pages/SchadenÄnderungPage";
import { GeburtstagslistePage } from "./pages/GeburtstagslistePage";
import { BemerkungenKunde } from "./pages/BemerkungenKunde";
import { TermineKundePage } from "./pages/KundeTermine";
import { DateiablageKundePage } from "./pages/DateiablageKundePage";
import { SettingsPage } from "./pages/Settings";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import "./styles.css";
import { AuthLayout } from "./components/AuthLayout";
import React, { useEffect, useState } from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {    
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 0)
  );

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} loader={() => defer({ userPromise: getUserData() })}>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
      <Route index element={<Navigate to="UserStart" />} />
        <Route path="UserStart" element={<UserStartPage />} />
        <Route path="KundenSuchePage" element={<KundenSuchePage />} />       
        <Route path="SchadenübersichtPage" element={<SchadenübersichtPage />} /> 
        <Route path="ZeiterfassungPage" element={<ZeiterfassungPage />} /> 
        <Route path="VersicherungsanalysenPage" element={<VersicherungsanalysenPage />} /> 
        <Route path="VertriebsaktionenPage" element={<VertriebsaktionenPage />} /> 
        <Route path="SchadenÄnderungPage" element={<SchadenÄnderungPage />} /> 
        <Route path="GeburtstagslistePage" element={<GeburtstagslistePage />} /> 
        <Route path="VersorgungsstreckePage" element={<VersorgungsstreckePage />} />         
        <Route path="UserKundenübersicht/:partnernummer" element={<UserKundenübersichtPage />} />
        <Route path="UserKundenübersicht/:partnernummer/BemerkungenKunde" element={<BemerkungenKunde />} />
        <Route path="UserKundenübersicht/:partnernummer/TermineKunde" element={<TermineKundePage />} />
        <Route path="UserKundenübersicht/:partnernummer/DateiablageKunde" element={<DateiablageKundePage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
    </Route>
  )
);
