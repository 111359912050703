import React, { useState, useEffect, useRef} from 'react';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import imageAXAGruppenbild from '../img/BeratungsdokuGruppenBild.png';
import imageDBVGruppenbild from '../img/BeratungsdokuDBV.png';
import imageDBVGruppenbildneutral from '../img/BeratungsdokuNeutralDBV.png';
import imageAXAGruppenbildneutral from '../img/BeratungsdokuNeutralAXA.png';
import Versorgungsrechnerbild from '../img/Versorgungsrechnerbild.png';
import BildBüro from '../img/BildBüro.png';
import image360Grad from '../img/plan360.png';
import imageaxalogo from '../img/axalogowhite.png';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import ConfirmDialog from '../hooks/confirmDialog';
import { toggleModalBeratungsdokumentation, toggleConfirmDialogBeratungsdoku, updateEventData} from '../hooks/reduxactions'
import PDFMerger from 'pdf-merger-js/browser';
import { CustomSlateEditor } from '../components/CustomSlateEditor';
import { HoveringToolbar } from '../components/HoveringToolbar';
import { createEditor } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { fetchdataUrl, augustinindividualisiert } from '../A-config/config';
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  Input,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Snackbar,
  Alert,
  LinearProgress,
  Portal
} from '@mui/material'

import {TouchAppSharp, BoyRounded, GirlRounded, PersonAddRounded, MaleRounded, FemaleRounded, ExpandMoreSharp, PrintSharp, BookmarkSharp, SaveAltSharp, CloseSharp, SaveAsSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Co2Sharp, ConstructionOutlined, SaveSharp, ExpandMore} from '@mui/icons-material';

const style = {
    overflow: "hidden",
    overflowY: "scroll",
    maxWidth: '980px',
    minWidth: "680px",
    position: 'absolute',
    height: '90%',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none !important',
    boxShadow: 24,
    p: 4,
    zIndex: 998,
};

const style2 = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  zIndex: 999,
};

export default function BeratungsdokuModal({vndata, alletermine}) {

    const [checkboxStates, setCheckboxStates] = useState({
        personalausweisPN: false,
        personalausweisVN: false,
    });

    const { updateUserStats } = useUpdateUserStats();
    const [IsPopupVersorgungsrechnerOpen, setIsPopupVersorgungsrechnerOpen] = useState(false);
    const [refreshKey, setRefreshKey] = useState(Date.now());
    const [editorContents, setEditorContents] = useState({});
    const [BeratungsdokuInitialValues, setBeratungsdokuInitialValues] = useState({});
    const textfieldRef = React.useRef([]);
    const [valuesCheckboxandTextfield, setValuesCheckboxandTextfield] = useState({});
    const { user, hasPermission} = useAuth();
    const [valueTab, setValueTab] = React.useState(0);
    const [optionsDoku, setoptionsDoku] = useState([]);
    const [vertragsdaten, setvertragsdaten] = useState([]);
    const [showSpeichern, setshowSpeichern] = useState(false);
    const [showPDFCreation, setshowPDFCreation] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isFirstMount, setIsFirstMount] = useState(true);
    const [openDialogKinder, setOpenDialogKinder] = useState(false);
    const [NameKind, setNameKind] = useState('');
    const [VornameKind, setVornameKind] = useState('');
    const [GebDatumKind, setGebDatumKind] = useState('');
    const [GeschlechtKind, setGeschlechtKind] = useState('');
    const AXADBVColor = useSelector(state => state.axaDbvColor);
    const isModalOpen = useSelector(state => state.isModalOpen);
    const isConfirmDialogBeratungsdokuOpen = useSelector(state => state.isConfirmDialogBeratungsdokuOpen);
    const [versorgungsrechnerurl, setversorgungsrechnerurl] = useState('');


    const dispatch = useDispatch();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "error"
    });

    const handleSnackbarClose = () => {
        setSnackbar({
        open: false,
        message: "",
        severity: "error"
        });
    }

    const popupStyle = {
        visibility: IsPopupVersorgungsrechnerOpen ? 'visible' : 'hidden',
        position: 'fixed',
        top: '20%', // Adjust as needed
        left: '50%',
        transform: 'translate(-50%, -20%)',
        width: '1000', // Adjust as needed
        height: '1100', // Adjust as needed
        overflow: 'auto',
        zIndex: 1300, // Ensure it's above Modal
        background: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleShowConfirmDialog = () => dispatch(toggleConfirmDialogBeratungsdoku(true));
    const handleHideConfirmDialog = () => dispatch(toggleConfirmDialogBeratungsdoku(false));

    const handleAddChild = () => {
        if (!NameKind || !VornameKind || !GebDatumKind || !GeschlechtKind) {
        setSnackbar({
              open: true,
              message: "Bitte fülle zunächst alle Felder aus.",
              severity: "error"
        });
        // display error message or take any other appropriate action
        return;
        }

        const newChild = {
        Name: NameKind,
        Surname: VornameKind,
        Birthdate: GebDatumKind,
        Geschlecht: GeschlechtKind
        };

        // Create new array with new child, or add to existing array
        const updatedKinder = vndata.Kinder ? [...vndata.Kinder, newChild] : [newChild];

        // Update data with new kinder array
        const updatedData = {
        ...vndata,
        Kinder: updatedKinder
        };

        dispatch(updateEventData(updatedData));

        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchAddChildren: 1,
            pnr: vndata.PNr,
            name: NameKind,
            surname: VornameKind,
            birthdate: GebDatumKind,
            Geschlecht: GeschlechtKind
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        })
        .then((response) => {
        return response.json();
        })
        .then((data2) => {

        })
        .catch((error) => {
        // Handle error
        });

        const wordsToMatch = ['Ausbildung', NameKind, VornameKind];
        let matchFound = false;

        alletermine.map(item => {
        if (item.TerminTitleText) {
            const words = item.TerminTitleText.split(' ');
            if (wordsToMatch.every(wordToMatch => words.includes(wordToMatch))) {
                matchFound = true;
            }
        }
        });

        if (!matchFound) {
        //Automatische Anlage Ausbildungstermin:
        fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchAddTermin: 1,
                    TerminNameVN: vndata.Nachname,
                    TerminVornameVN: vndata.Vorname,
                    TerminPartnernummer: vndata.PNr,
                    TerminDateText: moment(GebDatumKind).add(15, 'years').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                    TerminBearbeiterText: user.vollstaendigerName,
                    TerminTitleText: "Ausbildung - " + VornameKind + " " + NameKind + " (" + GeschlechtKind + ")",
                    TerminArt: "Ausbildung",
                    BemerkungenTermin: null,
                    TerminFelder: null
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                // handleRefetchData();
                setSnackbar({
                    open: true,
                    message: "Neues Kind angelegt. Zusätzlich wurde ein Ausbildungstermin zum 15. Geburtstag erstellt.",
                    severity: "info"
                });
            });
        } else {
                setSnackbar({
                    open: true,
                    message: "Neues Kind angelegt. Es wurde kein Ausbildungstermin angelegt, da für dieses Kind bereits ein Termin besteht.",
                    severity: "info"
                });
        }
    }

    //load firmeninfos and versorgungsrechnerurl at start
    useEffect(() => {
        augustinindividualisiert ? setversorgungsrechnerurl('https://versorgungsrechner.augustinkg.de') : setversorgungsrechnerurl('https://meineversorgung.augustinkg.de')       
    }, []);

    const handleDoOnConfirmDialog = () => {
        dispatch(toggleConfirmDialogBeratungsdoku(false));
        savedata();
        setTimeout(() => {
                setshowSpeichern(false);
                dispatch(toggleModalBeratungsdokumentation(false));
        }, 500);
    };

    const handleCancelConfirmDialog = () => {
        dispatch(toggleConfirmDialogBeratungsdoku(false));
        handleHideConfirmDialog();
        dispatch(toggleModalBeratungsdokumentation(false));
    };

    const handleChangeTabs = (event, newValue) => {
        setValueTab(newValue);
    };

    const togglePopupVersorgungsrechner = () => {
        setIsPopupVersorgungsrechnerOpen(!IsPopupVersorgungsrechnerOpen);

        if (!IsPopupVersorgungsrechnerOpen) {
            setTimeout(sendDataToIframe, 500);
        }
    };

    const sendDataToIframe = () => {
        const iframe = document.getElementById('versorgungsrechneriframe');
        if (!iframe) return;
    
   
        const message = {
            action: 'fillnames',
            value: `${vndata.Vorname} ${vndata.Nachname}`,
        };
    
        iframe.contentWindow.postMessage(message, versorgungsrechnerurl);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates(prevStates => ({
          ...prevStates,
          [name]: checked,
        }));
    };

    const handleDeleteDokumentation = () => {
    if (selectedOption === optionsDoku[0].ID) {
        // show error message
        setSnackbar({
        open: true,
        message: "Dieser Eintrag kann nicht gelöscht werden.",
        severity: "error"
        });
    } else {
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchDeleteDoku: 1,
                id: selectedOption
        }),
        })
        .then(response => response.json())
      .then(data => {
        // remove deleted item from optionsDoku
        const updatedOptions = optionsDoku.filter(option => option.ID !== selectedOption);
        // set the updated options and select the first option
        setoptionsDoku(updatedOptions);
        setSelectedOption(updatedOptions.length > 0 ? updatedOptions[0].ID : '');
        setSnackbar({
          open: true,
          message: "Dokumentation erfolgreich gelöscht.",
          severity: "success"
        });
      })
      .catch((error) => {});
    }
    };

    const handleNewBeratungsdoku = () => {

        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddNewBeratungsdoku: 1,
                pnr: vndata.PNr,
                username: user.kürzel,
        }),
        })
        .then(response => response.json())
        .then(data => {
            const updatedOptions = [...optionsDoku, data];
            setoptionsDoku(updatedOptions);
            setSelectedOption(data.ID);
            setSnackbar({
             open: true,
             message: "Dokumentation erfolgreich hinzugefügt.",
             severity: "success"
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const handleEditorContentChange = (id, content) => {
        setEditorContents(prevContents => ({
            ...prevContents,
            [id]: content,
        }));
    };

    useEffect(() => {
        if (!selectedOption)
        {
            return;
        }
        const vndatastring = JSON.stringify(valuesCheckboxandTextfield, null, 2);
        updateUserStats(user.vollstaendigerName, "update", "Beratungsdokumentation", "Beratungsdokumentation bearbeitet", vndata.PNr, "");

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateBeratungsdoku: 1,
                data: valuesCheckboxandTextfield,
                id: selectedOption,
                username: user.kürzel,
            }),
        })
        .then(response => response.json())
        .then(data => {
            // Create a new array by mapping over the existing state
            const updatedOptions = optionsDoku.map((option) => {

            //Das +Zeichen konvertiert einen String in ein INT
            if(option.ID === data.ID) {
                    // Replace it with the new data
                    return data;
                } else {
                    // Otherwise return the option unchanged
                    return option;
                }
            });
            // Set the state to the new array
            setoptionsDoku(updatedOptions);
        })
        .catch((error) => {
            console.log(error)
            // Handle error
        });
    }, [valuesCheckboxandTextfield]);

    function savedata() {
        setshowSpeichern(true);

        // If no selection is done, select the first item
        if (!selectedOption && optionsDoku.length > 0) {
            setSelectedOption(optionsDoku[0].ID);
        }

        Object.keys(textfieldRef.current).forEach((key) => {
            valuesCheckboxandTextfield[key] = textfieldRef.current[key].value;
        });

        setValuesCheckboxandTextfield(prevContents => ({
            ...prevContents,
            ...checkboxStates,
            ...editorContents
        }));
    }

    const handleCloseAndSaveBeratungsdoku = () => {
        handleShowConfirmDialog();
    }

    const handleSaveBeratungsdoku = () => {
        savedata();
        setTimeout(() => {
                setshowSpeichern(false);
        }, 500);
    }

    const fetchDataAndRefreshFields = async (selectedOption) => {
        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: "POST",
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchDataBeratungsdoku: 1,
            pnr: vndata.PNr,
            selectedID: selectedOption,
            username: user.kürzel,
            }),
        });
        const data = await response.json();

        const newTextValues = {...BeratungsdokuInitialValues};
        const newCheckboxStates = {};

        Object.keys(data).forEach((key) => {
            if (textfieldRef.current[key]) {
            textfieldRef.current[key].value = data[key];
            }
            newTextValues[key] = data[key];

            if (key.includes("personalausweis")) {
                newCheckboxStates[key] = data[key];
            }
        });

        setBeratungsdokuInitialValues(newTextValues);
        setCheckboxStates(newCheckboxStates);
        setRefreshKey(Date.now());
        return data;
    };

    const printdata = async () => {
        setshowPDFCreation(true);

        const generatePDF = () => {

        const element = document.getElementById("elementtoprint");

        const opt = {
            margin: 0.5,
            pagebreak: { mode: "avoid-all", before: ".header2forpagebreak" },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
        };

        const optlandscape = {
            ...opt,
            margin: [0.3,0.3,0.3,0.3],
            jsPDF: { unit: "in", format: "letter", orientation: "landscape" }
        };

        const optlandscapenomargin = {
            ...opt,
            margin: 0,
            jsPDF: { unit: "in", format: "letter", orientation: "landscape" }
        };

        // Create a new div to hold all tab content elements
        const allTabContent = document.createElement("div");
        allTabContent.className = "allTabContent";

        const storedData = localStorage.getItem('firmenInfos');
        const data = JSON.parse(storedData);

        const container = document.createElement("div");
            container.innerHTML = `
            <style>
                @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');
                .container {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }

                .text-container {
                    position: absolute;
                    top: 200px;
                    left: 45px;
                    z-index: 1; /* Place the spans on top of the image */
                    display: flex;
                    flex-direction: column;
                    color: ${AXADBVColor};
                }

                .text-container-kontakt {
                    position: absolute;
                    top: 775px;
                    left: 55px;
                    z-index: 1; /* Place the spans on top of the image */
                    display: flex;
                    flex-direction: column;
                    color: ${AXADBVColor};
                }

                .middle-container-image {                
                    position: absolute;
                    top: 400px;
                    left: 100px;
                    width: 80%;
                    height: 400px;
                }

                .text-container-Firmennamen {
                    position: absolute;
                    top: 12px;
                    left: 150px;
                    z-index: 1; /* Place the spans on top of the image */
                    display: flex;
                    flex-direction: column;
                    color: white;
                }

                .text-overlay {
                    font-weight: bold;
                    font-family: 'League Spartan', sans-serif;
                    font-size: 30px;
                    padding: 10px;
                }

                .larger-text-overlay {
                    font-weight: bold;
                    font-family: 'League Spartan', sans-serif;
                    font-size: 50px;
                    padding: 10px;
                }

                .smaller-text-overlay {
                    font-family: 'League Spartan', sans-serif;
                    font-size: 25px;
                }

                .smaller-text-overlay-kontakt {
                    font-weight: normal;
                    font-family: 'League Spartan', sans-serif;
                    font-size: 16px;
                    padding: 10px;
                    padding-bottom: 20px;
                }
                .image {
                    max-width: 100%;
                    z-index: 1; /* Ensure the image is behind the text if needed */
                }
                .buro-image {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                 }
                 .section {
                    page-break-inside: avoid;
                }                
                .contentGroup {
                    margin-top: 40px;
                    page-break-after: avoid;
                }
            </style>
            <div class="container">
                <div class="text-container-Firmennamen">
                    <span class="text-overlay" style="font-size: 35px; padding: 0px; padding-bottom: 5px; padding-top: 10px">${data.firmenName}</span>
                    <span class="text-overlay" style="font-size: 20px; padding: 0px; padding-bottom: 2px;">${data.strasse}</span>
                    <span class="text-overlay" style="font-size: 20px; padding: 0px;">${data.plzStadt}</span>
                </div>
                <div class="text-container-kontakt">
                    <span class="smaller-text-overlay-kontakt" style="padding-top: 10px;">${data.webauftritt}</span>
                    <span class="smaller-text-overlay-kontakt" style="padding-top: 10px;">${data.telefonnummer}</span>
                    <span class="smaller-text-overlay-kontakt" style="padding-top: 8px;">${data && data.checkboxpersemailberatungsdokuanzeigen === true ? user.email : data.email}</span>
                </div>
                <div class="middle-container-image">
                <img class="buro-image" src="${augustinindividualisiert ? BildBüro : ""}">
                </div>
                <div class="text-container">
                    <span class="larger-text-overlay">Persönliche<br>Beratungsdokumentation</span>
                    <span class="smaller-text-overlay" style="padding: 10px;">für <b>${vndata.Vorname} ${vndata.Nachname}, geb. ${vndata.gebdatum}</b></span>
                    <span class="text-overlay">${data.slogan}</span>
                </div>
                <img class="image" src="${AXADBVColor === "#2e3192" ? imageAXAGruppenbildneutral
                    : AXADBVColor === "#e88401" ? imageDBVGruppenbildneutral : imageAXAGruppenbildneutral }">
            </div>`;
        allTabContent.appendChild(container);

        const tabContents = element.querySelectorAll(".tabContentBeratungsdoku");
        tabContents.forEach((tabContent, index) => {
            const clonedTabContent = tabContent.cloneNode(true);
            clonedTabContent.style.display = "block";
            clonedTabContent.style.marginLeft = "50px";
            clonedTabContent.style.marginRight = "30px";
            if (index === 1)
            {
                // Create a header element for the content
                const header = document.createElement("div");
                // Add a class to the header for page breaks
                header.className = "header2forpagebreak";
                allTabContent.appendChild(header);
            }
            allTabContent.appendChild(clonedTabContent);
        });

        const clonedElement = allTabContent.cloneNode(true);

    setTimeout(async function() {
        // Convert the allTabContent to a PDF
        opt.filename = "allTabContent.pdf";
        const allTabContentBlob = await html2pdf().set(opt).from(clonedElement).outputPdf('blob');

        // Create a new div to hold the new tab content div and append it to the original element
        const newElement = document.createElement("div");


        // Definieren einer Hilfsfunktion, die auf die iframe-Antwort wartet
        const waitForIframeResponse = () => {
            return new Promise((resolve) => {
                const handleMessage = (event) => {
                    // Überprüfen Sie hier die Herkunft und die Daten der Nachricht
                    // um sicherzustellen, dass sie vom iframe und mit der erwarteten Antwort kommt
                    if (event.origin === versorgungsrechnerurl && event.data.action === 'pdfGenerated') {
                        window.removeEventListener('message', handleMessage);
                        resolve(event.data.pdfBlob); // Resolve the promise with the PDF blob from the iframe
                    }
                };
                window.addEventListener('message', handleMessage);
            });
        };

        // Senden der Anfrage an den iframe, um das PDF zu generieren
        const iframe = document.getElementById('versorgungsrechneriframe');
        iframe.contentWindow.postMessage({ action: 'printPDF' }, '*');

        // Warten auf die Antwort des iframes
        const iframePdfBlob = await waitForIframeResponse();

        // Clone the vertragsdatendiv element and append it to the new element
        const vertragsdatendiv = element.querySelector(".vertragsdatendiv").cloneNode(true);
        const title = vertragsdatendiv.querySelector(".titleDiv").cloneNode(true);  // Clone the title
        const table = vertragsdatendiv.querySelector("table");  // Get the table from the div

        let newTable = document.createElement("table");
        let tableContainer = document.createElement("div");  // Create a new table container

        newElement.appendChild(title);  // Append the title to the newElement
        newElement.appendChild(tableContainer);  // Add it to the newElement

        let totalHeight = 0;
        for (let row of table.rows) {  // Now we can access rows directly from the table
            let clonedRow = row.cloneNode(true);
            document.body.appendChild(clonedRow);  // Append to the body to calculate height.
            let rowHeight = clonedRow.getBoundingClientRect().height;  // Use getBoundingClientRect().height
            document.body.removeChild(clonedRow);  // Remove from body.
            totalHeight += rowHeight;
            newTable.appendChild(clonedRow);  // Add the row to the current table.

            if (totalHeight > 600) {  // If the height threshold is reached, create a new table.
                tableContainer.appendChild(newTable);
                tableContainer = document.createElement("div");  // Create a new table container
                newElement.appendChild(tableContainer);  // Add it to the newElement
                newTable = document.createElement("table");
                totalHeight = rowHeight;  // Start counting from the current row height
            }
        }

        // If the newTable still has rows or there are no rows (only one page), append it to the current tableContainer
        if (newTable.hasChildNodes()) {
            tableContainer.appendChild(newTable);  // Append the last table if it has any rows.
        }

        // Convert the vertragsdatendiv to a PDF
        optlandscape.filename = "vertragsdatendiv.pdf";
        const vertragsdatendivBlob = await html2pdf().set(optlandscape).from(newElement).outputPdf('blob');

        //Create Last Page with Plan360°
        const Content360Container = document.createElement("div");
        Content360Container.innerHTML = `
            <div>
            <img style="max-width: 100%;"
            src="${image360Grad}">
            </div>`;

        // Convert the Plan360° to a PDF
        optlandscape.filename = "360.pdf";
        const plan360Blob = await html2pdf().set(optlandscapenomargin).from(Content360Container).outputPdf('blob');


        // Merge the PDFs
        const merger = new PDFMerger();
        await merger.add(allTabContentBlob);
        await merger.add(vertragsdatendivBlob);
        await merger.add(plan360Blob);
        await merger.add(iframePdfBlob);
        const mergedPdf = await merger.saveAsBlob();

        // Create a URL for the merged PDF
        const mergedPdfUrl = URL.createObjectURL(mergedPdf);

        // Remove the new elements after the PDFs are created
        if (element.contains(newElement)) {
            element.removeChild(newElement);
        }

        setshowPDFCreation(false);

        // Here, you can use mergedPdfUrl as you need. For instance, download the merged PDF
        const link = document.createElement('a');
        link.href = mergedPdfUrl;
        link.download = `Beratungsdokumentation - ${vndata.Nachname}, ${vndata.Vorname}.pdf`;
        link.click();
        }, 2000); // Wait 2 seconds (2000 milliseconds) before running the function
    };
    
        // Ensure all fonts are loaded
        await document.fonts.ready;

        // Check if all images are loaded
        const images = document.querySelectorAll("img");
        await Promise.all(Array.from(images).map(img => {
            return new Promise(resolve => {
            if (img.complete) {
                resolve();
            } else {
                img.onload = resolve;
                img.onerror = resolve; // resolve even on error to avoid infinite waiting
            }
            });
        }));
        // Wait for all resources to be loaded before generating PDF
        if (document.readyState === 'complete') {
            generatePDF();
        } else {
            window.onload = generatePDF;
    }

    };

    useEffect(() => {
        //Hierdurch wird der useEffect nur ausgelöst, wenn das Modal wirklich geöffnet wird und nicht beim Start der Applikation.
        if (!isModalOpen) return;

        async function fetchData() {
            const data = await fetchDataAndRefreshFields(selectedOption);

            //Load Alle Dokus für PNR
            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchLoadalleDokus: 1,
                pnr: vndata.PNr
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            });
            const data2 = await response.json();
            setoptionsDoku(data2);

            // Only set the selected option to the first item on the initial mount
            if (data2.length > 0 && isFirstMount) {
                setSelectedOption(data2[0].ID);
                setIsFirstMount(false);
            }
        }
        fetchData();
    }, [isModalOpen, selectedOption, isFirstMount]);

    useEffect(() => {
        // This effect will be called after the component re-renders with the updated optionsDoku array
        if (optionsDoku.length > 0 && !selectedOption) {
        setSelectedOption(optionsDoku[0].ID);
        }
    }, [isModalOpen, selectedOption]);

    useEffect(() => {
        if (!isModalOpen) return;

        if (window.chrome && window.chrome.webview && isDesktop && localStorage.getItem("setcheckboxEVTaufrufen") === "true")
        {
        var dataarray = ['VertragsdatenÜbertragen'];
        window.chrome.webview.postMessage(dataarray);
        }

        const handleMessage = (event) => {
        if (event.data && Array.isArray(event.data) && event.data.length > 0 && event.data[0].Verkaufsform) {
            setvertragsdaten(event.data)
        }
      };
      window.chrome?.webview?.addEventListener("message", handleMessage);

      // Remove event listener on unmount
      return () => {
        window.chrome?.webview?.removeEventListener("message", handleMessage);
      };
  }, [isModalOpen]);

    return (
        <div>
        <Modal
                        open={isModalOpen}
                        onClose={handleCloseAndSaveBeratungsdoku}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >

                    <Fade in={isModalOpen}>
                        <Box sx={style}>
                            <Snackbar   anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                                    {snackbar.message}
                                </Alert>
                            </Snackbar>
                        {showPDFCreation ? (
                        <Box sx={style2}>
                         <Stack direction="column" >
                            <Alert icon={false} style={{justifyContent: "center"}} severity="info"><b>Die PDF wird erstellt...</b></Alert>
                            <LinearProgress></LinearProgress>
                         </Stack>
                        </Box>
                        ) : ( null ) }
                        <div style={{display:"flex", alignItems: "center", marginBottom: "10px"}}>
                        <ConfirmDialog
                                open={isConfirmDialogBeratungsdokuOpen}
                                onClose={() => dispatch(toggleConfirmDialogBeratungsdoku(false))}
                                message="Soll die Beratungsdokumentation vor dem Schließen abgespeichert werden?"
                                onConfirm={handleDoOnConfirmDialog}
                                onCancel={handleCancelConfirmDialog}
                        />
                        <Typography component="div" variant="h5"><b>Beratungsdokumentation</b></Typography>
                        <Tooltip title="Schließen">
                           <IconButton onClick={handleCloseAndSaveBeratungsdoku} style={{position: "absolute", right: "20px"}}><CloseSharp></CloseSharp></IconButton>
                        </Tooltip>

                        <Tabs sx={{ml: 4}} value={valueTab} onChange={handleChangeTabs}>
                            <Tab label="Datenerfassung" />
                            <Tab label="Private Vorsorge" />
                        </Tabs>
                        </div>
                        <Divider />
                            {showSpeichern &&
                            <Stack direction="column">
                                <Alert severity='success'>Die Daten werden gespeichert...</Alert>
                                <LinearProgress></LinearProgress>
                            </Stack>}
                            <div style={{display:"flex", alignItems:"center", marginTop: "5px"}}>
                                <FormControl variant="standard" sx={{minWidth: 500 }}>
                                    <InputLabel>Gespeicherte Dokumentationen</InputLabel>
                                    <Select
                                        sx={{fontSize: "0.9rem"}}
                                        label="Aufgabe für"
                                        size='small'
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        >
                                        {optionsDoku.sort((a, b) => {
                                            const dateA = moment(a["setletzteÄnderungDatum"], 'DD.MM.YYYY - HH:mm:ss');
                                            const dateB = moment(b["setletzteÄnderungDatum"], 'DD.MM.YYYY - HH:mm:ss');
                                            if (a["base"] === 1 && b["base"] === 1) {
                                                // both have "base" === 1, sort by date
                                                if (dateA.isAfter(dateB)) {
                                                return -1; // a has more recent date, should come before b
                                                } else if (dateB.isAfter(dateA)) {
                                                return 1; // b has more recent date, should come before a
                                                } else {
                                                return 0; // dates are equal, use default sorting
                                                }
                                            } else if (a["base"] === 1) {
                                                return -1; // a should come before b
                                            } else if (b["base"] === 1) {
                                                return 1; // b should come before a
                                            } else {
                                                // neither has "base" === 1, sort by date
                                                if (dateA.isSame(dateB)) {
                                                // dates are equal, sort by time
                                                const timeA = moment(a["setletzteÄnderungDatum"], 'DD.MM.YYYY - HH:mm:ss').format('HH:mm:ss');
                                                const timeB = moment(b["setletzteÄnderungDatum"], 'DD.MM.YYYY - HH:mm:ss').format('HH:mm:ss');
                                                if (timeA < timeB) {
                                                    return 1; // b has later time, should come before a
                                                } else if (timeB < timeA) {
                                                    return -1; // a has later time, should come before b
                                                } else {
                                                    return 0; // times are equal, use default sorting
                                                }
                                                } else if (dateB.isBefore(dateA)) {
                                                return -1; // a has more recent date, should come before b
                                                } else {
                                                return 1; // b has more recent date, should come before a
                                                }
                                            }
                                        }).map((a, b) => {
                                        if (a["base"] === 1) {
                                            return (
                                            <MenuItem sx={{ fontSize: "0.8rem" }} key={b} name={b} value={a["ID"]}>
                                                {"Aktuell" + " - Änderung: " + a["setletzteÄnderungDatum"] + " (" + a["setletzteÄnderungUser"] + ")"}
                                            </MenuItem>
                                            );
                                        } else {
                                            return (
                                            <MenuItem sx={{ fontSize: "0.8rem" }} key={b} name={b} value={a["ID"]}>
                                                {"Neu - Erstellt: " + a["setletzteErstellDatum"] + " (" + a["setletzteErstellUser"] + ") - Änderung: " + a["setletzteÄnderungDatum"] + " (" + a["setletzteÄnderungUser"] + ")"}
                                            </MenuItem>
                                            );
                                        }
                                        })}
                                </Select>
                                </FormControl>
                                <Stack direction="row" sx={{mt: 2, ml: 2}}>
                                    <Tooltip title="Als zusätzliche Dokumentation speichern">
                                        <IconButton onClick={handleNewBeratungsdoku}><BookmarkSharp></BookmarkSharp></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Ausgewählte Dokumentation löschen">
                                        <IconButton onClick={handleDeleteDokumentation}><Delete></Delete></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Drucken">
                                        <IconButton onClick={printdata}><PrintSharp></PrintSharp></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Speichern">
                                    <IconButton onClick={handleSaveBeratungsdoku}><SaveSharp></SaveSharp></IconButton>
                                    </Tooltip>
                                </Stack>
                            </div>
                               <Typography component="div" variant="body1">
                                 <div id="elementtoprint">
                                    <div className={`tabContentBeratungsdoku ${valueTab === 0 ? 'active' : ''}`}>
                                        <Card sx={{mt:2}}>
                                            <CardContent>
                                                <div style={{mb: 1, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                    <Typography variant='h5' color="primary" sx={{fontWeight: "bold"}}>DATENERFASSUNG</Typography>
                                                    <table style={{marginRight: "20px", fontSize: "14px", lineHeight: "1.0"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{paddingRight: "5px", color: "black"}}>Erstellt am:</td>
                                                            <td style={{color: "black"}}><b>{moment().format('DD.MM.YYYY')}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{paddingRight: "5px", color: "black"}}>Erstellt durch:</td>
                                                            <td style={{color: "black"}}><b>{user.vollstaendigerName}</b></td>
                                                        </tr>
                                                        </tbody>
                                                       
                                                    </table>
                                                </div>

                                                <Divider sx={{mb: 1, mt: 1}}></Divider>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                                <Typography variant='h6' color="primary" sx={{mb: 1, fontWeight: "bold"}}>VN / Interessent</Typography>
                                                                <Grid container spacing={4} direction="column">
                                                                    <Grid item>
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Nachname} size='small' sx={{mt:1}} fullWidth label="Name" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Vorname} size='small' sx={{mt:1}} fullWidth label="Vorname" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.gebdatum} size='small' sx={{mt:1}} fullWidth label="Geburtsdatum" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Straße} size='small' sx={{mt:1}} fullWidth label="Straße/Postfach" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.PLZOrt} size='small' sx={{mt:1}} fullWidth label="PLZ und Ort" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Email} size='small' sx={{mt:1}} fullWidth label="E-Mail" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Festnetz} size='small' sx={{mt:1}} fullWidth label="Telefon privat" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.telgesch} size='small' sx={{mt:1}} fullWidth label="Telefon geschäftlich" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password'}} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.Handy} size='small' sx={{mt:1}} fullWidth label="Telefon mobil" variant="standard" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['BerufVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Beruf" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['ArbeitgeberVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Arbeitgeber" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['EinkommenVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Einkommen (Brutto/Netto)" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['FamilienstandVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Familienstand" variant="standard" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                            <FormControlLabel sx={{ml: 0}} control={
                                                                            <Checkbox
                                                                                    name="personalausweisPN"
                                                                                    checked={checkboxStates['personalausweisPN'] || false}
                                                                                    onChange={handleCheckboxChange}
                                                                                />}
                                                                            labelPlacement="start" label="Personalausweis liegt vor" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} defaultValue={vndata.PAAblauf ? moment(vndata.PAAblauf).format('DD.MM.YYYY') : ''} size='small' sx={{mt:1}} fullWidth label="PA gültig bis" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['PA-NummerVN'] = el)} size='small' sx={{mt:1}} fullWidth label="PA-Nummer" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['AusstellendeBehoerdeVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Ausstellende Behörde" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['GeburtsortVN'] = el)} sx={{mt:1}} fullWidth label="Geburtsort" variant="standard" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['SteueridentnummerVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Steueridentnummer" variant="standard" />
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['SozialversicherungsnummerVN'] = el)} size='small' sx={{mt:1}} fullWidth label="Sozialversicherungsnummer" variant="standard" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                            <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['IBANVN'] = el)} size='small' sx={{mt:1}} fullWidth label="IBAN" variant="standard" />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="h6" color="primary" sx={{mb: 1, fontWeight: "bold"}}>Partner</Typography>

                                                                <Grid container spacing={4} direction="column">
                                                                <Grid item>
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['NamePN'] = el)} size='small' sx={{mt:1}} fullWidth label="Name" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['VornamePN'] = el)} size='small' sx={{mt:1}} fullWidth label="Vorname" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['GeburtsdatumPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Geburtsdatum" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['Straße/PostfachPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Straße/Postfach" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['PLZOrtPN'] = el)} size='small' sx={{mt:1}} fullWidth label="PLZ und Ort" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['E-MailPN'] = el)} size='small' sx={{mt:1}} fullWidth label="E-Mail" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['TelPrivatPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Telefon privat" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['TelGeschPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Telefon geschäftlich" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['TelMobilPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Telefon mobil" variant="standard" />
                                                                </Grid>
                                                                <Grid item>
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['BerufPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Beruf" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['ArbeitgeberPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Arbeitgeber" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['EinkommenPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Einkommen (Brutto/Netto)" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['FamilienstandPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Familienstand" variant="standard" />
                                                                </Grid>
                                                                <Grid item>
                                                                        <FormControlLabel sx={{ml: 0}} control={
                                                                        <Checkbox
                                                                            name="personalausweisVN"
                                                                            checked={checkboxStates['personalausweisVN'] || false}
                                                                            onChange={handleCheckboxChange}
                                                                        />}
                                                                        labelPlacement="start" label="Personalausweis liegt vor" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['PA-gueltig-bisPN'] = el)} size='small' sx={{mt:1}} fullWidth label="PA gültig bis" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['PA-NummerPN'] = el)} size='small' sx={{mt:1}} fullWidth label="PA-Nummer" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['AusstellendeBehoerdePN'] = el)} size='small' sx={{mt:1}} fullWidth label="Ausstellende Behörde" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['GeburtsortPN'] = el)} size='small' sx={{mt:1.4}} fullWidth label="Geburtsort" variant="standard" />
                                                                </Grid>
                                                                <Grid item>
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['SteueridentnummerPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Steueridentnummer" variant="standard" />
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['SozialversicherungsnummerPN'] = el)} size='small' sx={{mt:1}} fullWidth label="Sozialversicherungsnummer" variant="standard" />
                                                                </Grid>
                                                                <Grid item>
                                                                        <TextField className='beratungsdokutextfield' autoComplete='off' inputProps={{ autoComplete: 'new-password' }} autoCorrect='off' InputLabelProps={{ shrink: true }} inputRef={(el) => (textfieldRef.current['IBANPN'] = el)} size='small' sx={{mt:1}} fullWidth label="IBAN" variant="standard" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </CardContent>
                                            </Card>
                                            <Card sx={{mt: 1}}>
                                                <CardContent>
                                                    <div style={{display: "flex", justifyContent: "space-between" }}>
                                                    <Typography variant='h5' color="primary" sx={{fontWeight: "bold", mb: 2}}>KINDER</Typography>
                                                        <Tooltip title="Neues Kind hinzufügen">
                                                            <IconButton onClick={() => { setOpenDialogKinder(true); }}>
                                                                <PersonAddRounded />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Dialog open={openDialogKinder} onClose={() => setOpenDialogKinder(false)}>
                                                        <DialogTitle>Kind hinzufügen</DialogTitle>
                                                        <DialogContent>
                                                            <TextField sx={{mt: 1, mb: 1.5}} InputLabelProps={{ shrink: true }} label="Vorname" onChange={(event) => setVornameKind(event.target.value)} value={VornameKind} fullWidth />
                                                            <TextField sx={{mb: 1.5}} InputLabelProps={{ shrink: true }} label="Name" onChange={(event) => setNameKind(event.target.value)} value={NameKind} fullWidth />
                                                            <FormControl sx={{ mb: 1.5 }} fullWidth>
                                                            <InputLabel>Geschlecht</InputLabel>
                                                            <Select
                                                                value={GeschlechtKind}
                                                                label="Geschlecht"
                                                                onChange={(event) => setGeschlechtKind(event.target.value)}
                                                            >
                                                                <MenuItem value="männlich">männlich</MenuItem>
                                                                <MenuItem value="weiblich">weiblich</MenuItem>
                                                            </Select>
                                                            </FormControl>
                                                            <TextField sx={{mb: 1.5}} InputLabelProps={{ shrink: true }} label="Geburtsdatum" type="date" onChange={(event) => setGebDatumKind(event.target.value)} value={GebDatumKind} fullWidth />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleAddChild}>Hinzufügen</Button>
                                                            <Button onClick={() => setOpenDialogKinder(false)}>Abbrechen</Button>
                                                        </DialogActions>
                                                     </Dialog>
                                                    </div>
                                                    <Divider sx={{mb: 2}}></Divider>
                                                     <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                        {vndata && vndata.Kinder && vndata.Kinder.length > 0 ? (
                                                            vndata.Kinder.map((child, index) => (
                                                                <ListItem key={child.id || index}> 
                                                                    <ListItemAvatar>
                                                                    <Avatar>
                                                                        {child.Geschlecht === "männlich" ? <MaleRounded/> : <FemaleRounded/>}
                                                                    </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={`${child.Surname} ${child.Name}`} secondary={`geb. ${moment(child.Birthdate).format('DD.MM.YYYY')}`}/>
                                                                </ListItem>
                                                            ))
                                                        ) : (
                                                            <div>Keine Kinder</div>
                                                        )}
                                                    </List>
                                                </CardContent>
                                            </Card>
                                    </div>
                                    <div className={`tabContentBeratungsdoku ${valueTab === 1 ? 'active' : ''}`}>
                                        <Card sx={{mt:2}}>
                                                <CardContent>
                                                <div className="section">
                                                    <Typography variant='h5' color="primary" sx={{mb: 1, mt: 1, fontWeight: "bold"}}>PRIVATE VORSORGE</Typography>
                                                    <Divider sx={{mb: 1}}></Divider>
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography id="AllgemeinText" sx={{mb: 2, pt: 1, fontWeight: "bold"}} color="primary" gutterBottom variant="h6">
                                                            Allgemein
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`Kraftfahrt-versicherung-${refreshKey}`}
                                                            text="Kraftfahrt-Versicherung"
                                                            id="Kraftfahrt-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Kraftfahrt-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                        />
                                                    </div>
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Rechtsschutz-versicherung-${refreshKey}`}
                                                            text="Rechtsschutz-Versicherung"
                                                            id="Rechtsschutz-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Rechtsschutz-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Privathaftpflicht-versicherung-${refreshKey}`}
                                                            text="Privathaftpflicht-Versicherung"
                                                            id="Privathaftpflicht-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Privathaftpflicht-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['Kraftfahrt-Versicherung'] = el)} label="Kraftfahrt-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%"}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Rechtsschutz-Versicherung'] = el)} label="Rechtsschutz-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Privathaftpflicht-Versicherung'] = el)} label="Privathaftpflicht-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField> */}
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography sx={{mb: 2, pt: 3, fontWeight: "bold"}} color="primary" gutterBottom variant="h6" component="div">
                                                        Eigentum schützen
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`Wohngebäude-versicherung-${refreshKey}`}
                                                            text="Wohngebäude-Versicherung"
                                                            id="Wohngebäude-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Wohngebäude-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>                                                                  
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Hausrat-versicherung-${refreshKey}`}
                                                            text="Hausrat-Versicherung"
                                                            id="Hausrat-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Hausrat-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Glas-versicherung-${refreshKey}`}
                                                            text="Glas-Versicherung"
                                                            id="Glas-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Glas-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['Wohngebäude-Versicherung'] = el)} label="Wohngebäude-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%"}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Hausrat-Versicherung'] = el)} label="Hausrat-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Glas-Versicherung'] = el)} label="Glas-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField> */}
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography sx={{pmb: 2, pt: 3, fontWeight: "bold"}} color="primary" gutterBottom variant="h6" component="div">
                                                        Altersvorsorge gestalten
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`Renten-versicherung-${refreshKey}`}
                                                            text="Renten-Versicherung"
                                                            id="Renten-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Renten-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>     
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['Renten-Versicherung'] = el)} label="Renten-Versicherung/betriebliche Altersvorsorge/Riester" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>           */}
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography sx={{pmb: 2, pt: 3, fontWeight: "bold"}} color="primary" gutterBottom variant="h6" component="div">
                                                        Existenz sichern
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`Unfall-versicherung-${refreshKey}`}
                                                            text="Unfall-Versicherung"
                                                            id="Unfall-Versicherung"
                                                            initialText={BeratungsdokuInitialValues['Unfall-Versicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>                   
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Risikolebens-versicherung-${refreshKey}`}
                                                            text="Risikolebens-Versicherung"
                                                            id="Risikolv"
                                                            initialText={BeratungsdokuInitialValues['Risikolv']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Berufsunfähigkeitsvers-${refreshKey}`}
                                                            text="Berufs-/Dienstunfähigkeits- und Existenzschutz-Versicherung"
                                                            id="Berufsunfähigkeitsvers"
                                                            initialText={BeratungsdokuInitialValues['Berufsunfähigkeitsvers']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Krankenversicherung-${refreshKey}`}
                                                            text="Kranken-/Pflege-/Zahnzusatz-/Verdienstausfall- und Auslandsreisekranken-Versicherung"
                                                            id="Krankenversicherung"
                                                            initialText={BeratungsdokuInitialValues['Krankenversicherung']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['Unfall-Versicherung'] = el)} label="Unfall-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Risikolv'] = el)} label="Risikolebens-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Berufsunfähigkeitsvers'] = el)} label="Berufs-/Dienstunfähigkeits- und Existenzschutz-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Krankenversicherung'] = el)} label="Kranken-/Pflege-/Zahnzusatz-/Verdienstausfall- und Auslandsreisekranken-Versicherung" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField> */}
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography sx={{pmb: 2, pt: 3, fontWeight: "bold"}} color="primary" gutterBottom variant="h6" component="div">
                                                        Vermögen planen
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`Bausparen-${refreshKey}`}
                                                            text="Bausparen/Finanzierungen/Vermögenswirksame Leistungen und Wohnungsbauprämie"
                                                            id="Bausparen"
                                                            initialText={BeratungsdokuInitialValues['Bausparen']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>    
                                                    <div>
                                                    <CustomSlateEditor
                                                            key={`Kapitalanlage-${refreshKey}`}
                                                            text="Kapitalanlage/Sparplan"
                                                            id="Kapitalanlage"
                                                            initialText={BeratungsdokuInitialValues['Kapitalanlage']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div>
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['Bausparen'] = el)} label="Bausparen/Finanzierungen/Vermögenswirksame Leistungen und Wohnungsbauprämie" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>
                                                    <TextField inputRef={(el) => (textfieldRef.current['Kapitalanlage'] = el)} label="Kapitalanlage/Sparplan" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>                                        */}
                                                    <div className="contentGroup" style={{pageBreakInside: "avoid"}}>
                                                        <Typography sx={{mb: 2, pt: 3, fontWeight: "bold"}} color="primary" gutterBottom variant="h6" component="div">
                                                        Sonstiges
                                                        </Typography>
                                                        <CustomSlateEditor
                                                            key={`ZieleBemerkungen-${refreshKey}`}
                                                            text="Ziele/Wünsche/Bemerkungen"
                                                            id="ZieleBemerkungen"
                                                            initialText={BeratungsdokuInitialValues['ZieleBemerkungen']}
                                                            onContentChange={handleEditorContentChange}
                                                            className="multilineTextfield"
                                                    />
                                                    </div> 
                                                    {/* <TextField inputRef={(el) => (textfieldRef.current['ZieleBemerkungen'] = el)} label="Ziele/Wünsche/Bemerkungen" autoComplete='off' inputProps={{ autoComplete: 'new-password' }} InputLabelProps={{ shrink: true, sx: { color: AXADBVColor } }} autoCorrect='off' sx={{width: "100%", mt: 2}} variant='filled' multiline minRows={4} className='multilineTextfield'></TextField>                                                      */}
                                                    </div>
                                                </CardContent>
                                        </Card>
                                        </div>
                                        <Card sx={{mt: 1}}>
                                            <CardContent>
                                                    <div className="titleDiv">
                                                        <Typography variant='h5' color="primary" sx={{fontWeight: "bold", mb: 2}}>VERSORGUNGSBERECHNUNG</Typography>
                                                    </div>
                                                    <Divider sx={{mb: 2}}></Divider>
                                                    <img
                                                        src={Versorgungsrechnerbild}
                                                        alt="Toggle Popup"
                                                        width="50%"
                                                        onClick={togglePopupVersorgungsrechner}
                                                        style={{ cursor: 'pointer' }} // Make it look clickable
                                                    />
                                                    <Button onClick={togglePopupVersorgungsrechner} startIcon={<TouchAppSharp color="primary"/>}>Klicken zum öffnen...</Button>
                                            </CardContent>
                                            {/* Render the iframe as a popup using Portal */}
                                                <Portal>
                                                <Box
                                                sx={{...popupStyle, display: "flex", flexDirection: 'column', alignItems: "center"}}>
                                                        <Tooltip title="Schließen">
                                                        <Button onClick={togglePopupVersorgungsrechner} variant="text" startIcon={<CloseSharp />}>
                                                            Schließen
                                                        </Button>
                                                        </Tooltip>
                                                        <iframe
                                                        id="versorgungsrechneriframe"
                                                        width="1520px"
                                                        height="1020px"
                                                        src={versorgungsrechnerurl}
                                                        style={{
                                                            border: "none",
                                                            transform: 'scale(1)',
                                                            transformOrigin: '0 0',
                                                            overflow: 'hidden',
                                                        }}
                                                    ></iframe>
                                                </Box>
                                                </Portal>
                                        </Card>
                                        <div className='vertragsdatendiv'>
                                                <Card sx={{mt: 1}}>
                                                <CardContent>
                                                    <div className="titleDiv">
                                                        <Typography variant='h5' color="primary" sx={{mb: 1, mt: 1, fontWeight: "bold"}}>BESTEHENDE VERTRÄGE</Typography>
                                                    </div>
                                                    <Divider sx={{mb: 1}}></Divider>
                                                           <TableContainer>
                                                          <Table sx={{ minWidth: 650 }}>
                                                            <TableHead>
                                                            <TableRow>
                                                                <TableCell>Verkaufsform</TableCell>
                                                                <TableCell>Vertragsnummer</TableCell>
                                                                <TableCell>Sparte</TableCell>
                                                                <TableCell>Risiko</TableCell>
                                                                <TableCell>Beitrag/ZW</TableCell>
                                                                <TableCell>Ablauf</TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {vertragsdaten && vertragsdaten.length > 0 && vertragsdaten.reduce((acc, curr, i) => {
                                                                if (i === 0 || vertragsdaten[i - 1].VSNummer !== curr.VSNummer) {
                                                                    acc.push([curr]);
                                                                } else {
                                                                    acc[acc.length - 1].push(curr);
                                                                }
                                                                return acc;
                                                                }, []).map((group, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell sx={{width: "150px", fontSize: "11px"}}>{group[0].Verkaufsform}</TableCell>
                                                                    <TableCell sx={{width: "100px", fontSize: "11px"}}>{group[0].VSNummer}</TableCell>
                                                                    <TableCell sx={{width: "150px", fontSize: "11px"}}>
                                                                    {group.map((vertrag, idx) => (
                                                                        <>
                                                                        {idx !== 0 && <br />}
                                                                        {vertrag.Sparte}
                                                                        </>
                                                                    ))}
                                                                    </TableCell>
                                                                    <TableCell sx={{width: "250px", fontSize: "11px"}}>
                                                                    {group.map((vertrag, idx) => (
                                                                        <>
                                                                        {idx !== 0 && <br />}
                                                                        {vertrag.Risiko}
                                                                        </>
                                                                    ))}
                                                                    </TableCell>
                                                                    <TableCell sx={{width: "100", fontSize: "11px"}}>
                                                                    {group.map((vertrag, idx) => {
                                                                        if (idx !== 0) return <br />;
                                                                        if (vertrag.Beitrag) {
                                                                        const beitragValue = (
                                                                            parseFloat(vertrag.Beitrag.replace(/\./g, '').replace(',', '.').replace(' €', '')) /
                                                                            (
                                                                            vertrag.Zahlweise === "Jährlich" ? 1 :
                                                                            vertrag.Zahlweise === "Halbjährlich" ? 2 :
                                                                            vertrag.Zahlweise === "Vierteljährlich" ? 4 :
                                                                            vertrag.Zahlweise === "Monatlich" ? 12 : 1
                                                                            )
                                                                        ).toFixed(2).replace('.',',') + " €" +
                                                                        (
                                                                            vertrag.Zahlweise === "Monatlich" ? " (1/12)" :
                                                                            vertrag.Zahlweise === "Vierteljährlich" ? " (1/4)" :
                                                                            vertrag.Zahlweise === "Halbjährlich" ? " (1/2)" :
                                                                            vertrag.Zahlweise === "Jährlich" ? " (1/1)" : ""
                                                                        );
                                                                        return beitragValue;
                                                                        }
                                                                    })}
                                                                    </TableCell>
                                                                    <TableCell sx={{width: "50px", fontSize: "11px"}}>{group[0].Ablauf}</TableCell>
                                                                </TableRow>
                                                                ))}
                                                            </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                </CardContent>
                                                </Card>
                                        </div>
                                 </div>
                            </Typography>
                    </Box>
            </Fade>
        </Modal>
        </div>




    )

}