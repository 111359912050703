import React, { useState } from 'react';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import { Alert, Avatar, Button, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { fetchdataUrl } from '../A-config/config';
import { globalAppBarMessage } from '../hooks/reduxactions'; 
import { useDispatch } from 'react-redux';

export const RegisterPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errormessageRegister, seterrormessageRegister] = useState('');
  const { control, handleSubmit, setError, clearErrors } = useForm({
        reValidateMode: "onBlur",
  });

  const handleNavigation = (path) => {
    if (path) {
      navigate(path);
    }
  };

  const handleOnSubmit = (evt) => {

    fetch(`${fetchdataUrl}loginandregister.php`, {
    method: 'POST',
    body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchRegisterUser: 1,    
        username: evt.username,
        vollständigername: evt.vollständigername,
        email: evt.email,
        kuerzel: evt.kuerzelbearbeiter,
        vollstaendigerName: evt.vollständigername,
        password: evt.password,
        confirm_password: evt.passwordwiederholen,
        kuerzelbearbeiter: evt.kuerzelbearbeiter,
        Organummer: evt.Organummer,
    }),
    headers: {
        'Content-Type': 'application/json'
    }
    }).then((response) => {
        return response.json();
    }).then((data) => {
        if (data === "success")
        {
            seterrormessageRegister("");
            handleNavigation("/")
            dispatch(globalAppBarMessage(`Registrierung erfolgreich.<br><br>Bitte beachte, dass du zunächst von einem Admin <b>freigeschaltet</b> werden musst.<br><br>Zusätzlich wurde ein mobiler Nutzer mit dem Benutzernamen: <b>${evt.username}mobil</b> erstellt.<br>Du kannst diesen für einen parallelen Login auf den Mobilgeräten verwenden.`));
        }        
        else
        {
            seterrormessageRegister(data);
        }
    });   

  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrierung
        </Typography>
        {/* <Button onClick={() => {
        dispatch(globalAppBarMessage(`Registrierung erfolgreich.<br><br>Bitte beachte, dass du zunächst von einem Admin <b>freigeschaltet</b> werden musst.<br><br>Zusätzlich wurde ein mobiler Nutzer mit dem Benutzernamen: <b>mobil</b> erstellt.<br>Du kannst diesen für einen parallelen Login auf den Mobilgeräten verwenden.`));
            }}>Click Me</Button> */}
        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate sx={{ mt: 1 }}>
                        <Controller                                                              
                                control={control}
                                name="username"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field} 
                                    margin="normal" 
                                    id="username"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Benutzername"
                                    name="username"
                                    required
                                    autoFocus
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('username', { type: 'required'});                                            
                                    } else {
                                        clearErrors("username");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />

                         <Controller                                                              
                                control={control}
                                name="vollständigername"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}  
                                    margin="normal"
                                    id="vollständigername"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Vollständiger Name"
                                    name="vollständigername"
                                    required
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('vollständigername', { type: 'required'});                                            
                                    } else {
                                        clearErrors("vollständigername");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />
                        <Controller                                                              
                                control={control}
                                name="email"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}  
                                    margin="normal"
                                    id="email"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="E-Mail"
                                    name="email"
                                    required
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('email', { type: 'required'});                                            
                                    } else {
                                        clearErrors("email");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />

                         <Controller                                                              
                                control={control}
                                name="kuerzelbearbeiter"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}                                      
                                    margin="normal"
                                    id="kuerzelbearbeiter"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Dein Kürzel"
                                    name="kuerzelbearbeiter"
                                    required
                                    inputProps={{
                                        minLength: 2,
                                        maxLength: 3
                                    }}
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if (e.target.value === "") {
                                        setError('kuerzelbearbeiter', { type: 'required'});  
                                    } 
                                    else if (e.target.value.length < 2)
                                    {
                                        setError('kuerzelbearbeiter', { type: 'minLength', message: 'Input must be at least 2 characters long' });
                                    }
                                     else if (!/^[a-zA-Z]+$/.test(e.target.value)) 
                                    { 
                                        setError('kuerzelbearbeiter', { type: 'pattern'});                                                                                                                        
                                    } else {
                                        clearErrors("kuerzelbearbeiter");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />
                         <Controller                                                              
                                control={control}
                                name="Organummer"
                                defaultValue=""             
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}                                      
                                    margin="normal"
                                    id="Organummer"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="text"                                    
                                    label="Deine Organummer"
                                    name="Organummer"                                  
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)            
                                    }}
                                    ></TextField>
                                )}
                        />

                         <Controller                                                              
                                control={control}
                                name="password"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}  
                                    margin="normal"
                                    id="password"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="password"                                    
                                    label="Passwort"
                                    name="password"
                                    required
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('password', { type: 'required'});                                            
                                    } else {
                                        clearErrors("password");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />

                         <Controller                                                              
                                control={control}
                                name="passwordwiederholen"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField 
                                    {...field}  
                                    margin="normal"
                                    id="passwordwiederholen"   
                                    fullWidth                               
                                    autoComplete="off"
                                    type="password"                                    
                                    label="Passwort wiederholen"
                                    name="passwordwiederholen"
                                    required
                                    error={error !== undefined}
                                    onChange={(e) => {
                                    field.onChange(e)
                                    if(e.target.value === "") {
                                        setError('passwordwiederholen', { type: 'required'});                                            
                                    } else {
                                        clearErrors("passwordwiederholen");
                                    }
                                    }}
                                    ></TextField>
                                )}
                        />
                        <span style={{color: '#f14668', fontSize: '14px', fontWeight: 'bold'}}> {errormessageRegister}</span>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Absenden
          </Button>
          <Grid container>
            <Grid item>
              <Link component={RouterLink} to="/" variant="body2">
                  {"Du hast bereits einen Account? Hier einloggen."}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
