import { useState } from 'react';
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from "./useAuth";

const useUpdateUserStats = () => {
    const {user, hasPermission} = useAuth();

    const updateUserStats = (vollstaendigerName, operationType, tableName, description, partnernummer, infos) => {
        if (user && user.invisible)
        { 
        return;
        }

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                updateuserstats: 1,
                vollstaendigerName: vollstaendigerName,
                operationType: operationType,
                tableName: tableName,
                description: description,
                partnernummer: partnernummer,
                infos: infos
            }),
        })
        .then(response => response.json())
        .then(data => {
        })
        .catch(err => console.error("Error updating user stats", err));
    };

    return { updateUserStats };
};

const useGetUserStats = () => {
    const [userStats, setUserStats] = useState(null);

    const getUserStats = (vollstaendigerName) => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                getuserstats: 1,
                vollstaendigerName,
            }),
        })
        .then(response => response.json())
        .then(data => {  
            setUserStats(data);
        })
        .catch(err => console.error("Error fetching user stats", err));
    };

    return { userStats, getUserStats };
};

export { useUpdateUserStats, useGetUserStats };
