import { height } from '@mui/system';
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Stack, LinearProgress, Divider, Button, Typography } from '@mui/material';
import { ArrowCircleDownSharp } from '@mui/icons-material';
import OkDialog from '../hooks/customOkDialog';
import ConfirmDialog from "../hooks/confirmDialog";
import { fetchdataUrl } from '../A-config/config';

const DragoDropExcel = ({dataExcel}) => {

  //Confirm Dialog
  const [openConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setconfirmDialogMessage] = useState('');
  const [confirmDialogAction, setconfirmDialogAction] = useState('');
  const [datenübertragungLoading, setdatenübertragungLoading] = useState(false);

  const handleOpenConfirmDialog = (message, action) => {
    setconfirmDialogMessage(message);
    setShowConfirmDialog(true);
    setconfirmDialogAction(action);
  };

  const handleConfirmdialogConfirm = () => {
    if (confirmDialogAction == "Kundenhinzufügen")
    {
        setShowConfirmDialog(false);
        setdatenübertragungLoading(true);
        //ok was machen wir hier
        //zunächst holen wir uns die Header aus zone2 und die columns aus der zone3
        const headerIds = zone2.map((header) => header.id);
        const columnNames = zone3.map((column) => column.content);

        //wir machen ein neues object, bei dem die id aus zone2 
        const sortedHeaderObject = headerIds.map((id, index) => {
        return {
            [id]: index < columnNames.length ? columnNames[index] : ""
        };
        });

        
        const newDataExcel = dataExcel.map((row) => {
        return row.reduce((obj, val, index) => {
            const title = dataExcel[0][index];
            if (typeof val === "number" && title.includes("Geburtsdatum")) {
           const date = new Date((val - (25567 + 2)) * 86400 * 1000);
            const formattedDate = `${date.getUTCDate().toString().padStart(2, "0")}.${(
                date.getUTCMonth() + 1
            )
                .toString()
                .padStart(2, "0")}.${date.getUTCFullYear()}`;
            obj[title] = formattedDate;
            } else {
            obj[title] = val;
            }
            return obj;
        }, {});
        });

        const newObjectArray = [];

        newDataExcel.forEach((row) => {
            const newObj = {};
            Object.entries(sortedHeaderObject).forEach(([key, value]) => {
                const [valueKey, valueName] = Object.entries(value)[0];
                const matchingKey = Object.keys(row).find((k) => k === valueName);
                if (matchingKey) {
                newObj[valueKey] = row[matchingKey];
                } else {
                newObj[valueKey] = "";
                }
            });
            newObjectArray.push(newObj);
        });

        //remove first entry of array
        newObjectArray.shift();

         const fetchPromise = new Promise((resolve, reject) => {
            fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchDeleteandInsertKunden: 1,
                    arrayTermine: newObjectArray,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

        fetchPromise.then((data) => {
            if (data === "Rows")
            {
              handleOpenDialog('Es sind bereits Kundendaten abgespeichert. Bitte wende dich an den Support um die Kundendaten wirklich endgültig zu löschen und durch neue Daten zu ersetzen.')
              setdatenübertragungLoading(false);
            }
            else
            {
              setdatenübertragungLoading(false);
            }
        }).catch((error) => {
            // handle error here
        });
    }
  }
    const handleConfirmdialogDecline = () => {
      setShowConfirmDialog(false);    
  }



  //Simple OK Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const handleOpenDialog = (message) => {
    setDialogMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const initialZone2 = [
    { id: 'Titel', content: 'Titel' },
    { id: 'PNr', content: 'Partnernummer' },
    { id: 'Nachname', content: 'Nachname' },
    { id: 'Vorname', content: 'Vorname' },
    { id: 'gebdatum', content: 'Geburtsdatum' },
    { id: 'Straße', content: 'Straße' },
    { id: 'PLZ', content: 'PLZ' },
    { id: 'Ort', content: 'Wohnort' },
    { id: 'Betreuer', content: 'Betreuer' },
    { id: 'Handy', content: 'Handy' },
    { id: 'Festnetzprivat', content: 'Festnetz privat' },
    { id: 'Festnetzgesch', content: 'Festnetz gesch.' },
    { id: 'EMail', content: 'E-Mail Adresse' },
  ];

  const [zone1, setZone1] = useState([]);
  const [zone2, setZone2] = useState(initialZone2);
  const [zone3, setZone3] = useState([]);

  useEffect(() => {
    setZone3([]);
    setZone1([]);

    if (dataExcel[0]) {
        const items = dataExcel[0].map((item, index) => ({
        id: `item-${index}`,
        content: item,
        }));

        setZone1(items);
    }
  }, [dataExcel[0]]);
  
  const handleDragEnd = (result) => {
        const { source, destination } = result;

        // If dropped outside of a droppable, remove the item from zone3 and add it to zone1
        if (!destination) {
            const removedItem = zone3.splice(source.index, 1);
            setZone3([...zone3]);
            setZone1([...zone1, ...removedItem]);
            return;
        }

        // If dropped in the same droppable
        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === 'zone3') {
            const reorderedItems = reorder(zone3, source.index, destination.index);
            setZone3(reorderedItems);
            }
        } else {
            // If dropped between droppables
            if (destination.droppableId === 'zone3') {
            const item = zone1[source.index];
            setZone1((prev) => prev.filter((_, index) => index !== source.index));
            setZone3((prev) => [...prev.slice(0, destination.index), item, ...prev.slice(destination.index)]);
            }
        }
  };

    const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
    };

    const handleÜbertragungTermine = () =>
    {
        // handleOpenConfirmDialog('Soll dieser Benutzer wirklich gelöscht werden?', 'Nutzerlöschen')

        if (zone3.length === 0) 
        {
            handleOpenDialog('Die Reihenfolge ist leer. Bitte ordne hier zunächst Spalten an.')
        }
        else
        {
            handleOpenConfirmDialog('Sollen die Kundendaten jetzt in die Datenbank übertragen werden?', 'Kundenhinzufügen')
        }

    }



  return (
    <div style={{display: "flex"}}>
     <OkDialog open={openDialog} message={dialogMessage} onClose={handleCloseDialog} />
      <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          message={confirmDialogMessage}
          onConfirm={handleConfirmdialogConfirm}
          onCancel={handleConfirmdialogDecline}
      />       
     <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{display: "flex"}}>
        <div style={{border: "1px solid lightgray", width: "200px", height: "250px", overflowY: "auto", overflowX: "hidden",}} className="list">
        <div style={{margin: "5px"}}>
          <Typography variant='h6'>Excelspalte</Typography>
          <Droppable droppableId="zone1">
            {(provided, snapshot) => (
              <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "14px"}}
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? 'dragging-over' : ''}
              >
                {zone1.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="item"
                        style={{
                          backgroundColor: snapshot.isDragging ? '#EEE' : 'white',
                          ...provided.draggableProps.style
                        }}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        </div>
        <div style={{border: "1px solid lightgray", width: "200px", height: "250px", overflowY: "auto", overflowX: "hidden",}} className="list">
            <div style={{margin: "5px"}}>
             <Typography variant='h6'>Vorlage</Typography>
                <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "14px"}}>
                {zone2.map((item, index) => (
                <div key={item.id} className="item">
                    {item.content}
                </div>
                ))}
            </div>
            </div>
        </div>
        <div style={{border: "1px solid lightgray", width: "200px", height: "250px",}} className="list reorderable">
            <div style={{margin: "5px"}}>
                <Typography variant='h6'>Reihenfolge</Typography>                           
                <Droppable droppableId="zone3" direction="vertical">
                    {(provided, snapshot) => (
                    <div style={{whiteSpace: "nowrap", overflowX: "hidden", overflowY: "auto", textOverflow: "ellipsis",  height: "200px", fontSize: "14px" }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={snapshot.isDraggingOver ? 'dragging-over' : ''}
                    >
                       {zone3.length === 0 && (
                            <div style={{display: "flex", alignItems: "center", marginLeft: "5px", marginRight: "5px", marginTop: "50px"}}>
                            <ArrowCircleDownSharp style={{color: 'gray'}}></ArrowCircleDownSharp>
                            <span style={{paddingLeft: "10px", whiteSpace: "pre-wrap", color: 'gray'}} >Excelspalten aus linker Liste hier reinziehen und anhand mittlerer Liste sortieren...</span>
                            </div>
                        )}
                        {zone3.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className="item"
                                style={{
                                    backgroundColor: snapshot.isDragging ? '#EEE' : 'white',
                                    ...provided.draggableProps.style,
                                }}
                                >
                                {item.content}
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </div>
                </div>
      </div>
    </DragDropContext> 
    <Stack sx={{ml: 2}}>
        <Button style={{height: "30px"}} onClick={handleÜbertragungTermine} variant='text'>Übertragung starten...</Button>
       {datenübertragungLoading ? (
                <Stack sx={{mt: 1, ml: 1}} direction="column">
                        <span style={{fontSize: "12px"}}>Kundendaten werden übertragen...</span>
                        <LinearProgress></LinearProgress>
                        </Stack>
        ) : ( null )}
    </Stack>
    
   </div>

      
  );
};

export default DragoDropExcel