import { useEffect, useState } from "react";
import { Card, Tabs, Tab, Typography } from '@mui/material';
// import { SchadenSuchenPage } from "./UserSucheSchaden_OLD";
import SchadenSuchenPageServerSideWithQueryProvider from "./UserSucheSchadenServerSide";
import UserSucheServerSideWithQueryProvider from "./UserSucheServerSide";
// import { UserSuchenPage } from "./UserSuche_OLD";

export const KundenSuchePage = () => {
  const [valueTabControl, setvalueTabControl] = useState(() => {
    // Get the last selected value from local storage or default to 0
    return parseInt(localStorage.getItem("selectedTab") || "0", 10);
  });

  const handleChange = (event, newValue) => {
    setvalueTabControl(newValue);
  };

  useEffect(() => {
    // Save the selected tab value to local storage
    localStorage.setItem("selectedTab", valueTabControl.toString());
  }, [valueTabControl]);

  return (
    <div>
      <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
        <Tabs value={valueTabControl} onChange={handleChange}>
          <Tab label="Kundensuche" />
          <Tab label="Schadensuche" />
        </Tabs>
        <Typography sx={{ mt: 1.2 }} component="div" variant="body1">
          {valueTabControl === 0 && <UserSucheServerSideWithQueryProvider />}
          {valueTabControl === 1 && <SchadenSuchenPageServerSideWithQueryProvider />}
        </Typography>
      </Card>
    </div>
  );
};