import React, { useState, useEffect } from 'react';
import {IconButton, Tooltip, Typography, Modal, Box, Fade, Divider, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from 'moment';
import { fetchdataUrl } from '../A-config/config';
import {ArrowCircleUpSharp, ArrowCircleDownSharp, ExpandMoreSharp, PrintSharp, BookmarkSharp, SaveAltSharp, CloseSharp, SaveAsSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Co2Sharp, ConstructionOutlined, SaveSharp, ExpandMore} from '@mui/icons-material';
import { useAuth } from "../hooks/useAuth";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const infosContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '15px',
};

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

export default function ZeiterfassungModalKorrekturen({ openModal, setopenModal, selectedUser, refreshInfo }) {
    const handleClose = () => setopenModal(false);
    const [valueVollzeitMATextfield, setvalueVollzeitMATextfield] = useState('');
    const [valueTeilzeitMATextfield, setvalueTeilzeitMATextfield] = useState('');
    const [valueBemerkungTextfield, setvalueBemerkungTextfield] = useState('');
    const [error, setError] = useState('');
    const [korrekturenData, setKorrekturenData] = useState([]);
    const { user, logout, hasPermission } = useAuth();

    const isValidTime = (time) => {
        const regex = /^-?\d+:[0-5]\d:[0-5]\d$/;
        return regex.test(time);
    };

    const handleTextFieldVollzeitMAChange = (e) => {
        setvalueVollzeitMATextfield(e.target.value);
    };

    const handleTextFieldTeilzeitMAChange = (e) => {
        setvalueTeilzeitMATextfield(e.target.value);
    };

    const handleTextFieldBemerkungChange = (e) => {
        setvalueBemerkungTextfield(e.target.value);
    };

    const handledeleterow = (e) => {
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchDeleteRowZeiterfassungKorrektur: 1,
                id: e,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            getInfosKorrekturenfromDB();
            refreshInfo();
        });
    };

    const convertSecondsToTime = (secondsTime) => {
        const isNegative = secondsTime < 0;
        const absoluteSeconds = Math.abs(secondsTime);
        const duration = moment.duration(absoluteSeconds, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return isNegative ? `-${formattedTime}` : formattedTime;
    };

    const convertTimeToSeconds = (time) => {
        const isNegative = time.startsWith('-');
        const parts = time.replace('-', '').split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseInt(parts[2], 10);
    
        let totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    
        return isNegative ? -totalSeconds : totalSeconds;
    };

    const getInfosKorrekturenfromDB = async () => {
        const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchgetInfosKorrekturenZeiterfassung: 1,
                username: selectedUser,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
            if (data.success) {
                const sortedData = data.data.sort((a, b) => moment(b.KorrekturDatum, 'DD.MM.YYYY - HH:mm:ss') - moment(a.KorrekturDatum, 'DD.MM.YYYY - HH:mm:ss'));
                setKorrekturenData(sortedData);
            }                
    };

    useEffect(() => {
        getInfosKorrekturenfromDB()
        setvalueBemerkungTextfield('');
        setvalueTeilzeitMATextfield('');
        setvalueVollzeitMATextfield('');
    }, [selectedUser]);

    const handleOnSubmit = (evt) => {
        evt.preventDefault();
        setError(''); 

        // Validate Bemerkung
        if (valueBemerkungTextfield === "" || !valueBemerkungTextfield) {
            setError('Bitte trage eine Bemerkung ein.');
            return;
        }

        // Validate Arbeitszeit fields
        const vollzeitMATextfieldValid = valueVollzeitMATextfield === "" || isValidTime(valueVollzeitMATextfield);
        const teilzeitMATextfieldValid = valueTeilzeitMATextfield === "" || isValidTime(valueTeilzeitMATextfield);

        if (!valueVollzeitMATextfield && !valueTeilzeitMATextfield) {
            setError('Bitte trage mindestens eine Arbeitszeitänderung ein.');
            return;
        }

        if (!vollzeitMATextfieldValid || !teilzeitMATextfieldValid) {
            if (!vollzeitMATextfieldValid) {
                setError('Arbeitszeit Vollzeit-MA ungültig. Bitte nutze folgendes Format hh:mm:ss.');
                return;
            }
            if (!teilzeitMATextfieldValid) {
                setError('Arbeitszeit Teilzeit-MA ungültig. Bitte nutze folgendes Format hh:mm:ss.');
                return;
            }
        }

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchInsertKorrekturZeiterfassungüberModal: 1,
                arbeitszeitsaldiert: valueVollzeitMATextfield,
                arbeitszeitgesamt: convertTimeToSeconds(valueTeilzeitMATextfield),
                Bemerkung: valueBemerkungTextfield,
                username: selectedUser,      
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            refreshInfo()
            getInfosKorrekturenfromDB()
        });
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={openModal}>
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                            <span><b>Arbeitszeitkorrekturen:</b></span>
                        </div>
                        <Button onClick={() => setopenModal(false)}>
                        <CloseSharp />
                        </Button>
                    </div>
                    <Divider sx={{ marginTop: "5px" }} />
                    {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Zeiterfassungsadmin') || hasPermission(user, 'Zeiterfassungsbearbeiter')? 
                    <form onSubmit={handleOnSubmit}>
                        <div style={infosContainerStyle}>
                            <div style={contentStyle}>
                                <TextField
                                    size='small'
                                    multiline
                                    rows={3}
                                    label="Bemerkung zur Änderung"
                                    variant="outlined"
                                    value={valueBemerkungTextfield}
                                    onChange={handleTextFieldBemerkungChange}
                                />
                            </div>
                            <div style={contentStyle}>
                                <TextField
                                    size='small'
                                    label="Arbeitszeit (Vollzeit-MA)"
                                    variant="outlined"
                                    value={valueVollzeitMATextfield}
                                    onChange={handleTextFieldVollzeitMAChange}
                                />
                            </div>
                            <div style={contentStyle}>
                                <TextField
                                    size='small'
                                    label="Arbeitszeit (Teilzeit-MA)"
                                    variant="outlined"
                                    value={valueTeilzeitMATextfield}
                                    onChange={handleTextFieldTeilzeitMAChange}
                                />
                            </div>
                            
                        </div>
                        {error && <Typography sx={{fontSize: "12px", marginTop: "5px"}} color="error">{error}</Typography>}
                        <Button sx={{marginTop: "10px"}} type='submit'>Speichern</Button>
                    </form> : null}
                    <Divider sx={{ marginTop: "15px" }} />
                    <TableContainer component={Paper} sx={{ maxHeight: "300px", marginTop: 2 }}>
                        <Table size="small" aria-label="korrekturen table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontSize: "12px"}}>Änderungsdatum</TableCell>
                                    <TableCell sx={{fontSize: "12px", width: "100px"}}>Änderung Vollzeit</TableCell>
                                    <TableCell sx={{fontSize: "12px", width: "100px"}}>Änderung Teilzeit</TableCell>
                                    <TableCell sx={{fontSize: "12px"}}>Bemerkung</TableCell>
                                    <TableCell sx={{fontSize: "12px"}}>Aktionen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {korrekturenData.map((row) => (
                                    <TableRow sx={{ maxHeight: "20px" }} key={row.id}>
                                    <TableCell sx={{ fontSize: "12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "130px" }}>
                                        <Tooltip title={row.KorrekturDatum} arrow>
                                            <span>{row.KorrekturDatum}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: "bold", fontSize: "12px", color: row.arbeitszeitsaldiert.startsWith("-") ? "#ff6d75" : "#14a37f", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100px" }}>
                                        <Tooltip title={row.arbeitszeitsaldiert} arrow>
                                            <span>{row.arbeitszeitsaldiert}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100px" }}>
                                        <Tooltip title={row.arbeitszeitgesamt} arrow>
                                            <span>{convertSecondsToTime(row.arbeitszeitgesamt)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "200px" }}>
                                        <Tooltip title={row.Feiertaginfo} arrow>
                                            <span>{row.Feiertaginfo}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{fontSize: "12px"}}>
                                        <Tooltip title='Eintrag löschen'>
                                            <IconButton onClick={() => handledeleterow(row.id)} fontSize='small' color="primary">
                                            <Delete />
                                            </IconButton>
                                        </Tooltip>                                        
                                    </TableCell>

                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Fade>
        </Modal>
    );
}