export const updatePnr = pnr => {
  return {
    type: 'UPDATE_PNR',
    pnr: pnr
  };
};

export const updatejustoldPnr = myPNr => ({
  type: 'UPDATE_MY_OLD_PARTNERNUMMER',
  myPNr: myPNr
});

export const updateEventData = data => ({
  type: 'UPDATE_EVENTDATA',
  data: data
});

export const updateQuillValue = (Bemerkungen) => {
  return {
    type: "UPDATE_QUILL_VALUE",
    Bemerkungen: Bemerkungen
  };
};

export const setupdateZeiterfassung = data => ({
  type: 'UPDATE_ZEITERFASSUNG_GRUNDDATEN',
  payload: data
});

export const setloading = data => ({
  type: 'UPDATE_SETLOADING',
  loading: data
});

export const setloadingSchaden = data => ({
  type: 'UPDATE_SETLOADING_SCHADEN',
  loading: data
});

export const refreshDataAufgaben = () => ({
  type: 'REFRESH_DATAAUFGABEN'
});

export const refreshDataKundenSchaeden = data => ({
  type: 'REFRESH_DATAKUNDENSCHAEDEN',
  refreshDataKundenSchaeden: data
});

export const setuserstatus = data => ({
  type: 'SET_USERSTATUS',
  data
});

export const refreshuserstatus = data => ({
  type: 'UPDATE_USERSTATUSES',
  refreshuserstatus: data
});

export const globalAppBarMessage = data => ({
  type: 'REFRESH_GLOBALAPPBARMESSAGE',
  globalAppBarMessage: data
});

export const setAufgabenCount = (count) => {
  return {
    type: "SET_AUFGABENCOUNT",
    payload: count,
  };
};

export const setSchadenÄnderungCount = (count) => {
  return {
    type: "SET_SCHADENÄNDERUNGCOUNT",
    payload: count,
  };
};

export const setbadgeContentNumber = (count) => {
  return {
    type: "SET_BADGENUMBER",
    payload: count,
  };
};

export const setAXADBVColor = (color) => {
  return {
    type: "SET_AXA_DBV_COLOR",
    payload: color,
  };
};

export const setServerInfoMessage = (message) => {
  return {
    type: "SET_SERVERINFOMESSAGE",
    payload: message,
  };
};

export function toggleModalBeratungsdokumentation(isOpen) {
  return {
    type: "TOGGLE_MODAL_BERATUNGSDOKU",
    payload: isOpen,
  };
};

export function toggleGlobalServerMessage(isOpen) {
  return {
    type: "TOGGLE_GlobalMessage",
    payload: isOpen,
  };
};

export function toggleModalSchaden(isOpen) {
  return {
    type: "TOGGLE_MODAL_SCHADEN",
    payload: isOpen,
  };
};
export function toggleInfoBarAppbar(isOpen) {
  return {
    type: "TOGGLE_INFOBAR_APPBAR",
    payload: isOpen,
  };
};

export function toggleConfirmDialogBeratungsdoku(isOpen2) {
  return {
    type: "TOGGLE_CONFIRMDIALOG_BERATUNGSDOKU",
    payload: isOpen2,
  };
};

