import { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const useTabContext = () => useContext(TabContext);

export const TabProvider = ({ children }) => {
  const [tabs, setTabs] = useState([]);

  return (
    <TabContext.Provider value={{ tabs, setTabs }}>
      {children}
    </TabContext.Provider>
  );
};