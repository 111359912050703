
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
// import { DashboardTermineTable } from "./DashboardTermineTable";
import DashboardTermineTableServerSide from "./DashboardTermineTableServerside";
import { DashboardAufgabenTable } from "./DashboardAufgabenTable";

import {
  Avatar,
  CardHeader,
  Box,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Divider, 
  Stack,
  Checkbox,
  FormControlLabel, 
  Popover
} from '@mui/material';
import { PlaylistAddCheckSharp, CarCrashRounded, UnpublishedSharp, PrintSharp, EventAvailableRounded, DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star, DeleteSharp} from '@mui/icons-material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';


export const UserStartPage = () => {  

  return (   
    <div>
          <Card sx={{minHeight: "400px", borderTopRightRadius: 0, borderTopLeftRadius: 0, '& .MuiCardContent-root': { paddingLeft: "16px", paddingRight: "16px", paddingBottom: "0px !important", paddingTop: "0px" }}}>
                  <CardContent>
                  <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Aufgaben</h2>  
                  <Divider></Divider>
                  <div>
                  <DashboardAufgabenTable/>
                  </div>               
                </CardContent>                           
          </Card>    

          <Card sx={{minHeight: "400px", mt: 1, '& .MuiCardContent-root': { paddingLeft: "16px", paddingRight: "16px", paddingBottom: "0px", paddingTop: "0px" }}}>
              <CardContent>
              <h2 style={{paddingLeft: '10px', fontFamily: 'roboto'}}>Termine</h2>  
              <Divider></Divider>
              <div>        
              <DashboardTermineTableServerSide></DashboardTermineTableServerSide>                      
               </div>
              </CardContent>              
          </Card>  
    </div>                  
    );
}
