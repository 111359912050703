import React, { useState, useEffect, useRef} from 'react';
import { useForm, useController, Controller, useFieldArray } from "react-hook-form";
import moment from 'moment';
import { useAuth } from "../hooks/useAuth";
import TextFieldPopup from '../components/TextFieldPopup';
import { jsPDF } from "jspdf";
import { fetchdataUrl } from '../A-config/config';
import { globalAppBarMessage } from '../hooks/reduxactions';
import { useDispatch } from 'react-redux';
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Modal,
  Typography,
  Select, 
  InputLabel,
  FormControl,
  Fade,
  Backdrop,
  FormHelperText,
  Grid,
  Popover,
  Switch,
  Toolbar
} from '@mui/material'

import { PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star} from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import DateFnsAdapter from '@date-io/date-fns';
import { de } from 'date-fns/locale'; // import the locale you want to use

const style = {
  width: '500px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};


export default function TermineModal({openModalTermine, setopenModalTermine, dataImported, handleRefetchData}) {
    const { updateUserStats } = useUpdateUserStats();
    const handleCloseTermine = () => setopenModalTermine(false);
    const [konfigurationsModusAktiv, setkonfigurationsModusAktiv] = useState(false);
    const [optionsBearbeiter, setOptionsBearbeiter] = useState([]);
    const [optionsTerminArt, setOptionsTerminArt] = useState([]);
    const [optionsTerminArtChange, setoptionsTerminArtChange] = useState("");
    const [inputFieldValues, setInputFieldValues] = useState({});
    const [anchorEl, setAnchorElPopoverArthinz] = useState(null);
    const [anchorElFeldTerminArt, setAnchorElFeldTerminArt] = useState(null);
    const [terminFeldList, setTerminFeldList] = useState([]);
    const TerminArthinzRef = useRef();
    const FeldTerminArthinzRef = useRef();
    const TerminArtSelectRef = useRef();    
    const [refetchData, setRefetchData] = useState(false);
    const open = Boolean(anchorEl);
    const openFeldTerminArt = Boolean(anchorElFeldTerminArt);
    const {user, hasPermission} = useAuth();
    const dispatch = useDispatch();
    const { control, handleSubmit, setError, clearErrors, reset, trigger, getValues } = useForm({
    reValidateMode: 'onChange',
    });

    const [openTextField, setopenTextField] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");

    const openTextFieldDialog = (value) => {
        setTextFieldValue(value);
        setopenTextField(true);
    };

    const closeTextFieldDialog = () => {
        setopenTextField(false);
    };

    useEffect(() => {
        //Hierdurch wird der useEffect nur ausgelöst, wenn das Modal wirklich geöffnet wird und nicht beim Start der Applikation.
        if (!openModalTermine) return;

        // Cleanup function to remove added text fields and reset all entries
        reset();
        setoptionsTerminArtChange("");

        //Load Bearbeiter
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadBearbeiter: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setOptionsBearbeiter(data);
        });   

        //Load TerminArt
        fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchLoadTerminArten: 1
        }),
        headers: {
            'Content-Type': 'application/json'
        }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            const updatedOptions = ["Planmäßiger Beratungstermin", ...data];
            setOptionsTerminArt(updatedOptions);
            //Trigger all Required Fields on Mount
            trigger();
        });             

    },[refetchData, openModalTermine]);

    // Update the state when the TerminArt selection changes or new TerminFeld values are added
    useEffect(() => {
        // Fetch the list of TerminFeld values for the selected TerminArt from the server
        const fetchTerminFeldList = async () => {
            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                body: JSON.stringify({
                    access_token: sessionStorage.getItem("access_token"),
                    fetchTerminFeldList: 1,
                    TerminArt: optionsTerminArtChange,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }}).then((response) => {
                    return response.json();
                }).then((data) => {
                    setTerminFeldList(data)
            }).catch(() => {
                console.log("Terminfeldliste konnte nicht geladen werden. Serverfehler.")
            }
        );

        };

        // Call the fetchTerminFeldList function when the TerminArt selection changes
        fetchTerminFeldList();        

    }, [optionsTerminArtChange]);

    const handleKonfigurationsModusToggle = () => {
    setkonfigurationsModusAktiv((prevValue) => !prevValue);
    };

    const handleClickFeldTerminArtPlus = (e) => {
       if (TerminArtSelectRef.current.value != "" && FeldTerminArthinzRef.current.value != "") 
        {
            //Terminfeld in Datenbank speichern           
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddTerminFeld: 1,
                TerminFeld: FeldTerminArthinzRef.current.value,
                TerminArt: TerminArtSelectRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setTerminFeldList([...terminFeldList, FeldTerminArthinzRef.current.value]);
                // setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
            });
        }
        else
        {
            dispatch(globalAppBarMessage(`Bitte wähle zunächst eine Terminart aus.`));
        }
    }

    const handleClickTerminArtPlus = (e) => {
        if (TerminArthinzRef.current.value === "Schaden")
        {
            dispatch(globalAppBarMessage(`Die Terminart <b>Schaden</b> ist reserviert.<br><br>Bitte wähle eine andere Bezeichnung.`));
            return;
        } else if (TerminArthinzRef.current.value === "Planmäßiger Beratungstermin")
        {
            dispatch(globalAppBarMessage(`Die Terminart <b>Planmäßiger Beratungstermin</b> ist reserviert.<br><br>Bitte wähle eine andere Bezeichnung.`));
            return;
        }
        if (!TerminArthinzRef.current.value == "") 
        {
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddTerminArthinz: 1,
                TerminArten: TerminArthinzRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
            });
        }
    }
    
    const handleClickTerminArtloeschen = (e) => {

        if (!TerminArtSelectRef.current.value == "") 
        {
            fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchDeleteTerminArt: 1,
                TerminArt: TerminArtSelectRef.current.value,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setRefetchData(!refetchData); // Set refetchData to trigger the useEffect hook
            });
        }
    }

   const handlePrint = () => {        
        const doc = new jsPDF();

        const terminTitel = getValues("TerminTitel") || '';
        const terminBearbeiter = getValues("selectTerminFür") || '';
        const terminArt = getValues("selectTerminArt") || '';
        const terminDatum = getValues("TerminDatum") || '';
        const bemerkungen = getValues("TextFieldBemerkungen") || '';  // use empty string if value is null

        // Adding Headline
        doc.setFontSize(20);
        let nameundvorname = " ohne Kundenbezug";
        if (dataImported && dataImported.Nachname) {
             nameundvorname = " für: " + dataImported.Nachname + ", " + dataImported.Vorname;
        }
        doc.setFont('helvetica', 'bold');
        doc.text('Termin' + nameundvorname, 20, 20); // Center alignment
        doc.line(20, 21, 190, 21); // underline
        doc.setFont('helvetica', 'normal');

        // Adding vertical text
        const xOffset = 10; // Change according to your requirements
        const yOffset = 60; // Change according to your requirements

        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'bold');
        doc.text(nameundvorname, xOffset, yOffset, {angle: 90});
        doc.line(12, 10, 12, 290);
        doc.setFont('helvetica', 'normal');

        // Add space
        doc.setFontSize(10);
        doc.text('\n', 20, 30);

        doc.text('Druckdatum: ', 20, 40);
        doc.line(20, 41, 40, 41); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(moment().format('DD.MM.YYYY'), 60, 40);
                doc.setFont('helvetica', 'normal');

        doc.text('Bearbeiter: ', 20, 45);
        doc.line(20, 46, 37, 46); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(terminBearbeiter, 60, 45);
                doc.setFont('helvetica', 'normal');

        doc.text('Fälligkeit des Termins: ', 20, 50);
        doc.line(20, 51, 55, 51); // underline
                doc.setFont('helvetica', 'bold');
                let formattedDate = " ";
                if (moment(terminDatum).isValid()) {
                    formattedDate = moment(terminDatum).format('DD.MM.YYYY');
                }
                doc.text(formattedDate, 60, 50);
                doc.setFont('helvetica', 'normal');

        doc.text('Terminart: ', 20, 55);
        doc.line(20, 56, 36, 56); // underline
                doc.setFont('helvetica', 'bold');
                doc.text(terminArt, 60, 55);
                doc.setFont('helvetica', 'normal');

        // Adding "Titel des Termins" with underline
        doc.setFontSize(12);
        doc.text('Titel des Termins: ', 20, 80);
        doc.line(20, 81, 190, 81); // underline

        // Adding value of Title with Bold
        doc.setFont('helvetica', 'bold');
        doc.text(terminTitel, 20, 90);
        doc.setFont('helvetica', 'normal');

        // Add space
        doc.setFontSize(10);
        doc.text('\n', 20, 100);

        // Adding "Bemerkungen" with underline
        doc.setFontSize(12);
        doc.text('Bemerkungen: ', 20, 110);
        doc.line(20, 111, 190, 111); // underline

        // Adding value of Bemerkungen with a new line
        const bemerkungenLines = doc.splitTextToSize(bemerkungen, 180); // width = 180 to consider margins
        let y = 120;
        const lineHeight = 7;  // adjust this as needed
        bemerkungenLines.forEach(line => {
            if (y + lineHeight > 280) {  // 280 is just before the end of the page
                doc.addPage();
                y = 20;  // reset y to the top of the new page
            }
            doc.text(line, 20, y);
            y += lineHeight;
        });

        // Prepare dynamicFieldsText
        let dynamicFieldsText = '';
        terminFeldList.forEach((terminFeldValue, index) => {
            dynamicFieldsText += `${terminFeldValue}: ${inputFieldValues[terminFeldValue] || ''}\n`;
        });

        // Add the "Weitere Informationen:" header to the PDF document
        if (y + lineHeight > 280) {
            doc.addPage();
            y = 20;
        }
        doc.setFontSize(12);
        doc.text('Weitere Informationen:', 20, y + 10);
        doc.line(20, y + 11, 190, y + 11); // underline
        y += 3*lineHeight;

        // Split the dynamic fields text into lines
        const dynamicFieldsLines = doc.splitTextToSize(dynamicFieldsText, 180); // adjust this as needed

        // Add the dynamic fields text to the PDF document, with automatic page breaks
        dynamicFieldsLines.forEach(line => {
            if (y + lineHeight > 280) {
                doc.addPage();
                y = 20;
            }
            doc.text(line, 20, y);
            y += lineHeight;
        });

        doc.save('Neuer Termin.pdf');
    };

    const handleClickTerminArthinz = (event) => {
        setAnchorElPopoverArthinz(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElPopoverArthinz(null);
    };

    const handleClickFeldTerminArthinz = (event) => {
        setAnchorElFeldTerminArt(event.currentTarget);
    };

    const handleCloseFeldTerminArt = () => {
        setAnchorElFeldTerminArt(null);
    };

    const handleOnSubmit = (evt) => {

        let terminNameVN, terminVornameVN, terminPartnernummer;
        if (!dataImported) {
            terminNameVN = '-';
            terminVornameVN = '-';
            terminPartnernummer = '-';
        } else {
            terminNameVN = dataImported.Nachname;
            terminVornameVN = dataImported.Vorname;
            terminPartnernummer = dataImported.PNr;
        }

        const terminFelder = [];
        terminFeldList.forEach((terminFeldValue, index) => {  
        const value = inputFieldValues[terminFeldValue] || "";
        terminFelder.push({ terminFeld: terminFeldValue, value });        
        });


        let formattedDate = null;  

        if (evt.TerminDatum === "" && dataImported)
        {
            formattedDate = null;
        } else if (evt.TerminDatum === "" && !dataImported) 
        {
            dispatch(globalAppBarMessage(`Bitte trage ein Datum ein.`));
            return;
        }
        else if (moment(evt.TerminDatum).isValid()) {
            if (moment(evt.TerminDatum).year() < 2000) {
                dispatch(globalAppBarMessage(`Ungültiges Datum. Bitte berichtige das Jahr.`));
                return;
            } else if (moment(evt.TerminDatum).year() > 2999)
            {
                dispatch(globalAppBarMessage(`Ungültiges Datum. Bitte berichtige das Jahr.`));
                return;
            } else
            {
                formattedDate = moment(evt.TerminDatum).format('YYYY-MM-DD HH:mm:ss'); 
            }
        }
        else
        {
            dispatch(globalAppBarMessage(`Ungültiges Datum.`));
            return;
        }

        updateUserStats(user.vollstaendigerName, "update", "Kunde", "Neuen Termin angelegt", terminPartnernummer, "");

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchAddTermin: 1,
                TerminNameVN: terminNameVN,
                TerminVornameVN: terminVornameVN,
                TerminPartnernummer: terminPartnernummer,
                TerminDateText: formattedDate,
                TerminBearbeiterText: evt.selectTerminFür, 
                TerminTitleText: evt.TerminTitel,
                TerminArt: evt.selectTerminArt, 
                BemerkungenTermin: evt.TextFieldBemerkungen,
                TerminFelder: terminFelder              
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                return response.json();
            }).then((data) => { 
                handleRefetchData();
                handleCloseTermine();                
        });        
    };
   
    return (
    <Modal
                        open={openModalTermine}
                        onClose={handleCloseTermine}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                    <Fade in={openModalTermine}>
                        <Box sx={style}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{ fontSize: '20px', alignSelf: 'center', fontFamily: 'roboto', marginLeft: "2px" }}>
                                <span><b>Termine</b></span>
                                <Tooltip sx={{ml: 2}} title='Termin drucken'>
                                    <IconButton onClick={handlePrint} fontSize='small' color="primary">
                                    <PrintSharp />
                                </IconButton>
                            </Tooltip>                                
                            </div>
                            {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Admin') ?
                            <div style={{ display: "flex", alignItems: "center" }}>
                                { konfigurationsModusAktiv ?
                                <div>                                    
                                <Tooltip title='Neue Terminart hinzufügen'>
                                    <IconButton onClick={handleClickTerminArthinz} fontSize='small' color="primary">
                                    <NoteAdd />
                                    </IconButton>
                                </Tooltip>

                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <Stack direction="row" spacing={1} sx={{margin: 1}}>
                                    <TextField placeholder='Terminart hinzufügen...' size='small' inputRef={TerminArthinzRef}></TextField>
                                    <IconButton onClick={handleClickTerminArtPlus} color="primary">
                                    <AddCircle />
                                    </IconButton>                             
                                    </Stack>
                                </Popover>

                                <Tooltip title='Ausgewählte Terminart löschen'>
                                <IconButton sx={{ml: 0}} onClick={handleClickTerminArtloeschen} fontSize='small' color="error">
                                <Delete />
                                </IconButton>
                                </Tooltip>     

                                <Popover
                                    open={openFeldTerminArt}
                                    anchorEl={anchorElFeldTerminArt}
                                    onClose={handleCloseFeldTerminArt}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <Stack direction="row" spacing={1} sx={{margin: 1}}>
                                    <TextField placeholder='Feld hinzufügen...' size='small' inputRef={FeldTerminArthinzRef}></TextField>
                                    <IconButton onClick={handleClickFeldTerminArtPlus} color="primary">
                                    <AddCircle />
                                    </IconButton>                             
                                    </Stack>
                                </Popover>
                                </div> : null }                                
                                <Tooltip title="Konfigurationsmodus aktivieren">    
                                    <Switch checked={konfigurationsModusAktiv} onChange={handleKonfigurationsModusToggle} />
                                </Tooltip>                                           
                            </div> : null }        
                        </div>
                        <Divider sx={{mt: 1, mb: 2}}></Divider>
                        <form onSubmit={handleSubmit(handleOnSubmit)}>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <Controller                                                              
                                        control={control}
                                        name="TerminTitel"
                                        defaultValue=""
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField 
                                            {...field} 
                                            margin="normal"                                     
                                            fullWidth                               
                                            size="small"
                                            autoComplete="off"
                                            type="text"                                    
                                            label="Titel des Termins"
                                            error={error !== undefined}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                if(e.target.value === "") {
                                                    setError('TerminTitel', { type: 'required'});                                            
                                                } else {
                                                    clearErrors("TerminTitel");
                                                }
                                            }}
                                            />
                                        )}
                                    />
                                    <Controller
                                            control={control}
                                            name="TerminDatum"
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={de}>
                                                <DesktopDatePicker
                                                    placeholder="Kein Termin"
                                                    label="Datum"
                                                    format="dd.MM.yyyy"
                                                    value={value}     
                                                                                                
                                                    onChange={(date) => {
                                                        onChange(date);                                                     
                                                        if (!date) {
                                                            onChange("");
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField                           
                                                          size="small"
                                                          {...params}
                                                          error={!!error}
                                                          helperText={error ? error.message : null}
                                                          style={{ marginTop: "16px", marginBottom: "8px", marginLeft: "15px" }}
                                                        />
                                                      )}
                                                />
                                                </LocalizationProvider>
                                            )}
                                    />
                            </div>                                  
                            <Stack direction="row" marginTop={2} marginBottom={3} spacing={2}>
                                <Controller                                                              
                                        control={control}
                                        name="selectTerminArt"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth error={error !== undefined}>                                                
                                            <InputLabel style={{ top: '-5px' }}>Terminart</InputLabel>
                                            <Select
                                                {...field}
                                                inputRef={TerminArtSelectRef}
                                                fullWidth
                                                label="Terminart"
                                                size='small'
                                                error={error !== undefined}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setoptionsTerminArtChange(e.target.value);
                                                    // Clear all input fields
                                                    setInputFieldValues({});

                                                    if (e.target.value === "") {
                                                        setError("selectTerminArt", { type: "required" });
                                                    } else {
                                                        clearErrors("selectTerminArt");
                                                    }
                                                }}
                                            >
                                            <MenuItem disabled value="">
                                            <em>Bitte auswählen...</em>
                                            </MenuItem>
                                           {Array.isArray(optionsTerminArt) && optionsTerminArt.sort().map((Aufgabenartx) => (
                                            <MenuItem key={Aufgabenartx} value={Aufgabenartx}>
                                                {Aufgabenartx}
                                            </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                        )}
                                    />                                
                                        <Controller
                                            control={control}
                                            name="selectTerminFür"
                                            defaultValue={user.vollstaendigerName}
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth error={!!error}>
                                                    <InputLabel style={{ top: '-5px' }}>Bearbeiter</InputLabel>
                                                    {optionsBearbeiter.length > 0 ? (
                                                        <Select
                                                            {...field}
                                                            fullWidth
                                                            label="Bearbeiter"
                                                            size='small'
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                                if (e.target.value === "") {
                                                                    setError("selectTerminFür", { type: "required" });
                                                                } else {
                                                                    clearErrors("selectTerminFür");
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem disabled value=""><em>Bitte auswählen...</em></MenuItem>
                                                            {optionsBearbeiter.sort((a, b) => a.vollstaendigerName.localeCompare(b.vollstaendigerName)).map((user) => (
                                                                <MenuItem key={user.id} value={user.vollstaendigerName}>{user.vollstaendigerName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <CircularProgress size={24} /> // Or some other loading indicator
                                                    )}
                                                </FormControl>
                                            )}
                                        />                  
                            </Stack>
                            <div>
                                {terminFeldList.length > 0 &&
                                terminFeldList.map((terminFeldValue, index) => (
                                index % 2 === 0 && (
                                <Stack sx={{mb: 3}} direction="row" key={index} spacing={2}>
                                    <TextField
                                    autoComplete='off'
                                    inputProps={{ autoComplete: 'new-password' }}
                                    autoCorrect='off'
                                    sx={{width: "243px"}}
                                    size="small"
                                    label={terminFeldValue}
                                    defaultValue=""
                                    id={`terminfeld-${terminFeldValue}`}
                                    value={inputFieldValues[terminFeldValue] || ""}
                                    onChange={(e) => {
                                        setInputFieldValues((prevState) => ({
                                        ...prevState,
                                        [terminFeldValue]: e.target.value,
                                        }));
                                    }}
                                    />
                                    {index + 1 < terminFeldList.length && (
                                    <TextField
                                        autoComplete='off'
                                        inputProps={{ autoComplete: 'new-password' }}
                                        autoCorrect='off'
                                        sx={{width: "243px"}}
                                        size="small"
                                        label={terminFeldList[index + 1]}
                                        defaultValue=""
                                        id={`terminfeld-${terminFeldList[index + 1]}`}
                                        value={inputFieldValues[terminFeldList[index + 1]] || ""}
                                        onChange={(e) => {
                                        setInputFieldValues((prevState) => ({
                                            ...prevState,
                                            [terminFeldList[index + 1]]: e.target.value,
                                        }));
                                        }}
                                    />
                                    )}
                                </Stack>
                                )
                            ))}
                            {konfigurationsModusAktiv ?                           
                            <Tooltip sx={{mt: -2, mb: 1}} title='Neues Feld zur Terminart hinzufügen'>                                    
                                    <Button size="small" color='primary' startIcon={<AddCircle/>} onClick={handleClickFeldTerminArthinz}>Terminfeld hinzufügen...</Button>
                            </Tooltip>
                            : <div> </div>}
                        </div>
                         <Controller
                            control={control}
                            name="TextFieldBemerkungen"
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field, fieldState: { error } }) => {
                                const handleSaveTextFieldDialog = (value) => {
                                    // Update the field value in the parent component
                                    field.onChange(value);
                                    const textField = document.getElementById("TextFieldBemerkungen");
                                    setTimeout(() => {
                                            const textField = document.getElementById("TextFieldBemerkungen");
                                            textField.scrollTop = 0;
                                    }, 0);
                                };

                                return (
                                <>
                                    <TextField
                                    {...field}
                                    label="Bemerkungen"
                                    id="TextFieldBemerkungen"
                                    inputProps={{ style: { fontSize: 14, paddingRight: "20px" } }} // font size of input text
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={6}
                                    fullWidth
                                    onClick={() => {
                                        openTextFieldDialog(field.value); // Call openTextFieldDialog when the TextField is focused
                                    }}
                                    />
                                    <TextFieldPopup
                                    key={openTextField ? "open" : "closed"}
                                    open={openTextField}
                                    value={textFieldValue}
                                    onClose={closeTextFieldDialog}
                                    onSave={handleSaveTextFieldDialog}
                                    />
                                </>
                                );
                            }}
                            />                 

                            <Divider sx={{mt: 3, mb: 3}}></Divider>
                            <Stack direction="row" spacing={2}>
                                    <Button
                                        type='submit'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        >
                                        <DownloadDone fontSize='small' sx={{ mr: 1}}></DownloadDone>
                                        Speichern
                                    </Button>                                        

                                    <Button
                                        color='error'
                                        variant="text"
                                        size='medium'
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleCloseTermine}
                                        >
                                        <Cancel fontSize='small' sx={{ mr: 1}}></Cancel>
                                        Abbrechen
                                    </Button>                                    
                            </Stack>                         

                         </form>                      
                        </Box>
                    </Fade>
    </Modal>   
  
);
}
