import {FlightTakeoff, Help, MoreTime, NightsStay, AccessTime, HourglassFull, CloseSharp, PrintSharp, Cancel, AddCircle, NoteAdd, Delete, Edit, DownloadDone, Star, Height } from '@mui/icons-material';
import {
    LinearProgress,
    Badge,
    CircularProgress,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Divider,
    Modal,
    Typography,
    Select, 
    InputLabel,
    FormControl,
    Fade,
    Backdrop,
    FormHelperText,
    Grid,
    Popover,
    Switch,
    Toolbar,
    CardContent, Table, Paper, TableContainer, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import moment, { months } from 'moment';
import { useAuth } from "../hooks/useAuth";
import { useState, useEffect, useRef } from "react";
import { fetchdataUrl } from '../A-config/config';
import ZeiterfassungTableServerside from '../pages/ZeiterfassungTableServerside';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import AdapterDayjs from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import dayjs from 'dayjs';
import ZeiterfassungModalKorrekturen from '../modals/ZeiterfassungModalKorrekturen';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import ZeiterfassungModalUrlaub from '../modals/ZeiterfassungModalUrlaub';

const formatSecondstoHHMMSSPositiveandnegative = (timeseconds) => {    
    const duration = moment.duration(timeseconds, 'seconds');
    const totalHours = Math.floor(Math.abs(duration.asHours())); 
    const minutes = Math.abs(duration.minutes()); 
    const seconds = Math.abs(duration.seconds()); 
    const prefix = timeseconds < 0 ? '-' : '';

    return `${prefix}${totalHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default function Zeiterfassung() {
    const { user, logout, hasPermission} = useAuth();
    const [startTime, setStartTime] = useState('');
    const [todayData, settodayData] = useState([]);
    const [endTime, setendTime] = useState('');
    const [breakStatus, setBreakStatus] = useState('');
    const [breakTimes, setBreakTimes] = useState([]);
    const [FirmenInfosState, setfirmenInfos] = useState([]);
    const [users, setusers] = useState([]);
    const [selectedUser, setselectedUser] = useState(user.vollstaendigerName);
    const [selectedUserdata, setselectedUserdata] = useState([]);    
    const [month, setMonth] = useState(new Date());
    const [arbeitszeitgesamtTeilzeit, setarbeitszeitgesamtTeilzeit] = useState('');
    const [totalArbeitszeit, setTotalArbeitszeit] = useState(moment.duration());
    const [refreshInfo, setrefreshInfo] = useState(false);
    const [refreshChildComp, setrefreshChildComp] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [openModalUrlaub, setopenModalUrlaub] = useState(false);
    const [loading, setisloading] = useState(true);
    const [arbeitszeitProzentundGesamt, setarbeitszeitProzentundGesamt] = useState("");
    const [arbeitszeitMonatTeilzeitGesamtMonat, setarbeitszeitMonatTeilzeitGesamtMonat] = useState("");
    const [arbeitszeitMonatTeilzeitGesamtJahr, setarbeitszeitMonatTeilzeitGesamtJahr] = useState("");
    const [nochoffeneArbeitszeitimJahr, setnochoffeneArbeitszeitimJahr] = useState("");
    const [nochoffeneArbeitszeitimMonat, setnochoffeneArbeitszeitimMonat] = useState("");
    const [prozentualearbeitszeituser, setprozentualearbeitszeituser] = useState("");
    const componentRef = useRef();
    const updateZeiterfassung = useSelector(state => state.updateZeiterfassung);
    const [firstMount, setfirstMount] = useState(true);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Zeiterfassung'
    });

    useEffect(() => {
        setisloading(true);
        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchLoadBearbeiter: 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
        .then((data) => {
            setusers(data);
        });
    }, [refreshInfo]);

    useEffect(() => {
        if (firstMount)
        {
            setisloading(true)
            setfirstMount(false)
        }

        const fetchData = async () => {
            try {
                setBreakTimes([]);
                const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: sessionStorage.getItem("access_token"),
                        fetchInsertAndCheckZeiterfassung: 1,
                        vollstaendigerName: selectedUser,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                const SecondsArbeitsZeitSaldiert = data.data.reduce((total, entry) => {
                    const az = entry.arbeitszeitsaldiert;
                    if (!az) {
                        return total;  // Skip null or empty entries
                    }
                    const isNegative = az.startsWith('-');
                    const duration = moment.duration(az.replace('-', ''));
                    return isNegative ? total - duration.asSeconds() : total + duration.asSeconds();
                }, 0);
                setTotalArbeitszeit(formatSecondstoHHMMSSPositiveandnegative(SecondsArbeitsZeitSaldiert));
    
                const today = moment().format('DD.MM.YYYY');
                const todayData = data.data.find(entry => {
                    const entryDate = entry.TagundDatum.split(' - ')[1];
                    const formattedToday = moment(today, 'DD.MM.YYYY');
                    const formattedEntryDate = moment(entryDate, 'DD.MM.YYYY');
                    return formattedEntryDate.isSame(formattedToday, 'day');
                });
    
                if (todayData) {
                    settodayData(todayData);
                    setStartTime(todayData.start_time);
                    setendTime(todayData.end_time);
    
                    if (todayData.break_times) {
                        const breaks = JSON.parse(todayData.break_times);
                        setBreakTimes(breaks);
                        const lastBreak = breaks[breaks.length - 1];
                        if (lastBreak && !lastBreak.end) {
                            setBreakStatus('pauseend');
                        } else {
                            setBreakStatus('pausestart');
                        }
                    } else {
                        setBreakStatus('pausestart');
                    }
                } else {
                    setStartTime(null);
                    setendTime(null);
                    setBreakStatus('pausestart');
                }

                const firmenInfos = JSON.parse(localStorage.getItem('firmenInfos'));
                setfirmenInfos(firmenInfos)
                const Wochenarbeitszeit = firmenInfos.arbeitszeitVollzeitkraft;
                const UrlaubstageVollzeitkraft = firmenInfos.UrlaubstageVollzeitkraft;
                let ArbeitszeitIndividuell;
        
                let prozentualearbeitszeit;
                if (users.length > 0 && selectedUser) {
                    const user = users.find(user => user.vollstaendigerName === selectedUser);
                    if (user) {
                        ArbeitszeitIndividuell = user.arbeitsstunden;
                        prozentualearbeitszeit = ((ArbeitszeitIndividuell / Wochenarbeitszeit) * 100).toFixed(2);
                        setarbeitszeitProzentundGesamt(`${ArbeitszeitIndividuell} / ${Wochenarbeitszeit} Stunden (${prozentualearbeitszeit}%)`);
                        setselectedUserdata(user)
                    }
                }
        
                if (prozentualearbeitszeit) {
                    const monthparsedtocompare = month ? moment(month).startOf('month') : null;
                    const yearparsedtocompare = month ? moment(month).startOf('year') : null;
        
                    const totalSecondsGesamtArbeitsZeitaktuellerMonat = data.data.reduce((total, entry) => {
                        const arbeitszeitgesamt1 = entry.arbeitszeitgesamt;
                        const datum = entry.TagundDatum;
                        const datePart = datum.split(' - ')[1];
                        const date = moment(datePart, 'DD.MM.YYYY');
        
                        if (!monthparsedtocompare || !date.isSame(monthparsedtocompare, 'month')) {
                            return total;
                        }
        
                        if (!arbeitszeitgesamt1) {
                            return total;
                        }
        
                        const duration = moment.duration(arbeitszeitgesamt1, 'seconds');
                        return total + duration.asSeconds();
                    }, 0);
        
                    const totalSecondsGesamtArbeitsZeitaktuellesJahr = data.data.reduce((total, entry) => {
                        const arbeitszeitgesamt1 = entry.arbeitszeitgesamt;
                        const datum = entry.TagundDatum;
                        const datePart = datum.split(' - ')[1];
                        const date = moment(datePart, 'DD.MM.YYYY');
        
                        if (!yearparsedtocompare || !date.isSame(yearparsedtocompare, 'year') && (entry.KorrekturDatum === null || entry.KorrekturDatum === "")) {
                            return total;
                        }
        
                        if (!arbeitszeitgesamt1) {
                            return total;
                        }
        
                        const duration = moment.duration(arbeitszeitgesamt1, 'seconds');
                        return total + duration.asSeconds();
                    }, 0);
        
                    const totalArbeitsTageimJahr = data.data.reduce((total, entry) => {
                        const Feiertaginfox = entry.Feiertaginfo;
                        const datum = entry.TagundDatum;
                        const datePart = datum.split(' - ')[1];
                        const date = moment(datePart, 'DD.MM.YYYY');
        
                        if (!yearparsedtocompare || !date.isSame(yearparsedtocompare, 'year')) {
                            return total;
                        }
        
                        if (Feiertaginfox === "Sonntag" || Feiertaginfox === "Samstag" || (Feiertaginfox && Feiertaginfox.startsWith("Feiertag"))) {
                            return total;
                        }
                        return total + 1;
                    }, 0);
        
                    const ArbeitsTageimJahr_AbzugUrlaub = totalArbeitsTageimJahr - UrlaubstageVollzeitkraft;
                    const ArbeitsstundenimJahr = (Wochenarbeitszeit / 5) * ArbeitsTageimJahr_AbzugUrlaub;
                    const ArbeitsstundenimMonat = ArbeitsstundenimJahr / 12;
                    const ArbeitsstundenimJahrtotalSeconds = (Math.round(ArbeitsstundenimJahr * 3600) * prozentualearbeitszeit) / 100;
                    const ArbeitsstundenimMonattotalSeconds = (Math.round(ArbeitsstundenimMonat * 3600) * prozentualearbeitszeit) / 100;
                    const offeneArbeitszeitimJahr = totalSecondsGesamtArbeitsZeitaktuellesJahr - ArbeitsstundenimJahrtotalSeconds;
                    const offeneArbeitszeitimMonat = totalSecondsGesamtArbeitsZeitaktuellerMonat - ArbeitsstundenimMonattotalSeconds;
        
                    setnochoffeneArbeitszeitimJahr(formatSecondstoHHMMSSPositiveandnegative(offeneArbeitszeitimJahr));
                    setnochoffeneArbeitszeitimMonat(formatSecondstoHHMMSSPositiveandnegative(offeneArbeitszeitimMonat));
                    setarbeitszeitMonatTeilzeitGesamtJahr(`${formatSecondstoHHMMSSPositiveandnegative(totalSecondsGesamtArbeitsZeitaktuellesJahr)} / ${formatSecondstoHHMMSSPositiveandnegative(ArbeitsstundenimJahrtotalSeconds)}`);
                    setarbeitszeitMonatTeilzeitGesamtMonat(`${formatSecondstoHHMMSSPositiveandnegative(totalSecondsGesamtArbeitsZeitaktuellerMonat)} / ${formatSecondstoHHMMSSPositiveandnegative(ArbeitsstundenimMonattotalSeconds)}`);
                }
                setisloading(false);

            } catch (error) {
                console.error("Error fetching data", error);
                setisloading(false);
            }
        };           
    
        fetchData();
    }, [selectedUser, users, month, refreshInfo, updateZeiterfassung]);
    
    const updateStartTime = () => {
        let taeglicheGutschriftgeteilt = FirmenInfosState.taeglicheGutschrift / 2;
        const taeglicheGutschriftgeteiltduration = moment.duration(taeglicheGutschriftgeteilt, 'minutes');
        const currentTime = moment();
        const newTime = currentTime.subtract(taeglicheGutschriftgeteiltduration);
        const formattedNewTime = newTime.format('YYYY-MM-DD HH:mm:ss');

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateZeiterfassung: 1,
                vollstaendigerName: user.vollstaendigerName,
                action: "checkin",
                currenttime: formattedNewTime
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setStartTime(data.message);            
            setrefreshChildComp(!refreshChildComp);
        });
    };

    const updateCheckout = () => {
        let Tagesarbeitszeit = FirmenInfosState.arbeitszeitVollzeitkraft / 5;
        const Tagesarbeitszeitduration = moment.duration(Tagesarbeitszeit, 'hours');
        let taeglicheGutschriftgeteilt = FirmenInfosState.taeglicheGutschrift / 2;
        const taeglicheGutschriftgeteiltduration = moment.duration(taeglicheGutschriftgeteilt, 'minutes');
        const currentTime = moment();
        const newTime = currentTime.add(taeglicheGutschriftgeteiltduration);
        const formattedNewTime = newTime.format('YYYY-MM-DD HH:mm:ss');


        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateZeiterfassung: 1,
                vollstaendigerName: user.vollstaendigerName,
                action: "checkout",
                currenttime: formattedNewTime,
                taeglicheArbeitszeitinSekunden: Tagesarbeitszeitduration.asSeconds()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setrefreshInfo();
            setendTime(data.message);
            setrefreshChildComp(!refreshChildComp);
        });
    };

    const updatePauseTime = () => {
        let statusbreak = breakStatus;

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchUpdateZeiterfassungPause: 1,
                vollstaendigerName: user.vollstaendigerName,
                statusbreak: statusbreak
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setBreakTimes(data.breakTimes);
            // Toggle break status
            if (statusbreak === 'pausestart') {
                setBreakStatus('pauseend');
            } else {
                setBreakStatus('pausestart');
            }
        });
    };

    const handleUserChange = (event) => {
        setselectedUser(event.target.value);
    }

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const calculateTotalBreakTime = () => {
        let totalBreakTime = 0;
        breakTimes.forEach(breakTime => {
            if (breakTime.end) {
                const start = moment(breakTime.start);
                const end = moment(breakTime.end);
                totalBreakTime += end.diff(start);
            }
        });
        const duration = moment.duration(totalBreakTime);
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const getPageMargins = () => {
        return `@page { margin: 10 10 10 10 !important; }`;
    };

    const taeglicheKorrektur = FirmenInfosState?.taeglicheGutschrift ?? 0;
    const taeglicheKorrekturInSeconds = taeglicheKorrektur * 60;
    const formattedTaeglicheKorrektur = formatSecondstoHHMMSSPositiveandnegative(taeglicheKorrekturInSeconds);

    return (
    //     <Popover        
    //     open={open}
    //     anchorEl={anchor}
    //     onClose={handleClose}
    //     anchorOrigin={{
    //         vertical: 'top',
    //         horizontal: 'left',
    //     }}
    //     transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'left',
    //     }}
    // >
        // <div style={{minWidth: "90vw", minHeight: "90vh", overflowY: "auto", overflowX: "auto"}}>
        loading ? <LinearProgress/> :
        <Box ref={componentRef} sx={{ display: "flex", flexDirection: "column", height: "100%", padding: 2 }}>
            <style>{getPageMargins()}</style>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                <Typography component="div" sx={{ fontSize: "20px", fontWeight: "bold", marginRight: 2 }}>Zeiterfassung</Typography>
                <FormControl sx={{ width: 200}} size="small">
                {hasPermission(user, 'Serveradmin') || hasPermission(user, 'Applikationsadmin') || hasPermission(user, 'Zeiterfassungsadmin') || hasPermission(user, 'Zeiterfassungsbearbeiter') ?
                <Select 
                    sx={{ fontSize: "14px", height: "40px", marginRight: "10px"}}
                    value={selectedUser}
                    onChange={handleUserChange}
                    displayEmpty
                >
                    <MenuItem disabled value="">
                        <em>Bitte auswählen...</em>
                    </MenuItem>
                    {Array.isArray(users) && users.sort().map((user) => (
                        <MenuItem sx={{fontSize: "14px"}} key={user.id} value={user.vollstaendigerName}>
                            {user.vollstaendigerName}
                        </MenuItem>
                    ))}
                </Select> : null }
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
                <DesktopDatePicker
                    views={['year', 'month']}
                    openTo="month"
                    label="Datum auswählen"
                    value={month}
                    onChange={(newValue) => setMonth(newValue)}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            sx={{
                                width: "200px",
                                '& .MuiInputBase-input': {
                                    fontSize: "14px",
                                    height: "22px"
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: "14px",
                                }
                            }} 
                            size="small" 
                        />
                    )}
                />
            </LocalizationProvider>
            <Button size="small" onClick={handlePrint} sx={{color: "#2e3192", marginLeft: "10px"}} variant='standard' startIcon={<PrintSharp></PrintSharp>}>Drucken</Button>
            </Box>
            <Divider />
            <Stack direction="row" spacing={1} sx={{ marginTop: 2, marginBottom: 1 }}>
                {/* <Button size="small" onClick={updateStartTime} sx={{color: "#2e3192"}} variant='standard' startIcon={<AccessTime></AccessTime>}>Einstempeln</Button>
                <Button size="small" onClick={updatePauseTime} sx={{color: "#2e3192"}} variant='standard' startIcon={<HourglassFull></HourglassFull>}>
                    {breakStatus === 'pausestart' ? 'Pause beginnen' : 'Pause beenden'}
                </Button>
                <Button size="small" onClick={updateCheckout} sx={{color: "#2e3192"}} variant='standard' startIcon={<NightsStay></NightsStay>}>Ausstempeln</Button>
                <Button size="small" sx={{color: "#2e3192"}} variant='standard' startIcon={<MoreTime></MoreTime>} onClick={() => setopenModal(true)}>Korrekturen</Button> */}
                <Button size="small" disabled={!!startTime || selectedUser !== user.vollstaendigerName || (todayData.Feiertaginfo !== null && todayData.Feiertaginfo !== '')} onClick={updateStartTime} sx={{color: "#2e3192"}} variant='standard' startIcon={<AccessTime></AccessTime>}>Einstempeln</Button>
                <Button size="small" disabled={!!endTime || selectedUser !== user.vollstaendigerName || startTime === null} onClick={updatePauseTime} sx={{color: "#2e3192"}} variant='standard' startIcon={<HourglassFull></HourglassFull>}>
                    {breakStatus === 'pausestart' ? 'Pause beginnen' : 'Pause beenden'}
                </Button>
                <Button size="small" disabled={!!endTime || selectedUser !== user.vollstaendigerName || startTime === null || breakStatus === "pauseend"} onClick={updateCheckout} sx={{color: "#2e3192"}} variant='standard' startIcon={<NightsStay></NightsStay>}>Ausstempeln</Button>
                <Button size="small" sx={{color: "#2e3192"}} variant='standard' startIcon={<MoreTime></MoreTime>} onClick={() => setopenModal(true)}>Korrekturen</Button>
                <Badge color="success" badgeContent="Vorschau">
                    <Button size="small" sx={{color: "#2e3192"}} variant='standard' startIcon={<FlightTakeoff></FlightTakeoff>} onClick={() => setopenModalUrlaub(true)}>Urlaub</Button>

                </Badge>
            </Stack>
            <Divider sx={{ marginY: 1 }} />
            <Grid container spacing={1} sx={{width: "800px", marginLeft: "5px"}}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Eingestempelt:</Typography>
                    <Typography sx={{ paddingLeft: startTime ? '0px' : '40px' }}>
                        {startTime ? moment(startTime).format('HH:mm:ss') + " Uhr" : "--"}
                    </Typography>
                    </Grid>
      
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Pausenbeginn:</Typography>
                    <Typography sx={{ paddingLeft: breakTimes.length > 0 ? '0px' : '40px' }}>
                        {breakTimes.length > 0 ? moment(breakTimes[breakTimes.length - 1].start).format('HH:mm:ss') + " Uhr" : "--"}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Pausenende:</Typography>
                    <Typography sx={{ paddingLeft: breakTimes.length > 0 ? '0px' : '40px' }}>
                        {breakTimes.length > 0 && breakTimes[breakTimes.length - 1].end ? moment(breakTimes[breakTimes.length - 1].end).format('HH:mm:ss') + " Uhr" : "--"}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Ausgestempelt:</Typography>
                    <Typography sx={{ paddingLeft: endTime ? '0px' : '45px' }}>
                        {endTime ? moment(endTime).format('HH:mm:ss') + " Uhr" : "--"}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Gesamte Pause:</Typography>
                    <Typography sx={{ paddingLeft: calculateTotalBreakTime() !== "00:00:00" ? '0px' : '60px' }}>
                        {calculateTotalBreakTime() !== "00:00:00" ? calculateTotalBreakTime() : "--"}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Tageskulanz:</Typography>
                    <Typography sx={{ color: formattedTaeglicheKorrektur.startsWith("-") ? "#ff6d75" : formattedTaeglicheKorrektur === "00:00:00" ? "black" : "#14a37f" }}>
                        {formattedTaeglicheKorrektur !== "00:00:00" ? <b>{formattedTaeglicheKorrektur}</b> : formattedTaeglicheKorrektur}</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ marginY: 1 }} />
            <Grid container spacing={1} sx={{width: "800px", marginTop: "0px", marginLeft: "5px"}}>
                <Grid item xs={6}>
                    <Box sx={{display: "flex"}}>
                        <Typography variant="subtitle2">Über-/Unterstunden (hh:mm:ss):</Typography>
                        <Tooltip
                        title={
                            <div>Dieses Anzeige ist nur für <span style={{color: "#ff6d75"}}>Vollzeit-MA</span> relevant.<br></br><br>
                            </br><u>Folgende Daten werden angezeigt:</u><br></br>
                                 Kummulierte Über-/Unterstunden. Die Daten werden über die Jahre hinweg addiert bzw. subtrahiert und stellen hierdurch ein fortlaufendes Arbeitszeitkonto dar.
                                 Die zu leistenden Stunden sind abhängig von der gewählten wöchentlichen Arbeitszeit. 
                            </div>
                        }
                        >
                            <Help sx={{height: "14px", width: "14px", paddingLeft: "2px"}}></Help>
                        </Tooltip>
                    </Box>
                    <Typography 
                        sx={{ 
                            fontWeight: "bold", 
                            color: typeof totalArbeitszeit === 'string' && totalArbeitszeit.startsWith("-") ? "#ff6d75" : "#14a37f" 
                        }}
                    >
                        {typeof totalArbeitszeit === 'string' && totalArbeitszeit ? totalArbeitszeit : ""}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: "flex"}}>
                        <Typography variant="subtitle2">Wöchentliche Arbeitzeit:</Typography>
                        <Tooltip
                        title={
                            <div>Wöchentliche Stunden im Verhältnis zu den Stunden, welche eine Vollzeitkraft bei 100%-iger Beschäftigung leisten muss.
                                <br>
                                </br><br></br>
                                Die wöchentliche Arbeitszeit kann jeder Mitarbeiter in den Allgemeinen Einstellungen hinterlegen.<br></br><br></br>
                                Die wöchentliche Arbeitszeit für die Vollzeitkraft kann nur ein Administrator in den zusätzlichen Einstellungen festlegen.
                            </div>
                        }
                        >
                            <Help sx={{height: "14px", width: "14px", paddingLeft: "2px"}}></Help>
                        </Tooltip>
                    </Box>
                    <Typography>{typeof arbeitszeitProzentundGesamt === 'string' && arbeitszeitProzentundGesamt ? arbeitszeitProzentundGesamt : ""}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: "flex"}}>
                        <Typography variant="subtitle2">Arbeitsstunden im ausgewählten Monat (hh:mm:ss):</Typography>
                        <Tooltip
                        title={
                            <div>Dieses Anzeige ist nur für <span style={{color: "#ff6d75"}}>Teilzeit-MA</span> relevant.<br></br><br>
                            </br><u>Folgende Daten werden angezeigt:</u><br></br>
                                Gesamte Arbeitszeit im jeweiligen Monat (abzüglicher aller Pausen) / <br></br>
                                Zu leistende Arbeitszeit im jeweiligen Monat (abhängig vom Beschäftigungsgrad) <br></br>
                                Noch zu leistende Stunden im Monat bzw. zu viel geleistete Stunden im jeweiligen Monat
                            </div>
                        }
                        >
                            <Help sx={{height: "14px", width: "14px", paddingLeft: "2px"}}></Help>
                        </Tooltip>
                    </Box>
                    <Stack direction="row">
                        <Typography>{typeof arbeitszeitMonatTeilzeitGesamtMonat === 'string' && arbeitszeitMonatTeilzeitGesamtMonat ? arbeitszeitMonatTeilzeitGesamtMonat : ""}</Typography>
                        <Typography sx={{ 
                                fontWeight: "bold", 
                                marginLeft: "5px",
                                color: typeof nochoffeneArbeitszeitimMonat === 'string' && nochoffeneArbeitszeitimMonat.startsWith("-") ? "#ff6d75" : "#14a37f" 
                        }}
                        >{typeof nochoffeneArbeitszeitimMonat === 'string' && nochoffeneArbeitszeitimMonat ? "(" + nochoffeneArbeitszeitimMonat + ")": ""}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: "flex"}}>
                    <Typography variant="subtitle2">Arbeitsstunden im ausgewählten Jahr (hh:mm:ss):</Typography>
                        <Tooltip
                        title={
                            <div>Dieses Anzeige ist nur für <span style={{color: "#ff6d75"}}>Teilzeit-MA</span> relevant.<br></br><br>
                            </br><u>Folgende Daten werden angezeigt:</u><br></br>
                                Gesamte Arbeitszeit im jeweiligen Jahr (abzüglicher aller Pausen & manueller Korrekturen!) / <br></br>
                                Zu leistende Arbeitszeit im jeweiligen Jahr (abhängig vom Beschäftigungsgrad) <br></br>
                                Noch zu leistende Stunden im Jahr bzw. zu viel geleistete Stunden im jeweiligen Jahr
                            </div>
                        }
                        >
                            <Help sx={{height: "14px", width: "14px", paddingLeft: "2px"}}></Help>
                        </Tooltip>
                    </Box>                  
                    <Stack direction="row">
                        <Typography>{typeof arbeitszeitMonatTeilzeitGesamtJahr === 'string' && arbeitszeitMonatTeilzeitGesamtJahr ? arbeitszeitMonatTeilzeitGesamtJahr : ""}</Typography>
                        <Typography sx={{ 
                            fontWeight: "bold", 
                            marginLeft: "5px",
                            color: typeof nochoffeneArbeitszeitimJahr === 'string' && nochoffeneArbeitszeitimJahr.startsWith("-") ? "#ff6d75" : "#14a37f" 
                        }}
                        >{typeof nochoffeneArbeitszeitimJahr === 'string' && nochoffeneArbeitszeitimJahr ? "(" + nochoffeneArbeitszeitimJahr + ")": ""}</Typography>
                    </Stack>      
                </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Stack direction="row" spacing={2}>
               
            </Stack>
            <Box sx={{ marginTop: 2 }}>
                <ZeiterfassungTableServerside datechange={month} selectedUser={selectedUser} refreshChildComp={refreshChildComp} refreshInfo={() => setrefreshInfo(!refreshInfo)} />
                <ZeiterfassungModalKorrekturen openModal={openModal} setopenModal={setopenModal} selectedUser={selectedUser} refreshInfo={() => setrefreshInfo(!refreshInfo)} />
                <ZeiterfassungModalUrlaub openModal={openModalUrlaub} setopenModal={setopenModalUrlaub} selectedUser={selectedUserdata} firmeninfos={FirmenInfosState} selectedyear={month} refreshInfo={() => setrefreshInfo(!refreshInfo)} />
            </Box>
        </Box>
        // </div>

    // </Popover>
    );
};
