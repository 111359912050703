import React from 'react';
import PropTypes from 'prop-types';
import { Transition, DialogContentText, Stack, Typography, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { HelpSharp } from '@mui/icons-material';

const ConfirmDialog = ({ open, onClose, message, onConfirm, onCancel }) => {
  const renderMessage = () => {
    if (typeof message === 'string') {
      return <DialogContentText>{message}</DialogContentText>;
    } else if (React.isValidElement(message)) {
      return message;
    } else {
      return null; 
    }
  };

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <DialogContent>
        {renderMessage()}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onConfirm}>Ja</Button>
        <Button variant="text" onClick={onCancel}>Nein</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node // Allows JSX elements
  ]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmDialog;