import React, { createContext, useContext, useMemo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { fetchdataUrl } from '../A-config/config';

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const [firmeninfos, setfirmeninfos] = useState([])
  const [alleUser, setAlleUser] = useState([]);

  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);
    navigate("/dashboard/UserStart", { replace: true });
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };
  
  const updateProfilePicture = (newPath) => {
      setUser((prevUser) => ({
        ...prevUser,
        profilepicturepath: newPath,
      }));
  };

  const fetchUserData = async (username) => {
    // Bessere Sicherheit bekommen wir, wenn wir das access_token wirklich auch nur auf die Session beschränken.
    sessionStorage.setItem('access_token', localStorage.getItem('access_token'));

    fetch(`${fetchdataUrl}fetchdata.php`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: sessionStorage.getItem("access_token"),
        fetchSpecificUseronStart: 1,
        username: username,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((datanew) => {
        if (datanew && datanew.length > 0) {
          setUser(datanew[0]);
        } else {
          logout();
          console.error('Error fetching user data:', datanew);
        }
      })
      .catch((error) => {
        logout();
        console.error('Error fetching user data:', error);
      });
  };

  useEffect(() => {
    if (user) {
      fetchUserData(user.username);
    }   
  }, []);

  const handleChromeWebviewMessage = event => {
    if (event.data.ordnerlist) {
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem('access_token'),
          insertorupdateOrdnerlist: 1,
          ordnerstruktur: event.data.ordnerlist,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => console.log(response))
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    } 
    else if (event.data.grundsätzlicheordnerstrukturlist)
    {
      fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
          access_token: sessionStorage.getItem('access_token'),
          insertorupdateGrundsOrdnerlist: 1,
          ordnerstruktur: event.data.grundsätzlicheordnerstrukturlist,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          localStorage.setItem('pfadkundenablage', event.data.grundsätzlicheordnerstrukturlist);
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    }
  };

  useEffect(() => {
        if (!user) {
          return;
        } 
               
        fetch(`${fetchdataUrl}fetchdata.php`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchAlleUser: 1
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => response.json()).then((data) => {
          setAlleUser(data);
        });

        // Admin Info an Mainwindow weiterleiten
        if (window.chrome && window.chrome.webview && isDesktop && user) {
          window.chrome.webview.postMessage(['Admin', user.admin]);
        }

        // Berechtigungen an Mainwindow weiterleiten
        if (window.chrome && window.chrome.webview && isDesktop && user) {
          window.chrome.webview.postMessage(['Berechtigungen', user.berechtigungen]);
        } 

        // Send OrdnerStruktur and GrundsOrdnerstruktur data to C# App
        Promise.all([
          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem('access_token'),
              getordnerstruktur: 1,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json()),
          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem('access_token'),
              getgrundsordnerstruktur: 1,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json()),
          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem('access_token'),
              fetchLoadSchadenArten: 1,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json()),
          fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem('access_token'),
              fetchLoadBearbeiter: 1,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json()),
        ])
          .then(data => {       
            // Check if the fetched data at each index is an array
            if (Array.isArray(data[0])) {
              data[0].unshift('Ordnerstruktur');
              if (window.chrome && window.chrome.webview && isDesktop) {
                window.chrome.webview.postMessage(data[0]);
              }
            } else {
              console.error('Data at index 0 is not an array:', data[0]);
            }
        
            if (Array.isArray(data[1])) {
              data[1].unshift('GrundsOrdnerstruktur');
              if (window.chrome && window.chrome.webview && isDesktop) {
                window.chrome.webview.postMessage(data[1]);
              }
            } else {
              console.error('Data at index 1 is not an array:', data[1]);
            }
        
            if (window.chrome && window.chrome.webview && isDesktop) {
              const dataarray = ["SendUserData", user.vollstaendigerName, user.kürzel, user.email, user.berechtigungen];
              window.chrome.webview.postMessage(dataarray);
            }
        
            let newData = { 'Schadenarten': 'true' };
            if (typeof data[2] === 'object' && data[2] !== null) {
              Object.keys(data[2]).forEach(key => {
                newData[key] = data[2][key];
              });
              if (window.chrome && window.chrome.webview && isDesktop) {
                window.chrome.webview.postMessage(newData);
              }
            } else {
              console.error('Data at index 2 is not an object:', data[2]);
            }
        
            if (Array.isArray(data[3])) {
              data[3].unshift('SendBearbeiter');
              if (window.chrome && window.chrome.webview && isDesktop) {
                window.chrome.webview.postMessage(data[3]);
              }
            } else {
              console.error('Data at index 3 is not an array:', data[3]);
            }
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });

        if (window.chrome && window.chrome.webview && isDesktop) {
          window.chrome.webview.addEventListener('message', handleChromeWebviewMessage);
      }

    return () => {
      if (window.chrome && window.chrome.webview && isDesktop) {
        window.chrome.webview.removeEventListener('message', handleChromeWebviewMessage);
      }
    };

  }, [user]);

  const hasPermission = (user, requiredPermission) => {
    if (!user || !user.berechtigungen) return false;
    return user.berechtigungen.includes(requiredPermission);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateProfilePicture,
      setUser, 
      hasPermission,
      alleUser
    }),
    [user]
  );

   return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
