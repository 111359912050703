import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Tooltip } from '@mui/material';
import moment from 'moment';
import { fetchdataUrl } from '../A-config/config';
import { useAuth } from '../hooks/useAuth';

const CurrentVacationÜbersicht = () => {
    const [userVacations, setUserVacations] = useState([]);
    const { alleUser } = useAuth();

    useEffect(() => {
        const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        const endDate = moment().add(30, 'days').format('YYYY-MM-DD');

        fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
                access_token: sessionStorage.getItem("access_token"),
                fetchGetUrlaubinRange: 1,
                startdate: startDate,
                enddate: endDate
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
          .then((data) => {
              if (data.success) {
                  setUserVacations(data.data);
              }
          });
    }, []);

    const getKuerzel = (username) => {
        const user = alleUser.find(user => user.vollstaendigerName === username);
        return user ? user.kürzel : '';
    };

    const aggregatedVacations = useMemo(() => {
        const vacationMap = {};
        const today = moment();

        userVacations.forEach(vacation => {
            const username = vacation.username;
            const date = moment(vacation.TagundDatum.split(' - ')[1], 'DD.MM.YYYY');

            if (!vacationMap[username]) {
                vacationMap[username] = [];
            }

            vacationMap[username].push(date);
        });

        const result = [];

        Object.entries(vacationMap).forEach(([username, dates]) => {
            dates.sort((a, b) => a - b);
            let start = dates[0];
            let end = dates[0];

            for (let i = 1; i < dates.length; i++) {
                const currentDate = dates[i];
                const previousDate = dates[i - 1];

                if (currentDate.diff(previousDate, 'days') > 1) {
                    if (previousDate.isoWeekday() === 5 && currentDate.isoWeekday() === 1) {
                        end = currentDate;
                    } else {
                        result.push({
                            username,
                            startdate: start,
                            enddate: end
                        });
                        start = currentDate;
                    }
                } else {
                    end = currentDate;
                }
            }

            result.push({
                username,
                startdate: start,
                enddate: end
            });
        });

        return result
            .filter(vacation => today.isBetween(vacation.startdate, vacation.enddate, 'days', '[]'))
            .sort((a, b) => a.startdate.diff(b.startdate));
    }, [userVacations]);

    const calculateProgress = (startdate, enddate) => {
        const totalDays = enddate.diff(startdate, 'days') + 1;
        const daysPassed = moment().diff(startdate, 'days') + 1;
        return (daysPassed / totalDays) * 100;
    };
  
    return (
        aggregatedVacations.length > 0 ?
        <div style={{ maxWidth: '550px', marginTop: "10px" }}>
            <table style={{ borderCollapse: "collapse", width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: "left", paddingRight: "10px", borderBottom: "1px solid #fff" }}>Benutzer</th>
                        <th style={{ textAlign: "center", borderBottom: "1px solid #fff", marginBottom: "5px" }}>Urlaubsdauer</th>
                    </tr>
                </thead>
                <tbody>
                    {aggregatedVacations.map((vacation) => (
                        <tr key={`${vacation.username}-${vacation.startdate.format('YYYY-MM-DD')}`}>
                            <td style={{ paddingRight: "20px", whiteSpace: 'nowrap' }}>{vacation.username}</td>
                            <td style={{ textAlign: "center", whiteSpace: 'nowrap', position: 'relative' }}>
                                <div style={{ borderRadius: "10px", backgroundColor: "#e0e0e040", height: '18px', position: 'relative', overflow: 'hidden' }}>
                                    <div style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#5c60ed",
                                        height: '100%',
                                        width: `${calculateProgress(vacation.startdate, vacation.enddate)}%`,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1
                                    }}></div>
                                    <span style={{ paddingTop: "2px", position: 'relative', zIndex: 2, color: '#fff', fontSize: '11px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {vacation.startdate.format('DD.MM.YYYY')} - {vacation.enddate.format('DD.MM.YYYY')} (+{vacation.enddate.diff(moment(), 'days') + 1})
                                    </span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        :
        null
    );
};

export default CurrentVacationÜbersicht;
