import { useAuth} from "../hooks/useAuth";
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import {
  Avatar,
  CardHeader,
  Box,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Divider, 
  Stack,
  Checkbox,
  FormControlLabel, 
  Popover
} from '@mui/material';
import { DateRangeSharp, CancelSharp, CheckCircleOutlineSharp, HighlightOffSharp, NoteAddSharp, Delete, Edit, WarningAmberOutlined, CheckCircle, Star} from '@mui/icons-material';

import TermineModal from '../modals/TermineModalNeu';
import TermineModalUpdate from '../modals/TermineModalUpdate';
import { DateRangePickerCustom } from "../components/DateRangePickerCustom";

//Tage zwischen 2 Daten - d1 und d2 müssen im Format new Date(Jahr, Monat, Tag) sein.
var numDaysBetween = function(d1, d2) {
    var diff = d1.getTime() - d2.getTime();
    return diff / (1000 * 60 * 60 * 24);
};

const customLocalization = {
  ...MRT_Localization_DE,
  noResultsFound: "Es wurden keine Termine gefunden", 
  noRecordsToDisplay: "Es wurden keine Termine gefunden" 
};

export const TermineKundePage = ({TermineData, myData, handleRefetchData}) => {

  const handleOpenTermine = () => setopenModalTermine(true);
  const [openModalTermine, setopenModalTermine] = useState(false);
  const [openModalTermineUpdate, setopenModalTermineUpdate] = useState(false);
  const [anchorPopoverDate, setanchorPopoverDate] = useState(null);
  const [tableKeyTermineKunde, settableKeyTermineKunde] = useState(0);
  const [openPopoverDatePicker, setopenPopoverDatePicker] = useState(false);
  const [isTableTermineLoading, setisTableTermineLoading] = useState(true)
  const [dataTermine, setTermineData] = useState([]);
  const { user, hasPermission} = useAuth();
  const [rowDATA, setrowDATA] = useState('');

  const [dateRange, setDateRange] = useState([{
    startDate: moment(1950, 'year').toDate(),
    endDate: moment(3000, 'year').toDate(),
    key: 'selection'
  }]);

  const handleDateRangeChange = (range) => {
    setDateRange([range]);
  };

  const [columnVisibility, setColumnVisibility] = useState(() => {
      try {
        const visibilityString = localStorage.getItem('columnVisibilityTerminePartner');
        return JSON.parse(visibilityString) || { ID: false };
      } catch (e) {
        console.error(e);
        return { ID: false };
      }
  });

  const [contentHeight, setContentHeight] = useState(() => {
    const savedHeight = localStorage.getItem('contentHeightUserTermine');
    return savedHeight ? parseInt(savedHeight) : 300; // Default to 300 if nothing is stored
  });

  const calculateContentHeightAndPageSize = () => {
    const windowHeight = window.innerHeight - 578;

    localStorage.setItem('contentHeightUserTermine', windowHeight.toString());
    setContentHeight(windowHeight);
  };

  useEffect(() => {
    calculateContentHeightAndPageSize(); // Call on initial load

    // Set up event listener for resize
    window.addEventListener('resize', calculateContentHeightAndPageSize);

    // Clean-up function
    return () => {
      window.removeEventListener('resize', calculateContentHeightAndPageSize);
    };
  }, []);

  useEffect(() => {
    setTermineData(TermineData);
    localStorage.setItem('columnVisibilityTerminePartner', JSON.stringify(columnVisibility));
    // Update the key to force re-mount and re-render of the table
    settableKeyTermineKunde(key => key + 1);
  }, [columnVisibility, TermineData]);

  useEffect(() => {
      setisTableTermineLoading(false)
  }, []);

  const handleColumnVisibilityChange = (updater) => {
  // Update the visibility state of the columns
  setColumnVisibility(updater);
  };

  const handleDateRangePickerButtonClick = (event) => {
      setopenPopoverDatePicker(true);
      setanchorPopoverDate(event.currentTarget)
  };

  const closePopoverDatePicker = () => {
      setopenPopoverDatePicker(false);
  }

  const columnsTableTermine = React.useMemo(
          () => [
            {
              accessorKey: 'Überfälligkeit',
              header: '',
              Cell: ({ cell }) => {
                const dateTermin = cell.row.original.TerminDateText;
                if (dateTermin === null || dateTermin === "0000-00-00 00:00:00") {
                  return <span></span>; // or any other placeholder you wish to show for null or invalid dates
                }
                const dateParts = dateTermin.substring(0,10).split("-");
                const DateNowArray = moment().format('DD.MM.yyyy').split(".");
                const DateAk = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                const DateNow = new Date(DateNowArray[2], DateNowArray[1] - 1, DateNowArray[0]);
                return numDaysBetween(DateNow, DateAk) > 0 ?
                  <div style={{ textAlign: 'center', padding: 0 }}>  
                    <Tooltip arrow placement="left" title="Überfällige Aufgabe">
                      <IconButton size='small' style={{color: '#f14668', padding: 0}}>
                        <WarningAmberOutlined />
                      </IconButton>
                    </Tooltip>
                  </div>
                  : 
                  <span></span>;
              },  
              enableHiding: false,
              enableColumnActions: false,
              enableSorting: false,    
              enableColumnFilter: false,        
              minSize: 40, //min size enforced during resizing
              maxSize: 40, //max size enforced during resizing
              size: 40, //medium column
            },
            {
            header: 'ID',
            accessorKey: 'ID',
            muiTableHeadCellProps: {
            },
            minSize: 60, //min size enforced during resizing
            maxSize: 60, //max size enforced during resizing
            size: 60, //medium column
        },
        {
          header: 'Datum',
          accessorKey: 'TerminDateText',
          Cell: ({ cell }) => {
            const dateValue = cell.getValue();
            if (!dateValue || dateValue === "0000-00-00 00:00:00") {
              return <span></span>;
            }
            const formattedDate = moment(dateValue).format("DD.MM.YYYY");
            return <span>{formattedDate}</span>;
          },
          minSize: 90,
          maxSize: 90,
          size: 90,
        },
        {
            header: 'Thema',
            accessorKey: 'TerminTitleText',
            muiTableHeadCellProps: {
            },
            minSize: 90, //min size enforced during resizing
            maxSize: 250, //max size enforced during resizing
            size: 250, //medium column
        }, 
        {
            header: 'Terminart',
            accessorKey: 'TerminArt',
            muiTableHeadCellProps: {
            },
            minSize: 100, //min size enforced during resizing
            maxSize: 100, //max size enforced during resizing
            size: 100, //medium column
        },    
          {
            header: 'Vorname',
            accessorKey: 'TerminVornameVN',
            muiTableHeadCellProps: {
            },
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column
        },
        {
            header: 'Name',
            accessorKey: 'TerminNameVN',
            minSize: 90, //min size enforced during resizing
            maxSize: 90, //max size enforced during resizing
            size: 90, //medium column         
        },
          {
            header: 'Bearbeiter',
            accessorKey: 'TerminBearbeiterText',
            muiTableHeadCellProps: {
            },
            minSize: 100, //min size enforced during resizing
            maxSize: 100, //max size enforced during resizing
            size: 100, //medium column
        },
          ],
          []
  )

  let filteredDataTableTermine = [];

  if (Array.isArray(dataTermine)) {
    filteredDataTableTermine = dataTermine.filter(row => {
      const dateText = row.TerminDateText;
      if (!dateText || dateText === "0000-00-00 00:00:00") {
        // Include these rows if you want to show entries without a valid date
        return true;
      }
      const rowDate = moment(dateText, 'YYYY-MM-DD HH:mm:ss').toDate();
      return moment(rowDate).isBetween(moment(dateRange[0].startDate), moment(dateRange[0].endDate), null, '[]');
    });
  }

  const tableKundeTermine = useMaterialReactTable({
    columns: columnsTableTermine,
    data: filteredDataTableTermine,
    state: {
      isLoading: isTableTermineLoading,
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showGlobalFilter: false,
      sorting: [{ id: "TerminDateText", desc: true }]
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setopenModalTermineUpdate(true);
        setrowDATA(row.original);
      }
    }),
    muiTableHeadRowProps: {
      sx: { justifyContent: "center" }
    },
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      elevation: 0
    },
    enableMultiRowSelection: false,
    enablePagination: true,
    autoResetPageIndex: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { height: contentHeight, minHeight: '250px' }
    },
    localization: customLocalization,
    renderToolbarInternalActions: ({ table }) => (
      <Box>
        <MRT_ToggleGlobalFilterButton table={table} />
        <Tooltip title="Neuen Termin hinzufügen">
          <IconButton onClick={handleOpenTermine}>
            <NoteAddSharp />
          </IconButton>
        </Tooltip>
        <Tooltip title="Datum auswählen">
          <IconButton onClick={handleDateRangePickerButtonClick}>
            <DateRangeSharp />
          </IconButton>
        </Tooltip>
        <Popover
          open={openPopoverDatePicker}
          anchorEl={anchorPopoverDate}
          onClose={closePopoverDatePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
        <DateRangePickerCustom dateRange={dateRange} onDateRangeChange={handleDateRangeChange} />
        </Popover>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    )
  });

  return (
            <Card>
              <CardContent>
                <TermineModal openModalTermine={openModalTermine} setopenModalTermine={setopenModalTermine} dataImported={myData} handleRefetchData={handleRefetchData}></TermineModal>
                <TermineModalUpdate openModalTermine={openModalTermineUpdate} setopenModalTermine={setopenModalTermineUpdate} handleRefetchData={handleRefetchData} rowDATA={rowDATA} openfromVertrieb={false} rowDATAVertrieb={null}></TermineModalUpdate>    

                <div style={{margin: "5px"}}>
                <MaterialReactTable table={tableKundeTermine}></MaterialReactTable>
               </div>  
              </CardContent>           
            </Card>     
  )
};