import { useAuth } from "../hooks/useAuth";
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Quill from 'quill';
import { Link, Box, Collapse, Alert, AlertTitle, IconButton, Divider, LinearProgress, Card, Button, CardContent, CircularProgress, Stack, Typography} from '@mui/material';
import { RestoreSharp, CloseSharp, SaveSharp, CleaningServicesSharp} from "@mui/icons-material";
import { fetchdataUrl } from '../A-config/config';
import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';
import moment from 'moment';
import { setloading } from "../hooks/reduxactions";

const CustomToolbar = ({ handleSaveButtonClick, handleCleanUpButtonClick, handleRestoreButtonClick, showloading}) => {
  return (
    <div id="toolbar" style={{ display: "flex" }}>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <div style={{marginRight: "7px", color: "lightgray"}}>|</div>
    <span className="ql-formats">
      <select className="ql-size" defaultValue="">
        <option value="small">Klein</option>
        <option value="">Normal</option>
        <option value="large">Groß</option>
        <option value="huge">Sehr Groß</option>
      </select>
      <button className="ql-blockquote" />     
    </span>
    <div style={{marginRight: "7px", color: "lightgray"}}>|</div>
    <span className="ql-formats">
      <select className="ql-align" />
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button> 
      <button className="ql-indent" value="+1" />
      <button className="ql-indent" value="-1" />
    </span>

    <span className="ql-formats">
      {/* <button className="ql-image" /> */}
      <button className="ql-clean" />
      <button title="Speichern" onClick={handleSaveButtonClick}>
        <SaveSharp />
      </button>
      <button title="Formattierung verschönern" onClick={handleCleanUpButtonClick}>
        <CleaningServicesSharp />
      </button>
      <button title="Letzte gespeicherte Bemerkungen wiederherstellen" onClick={handleRestoreButtonClick}>
        <RestoreSharp />
      </button>
    </span>
  </div>
  );
};

export const BemerkungenKunde = ({data}) => {

  const quillRef = useRef(null);
	const editorRef = useRef(null);
  const quillDataRef = useRef();
  const versionRef = useRef(null);
  const [bemerkungenVersion, setBemerkungenVersion] = useState(0);
  const [isloading, setIsloading] = useState(true);
  const [showloading, setshowloading] = useState(false)
  const [openAlert, setopenAlert] = React.useState(false);
  const [updatedFailedBemerkungen, setupdatedFailedBemerkungen] = useState("");
  const [updatedFailedVersion, setupdatedFailedVersion] = useState("");  
  const { updateUserStats } = useUpdateUserStats();
  const { user, hasPermission} = useAuth();
  const [prevPnr, setPrevPnr] = useState(null);

  const [editorHeight, setEditorHeight] = useState(() => {
    // Initial height calculation, similar to your content height logic
    const savedHeight = localStorage.getItem('contentHeightUserBemerkungen');
    return savedHeight ? `calc(${parseInt(savedHeight)}px - 20px)` : '440px';
  });

  useEffect(() => {
    if (data.PNr === prevPnr) return; 
    setPrevPnr(data.PNr);

    const initializeQuill = async () => {
      try {
        if (editorRef.current) {
        quillRef.current = new Quill(editorRef.current, {
            theme: 'snow',
            modules: {
              toolbar: '#toolbar',
            },
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true
            },              
          });

          const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
            method: 'POST',
            body: JSON.stringify({
              access_token: sessionStorage.getItem("access_token"),
              fetchLoadBemerkungenAndVersionUser: 1,
              pnr: data.PNr,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const result = await response.json();

          versionRef.current = result.Version
          const quill = quillRef.current;

          //Insert like this so that it is api driven. Quill weiss nun, dass eine history da ist und wir können sie danach clearen.
          //Wenn wir hier nicht clearen, entfernt das erste Strg+z den gesamten Inhalt.
          quill.clipboard.dangerouslyPasteHTML(result.Bemerkungen);
          quillDataRef.current = quill.root.innerHTML; 
          quill.history.clear();

          //Kein Spellcheck
          quill.root.setAttribute("spellcheck", "false");

          let changes = false;
          quill.on('text-change', () => {
            quillDataRef.current = quill.root.innerHTML;
            changes = true;
          });

          const handleBlur = async () => {
            if (changes)
            {
              handleBemerkungenSpeichern();
              changes = false;
            }
          };
          
          const handleDoubleClick = () => {
            let dateTime = moment().format('DD.MM.yyyy - HH:mm:ss');
            let username = user.vollstaendigerName;
            let text = dateTime + " " + username + ':\n\n\n';
            quillRef.current.updateContents([
              { insert: text },
              { retain: quillRef.current.getLength() },
            ]);
            quillRef.current.setSelection(text.length - 2);
          };

          quill.root.addEventListener("dblclick", handleDoubleClick);
          quill.root.addEventListener('blur', handleBlur);

          //Fix, damit beim Click auf die Toolbar nicht automatisch alles deselected wird.
          const toolbar = document.querySelector(".ql-toolbar");
          if (toolbar) {
            toolbar.addEventListener("mousedown", (event) => {
              event.preventDefault();
            });
          }

          setIsloading(false);

          return () => {
            quill.off('text-change');
            quill.root.removeEventListener('blur', handleBlur);
            quill.root.removeEventListener('dblclick', handleDoubleClick);
          };
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setIsloading(false);
      }
    };

    initializeQuill();
  }, [data]);

  const handleBemerkungenSpeichern = () => {
        setshowloading(true);

        updateUserStats(user.vollstaendigerName, "update", "Kunde", "Neue Bemerkung beim Kunden hinterlegt", data.PNr, quillDataRef.current);
    
        fetch(`${fetchdataUrl}fetchdata.php`, {
              method: 'POST',
              body: JSON.stringify({
                  access_token: sessionStorage.getItem("access_token"),
                  fetchUpdateOldPNrDataBeforeChange: 1,
                  Bemerkungen: quillDataRef.current,
                  version: versionRef.current,
                  pnr: data.PNr,
              }),
              headers: {
                  'Content-Type': 'application/json'
              }
              }).then((response) => {
                  return response.json();
              }).then((responseData) => {
             setshowloading(false);

             if (responseData.message === "Update failed") {
                  setopenAlert(true);
                  setupdatedFailedBemerkungen(responseData.Bemerkungen);
                  setupdatedFailedVersion(responseData.version);
              } else {
                  versionRef.current = responseData.version;
                  setopenAlert(false);
              }
        });
  };

  const handleCleanUpBemerkungen = () => {
    setshowloading(true);
    const quillEditor = quillRef.current;
    let quillContent = quillEditor.getContents();
    let regex = /(\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}, [\w\s-]+:|\d{2}\.\d{2}\.\d{4} - \d{2}:\d{2}:\d{2} [\w\s]+:|\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}, [\w\s]+:)/g;
    let newLines = [];
    let firstMatchSkipped = false; // Flag to skip newline insertion for the first match

    quillContent.ops.forEach((op) => {
        if (typeof op.insert !== 'string') {
            newLines.push(op);
            return;
        }

        let text = op.insert;
        let lastIndex = 0;
        let result;

        while ((result = regex.exec(text)) !== null) {
            let matchIndex = result.index;
            let matchText = result[0];

            // Add text before the match
            if (matchIndex > lastIndex) {
                newLines.push({ insert: text.substring(lastIndex, matchIndex) });
            }

            // Check if we need to add newlines before the match
            if (!firstMatchSkipped) {
                // Skip the first match's newline insertion
                firstMatchSkipped = true;
            } else {
                const precedingText = text.substring(lastIndex, matchIndex);
                if (!precedingText.endsWith('\n\n')) {
                    // Ensure exactly two newlines before the match, adjust based on existing newlines
                    const additionalNewlines = precedingText.endsWith('\n') ? '\n' : '\n\n';
                    newLines.push({ insert: additionalNewlines });
                }
            }

            // Add the matched text
            newLines.push({ insert: matchText });

            // Update lastIndex to end of current match
            lastIndex = matchIndex + matchText.length;
        }

        // Add any remaining text after the last match
        if (lastIndex < text.length) {
            newLines.push({ insert: text.substring(lastIndex) });
        }
    });

    quillEditor.setContents({ ops: newLines });
    handleBemerkungenSpeichern();
    setshowloading(false);
  };

  const handleRestoreBemerkungen = () => {
    fetch(`${fetchdataUrl}fetchdata.php`, {
        method: 'POST',
        body: JSON.stringify({
            access_token: sessionStorage.getItem("access_token"),
            fetchRestoreBemerkungen: 1,
            pnr: data.PNr,
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response.json();
    }).then((responseData) => {
        if (responseData.backupBemerkungen) {
            quillDataRef.current = responseData.backupBemerkungen;
            quillRef.current.clipboard.dangerouslyPasteHTML(responseData.backupBemerkungen);

        }
    });    
  };

  const calculateEditorHeight = () => {
    const windowHeight = window.innerHeight - 440;
    const newHeight = `calc(${windowHeight}px - 20px)`;
    setEditorHeight(newHeight);

    localStorage.setItem('contentHeightUserBemerkungen', windowHeight.toString());
  };

  useEffect(() => {
    calculateEditorHeight(); 

    window.addEventListener('resize', calculateEditorHeight); 

    return () => {
      window.removeEventListener('resize', calculateEditorHeight); 
    };
  }, []);

	return (
    <div>
    {isloading ?     
    <LinearProgress /> 
    : null}
    <Box sx={{ width: '100%',}}>
              <Collapse in={openAlert}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setopenAlert(false);
                      }}
                    >
                      <CloseSharp fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  <b>Speichern fehlgeschlagen.</b> Der Kunde wurde zeitgleich von einem anderen Benutzer bearbeitet.<br></br> Gehe wie folgt vor:<br></br>
                  1. Kopiere deine Bemerkung<br></br>
                  2. <Link onClick={() => {
                    quillRef.current.root.innerHTML = updatedFailedBemerkungen;
                    quillDataRef.current = updatedFailedBemerkungen;
                    versionRef.current = updatedFailedVersion;
                  }}                             
                  sx={{cursor: "pointer"}}>Hier klicken, um die neuen Daten aus der Datenbank zu laden</Link><br></br>                  
                  3. Füge die Bemerkung erneut ein und speichere die Daten.
                </Alert>
              </Collapse>
    </Box>
    <LinearProgress sx={{marginTop: "5px", display: showloading ? "block" : "none"}}></LinearProgress>
    <CustomToolbar handleSaveButtonClick={handleBemerkungenSpeichern} handleCleanUpButtonClick={handleCleanUpBemerkungen} handleRestoreButtonClick={handleRestoreBemerkungen} showloading={showloading}/>
    <div ref={editorRef} style={{ display: isloading ? 'none' : 'block', height: editorHeight }} />
  </div>
  )
};

export default BemerkungenKunde;



















// import { useAuth } from "../hooks/useAuth";
// import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { Link, Box, Collapse, Alert, AlertTitle, IconButton, Divider, LinearProgress, Card, Button, CardContent, CircularProgress, Stack, Typography} from '@mui/material';
// import { RestoreSharp, CloseSharp, SaveSharp, CleaningServicesSharp} from "@mui/icons-material";
// import ReactQuill, { Quill } from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import moment from 'moment';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateQuillValue, updatejustoldPnr, setloading} from '../hooks/reduxactions';
// import Resizer from "react-image-file-resizer";
// import { fetchdataUrl } from '../A-config/config';
// import { useUpdateUserStats, useGetUserStats } from '../hooks/updateuserstats';

// const CustomToolbar = ({ handleSaveButtonClick, handleCleanUpButtonClick, handleRestoreButtonClick, showloading}) => {
//   return (
//     <div id="toolbar" style={{display: "flex"}}>
//       <span className="ql-formats">
//         <button className="ql-bold" />
//         <button className="ql-italic" />
//         <button className="ql-underline" />
//         <button className="ql-strike" />
//         <select className="ql-color" />
//         <select className="ql-background" />
//       </span>

//       <span className="ql-formats">
//         <button className="ql-header" value="1"></button>
//         <button className="ql-header" value="2"></button>
//         <button className="ql-blockquote" />
//         <button className="ql-code-block" />
//       </span>

//       <span className="ql-formats">
//         <button className="ql-list" value="ordered" />
//         <button className="ql-list" value="bullet" />
//         <button className="ql-indent" value="+1" />
//         <button className="ql-indent" value="-1" />
//       </span>

//       <span className="ql-formats">
//       <button className="ql-image"/>
//       <button className="ql-clean"/>
//       <button title="Speichern"
//           onClick={handleSaveButtonClick}>
//           <SaveSharp></SaveSharp>
//       </button>
//       <button title="Formattierung verschönern"
//           onClick={handleCleanUpButtonClick}>
//           <CleaningServicesSharp></CleaningServicesSharp>
//       </button>
//       <button title="Letzte gespeicherte Bemerkungen wiederherstellen"
//           onClick={handleRestoreButtonClick}>
//           <RestoreSharp></RestoreSharp>
//       </button>
//       </span>
//       {/* <Box sx={{display: "flex", alignItems: "center", visibility: showloading ? "visible": "collapse"}}>
//         <CircularProgress size={20} sx={{marginRight: "8px"}}></CircularProgress>
//         {/* <Typography variant="caption">Die Daten werden gespeichert...</Typography>
//       </Box> */}
//     </div>
//   );
// };

// const debounce = (func, delay) => {
//   let timeout;
//   return (...args) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       func.apply(this, args);
//     }, delay);
//   };
// };

// export const BemerkungenKunde = ({data, onUpdateVersion}) => {
//   const { updateUserStats } = useUpdateUserStats();
//   const { user, hasPermission} = useAuth();
//   const quillRef = useRef(null);
//   const dispatch = useDispatch();
//   const [showloading, setshowloading] = useState(false)
//   const [isloading, setIsloading] = useState(true);
//   const quillDataRef = useRef();
//   const [openAlert, setopenAlert] = React.useState(false);
//   const [updatedFailedBemerkungen, setupdatedFailedBemerkungen] = useState("");
//   const [updatedFailedVersion, setupdatedFailedVersion] = useState("");  

//   const [editorHeight, setEditorHeight] = useState(() => {
//     // Initial height calculation, similar to your content height logic
//     const savedHeight = localStorage.getItem('contentHeightUserBemerkungen');
//     return savedHeight ? `calc(${parseInt(savedHeight)}px - 20px)` : '450px';
//   });

//   const handleBlur = (event, source) =>{
//     if (source === 'user')
//     {
//       handleBemerkungenSpeichern();
//     }
//   }
//    // Existing logic to handle quill editor creation
//    const handleQuillCreate = (quill) => {
//     quillRef.current = quill;
//     if (quillRef.current) {
//       // Directly access the DOM node to apply the height
//       const editor = quillRef.current.getEditor().root;
//       editor.style.height = editorHeight;
//     }
//   };

//   const calculateEditorHeight = () => {
//     const windowHeight = window.innerHeight - 450;
//     const newHeight = `calc(${windowHeight}px - 20px)`;
//     setEditorHeight(newHeight);

//     localStorage.setItem('contentHeightUserBemerkungen', windowHeight.toString());

//     // Apply the new height immediately after calculation
//     if (quillRef.current) {
//       const editor = quillRef.current.getEditor().root;
//       editor.style.height = newHeight;
//     }
//   };

//   useEffect(() => {
//     calculateEditorHeight(); // Call on initial load

//     window.addEventListener('resize', calculateEditorHeight); // Adjust on window resize

//     return () => {
//       window.removeEventListener('resize', calculateEditorHeight); // Cleanup listener
//     };
//   }, []);


//   useEffect(() => {
//     fetch(`${fetchdataUrl}fetchdata.php`, {
//       method: 'POST',
//       body: JSON.stringify({
//           access_token: sessionStorage.getItem("access_token"),
//           fetchLoadBemerkungenUser: 1,
//           pnr: data.PNr,
//       }),
//       headers: {
//           'Content-Type': 'application/json'
//       }
//       })
//       .then(response => response.json())
//       .then(data => {      
//         quillDataRef.current = data;
//         setIsloading(false);
//     });
//   }, [data]);

//   const handleBemerkungenSpeichern = () => {

//     setshowloading(true);
//     updateUserStats(user.vollstaendigerName, "update", "Kunde", "Neue Bemerkung beim Kunden hinterlegt", data.PNr, "Bemerkung: " + quillDataRef.current);

//     fetch(`${fetchdataUrl}fetchdata.php`, {
//           method: 'POST',
//           body: JSON.stringify({
//               access_token: sessionStorage.getItem("access_token"),
//               fetchUpdateOldPNrDataBeforeChange: 1,
//               Bemerkungen: quillDataRef.current,
//               version: data.version,
//               pnr: data.PNr,
//           }),
//           headers: {
//               'Content-Type': 'application/json'
//           }
//           }).then((response) => {
//               return response.json();
//           }).then((responseData) => {
//          setshowloading(false);
//          if (responseData.message === "Update failed") {
//               setopenAlert(true);
//               setupdatedFailedBemerkungen(responseData.Bemerkungen);
//               setupdatedFailedVersion(responseData.version);
//           } else {
//               onUpdateVersion(responseData.version);
//               setopenAlert(false);
//           }
//     });
//   };

//   const debouncedSave = debounce(handleBemerkungenSpeichern, 500);

//   const handleQuillChange = (value) => {
//       quillDataRef.current = value;
//       // debouncedSave();
//   }; 

//   const handleRestoreBemerkungen = () => {
//     setshowloading(true);

//     fetch(`${fetchdataUrl}fetchdata.php`, {
//         method: 'POST',
//         body: JSON.stringify({
//             access_token: sessionStorage.getItem("access_token"),
//             fetchRestoreBemerkungen: 1,
//             pnr: data.PNr,
//         }),
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     }).then((response) => {
//         return response.json();
//     }).then((responseData) => {
//         setshowloading(false);

//         if (responseData.backupBemerkungen) {
//             quillDataRef.current = responseData.backupBemerkungen;
//         }
//     });    
//   };

//   const handleCleanUpBemerkungen = () => {
//     setshowloading(true);

//     let quillEditor = quillRef.current.getEditor();
//     let quillContent = quillEditor.getContents();
//     let lines = quillContent.ops;
//     let regex = /^(\d{2}\.\d{2}\.\d{2,4} [\w\/]+|\d{2}\.\d{2}\.\d{4} - \d{2}:\d{2}:\d{2} [\w\s]+:|\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}, [\w\s]+:)/;
//     let newLines = [];
//     let insertNewLine = false;

//     lines.forEach((line, index) => {
//         let lineText = typeof line.insert === 'string' ? line.insert : '';
//         let allLines = lineText.split('\n');

//         allLines.forEach((subline, subindex) => {
//             let match = subline.match(regex);
//             if (match) {
//                 // Check if previous line is not empty before adding a newline
//                 if (insertNewLine && subindex > 0 && allLines[subindex - 1] !== '') {
//                     newLines.push({ insert: '\n' });
//                 }
//                 insertNewLine = true;
//             }
//             newLines.push({ insert: subline });
//             if (subindex !== allLines.length - 1) {
//                 newLines.push({ insert: '\n' });
//             }
//         });
//     });

//     quillEditor.setContents({ops: newLines});
//     setshowloading(false);
//   };


//   //Workaround - damit die Tabstops auch bleiben.
//   // useEffect(() => {
//   //   var Block = Quill.import('blots/block');
//   //   Block.tagName = 'DIV';
//   //   Block.className = 'pre';
//   //   Quill.register(Block, true);
//   // }, []);

//     const resizeFile = (file) =>
//     new Promise((resolve) => {
//         Resizer.imageFileResizer(
//         file,
//         200,
//         200,
//         "JPEG",
//         100,
//         0,
//         (uri) => {
//             resolve(uri);
//         },
//         "base64"
//         );
//     });

//     const handleImageInsert = () => {
//     return function() {
//         var input = document.createElement('input');
//         input.setAttribute('type', 'file');
//         input.setAttribute('accept', 'image/*');
//         input.click();
//         input.onchange = async function() {
//         var file = input.files[0];
//         try {
//             const image = await resizeFile(file);
//             var cursorPosition = quillRef.current.getEditor().getSelection().index;
//             quillRef.current.getEditor().insertEmbed(cursorPosition, 'image', image);
//             quillRef.current.getEditor().setSelection(cursorPosition + 1);
//         } catch (err) {}
//         };
//     };
//     };

//     useEffect(() => {
//     if (quillRef.current) {
//         var quill = quillRef.current.editor;
//         var toolbar = quill.getModule('toolbar');
//         toolbar.addHandler('image', handleImageInsert());
//     }
//     }, [quillRef]);

//     useEffect(() => {
//       // Disable spellcheck as component is mounted
//       quillRef.current?.editor.root.setAttribute("spellcheck", "false");

//     //Fix, damit beim Click auf die Toolbar nicht automatisch alles deselected wird.
//       const toolbar = document.querySelector(".ql-toolbar");
//       if (toolbar) {
//         toolbar.addEventListener("mousedown", (event) => {
//           event.preventDefault();
//         });
//       }

//       const bemerkungenEditor = document.getElementById("bemerkungeneditor");
//       if (bemerkungenEditor) {
//         const handleDoubleClick = () => {
//           let dateTime = moment().format('DD.MM.yyyy - HH:mm:ss');
//           let username = user.vollstaendigerName;
//           let text = dateTime + "  " + username + ':\n\n\n';
//           let existingContent = quillRef.current.getEditor().getContents();
//           let newContent = {
//             ops: [{ insert: text }, ...existingContent.ops]
//           };
//           quillRef.current.getEditor().setContents(newContent);
//           quillRef.current.getEditor().setSelection(text.length -2);
//         };
  
//         bemerkungenEditor.addEventListener("dblclick", handleDoubleClick);
  
//         return () => {
//           bemerkungenEditor.removeEventListener("dblclick", handleDoubleClick);
//         };
//       }
//     }, [isloading]);

//   const modules = {
//     toolbar: {
//       container: "#toolbar",
//     },
//     clipboard: {
//       matchVisual: false
//     },
//   };

//   if (isloading) {
//     return <LinearProgress/>;
//   } else {
//   return (
//        <div>
//           <Box sx={{ width: '100%',}}>
//               <Collapse in={openAlert}>
//                 <Alert
//                   severity="error"
//                   action={
//                     <IconButton
//                       aria-label="close"
//                       color="inherit"
//                       size="small"
//                       onClick={() => {
//                         setopenAlert(false);
//                       }}
//                     >
//                       <CloseSharp fontSize="inherit" />
//                     </IconButton>
//                   }
//                   sx={{ mb: 2 }}
//                 >
//                   <b>Speichern fehlgeschlagen!</b> Der Kunde wurde zeitgleich von einem anderen Benutzer bearbeitet.<br></br> Gehe wie folgt vor:<br></br>
//                   1. Kopiere deine Bemerkung<br></br>
//                   2. <Link onClick={() => {
//                     quillDataRef.current = updatedFailedBemerkungen;
//                     onUpdateVersion(updatedFailedVersion);
//                   }}                             
//                   sx={{cursor: "pointer"}}>Hier klicken, um die neuen Daten aus der Datenbank zu laden</Link><br></br>                  
//                   3. Füge die Bemerkung erneut ein und speichere die Daten.
//                 </Alert>
//               </Collapse>
//             </Box>
//             <LinearProgress sx={{marginTop: "5px", display: showloading ? "block" : "none"}}></LinearProgress>
//             <div style={{marginBottom: "10px",
//               boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
//               borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px" }}>
//               <CustomToolbar handleSaveButtonClick={handleBemerkungenSpeichern} handleCleanUpButtonClick={handleCleanUpBemerkungen} handleRestoreButtonClick={handleRestoreBemerkungen} showloading={showloading}/>
//               <ReactQuill theme="snow"
//                 ref={handleQuillCreate}
//                 value={quillDataRef.current}
//                 modules={modules}
//                 placeholder={"Bemerkungen hier eintragen..."}
//                 id="bemerkungeneditor"
//                 onChange={handleQuillChange}
//                 onBlur={handleBlur}
//                 >
//               </ReactQuill>
//             </div>
//     </div>
//   );
//   }

// };



