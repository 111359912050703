import React, { useRef, useEffect, useState } from 'react';
import { Card, Badge, TextField, Stack, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Link, Divider, Popover, ToggleButton, ToggleButtonGroup, IconButton, Portal, Button, Tooltip, Typography, LinearProgress } from '@mui/material';
import { Celebration, Favorite, SentimentNeutral, Email, PrintSharp, Star, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, ColorLens, FormatItalic, FormatBoldSharp, FormatUnderlinedSharp, CheckBox } from '@mui/icons-material'; 
import { fetchdataUrl } from '../A-config/config';
import moment from "moment";
import * as XLSX from 'xlsx';
import VertriebsaktionenServerSideWithQueryProvider from './VertriebsaktionenServerSide';

export const VertriebsaktionenPage = () => {

  return (
    <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
      <VertriebsaktionenServerSideWithQueryProvider></VertriebsaktionenServerSideWithQueryProvider>    
    </Card>  
  );
};