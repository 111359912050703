import React, { useState, useEffect, useRef } from 'react';
import {Box, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, FormControl, Select, MenuItem, CircularProgress, Skeleton, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating, TextField, Alert,Popover, Card, Badge, CardMedia, Typography, CardContent, Button, CardActions, CardHeader, Avatar, Tabs, Tab, Grid, Divider, Stack, Link, Chip, Tooltip, IconButton} from "@mui/material";
import { UpdateRounded, HailRounded, BoyRounded, GirlRounded, MaleRounded, FemaleRounded, FamilyRestroomRounded, WorkHistoryRounded, ChildCareRounded, MailRounded, PhoneIphoneRounded, HeadphonesRounded, PhoneRounded, ContactEmergencyRounded, TopicRounded, ContactPageSharp, ContactPhoneSharp, CakeSharp, PersonSearchSharp, AssessmentRounded, RestartAltSharp, ElderlySharp, CloseSharp, ChangeCircle, BadgeSharp, Verified, Phone, Email, ContactMail, MobileFriendly, SupervisedUserCircle, History, ManageAccounts, Event, AccountCircle, Delete, DoneSharp, UpdateSharp, AssignmentRounded, PersonAddRounded, DeleteSharp, PrintSharp} from "@mui/icons-material";
import { useAuth} from "../hooks/useAuth";
import moment from "moment";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { SchadenübersichtPageContent } from './SchadenübersichtPageContent';
import { SchadenBemerkungen } from "./SchadenBemerkungen";
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

export const SchadenübersichtPage = () => {
  const location = useLocation();
  const [TransferSchadendaten, setTransferSchadendaten] = useState();
  const SchadenDaten = location.state?.data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CurrentselectedSchaden = useSelector((state) => state.currentselectedschaden);
  const [isloading, setisloading] = useState(true)
  const componentRef = useRef();

  useEffect(() => {
    setisloading(true)
    if (SchadenDaten)
    {
      setTransferSchadendaten(SchadenDaten)
      dispatch({ type: "SET_CURRENTSELECTEDSCHADEN", payload: SchadenDaten });                           
    } else if (CurrentselectedSchaden !== null)
    {
        setTransferSchadendaten(CurrentselectedSchaden)      
    } 

    setTimeout(() => {
      setisloading(false)
    }, 500);

  }, [SchadenDaten]);  

  const handleSchadenAkteDrucken = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Zeiterfassung'
  });

  return (
    TransferSchadendaten ? (
      !isloading ? (
        <div ref={componentRef}>
          <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, position: 'relative' }}>  
                <Button 
                    startIcon={<PrintSharp></PrintSharp>}
                    onClick={handleSchadenAkteDrucken} 
                    style={{ 
                        position: 'absolute', 
                        top: '180px', 
                        left: '18px',
                        zIndex: 1000 
                    }}
                >
                    Drucken
                </Button>
                <SchadenübersichtPageContent alleschadendaten={TransferSchadendaten} />
            </Card>
          <Card sx={{marginTop: "10px"}}>
            <SchadenBemerkungen data={TransferSchadendaten}/>   
          </Card>     
        </div>
      ) : (
        <Card style={{borderTopRightRadius: "0px", borderTopLeftRadius: 0}}>
        <Stack direction="column">
         <Alert icon={false} color="info">Die Schadendaten werden geladen...<br></br>
         <b>Sollte diese Nachricht mehrere Sekunden angezeigt werden, lade den Schaden
         bitte erneut.</b>        
         </Alert>         
        <LinearProgress></LinearProgress>
         </Stack>
            <div style={{display: "flex", marginTop: "30px", marginLeft: "10px"}}>
              <Skeleton variant="circular" sx={{mt: 2}} width={65} height={65} />
              <Stack direction="column" sx={{ml: 2}}>
                <Skeleton variant="text" width={105} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton variant="text" width={217} height={20} />
                <Stack direction="row">
                <Skeleton variant="text" width={105} height={20} />
                <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
                </Stack>
                <Skeleton sx={{mt: 0.5}} variant="rounded" width={217} height={60} />
              </Stack>
           </div>
              <Divider sx={{mt: 3}}></Divider>
              <Skeleton sx={{mt: 0.5, ml: 2, mt: 3, mb: 3}} variant="rounded" width={650} height={300} />
        </Card>
      )
    ) : (
      <Card style={{borderTopRightRadius: "0px", borderTopLeftRadius: 0}}>
        <Stack direction="column">
          <Alert variant='standard' icon={<PersonSearchSharp/>} severity="info">
            Es wurde noch kein Schaden ausgewählt. Bitte wähle über die interne Schadensuche oder über den Partner einen Schaden aus.
            <span> <Link onClick={() => navigate("/dashboard/KundenSuchePage")} sx={{color: "#014361", cursor: "pointer"}}><b>Hier geht es zur Suche.</b></Link></span>
          </Alert>
        </Stack>
        <div style={{display: "flex", marginTop: "30px", marginLeft: "10px"}}>
          <Skeleton variant="circular" sx={{mt: 2}} width={65} height={65} />
          <Stack direction="column" sx={{ml: 2}}>
            <Skeleton variant="text" width={105} height={20} />
            <Stack direction="row">
              <Skeleton variant="text" width={105} height={20} />
              <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
            </Stack>
            <Skeleton variant="text" width={217} height={20} />
            <Stack direction="row">
              <Skeleton variant="text" width={105} height={20} />
              <Skeleton sx={{ml: 1}} variant="text" width={105} height={20} />
            </Stack>
            <Skeleton sx={{mt: 0.5}} variant="rounded" width={217} height={60} />
          </Stack>
        </div>
        <Divider sx={{mt: 3}} />
        <Skeleton sx={{mt: 0.5, ml: 2, mt: 3, mb: 3}} variant="rounded" width={650} height={300} />
      </Card>
    )
  );  
};