import { legacy_createStore } from 'redux';

const initialState = {
  pnrprocessed: null,
  eventdata: null,
  axaDbvColor: "#2e3192",
  isModalOpen: false,
  isModalSchadenOpen: false,
  isConfirmDialogBeratungsdokuOpen: false,
  loadingSchaden: true,
  visitedCustomers: [],
  visitedSchaden: [],
  globalAppBarMessage: '',
  currentselectedschaden: null,
  isProcessing: false,
  refreshDataAufgaben: false 
};

const rootReducer = (state = initialState, action) => {

  switch (action.type) {
    
    case 'UPDATE_PNR':
      return {
        ...state,
        pnrprocessed: action.pnr
      };

    case 'UPDATE_MY_OLD_PARTNERNUMMER':
      return {
        ...state,
        updateoldpnr: action.myPNr
    };
    
    case 'UPDATE_EVENTDATA':
      return {
        ...state,
        eventdata: action.data
      };
    case 'SET_USERSTATUS':
        return {
          ...state,
          currentuserstatus: action.data
    };

    case 'SET_CURRENTSELECTEDSCHADEN':
      return {
        ...state,
        currentselectedschaden: action.payload
    };

    case 'SET_APP_BAR_HEIGHT':
      return {
        ...state,
        Appbarheight: action.payload
    };

    case 'SET_CARD_HEIGHT':
      return {
        ...state,
        Kundencardheight: action.payload
    };

    case 'UPDATE_USERSTATUSES':
      return {
        ...state,
        refreshuserstatus: !state.refreshuserstatus
      };
    case "UPDATE_QUILL_VALUE":
      return {
        ...state,
        quillValue: action.Bemerkungen,
      };
    case 'UPDATE_ZEITERFASSUNG_GRUNDDATEN':
        return {
          ...state,
          updateZeiterfassung: action.payload,
      };  
    case 'UPDATE_SETLOADING':
      return {
        ...state,
        loading: action.loading
      };      
    case 'UPDATE_SETLOADING_SCHADEN':
      return {
        ...state,
        loadingSchaden: action.loading
      };   

    case 'REFRESH_DATAAUFGABEN':
        return {
          ...state,
          refreshDataAufgaben: !state.refreshDataAufgaben
    };

    case 'SET_SCHADENÄNDERUNGCOUNT':
      return {
        ...state,
        änderungcount: action.payload
    };

    case 'SET_AUFGABENCOUNT':
      return {
        ...state,
        aufgabencount: action.payload
    };

    case 'SET_BADGENUMBER':
      return {
        ...state,
        badgecount: action.payload
    };

    case 'REFRESH_DATAKUNDENSCHAEDEN':
      return {
        ...state,
        refreshDataKundenSchaeden: !state.refreshDataKundenSchaeden
    };

    case 'REFRESH_GLOBALAPPBARMESSAGE':
      return {
        ...state,
        globalAppBarMessage: action.globalAppBarMessage     
    };
    
    case 'SET_AXA_DBV_COLOR':
      return {
        ...state,
        axaDbvColor: action.payload
    };

    case 'SET_SERVERINFOMESSAGE':
      return {
        ...state,
        servermessage: action.payload
    };

    case 'TOGGLE_MODAL_BERATUNGSDOKU':
      return {
        ...state,
        isModalOpen: action.payload
    };

    case 'TOGGLE_MODAL_SCHADEN':
      return {
        ...state,
        isModalSchadenOpen: action.payload
    };

    case 'TOGGLE_INFOBAR_APPBAR':
      return {
        ...state,
        isInfoBarOpen: action.payload
    };

    case 'TOGGLE_GlobalMessage':
      return {
        ...state,
        isGlobalMessageOpen: action.payload
    };

    case 'TOGGLE_CONFIRMDIALOG_BERATUNGSDOKU':
      return {
        ...state,
        isConfirmDialogBeratungsdokuOpen: action.payload
    };
    case 'ADD_VISITED_CUSTOMER':
      // Remove customer if it already exists in the state
      const filteredCustomers = state.visitedCustomers.filter(
        (customer) => customer.PNr !== action.payload.PNr
      );
      // Add the customer to the top of the list
      return {
        ...state,
        visitedCustomers: [action.payload, ...filteredCustomers],
    };    
    case 'ADD_VISITED_SCHADEN':
      // Remove customer if it already exists in the state
      const filteredCustomersSchaden = state.visitedSchaden.filter(
        (visitedcustomerSchaden) => visitedcustomerSchaden.ID !== action.payload.ID
      );
      // Add the customer to the top of the list
      return {
        ...state,
        visitedSchaden: [action.payload, ...filteredCustomersSchaden],
    };    
    default:
      return state;
  }
};

const store = legacy_createStore(rootReducer);

export default store;