import React, { useEffect, useState, useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFullScreenButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
} from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { fetchdataUrl } from '../A-config/config';
import { Popover, Box, Tooltip, IconButton } from '@mui/material';
import { RefreshSharp, DateRangeSharp } from '@mui/icons-material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { DateRangePickerCustom } from '../components/DateRangePickerCustom';
import LogInfoModal from "../modals/LogInfoModal";
import * as XLSX from 'xlsx';
import { useQuery, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import { useNavigation } from '../hooks/useNavigateFunction';

const queryClient = new QueryClient();

const BenutzerlogsServerside = () => {
    const { user, hasPermission } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { navigateToCustomer } = useNavigation();
    const [openPopoverDatePicker, setopenPopoverDatePicker] = useState(false);
    const [anchorPopoverDate, setanchorPopoverDate] = useState(null);
    const [openModalLogInfo, setopenModalLogInfo] = useState(false);
    const [rowDATA, setrowDATA] = useState('');

    const [dateRange, setDateRange] = useState([{
        startDate: moment(1950, 'year').toDate(),
        endDate: moment(),
        key: 'selection'
    }]);

    const handleDateRangeChange = (range) => {
        setDateRange([range]);
    };

    const [columnVisibilityLogs, setcolumnVisibilityLogs] = useState(() => {
        try {
            const visibilityString = localStorage.getItem('columnVisibilityLogs');
            return JSON.parse(visibilityString) || { ID: false };
        } catch (e) {
            console.error(e);
            return { ID: false };
        }
    });

    useEffect(() => {
        localStorage.setItem('columnVisibilityLogs', JSON.stringify(columnVisibilityLogs));
    }, [columnVisibilityLogs]);

    const handleColumnVisibilityChangeLogs = (updater) => {
        setcolumnVisibilityLogs(updater);
    };

    const defaultColumnWidths = 90;
    let storageItemLogs = localStorage.getItem('columnWidthsLogs');
    let columnWidthsFromLocalStorageLogs;

    if (storageItemLogs === "undefined" || !storageItemLogs) {
        columnWidthsFromLocalStorageLogs = defaultColumnWidths;
    } else {
        columnWidthsFromLocalStorageLogs = JSON.parse(storageItemLogs);
    }

    const [columnSizingStateLogs, setcolumnSizingStateLogs] = useState(columnWidthsFromLocalStorageLogs);

    const handleColumnSizingChange = (newColumnSizingState) => {
        const newState = newColumnSizingState(columnSizingStateLogs);
        localStorage.setItem('columnWidthsLogs', JSON.stringify(newState));
        setcolumnSizingStateLogs(newState);
    };

    const [contentHeight, setContentHeight] = useState(() => {
        const savedHeight = localStorage.getItem('contentHeightUserStart');
        return savedHeight ? parseInt(savedHeight) : 230;
    });

    const calculateContentHeightAndPageSize = () => {
        const windowHeight = window.innerHeight - 450;
        localStorage.setItem('contentHeightUserStart', windowHeight.toString());
        setContentHeight(windowHeight);
    };

    useEffect(() => {
        calculateContentHeightAndPageSize();
        window.addEventListener('resize', calculateContentHeightAndPageSize);
        return () => {
            window.removeEventListener('resize', calculateContentHeightAndPageSize);
        };
    }, []);

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });

    const {
        data: { data = [], meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            const startDate = dateRange[0].startDate ? moment(dateRange[0].startDate).format('YYYY-MM-DD') : null;
            const endDate = dateRange[0].endDate ? moment(dateRange[0].endDate).format('YYYY-MM-DD') : null;

            const requestBody = {
                access_token: sessionStorage.getItem("access_token"),
                fetchAlleLogsServerSide: 1,
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters,
                globalFilter: globalFilter,
                sorting: sorting,
                dateRangeStart: startDate,
                dateRangeEnd: endDate,
            };

            const response = await fetch(`${fetchdataUrl}fetchdata.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const json = await response.json();
            return json;
        },
        placeholderData: keepPreviousData,
    });

    const columns = useMemo(() => [
        { accessorKey: 'ID', header: 'ID' },
        {
            header: 'Datum',
            accessorKey: 'timestamp',
            enableResizing: false,
            minSize: 80,
            maxSize: 90,
            size: 80,
            Cell: ({ cell }) => {
                const newdate = moment(new Date(cell.getValue())).format("DD.MM.YYYY - HH:mm:ss");
                return <span>{newdate}</span>;
            },
        },
        {
            header: 'Benutzer',
            accessorKey: 'user_name',
            minSize: 90,
            size: 90,
        },
        {
            header: 'Beschreibung',
            accessorKey: 'description',
            minSize: 40,
            size: 90,
        },
        {
            header: 'Zusätzliche Infos',
            accessorKey: 'infos',
            minSize: 40,
            size: 90,
        },
        {
            header: 'Partnernummer',
            accessorKey: 'partnernummer',
            minSize: 40,
            size: 90,
        },
    ], []);

    const tableInstance = useMaterialReactTable({
        columns,
        data,
        state: {
            columnSizing: columnSizingStateLogs,
            columnVisibility: columnVisibilityLogs,
            isLoading,
            isError,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
        },
        initialState: {
            density: 'compact',
            columnSizing: columnSizingStateLogs,
            columnVisibility: columnVisibilityLogs,
        },
        onColumnVisibilityChange: handleColumnVisibilityChangeLogs,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableRowSelection: false,
        enableStickyHeader: true,
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        layoutMode: 'grid',
        onColumnSizingChange: handleColumnSizingChange,
        manualFilters: true,
        manualPagination: true,
        manualSorting: true,
        rowCount: meta?.totalRowCount ?? 0,
        muiTableHeadRowProps: {
            sx: { justifyContent: "center" },
        },
        muiTableBodyCellProps: {
            style: { overflow: "hidden", fontSize: "13px" },
        },
        muiTableProps: {
            sx: { tableLayout: 'fixed' },
        },
        muiTablePaperProps: {
            elevation: 0,
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                setrowDATA(row.original);
                setopenModalLogInfo(true);
            }
        }),
        muiTableContainerProps: ({ table }) => ({
            sx: { height: table.getState().isFullScreen ? '100%' : contentHeight, minHeight: '230px' }
        }),
        localization: MRT_Localization_DE,
        renderToolbarInternalActions: () => (
            <Box>
                <MRT_ToggleGlobalFilterButton table={tableInstance} />
                <Tooltip title="Datum auswählen">
                    <IconButton
                        onClick={(event) => {
                            setopenPopoverDatePicker(true);
                            setanchorPopoverDate(event.currentTarget);
                        }}>
                        <DateRangeSharp />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={openPopoverDatePicker}
                    anchorEl={anchorPopoverDate}
                    onClose={() => { setopenPopoverDatePicker(false); }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div>
                        <DateRangePickerCustom dateRange={dateRange} onDateRangeChange={handleDateRangeChange} />
                    </div>
                </Popover>
                <Tooltip title="Daten neu laden">
                    <IconButton onClick={() => refetch()}>
                        <RefreshSharp />
                    </IconButton>
                </Tooltip>
                <MRT_ShowHideColumnsButton table={tableInstance} />
                <MRT_ToggleFiltersButton table={tableInstance} />
                <MRT_ToggleFullScreenButton table={tableInstance} />
            </Box>
        )
    });

    return (
        <div>
            <MaterialReactTable table={tableInstance} />
            <LogInfoModal openModalLogInfo={openModalLogInfo} setopenModalLogInfo={setopenModalLogInfo} rowDATA={rowDATA}></LogInfoModal>
        </div>
    );
};

const BenutzerlogsServersideWithProvider = () => (
    <QueryClientProvider client={queryClient}>
        <BenutzerlogsServerside />
    </QueryClientProvider>
);

export default BenutzerlogsServersideWithProvider;
